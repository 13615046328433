import { Badge, Button, Checkbox, message, Modal, Steps } from 'antd';
import { APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE } from '@/constants/employee';
import './ExitApprovalReviewModal.scss';
import { useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { EXIT_APPROVAL_PROCESS_STATUS, EXIT_PROCESS_TAB_STATUS } from '@/constants/status';
import { IExitApprovalListDetail } from '@/models/employee';
import { fetchExitApprovalList, fetchExitTotal } from '@/store/hroInfo';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/models/store';
import { reviewExitApproval } from '@/api/exitApi';
import { isUserHasPermissions } from '@/store/userInfo';
import { EXIT_APPROVAL_LEAVE, EXIT_APPROVAL_OFFICE_HRO } from '@/constants/permission';

interface ReviewApprovalModalProps {
  isModalOpen: boolean,
  closeModal: (needRefreshData: boolean) => void,
  templateType: APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE,
  record?: IExitApprovalListDetail,
}

const ExitApprovalReviewModal = ({ isModalOpen, closeModal, templateType, record }: ReviewApprovalModalProps) => {
  const [ value, setValue ] = useState<string[]>([]);
  const [ current, setCurrent ] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();


  const hasExitApprovalLeavePermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_LEAVE ]));
  const hasExitApprovalOfficeHroPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_OFFICE_HRO ]));
  const hasExitPowerHroPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_LEAVE, EXIT_APPROVAL_OFFICE_HRO ]));

  useEffect(() => {
    setCurrent(record?.exitApprovalLeaveHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED ? 0 : 1);
  }, [ record ]);

  const handleCancel = async () => {
    setValue([]);
    closeModal(false);
  };
  
  const getReviewProcessId = () => {
    switch (templateType) {
    case APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_ADMIN:
      return record?.exitApprovalAdminProcessId;
    case APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.EXIT_BUSINESS_TEAM_APPROVER:
      return record?.exitApprovalBusinessTeamProcessId;
    case APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.WORKSPACE:
      return record?.exitApprovalWorkSpaceProcessId;
    case APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.LEAVE_HRO:
      return record?.exitApprovalLeaveHroProcessId;
    case APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_HRO:
      return record?.exitApprovalOfficeHroProcessId;
    default:
      return '';
    }
  };

  const handleChecked = (checkValueType: CheckboxValueType[]) => {
    setValue(checkValueType as string[]);
  };
  
  const handleOk = async () => {
    reviewExitApproval(getReviewProcessId()).then(() => {
      setValue([]);
      closeModal(false);
      message.success('审核已通过');
      dispatch(fetchExitApprovalList({ page: 0, size: 20 }));
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS }));
    }, () => {
      closeModal(true);
      message.error('审核失败');
    });

  };

  const adminCheckBoxGroupItems = [
    {
      value: 'accessControlCard',
      label: '员工已退还门禁卡',
    },
    {
      value: 'companyBookAndKey',
      label: '员工已退还书籍、锁柜钥匙等公司物品',
    }
  ];

  const businessTeamCheckBoxGroupItems = [
    {
      value: 'workHandover',
      label: '员工已完成工作交接',
    },
    {
      value: 'uKeyAndSoftware',
      label: '员工已归还所属项目的U Key和相关软件等',
    },
    {
      value: 'timeCard',
      label: '员工Timecard已填写到最后工作日',
    },
    {
      value: 'wechat',
      label: '员工退出各种微信工作群',
    }
  ];

  const workspaceCheckBoxItems = [
    {
      value: 'deviceData',
      label: '用户归还的设备数据已抹除',
    },
    {
      value: 'assets',
      label: '用户已归还名下资产，且归还的资产符合归还条件',
    }
  ];

  const checkBoxGroupItems = {
    [APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_ADMIN]: adminCheckBoxGroupItems,
    [APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.EXIT_BUSINESS_TEAM_APPROVER]: businessTeamCheckBoxGroupItems,
    [APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.WORKSPACE]: workspaceCheckBoxItems,
  };

  const stepOnChange = (value: number) => {
    setCurrent(value);
  };

  const handleHroReviewOkBtnClick = () => {
    let reviewProcessId = getReviewProcessId();
    if (hasExitPowerHroPermission && record?.exitApprovalOfficeHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED) {
      reviewProcessId = record?.exitApprovalOfficeHroProcessId;
    }
    reviewExitApproval(reviewProcessId).then(() => {
      setCurrent(current + 1);
      closeModal(false);
      const messageContent = templateType === APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_HRO ? '该员工已完成离职流转' : '审核已通过';
      message.success(messageContent);
      dispatch(fetchExitApprovalList({ page: 0, size: 20 }));
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS }));
    }, () => {
      closeModal(true);
      message.error('审核失败');
    });
  };

  const hroReviewBtn = (step: number, withBadge: boolean, disabled: boolean, hidden: boolean) => {
    return <div className={`hro-review-btn${hidden ? ' hidden' : ''}`}>
      {withBadge ? <Badge color="#6B9E78" text="已确认" />
        : <>
          <Button
            className="review-cancel"
            size="small"
            onClick={() => closeModal(false)}
            disabled={disabled}>
          取消
          </Button>
          <Button
            className="review-ok"
            size="small"
            onClick={() => handleHroReviewOkBtnClick()}
            disabled={disabled}>
        确认
          </Button>
        </>}
    </div>;
  };

  const hroReviewStep = () => {
    return <Steps
      direction="vertical"
      current={current}
      onChange={stepOnChange}
      items={[
        {
          title: <div className="leave-hro-review">
            员工假期数据已完成确认
            {hroReviewBtn(0, current > 0, !(hasExitApprovalLeavePermission &&
              record?.exitApprovalLeaveHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED), !hasExitApprovalLeavePermission)}
          </div>,
          status: current >= 0 ? 'process' : 'wait',
          disabled: true,
          className: `first-step ${current >= 0 ? 'process' : 'wait'}`,
        },
        {
          title: <div className="home-office-hro-review">
            已确认处理员工培训协议服务期事项并退出企业微信
            {hroReviewBtn(1, current > 1, !(hasExitApprovalOfficeHroPermission &&
              record?.exitApprovalOfficeHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED &&
              record.canOfficeHroReview), !hasExitApprovalOfficeHroPermission)}
          </div>,
          status: current >= 1 ? 'process' : 'wait',
          disabled: true,
          className: `${current >= 1 ? 'process' : 'wait'}`
        },
      ]}
    />;
  };

  return templateType === APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.LEAVE_HRO ||
  templateType === APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_HRO
    ? <Modal
      wrapClassName="approval-review-modal hro-review-modal"
      width={492}
      open={isModalOpen}
      centered
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      title="请确认以下事项"
      okText="确认"
      onCancel={handleCancel}
      footer={null}>
      <div className="hro-review-checked">
        {hroReviewStep()}
        <span className="review-note">备注：离职相关文件会在离职流转全部结束后发起签署和盖章</span>
      </div>
    </Modal>
    : <Modal
      wrapClassName="approval-review-modal"
      width={492}
      open={isModalOpen}
      centered
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      title="请确认以下事项"
      okText="确认"
      okButtonProps={{ 'disabled': checkBoxGroupItems[templateType]?.length !== value.length }}
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className="input-label"><span>* </span>请完成以下事项后及时勾选并最终确认</div>
      <div className="review-checked">
        <Checkbox.Group onChange={handleChecked} options={checkBoxGroupItems[templateType]}></Checkbox.Group>
      </div>
    </Modal>;
};

export default ExitApprovalReviewModal;
