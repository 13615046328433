import { useCallback, useEffect, useState } from 'react';
import { getAllEmployeeMaterials, getEmployeeHireInfo } from '@/api/employeeApi';
import { Empty, Tabs } from 'antd';
import { getMaterialConfigs, MATERIALS_MATOMO_NAME } from '@/pages/employee/employee-materials-upload/EmployeeMaterialsConfig';
import { IHireInfo } from '@/models/employee';
import FilePreviewer from '@/components/file-previewer/FilePreviewer';
import './MaterialCheck.scss';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY } from '@/constants/matomo';

interface IMaterialCheckProps {
  employeeId: string;
  isContractExtension: boolean;
  updateTime?: (arg) => void
}

const MaterialCheck = (props: IMaterialCheckProps) => {
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>({} as IHireInfo);
  const [ materials, setMaterials ] = useState([]);
  const { trackEvent } = useCustomMatomo();

  useEffect(() => {
    Promise.all([ getEmployeeHireInfo(props.employeeId), getAllEmployeeMaterials({ ...props }) ])
      .then(([ employeeData, { materialsData, updateDate } ]) => {
        if (!employeeData) {
          return;
        }
        setEmployeeInfo(employeeData);
        if (props.updateTime) {
          props.updateTime(updateDate);
        }
        const materialConfigs = getMaterialConfigs(employeeData.nationId, employeeData.contractSignType);
        const materialKeys = Object.keys(materialsData || []);
        const materials = materialConfigs.filter(config => materialKeys.includes(config.key) && materialsData[config.key]?.length > 0)
          .map((config, index) => ({
            label: config.name,
            key: `${index}`,
            matomoKey: config.key,
            children: (
              <div className="materials">
                {materialsData[config.key].map(fileInfo => (
                  <div key={fileInfo.fileId}>
                    <FilePreviewer
                      fileName={fileInfo.fileName}
                      storageUrl={fileInfo.fileUrl}/>
                  </div>
                ))}
              </div>
            )
          }));
        setMaterials(materials);
      });
  }, [ props.employeeId ]);

  const handleTabChange = useCallback((activeKey: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: `${MATOMO_ACTION.SwitchTab} to preview`,
      name: MATERIALS_MATOMO_NAME.find(name => name.key === materials.find(item => item.key === activeKey)?.matomoKey).MatomoName
    });
  }, [ materials ]);

  return (
    <>
      {employeeInfo &&
        <div className="material-check-container">
          <div className="employee-basic-info">
            <span className="employee-name">{employeeInfo.legalFullName}</span>
            <span className="employee-id">{employeeInfo.employeeId}</span>
            <span className="employee-info-item">{employeeInfo.homeOffice}</span>
            <span className="employee-info-item">{employeeInfo.nationalityCn}</span>
            <span className="employee-info-item">{employeeInfo.employmentType}</span>
            {!props.isContractExtension &&
              <span className="employee-info-item">{employeeInfo.hireType}</span>
            }
            <span className="employee-info-item last-item">{employeeInfo.position}</span>
          </div>
          {materials.length != 0
            ? <Tabs
              defaultActiveKey="0"
              onChange={handleTabChange}
              items={materials}
            />
            : <div className={'ant-tabs'}>
              <div className={'ant-tabs-content-holder'}>
                <Empty className={'ant-tabs-content empty'}/>
              </div>
            </div>
          }
        </div>}
    </>
  );
};

export default MaterialCheck;
