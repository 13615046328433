import { Badge, Button, Empty, message, Table, Tooltip, UploadFile } from 'antd';
import { IExitEmployeeListDetail, IExitEmployeeListResponse, IExitEmployeeResponse } from '@/models/employee';
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import './ExitToBeSignedTableInfo.scss';
import { EXIT_SIGNING_LIST_READ_PERMISSION, EXIT_SIGNING_PERMISSION, EXIT_UNSIGNED_CONTRACT_READ, } from '@/constants/permission';
import { getExitUserAndInfo, supplementExitUserInfo } from '@/api/exitApi';
import { EXIT_TYPE, EXIT_TYPE_CHINESE } from '@/constants/EXIT_TYPE_CHINESE';
import { EVENT_PROCESS_TAG, EXIT_PROCESS_TAB_STATUS, EXIT_REVIEW_PROCESS_STATUS, EXIT_SIGN_PROCESS_STATUS } from '@/constants/status';
import {
  InfoFieldValidatorConfig,
  NoneTypeFieldValidatorConfig
} from '@/constants/config/employee-form/exit-employee-table-info/FieldValidatorConfig';
import { WithTabDrawer } from '@/pages/hro/exit-process/with-tab-drawer/WithTabDrawer';
import useForm from 'antd/lib/form/hooks/useForm';
import { EXIT_AGREEMENT_TYPE, ID_TYPE } from '@/constants/employee';
import moment from 'moment';
import { DATE_FORMATTER, DATE_FORMATTER_TO_MONTH } from '@/constants/date';
import { noop } from 'lodash-es';
import ButtonIcon from '@/components/button-icon/ButtonIcon';
import ButtonWithSign from '@/components/button-with-sign/ButtonWithSign';
import {
  fetchExitTableColumnFilterCandidate,
  fetchExitToBeSignedList,
  fetchExitTotal,
  selectExitColumnCandidates,
  selectExitToBeSignedEmployeeData,
  updateExitTableSearchFilters
} from '@/store/hroInfo';
import { AppDispatch } from '@/models/store';
import ButtonWithStamp from '@/components/button-with-stamp/ButtonWithStamp';
import ContractPreviewModal from '@/pages/hro/exit-process/exit-employee-table-info/contract-preview-modal/ContractPreviewModal';
import InvoluntaryHroButtonWithStamp
  from '@/pages/hro/exit-process/exit-to-be-signed-table-info/Involuntary-hro-button-with-stamp/InvoluntaryHroButtonWithStamp';
import TableColumnFilter from '@/components/table-column-filter/TableColumnFilter';
import { EXIT_EMPLOYMENT_TYPE_OPTIONS, EXIT_TYPE_OPTIONS } from '@/constants/employeeOptions';
import TableColumnDateFilter from '@/components/table-column-date-filter/TableColumnDateFilter';

const ExitToBeSignedTableInfo = () => {
  const hasExitSigningListReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_SIGNING_LIST_READ_PERMISSION ]));
  const hasExitSigningPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_SIGNING_PERMISSION ]));
  const hasExitUnSignedContractReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_UNSIGNED_CONTRACT_READ ]));


  const [ shanghaiLayoffSlipUploaderOpen, setShanghaiLayoffSlipUploaderOpen ] = useState(false);
  const [ fileList, setFileList ] = useState([]);
  const [ employeeInfo, setEmployeeInfo ] = useState<IExitEmployeeResponse>();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ popOpen, setPopOpen ] = useState(false);
  const [ contractCheckModalOpen, setContractCheckModalOpen ] = useState(false);
  const [ selectedColumn, setSelectedColumn ] = useState(null);
  const [ showContractPreviewFooter, setShowContractPreviewFooter ] = useState(true);
  const [ form ] = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const fileListRef = useRef(null);

  const dataStoredInRedux = useSelector(selectExitToBeSignedEmployeeData) as IExitEmployeeListResponse;
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
    showSizeChanger: false,
  };
  const toBeSignedEmployeeList = dataStoredInRedux.content.map(employee => {
    return {
      ...employee,
      type: employee.type === EXIT_TYPE.VOLUNTARY ? EXIT_TYPE_CHINESE.VOLUNTARY : EXIT_TYPE_CHINESE.INVOLUNTARY,
    };
  });

  useEffect(() => {
    fileListRef.current = fileList.filter(file => file.status === 'done' && file.response.fileId === null);
  }, [ fileList ]);

  const setShanghaiLayoffFileList = (newFileList: UploadFile[]) => {
    setFileList(newFileList);
  };

  const setFileUploaderOpen = (isOpen: boolean) => {
    setShanghaiLayoffSlipUploaderOpen(isOpen);
  };

  const displayDrawer = useCallback((employeeId: string, exitSignProcessId: string) => {
    getExitUserAndInfo(employeeId, exitSignProcessId).then(employeeInfo => {
      setEmployeeInfo(employeeInfo);
      employeeInfo.exitContractSigningProcessId = exitSignProcessId;
      employeeInfo.idType = ID_TYPE[employeeInfo.idType];
      employeeInfo.hireDate = moment(employeeInfo.hireDate).format(DATE_FORMATTER);
      employeeInfo.terminationDate = moment(employeeInfo.terminationDate).format(DATE_FORMATTER);
      employeeInfo.lastDateOfWork = moment(employeeInfo.lastDateOfWork).format(DATE_FORMATTER);
      employeeInfo.socialSecurityTerminationDate =
        moment(employeeInfo.socialSecurityTerminationDate).format(DATE_FORMATTER_TO_MONTH);
      employeeInfo.providentFundTerminationDate =
        moment(employeeInfo.providentFundTerminationDate).format(DATE_FORMATTER_TO_MONTH);

      const data = {
        ...employeeInfo,
        hireDate: moment(employeeInfo.hireDate),
        terminationDate: moment(employeeInfo.terminationDate),
        lastDateOfWork: moment(employeeInfo.lastDateOfWork),
        socialSecurityTerminationDate:
          employeeInfo.socialSecurityTerminationDate === 'Invalid date' ? '' : moment(employeeInfo.socialSecurityTerminationDate),
        providentFundTerminationDate:
          employeeInfo.providentFundTerminationDate === 'Invalid date' ? '' : moment(employeeInfo.providentFundTerminationDate),
      };

      setFileList(employeeInfo.shanghaiLayoffSlipFiles);
      form.setFieldsValue(data);
      setDrawerOpen(true);
    });
  }, [ employeeInfo ]);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    setShanghaiLayoffFileList([]);
    setShanghaiLayoffSlipUploaderOpen(false);
    form.resetFields();
  }, []);

  const displayPop = () => {
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  };

  const closePop = () => {
    setPopOpen(false);
  };

  const handleQueryFilterConfirm = () => {
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
    dispatch(fetchExitToBeSignedList({ page: 0, size: 20 }));
    dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
  };

  useEffect(() => {
    if (!hasExitSigningListReadPermission) {
      return;
    }
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
    dispatch(fetchExitToBeSignedList({ page: 0, size: 20 }));
  }, [ hasExitSigningListReadPermission ]);


  const drawerConfirm = useCallback(() => {
    const formData = form.getFieldsValue([
      'employeeId', 'legalFullName', 'chineseName', 'terminationDate', 'agreementType', 'lastDateOfWork',
      'socialSecurityTerminationDate', 'providentFundTerminationDate', 'amountOfCompensation' ]);
    switch (formData.agreementType) {
    case EXIT_AGREEMENT_TYPE.NONE:
      formData.amountOfCompensation = null;
      break;
    case EXIT_AGREEMENT_TYPE.LEAVE_EARLY:
    case EXIT_AGREEMENT_TYPE.PASSIVE_WITHOUT_COMPENSATION_DURING_PROBATIONARY_PERIOD:
      formData.amountOfCompensation = 0;
      break;
    default:
    }
    const data = {
      ...formData,
      terminationDate: formData.terminationDate.format(DATE_FORMATTER),
      lastDateOfWork: formData.agreementType === 'none'
        ? null : formData.lastDateOfWork.format(DATE_FORMATTER),
      socialSecurityTerminationDate: formData.agreementType === 'none'
        ? null : formData.socialSecurityTerminationDate.format(DATE_FORMATTER_TO_MONTH),
      providentFundTerminationDate: formData.agreementType === 'none'
        ? null : formData.providentFundTerminationDate.format(DATE_FORMATTER_TO_MONTH),
      fileList: fileListRef.current,
      exitContractSigningProcessId: employeeInfo.exitContractSigningProcessId,
    };
    form.validateFields().then(() => {
      supplementExitUserInfo(data).then(() => {
        message.success('补充员工离职信息审核已通过');
        dispatch(fetchExitToBeSignedList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
        dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
      }, () => {
        message.error('补充离职信息失败');
      });
      closePop();
      closeDrawer();
    })
      .catch(() => {
        closePop();
      });
  }, [ employeeInfo ]);

  const renderFilterIcon = () => {
    return <i className="ri-filter-3-line"></i>;
  };

  const getTableFilterCommonProps = (filterFieldKey, close) => {
    return {
      close,
      filterFieldKey,
      handleConfirm: handleQueryFilterConfirm,
      filterCandidatesReduxQueryMethod: selectExitColumnCandidates,
      filterReduxUpdateMethod: updateExitTableSearchFilters
    };
  };

  const renderOperationButton = (
    record: IExitEmployeeListDetail
  ) => {
    if (record.type === EXIT_TYPE_CHINESE.VOLUNTARY) {
      switch (record.exitSignProcessStatus) {
      case EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_SIGNING:
      case EXIT_SIGN_PROCESS_STATUS.WAITING_EMPLOYEE_SIGNING:
        return <ButtonWithSign
          hasPermission={hasExitSigningPermission && record.exitReviewProcessStatus === EXIT_REVIEW_PROCESS_STATUS.REVIEWED}
          update={() => {
            dispatch(fetchExitToBeSignedList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
            dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
          }}
          employeeId={record.employeeId}
          employeeSigningStatus={record.exitSignProcessStatus}
          employeeSigningProcessId={record.exitSignProcessId}
        />;
      case EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING:
      case EXIT_SIGN_PROCESS_STATUS.HRO_SIGNED:
        return <ButtonWithStamp
          hroSignUrl={record.stampLink}
          disable={!hasExitSigningPermission || record.exitSignProcessStatus !== EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING}
          text={record.exitSignProcessStatus === EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING ? '盖电子章' : '已盖章'}/>;
      default:
        return <></>;
      }
    } else {
      return <InvoluntaryHroButtonWithStamp
        employeeId={record.employeeId}
        processId={record.exitSignProcessId}
        text={record.exitSignProcessStatus === EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING ? '盖电子章' : '已盖章'}
        hroSignUrl={record?.stampLink}
        disabled={!hasExitSigningPermission || record.exitSignProcessStatus !== EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING}
        hidden={!hasExitSigningPermission}/>;
    }
  };

  const openContractPreviewModal = useCallback((columnItem: IExitEmployeeListDetail, showContractPreviewFooter: boolean) => {
    setContractCheckModalOpen(true);
    setSelectedColumn(columnItem);
    setShowContractPreviewFooter(showContractPreviewFooter);
  }, []);

  const renderEventTips = (record: IExitEmployeeListDetail) => {
    if (record.exitReviewProcessTag === EVENT_PROCESS_TAG.EVENT_NEED_DEAL) {
      return <Tooltip
        placement="top"
        title="员工离职日期发生变化，请查看已补充协议信息并预览审核"
        getPopupContainer={triggerNode => triggerNode}
      >
        <i className="ri-information-fill"></i>
      </Tooltip>;
    }
  };

  const renderInfoBadge = (record: IExitEmployeeListDetail) => {
    if (record.type === EXIT_TYPE_CHINESE.VOLUNTARY) {
      switch (record.exitReviewProcessStatus) {
      case EXIT_REVIEW_PROCESS_STATUS.REVIEWED:
      case EXIT_REVIEW_PROCESS_STATUS.TO_BE_SUBMITTED:
        return <div className="operation-items">
          { renderEventTips(record) }
          <button
            className="button-badge"
            disabled={!hasExitSigningPermission || record.exitSignProcessStatus !== EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_SIGNING}
            onClick={() => displayDrawer(record.employeeId, record.exitSignProcessId)}
          >
            <Badge className="initializing-file-badge" color="#47A1AD" text="已补充协议信息"/>
          </button>
          {
            hasExitUnSignedContractReadPermission && <ButtonIcon
              icon={<i className="ri-eye-line" style={{ fontSize: 16, color: '#003D4F' }}></i>}
              disabled={false}
              onClick={() => openContractPreviewModal(record, false)}
            />
          }
        </div>;
      case EXIT_REVIEW_PROCESS_STATUS.UNDER_GENERATE:
        return <div className={`${!hasExitSigningPermission ? ' hidden' : ''}`}>
          { renderEventTips(record) }
          <Badge className="initializing-file-badge" color="#47A1AD" text="生成文件中"/>
        </div>;
      case EXIT_REVIEW_PROCESS_STATUS.GENERATE_FAILED:
        return <div className={`${!hasExitSigningPermission ? ' hidden' : ''}`}>
          { renderEventTips(record) }
          <Tooltip
            placement="bottom"
            title="员工信息审核出错，请重新补充"
            getPopupContainer={triggerNode => triggerNode}
            overlayStyle={{ width: 216 }}>
            <button
              className="button-badge"
              disabled={!hasExitSigningPermission}
              onClick={() => displayDrawer(record.employeeId, record.exitSignProcessId)}>
              <Badge className="initializing-file-badge" color="#F2617A" text="已补充协议信息"/>
            </button>
          </Tooltip>
        </div>;
      case EXIT_REVIEW_PROCESS_STATUS.TO_BE_REVIEWED:
        return <div className={`${!hasExitSigningPermission ? ' hidden' : ''}`}>
          { renderEventTips(record) }
          <Button
            data-testid="preview-contract"
            size="small"
            disabled={!hasExitSigningPermission}
            onClick={() => openContractPreviewModal(record, true)}>
            预览审核
          </Button>
        </div>;
      default:
        return <div className="info-badge-placeholder"></div>;
      }
    } else {
      return <div className="info-badge-placeholder"></div>;
    }
  };

  const closeContractPreviewModal = useCallback((needRefreshData: boolean) => {
    setContractCheckModalOpen(false);
    if (needRefreshData) {
      dispatch(fetchExitToBeSignedList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
    }
  }, []);

  const tableColumns = useMemo(() => {
    const columns = [];
    columns.push({
      title: '离职日期',
      dataIndex: 'terminationDate',
      className: 'align-center',
      width: '122px',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnDateFilter
        filterFieldKey={'terminationDate'}
        handleConfirm={handleQueryFilterConfirm}
        filterReduxUpdateMethod={updateExitTableSearchFilters}
        close={close}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '工号',
      className: 'align-center',
      dataIndex: 'employeeId',
      width: '88px',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('employeeId', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '中文名',
      dataIndex: 'chineseName',
      className: 'align-center',
      width: '100px',
      fixed: 'left',
      ellipsis: true,
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('chineseName', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '离职类型',
      className: 'align-center',
      dataIndex: 'type',
      key: 'type',
      width: '120px',
      filterDropdown: ({ close }) => <TableColumnFilter
        filters={EXIT_TYPE_OPTIONS}
        {...getTableFilterCommonProps('type', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '员工类型',
      className: 'align-center',
      dataIndex: 'employmentType',
      width: '120px',
      filterDropdown: ({ close }) => <TableColumnFilter
        filters={EXIT_EMPLOYMENT_TYPE_OPTIONS}
        {...getTableFilterCommonProps('employmentType', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '城市',
      dataIndex: 'homeOfficeChinese',
      className: 'align-center',
      width: '90px',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('homeOffice', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '部门',
      className: 'align-center',
      dataIndex: 'supervisoryOrgName',
      width: '100px',
      ellipsis: true,
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('supervisoryOrgName', close)}
      />,
      filterIcon: renderFilterIcon
    },
    );

    if (!hasExitSigningPermission) {
      return columns;
    }

    columns.push({
      title: '操作',
      className: 'align-center',
      dataIndex: 'exitSignProcessStatus',
      key: 'operation',
      width: '340px',
      render: (_, record: IExitEmployeeListDetail) => {
        return <div className={`exit-to-be-signed-operation-wrapper${!hasExitSigningPermission ? ' hidden' : ''}`}>
          <div className="operation-button-wrapper">
            {renderOperationButton(record)}
          </div>
          <div className="info-badge">
            {renderInfoBadge(record)}
          </div>
        </div>;
      }
    });

    return columns;
  }, []);

  const handleTableChange = useCallback(pagination => {
    dispatch(fetchExitToBeSignedList({ page: pagination.current - 1, size: pagination.pageSize }));
  }, [ tablePagination ]);

  return (
    <>
      <Table
        className="exit-to-be-signed-table"
        columns={tableColumns}
        dataSource={toBeSignedEmployeeList}
        rowKey="employeeId"
        pagination={tablePagination}
        onChange={handleTableChange}
        scroll={{ y: 'calc(100vh - 470px)', x: '1130px' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
      <WithTabDrawer
        form={form}
        employeeInfo={employeeInfo}
        drawerTitle="员工离职信息审核"
        supplementTitle="补充协议类型"
        drawerOpen={drawerOpen}
        closeDrawer={closeDrawer}
        drawerConfirm={drawerConfirm}
        popOpen={popOpen}
        displayPop={displayPop}
        closePop={closePop}
        InfoFieldValidatorConfig={InfoFieldValidatorConfig}
        TypeFieldValidatorConfig={NoneTypeFieldValidatorConfig}
        fileList={fileList}
        setShanghaiLayoffFileList={setShanghaiLayoffFileList}
        shanghaiLayoffSlipUploaderOpen={shanghaiLayoffSlipUploaderOpen}
        setFileUploaderOpen={setFileUploaderOpen}
      />
      <ContractPreviewModal isModalOpen={contractCheckModalOpen}
        closeModal={closeContractPreviewModal}
        processId={selectedColumn?.exitSignProcessId} employeeId={selectedColumn?.employeeId}
        showButtons={showContractPreviewFooter}/>
    </>
  );
};
export default ExitToBeSignedTableInfo;
