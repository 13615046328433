import { Badge, Button, Empty, message, Table, Tooltip, UploadFile } from 'antd';
import { IEmployeeExitInfo, IExitEmployeeListDetail, IExitEmployeeListResponse, IExitEmployeeResponse } from '@/models/employee';
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import { AppDispatch } from '@/models/store';
import {
  fetchExitEmployeeList,
  fetchExitTableColumnFilterCandidate,
  fetchExitTotal,
  selectExitColumnCandidates,
  selectExitEmployeeData,
  updateExitTableSearchFilters,
} from '@/store/hroInfo';
import ButtonIcon from '@/components/button-icon/ButtonIcon';
import './ExitEmployeeTableInfo.scss';
import {
  EXIT_INFO_PROCESS_STATUS, EXIT_PROCESS_TAB_STATUS,
  EXIT_REVIEW_PROCESS_STATUS,
  EXIT_SIGN_PROCESS_STATUS,
  HRBP_CAN_REVIEW_EXIT_FILES_STATUS_LIST
} from '@/constants/status';
import { EMPLOYMENT_TYPE, EXIT_AGREEMENT_TYPE, ID_TYPE } from '@/constants/employee';
import {
  EXIT_INITIATE_APPROVAL_PERMISSION,
  EXIT_INITIATE_INVOLUNTARY_SIGNING_PERMISSION,
  EXIT_LIST_READ_PERMISSION,
  EXIT_SIGNED_CONTRACT_READ,
  EXIT_SIGNING_PERMISSION,
  EXIT_TYPE_READ_PERMISSION,
  EXIT_UNSIGNED_CONTRACT_READ,
  EXIT_USER_ADDITIONAL_INFO_READ_PERMISSION,
} from '@/constants/permission';
import moment from 'moment/moment';
import useForm from 'antd/lib/form/hooks/useForm';
import {
  InfoFieldValidatorConfig,
  NoneTypeFieldValidatorConfig,
} from '@/constants/config/employee-form/exit-employee-table-info/FieldValidatorConfig';
import { DATE_FORMATTER, DATE_FORMATTER_TO_MONTH } from '@/constants/date';
import { WithTabDrawer } from '@/pages/hro/exit-process/with-tab-drawer/WithTabDrawer';
import { noop } from 'lodash-es';
import { getExitEmployeePreviewInfo, getExitUserAndInfo, getInvoluntaryExitFlowUrl, supplementExitUserInfo } from '@/api/exitApi';
import InitiateApprovalModal from '@/pages/hro/exit-process/exit-employee-table-info/Initiate-approval-modal/InitiateApprovalModal';
import { EXIT_TYPE, EXIT_TYPE_CHINESE } from '@/constants/EXIT_TYPE_CHINESE';
import { ExitEmployeeInfoPreviewDrawer } from '@/pages/hro/exit-process/exit-employee-info-preview-drawer/ExitEmployeeInfoPreviewDrawer';
import ContractPreviewModal from '@/pages/hro/exit-process/exit-employee-table-info/contract-preview-modal/ContractPreviewModal';
import ButtonWithSign from '@/components/button-with-sign/ButtonWithSign';
import InvoluntaryBpFhReviewModal
  from '@/pages/hro/exit-process/exit-employee-table-info/involuntary-bpfh-review-modal/InvoluntaryBpFhReviewModal';
import TableColumnFilter from '@/components/table-column-filter/TableColumnFilter';
import { EXIT_EMPLOYMENT_TYPE_OPTIONS, EXIT_INFO_PROCESS_OPTIONS, EXIT_TYPE_OPTIONS } from '@/constants/employeeOptions';
import TableColumnDateFilter from '@/components/table-column-date-filter/TableColumnDateFilter';

const ExitEmployeeTableInfo = () => {
  const hasExitTypeReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_TYPE_READ_PERMISSION ]));
  const hasExitListReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_LIST_READ_PERMISSION ]));
  const hasExitInitiateApprovalPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_INITIATE_APPROVAL_PERMISSION ]));
  const hasExitSigningPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_SIGNING_PERMISSION ]));
  const hasInitiateInvoluntarySigningPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_INITIATE_INVOLUNTARY_SIGNING_PERMISSION ]));
  const hasExitUserAdditionalInfoReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_USER_ADDITIONAL_INFO_READ_PERMISSION ]));
  const hasExitSignedContractReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_SIGNED_CONTRACT_READ ]));
  const hasExitUnSignedContractReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_UNSIGNED_CONTRACT_READ ]));

  const [ isModalOpen, setIsModalOpen ] = useState(null);
  const [ selectedColumn, setSelectedColumn ] = useState(null);
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ previewDrawerOpen, setPreviewDrawerOpen ] = useState(false);
  const [ popOpen, setPopOpen ] = useState(false);
  const [ shanghaiLayoffSlipUploaderOpen, setShanghaiLayoffSlipUploaderOpen ] = useState(false);
  const [ employeeInfo, setEmployeeInfo ] = useState<IExitEmployeeResponse>();
  const [ previewEmployeeInfo, setPreviewEmployeeInfo ] = useState<IEmployeeExitInfo>();
  const [ fileList, setFileList ] = useState([]);
  const fileListRef = useRef(null);
  const [ contractCheckModalOpen, setContractCheckModalOpen ] = useState(false);
  const [ bpfhContractCheckModalOpen, setBpfhContractCheckModalOpen ] = useState(false);
  const [ showContractPreviewFooter, setShowContractPreviewFooter ] = useState(true);
  const [ form ] = useForm();
  const hasActionColumnPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_SIGNING_PERMISSION,
      EXIT_INITIATE_INVOLUNTARY_SIGNING_PERMISSION, EXIT_INITIATE_APPROVAL_PERMISSION ]));
  const dispatch = useDispatch<AppDispatch>();
  const dataStoredInRedux = useSelector(selectExitEmployeeData) as IExitEmployeeListResponse;
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
    showSizeChanger: false,
  };
  const exitEmployeeList = dataStoredInRedux.content.map(employee => {
    return {
      ...employee,
      type: employee.type === EXIT_TYPE.VOLUNTARY ? EXIT_TYPE_CHINESE.VOLUNTARY : EXIT_TYPE_CHINESE.INVOLUNTARY,
    };
  });

  useEffect(() => {
    fileListRef.current = fileList.filter(file => file.status === 'done' && file.response.fileId === null);
  }, [ fileList ]);


  useEffect(() => {
    if (!hasExitListReadPermission) {
      return;
    }
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
    dispatch(fetchExitEmployeeList({ page: 0, size: 20 }));
  }, [ hasExitListReadPermission ]);

  const openModal = useCallback((columnItem: IExitEmployeeListDetail) => {
    setIsModalOpen(true);
    setSelectedColumn(columnItem);
  }, []);

  const closeModal = useCallback((needRefreshData: boolean) => {
    setIsModalOpen(false);
    if (needRefreshData) {
      dispatch(fetchExitEmployeeList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
    }
  }, []);

  const openContractPreviewModal = useCallback((columnItem: IExitEmployeeListDetail, showContractPreviewFooter: boolean) => {
    setContractCheckModalOpen(true);
    setSelectedColumn(columnItem);
    setShowContractPreviewFooter(showContractPreviewFooter);
  }, []);

  const openBpfhContractPreviewModal = useCallback((columnItem: IExitEmployeeListDetail) => {
    setBpfhContractCheckModalOpen(true);
    setSelectedColumn(columnItem);
  }, []);

  const closeContractPreviewModal = useCallback((needRefreshData: boolean) => {
    setContractCheckModalOpen(false);
    if (needRefreshData) {
      dispatch(fetchExitEmployeeList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
    }
  }, []);

  const closeBpfhContractPreviewModal = useCallback((needRefreshData: boolean) => {
    setBpfhContractCheckModalOpen(false);
    if (needRefreshData) {
      dispatch(fetchExitEmployeeList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
    }
  }, []);

  const enableInitiateApproval = useCallback((columnItem: IExitEmployeeListDetail) => {
    const isFteEmployee = EMPLOYMENT_TYPE.EMPLOYEE === columnItem.employmentType;
    const isSignStatusLegal = columnItem.exitSignProcessStatus === EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_APPROVAL;
    const isReviewStatusLegal = isFteEmployee ? columnItem.exitReviewProcessStatus === EXIT_REVIEW_PROCESS_STATUS.REVIEWED : true;
    const isInformationStatusLegal = isFteEmployee ? columnItem.exitInformationProcessStatus === EXIT_INFO_PROCESS_STATUS.SUBMITTED : true;
    const isAllStatusLegal = isSignStatusLegal && isReviewStatusLegal && isInformationStatusLegal;
    return hasExitInitiateApprovalPermission && isAllStatusLegal;
  }, []);

  const handleQueryFilterConfirm = () => {
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
    dispatch(fetchExitEmployeeList({ page: 0, size: 20 }));
    dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
  };

  const setShanghaiLayoffFileList = (newFileList: UploadFile[]) => {
    setFileList(newFileList);
  };

  const setFileUploaderOpen = (isOpen: boolean) => {
    setShanghaiLayoffSlipUploaderOpen(isOpen);
  };

  const displayPop = () => {
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  };

  const closePop = () => {
    setPopOpen(false);
  };

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    setShanghaiLayoffFileList([]);
    setShanghaiLayoffSlipUploaderOpen(false);
    form.resetFields();
  }, []);

  const displayDrawer = useCallback((employeeId: string, exitSignProcessId: string) => {
    getExitUserAndInfo(employeeId, exitSignProcessId).then(employeeInfo => {
      setEmployeeInfo(employeeInfo);
      employeeInfo.exitContractSigningProcessId = exitSignProcessId;
      employeeInfo.idType = ID_TYPE[employeeInfo.idType];
      employeeInfo.hireDate = moment(employeeInfo.hireDate).format(DATE_FORMATTER);
      employeeInfo.terminationDate = moment(employeeInfo.terminationDate).format(DATE_FORMATTER);
      employeeInfo.lastDateOfWork = moment(employeeInfo.lastDateOfWork).format(DATE_FORMATTER);
      employeeInfo.socialSecurityTerminationDate =
        moment(employeeInfo.socialSecurityTerminationDate).format(DATE_FORMATTER_TO_MONTH);
      employeeInfo.providentFundTerminationDate =
        moment(employeeInfo.providentFundTerminationDate).format(DATE_FORMATTER_TO_MONTH);

      const data = {
        ...employeeInfo,
        hireDate: moment(employeeInfo.hireDate),
        terminationDate: moment(employeeInfo.terminationDate),
        lastDateOfWork: moment(employeeInfo.lastDateOfWork),
        socialSecurityTerminationDate:
          employeeInfo.socialSecurityTerminationDate === 'Invalid date' ? '' : moment(employeeInfo.socialSecurityTerminationDate),
        providentFundTerminationDate:
          employeeInfo.providentFundTerminationDate === 'Invalid date' ? '' : moment(employeeInfo.providentFundTerminationDate),
      };

      setFileList(employeeInfo.shanghaiLayoffSlipFiles);
      form.setFieldsValue(data);
      setDrawerOpen(true);
    });
  }, [ employeeInfo ]);

  const closePreviewDrawer = useCallback(() => {
    setPreviewDrawerOpen(false);
  }, []);

  const displayPreviewDrawer = useCallback((employeeId: string, exitSignProcessId: string) => {
    setPreviewEmployeeInfo(null);
    getExitEmployeePreviewInfo(exitSignProcessId).then(info => {
      setPreviewEmployeeInfo(info);
      setPreviewDrawerOpen(true);
    });
  }, [ employeeInfo ]);

  const supplementButton = (record: IExitEmployeeListDetail) => {
    if (EMPLOYMENT_TYPE.EMPLOYEE !== record.employmentType) {
      return <div className="preview-content"></div>;
    } else if (EXIT_SIGN_PROCESS_STATUS.AGREEMENT_TO_BE_SUBMITTED === record.exitSignProcessStatus &&
      EXIT_REVIEW_PROCESS_STATUS.TO_BE_SUBMITTED === record.exitReviewProcessStatus) {
      return <Button
        data-testid="supplement-employee-info"
        className={`supplement-employee-info${!hasExitSigningPermission ? ' hidden' : ''}`}
        size="small"
        disabled={!hasExitSigningPermission}
        type="default"
        onClick={() => displayDrawer(record.employeeId, record.exitSignProcessId)}>
        补充信息
      </Button>;
    } else if (EXIT_REVIEW_PROCESS_STATUS.UNDER_GENERATE === record.exitReviewProcessStatus) {
      return <Badge className={`initializing-file-badge${!hasExitSigningPermission ? ' hidden' : ''}`} color="#47A1AD" text="生成文件中"/>;
    } else if (EXIT_SIGN_PROCESS_STATUS.AGREEMENT_TO_BE_SUBMITTED === record.exitSignProcessStatus &&
      EXIT_REVIEW_PROCESS_STATUS.GENERATE_FAILED === record.exitReviewProcessStatus) {
      return <Tooltip
        placement="bottom"
        title="员工信息审核出错，请重新补充"
        getPopupContainer={triggerNode => triggerNode}
        overlayStyle={{ width: 216 }}>
        <Button
          data-testid="supplement-employee-info-blur"
          className={`supplement-employee-info${!hasExitSigningPermission ? ' hidden' : ''}`}
          size="small"
          disabled={!hasExitSigningPermission}
          type="primary"
          onClick={() => displayDrawer(record.employeeId, record.exitSignProcessId)}>
          补充信息
        </Button>
      </Tooltip>;
    } else if (EXIT_REVIEW_PROCESS_STATUS.TO_BE_REVIEWED === record.exitReviewProcessStatus) {
      return <Button
        data-testid="preview-contract"
        size="small"
        className={`${!hasExitSigningPermission ? ' hidden' : ''}`}
        disabled={!hasExitSigningPermission}
        onClick={() => openContractPreviewModal(record, true)}>
        预览审核
      </Button>;
    } else if (HRBP_CAN_REVIEW_EXIT_FILES_STATUS_LIST.includes(record.exitSignProcessStatus) &&
      EXIT_REVIEW_PROCESS_STATUS.GENERATE_FAILED === record.exitReviewProcessStatus) {
      return <div className={`preview-content${!hasExitSigningPermission ? ' hidden' : ''}`}>
        <Tooltip
          placement="bottom"
          title="员工信息审核出错，请重新补充"
          getPopupContainer={triggerNode => triggerNode}
          overlayStyle={{ width: 216 }}>
          <button
            className="button-badge"
            disabled={!hasExitSigningPermission}
            onClick={() => displayDrawer(record.employeeId, record.exitSignProcessId)}>
            <Badge className="initializing-file-badge" color="#F2617A" text="已补充协议信息"/>
          </button>
        </Tooltip>
      </div>;
    } else if (HRBP_CAN_REVIEW_EXIT_FILES_STATUS_LIST.includes(record.exitSignProcessStatus)) {
      const disabled = !(
        hasExitSigningPermission &&
        (record.type == EXIT_TYPE_CHINESE.VOLUNTARY &&
          record.exitSignProcessStatus === EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_APPROVAL) ||
        (record.type == EXIT_TYPE_CHINESE.INVOLUNTARY &&
          record.exitSignProcessStatus === EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_INITIATE_SIGNING)
      );
      const hasInvoluntaryPreviewPermission = hasExitUnSignedContractReadPermission && record.type == EXIT_TYPE_CHINESE.INVOLUNTARY;
      const hasVoluntaryPreviewPermission = hasExitUnSignedContractReadPermission &&
        record.type == EXIT_TYPE_CHINESE.VOLUNTARY && hasExitSigningPermission;
      const hasPreviewIconPermission = hasInvoluntaryPreviewPermission || hasVoluntaryPreviewPermission;
      return <div className={`preview-content${hasPreviewIconPermission ? '' : ' hidden'}`}>
        <button
          className="button-badge"
          disabled={disabled}
          onClick={() => displayDrawer(record.employeeId, record.exitSignProcessId)}>
          <Badge className="initializing-file-badge" color="#47A1AD" text="已补充协议信息"/>
        </button>
        {
          hasExitUnSignedContractReadPermission && <ButtonIcon
            icon={<i className="ri-eye-line" style={{ fontSize: 16 }}></i>}
            disabled={false}
            onClick={() => openContractPreviewModal(record, false)}/>
        }
      </div>;
    }
  };

  const fetchContractUrl = useCallback((columnItem: IExitEmployeeListDetail) => {
    getInvoluntaryExitFlowUrl(columnItem.exitSignProcessId).then(({ url }) => {
      window.open(url);
    }).catch(() => {
      message.error('获取文件链接失败');
    });
  }, []);

  const bpFhButton = (record: IExitEmployeeListDetail) => {
    const bpBtnSpanClassName = `initiate-signing-button 
                  ${record.type == EXIT_TYPE_CHINESE.INVOLUNTARY &&
    record.employmentType == EMPLOYMENT_TYPE.EMPLOYEE &&
    hasExitSignedContractReadPermission ? '' : 'hidden'}`;
    const hasPreviewIconPermission = record.employmentType == EMPLOYMENT_TYPE.EMPLOYEE &&
      record.type == EXIT_TYPE_CHINESE.INVOLUNTARY && hasExitUnSignedContractReadPermission;

    switch (record.exitSignProcessStatus) {
    case EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_REVIEW:
    case EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_PREVIEW_EXIT_FILES:
      return <span className={bpBtnSpanClassName}>
        <Button
          data-testid="preview-contract"
          size="small"
          disabled={!hasExitSignedContractReadPermission}
          onClick={() => openBpfhContractPreviewModal(record)}>
        预览审核
        </Button>
      </span>;
    case EXIT_SIGN_PROCESS_STATUS.AGREEMENT_TO_BE_SUBMITTED:
    case EXIT_SIGN_PROCESS_STATUS.AGREEMENT_TO_BE_REVIEWED:
    case EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_INITIATE_SIGNING:
      return <span className={bpBtnSpanClassName}>
        <ButtonWithSign
          employeeId={record.employeeId}
          employeeSigningStatus={record.exitSignProcessStatus}
          employeeSigningProcessId={record.exitSignProcessId}
          hasPermission={hasInitiateInvoluntarySigningPermission && EXIT_REVIEW_PROCESS_STATUS.REVIEWED === record.exitReviewProcessStatus}
          update={() => dispatch(fetchExitEmployeeList({ page: tablePagination.current - 1, size: tablePagination.pageSize }))}
        />
      </span>;
    case EXIT_SIGN_PROCESS_STATUS.WAITING_INVOLUNTARY_EMPLOYEE_SIGNING:
      return <span className={bpBtnSpanClassName}>
        <Badge className="submitted-badge" color="#6B9E78" text="签署中"/>
      </span>;
    case EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_APPROVAL:
      return <span className={`initiate-signing-button${hasPreviewIconPermission ? '' : ' hidden'}`}>
        <Badge className="reviewed-badge" color="#6B9E78" text="已审核"/>
        {
          hasExitUnSignedContractReadPermission &&
          <ButtonIcon icon={<i className="ri-eye-line" style={{ fontSize: 16 }}></i>} disabled={false}
            onClick={() => fetchContractUrl(record)}/>
        }
      </span>;
    default:
      return <span className="initiate-signing-button"></span>;
    }
  };

  const renderFilterIcon = (filterFieldKey: string) => {
    return <i className="ri-filter-3-line" data-testid={`${filterFieldKey}-test-id`}></i>;
  };

  const getTableFilterCommonProps = (filterFieldKey, close) => {
    return {
      close,
      filterFieldKey,
      handleConfirm: handleQueryFilterConfirm,
      filterCandidatesReduxQueryMethod: selectExitColumnCandidates,
      filterReduxUpdateMethod: updateExitTableSearchFilters
    };
  };

  const tableColumns = useMemo(() => {
    const columns = [];
    columns.push(
      {
        title: '离职日期',
        dataIndex: 'terminationDate',
        className: 'align-center',
        width: '122px',
        fixed: 'left',
        filterDropdown: ({ close }) => <TableColumnDateFilter
          filterFieldKey={'terminationDate'}
          handleConfirm={handleQueryFilterConfirm}
          filterReduxUpdateMethod={updateExitTableSearchFilters}
          close={close}
        />,
        filterIcon: renderFilterIcon('terminationDate')
      },
      {
        title: '工号',
        className: 'align-center',
        dataIndex: 'employeeId',
        width: '88px',
        fixed: 'left',
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('employeeId', close)}
        />,
        filterIcon: renderFilterIcon('employeeId')
      },
      {
        title: '中文名',
        dataIndex: 'chineseName',
        className: 'align-center',
        width: '100px',
        fixed: 'left',
        ellipsis: true,
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('chineseName', close)}
        />,
        filterIcon: renderFilterIcon
      }
    );

    if (hasExitTypeReadPermission) {
      columns.push(
        {
          title: '离职类型',
          className: 'align-center',
          dataIndex: 'type',
          key: 'type',
          width: '110px',
          filterDropdown: ({ close }) => <TableColumnFilter
            filters={EXIT_TYPE_OPTIONS}
            {...getTableFilterCommonProps('type', close)}
          />,
          filterIcon: renderFilterIcon('type')
        }
      );
    }

    columns.push(
      {
        title: '员工类型',
        className: 'align-center',
        dataIndex: 'employmentType',
        width: '120px',
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_EMPLOYMENT_TYPE_OPTIONS}
          {...getTableFilterCommonProps('employmentType', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '城市',
        dataIndex: 'homeOfficeChinese',
        className: 'align-center',
        width: '80px',
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('homeOffice', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '部门',
        className: 'align-center',
        dataIndex: 'supervisoryOrgName',
        width: '100px',
        ellipsis: true,
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('supervisoryOrgName', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '员工离职信息',
        className: 'align-center',
        dataIndex: 'exitInformationProcessStatus',
        width: '130px',
        render: (_, { exitInformationProcessStatus, employeeId, exitSignProcessId, type, employmentType }: IExitEmployeeListDetail) => {
          const isFteEmployee = EMPLOYMENT_TYPE.EMPLOYEE === employmentType;
          const isInvoluntaryEmployee = type === EXIT_TYPE_CHINESE.INVOLUNTARY;
          const isInformationUnSubmitted = exitInformationProcessStatus === EXIT_INFO_PROCESS_STATUS.UN_SUBMITTED;
          const isInformationSubmitted = exitInformationProcessStatus === EXIT_INFO_PROCESS_STATUS.SUBMITTED ||
            exitInformationProcessStatus === EXIT_INFO_PROCESS_STATUS.FINISH;
          if (isFteEmployee && (isInformationUnSubmitted || (exitInformationProcessStatus == null && isInvoluntaryEmployee))) {
            return <Badge className="exit-employee-info-not-submitted-badge" color="#F2617A" text="未提交"/>;
          } else if (isFteEmployee && isInformationSubmitted) {
            return <div className="exit-employee-info-submitted-wrapper">
              <Badge className="submitted-badge" color="#6B9E78" text="已提交"/>
              <div className={!hasExitUserAdditionalInfoReadPermission ? 'hidden' : ''}>
                <ButtonIcon
                  testId="preview-btn-icon-test-id"
                  icon={<i className="ri-eye-line" style={{ fontSize: 16 }}></i>}
                  disabled={!hasExitUserAdditionalInfoReadPermission}
                  onClick={() => {
                    displayPreviewDrawer(employeeId, exitSignProcessId);
                  }}/>
              </div>
            </div>;
          }
          return '-';
        },
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_INFO_PROCESS_OPTIONS}
          {...getTableFilterCommonProps('exitInformationProcessStatus', close)}
        />,
        filterIcon: renderFilterIcon
      }
    );

    if (hasActionColumnPermission) {
      columns.push({
        title: '操作',
        className: 'align-center',
        dataIndex: 'exitSignProcessStatus',
        key: 'action',
        width: '380px',
        render: (_, record: IExitEmployeeListDetail) => {
          return (
            record.editable && <div className="exit-employee-action-wrapper">
              <div className="info-preview-button">
                {supplementButton(record)}
              </div>
              {bpFhButton(record)}
              {
                hasExitInitiateApprovalPermission && <Button
                  size="small"
                  className={`exit-hro-initiate-approval-button${hasExitInitiateApprovalPermission ? '' : ' hidden'}`}
                  disabled={!enableInitiateApproval(record)}
                  onClick={() => openModal(record)}>
                  发起流转
                </Button>
              }
            </div>
          );
        }
      });
    }
    return columns;
  }, []);

  const handleTableChange = useCallback(pagination => {
    dispatch(fetchExitEmployeeList({ page: pagination.current - 1, size: pagination.pageSize }));
  }, [ tablePagination ]);

  const drawerConfirm = useCallback(() => {
    const formData = form.getFieldsValue([
      'employeeId', 'legalFullName', 'chineseName', 'terminationDate', 'agreementType', 'lastDateOfWork',
      'socialSecurityTerminationDate', 'providentFundTerminationDate', 'amountOfCompensation' ]);
    switch (formData.agreementType) {
    case EXIT_AGREEMENT_TYPE.NONE:
      formData.amountOfCompensation = null;
      break;
    case EXIT_AGREEMENT_TYPE.LEAVE_EARLY:
    case EXIT_AGREEMENT_TYPE.PASSIVE_WITHOUT_COMPENSATION_DURING_PROBATIONARY_PERIOD:
      formData.amountOfCompensation = 0;
      break;
    default:
    }
    const data = {
      ...formData,
      terminationDate: formData.terminationDate.format(DATE_FORMATTER),
      lastDateOfWork: formData.agreementType === 'none'
        ? null : formData.lastDateOfWork.format(DATE_FORMATTER),
      socialSecurityTerminationDate: formData.agreementType === 'none'
        ? null : formData.socialSecurityTerminationDate.format(DATE_FORMATTER_TO_MONTH),
      providentFundTerminationDate: formData.agreementType === 'none'
        ? null : formData.providentFundTerminationDate.format(DATE_FORMATTER_TO_MONTH),
      fileList: fileListRef.current,
      exitContractSigningProcessId: employeeInfo.exitContractSigningProcessId,
    };
    form.validateFields().then(() => {
      supplementExitUserInfo(data).then(() => {
        message.success('补充员工离职信息审核已通过');
        dispatch(fetchExitEmployeeList({ page: 0, size: 20 }));
      }, () => {
        message.error('补充离职信息失败');
      });
      closePop();
      closeDrawer();
    })
      .catch(() => {
        closePop();
      });
  }, [ employeeInfo ]);

  return (
    <>
      <Table
        columns={tableColumns}
        className='exit-employee-list-table'
        dataSource={exitEmployeeList}
        rowKey="employeeId"
        pagination={tablePagination}
        onChange={handleTableChange}
        scroll={{ y: 'calc(100vh - 470px)', x: '1330px' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
      <WithTabDrawer
        form={form}
        employeeInfo={employeeInfo}
        drawerTitle="员工离职信息审核"
        supplementTitle="补充协议类型"
        drawerOpen={drawerOpen}
        closeDrawer={closeDrawer}
        drawerConfirm={drawerConfirm}
        popOpen={popOpen}
        displayPop={displayPop}
        closePop={closePop}
        InfoFieldValidatorConfig={InfoFieldValidatorConfig}
        TypeFieldValidatorConfig={NoneTypeFieldValidatorConfig}
        fileList={fileList}
        setShanghaiLayoffFileList={setShanghaiLayoffFileList}
        shanghaiLayoffSlipUploaderOpen={shanghaiLayoffSlipUploaderOpen}
        setFileUploaderOpen={setFileUploaderOpen}
      />
      <ExitEmployeeInfoPreviewDrawer employeeInfo={previewEmployeeInfo} drawerOpen={previewDrawerOpen} closeDrawer={closePreviewDrawer}/>
      <ContractPreviewModal isModalOpen={contractCheckModalOpen}
        closeModal={closeContractPreviewModal}
        processId={selectedColumn?.exitSignProcessId} employeeId={selectedColumn?.employeeId}
        showButtons={showContractPreviewFooter}/>
      <InvoluntaryBpFhReviewModal isModalOpen={bpfhContractCheckModalOpen}
        closeModal={closeBpfhContractPreviewModal}
        processId={selectedColumn?.exitSignProcessId} status={selectedColumn?.exitSignProcessStatus}/>
      <InitiateApprovalModal isModalOpen={isModalOpen} closeModal={closeModal} processId={selectedColumn?.exitSignProcessId}/>
    </>
  );
};
export default ExitEmployeeTableInfo;
