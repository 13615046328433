import { Empty, Table } from 'antd';
import { useEffect, useState } from 'react';
import { IEmployeeSigningDetail, IEmployeeSigningResponse } from '@/models/employee';
import { getEmployeeSigningInfo } from '@/api/employeeApi';
import {
  getEmployeeSigningDetailColumns
} from '@/constants/config/hro/entry-contract-process/to-be-signed-table-info/ToBeSignedTableInfoConfig';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';

interface TableInfoProps {
    totalCount:number,
}

const ToBeSignedTableInfo = (props: TableInfoProps) => {
  const { totalCount }  = props;
  const [ ToBeSignedData,setToBeSignedData ] = useState<IEmployeeSigningDetail[]>([]);


  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();

  useEffect(() => {
    if (userInfo && hasPermission) {
      getEmployeeSigningInfo().then((res: IEmployeeSigningResponse) => {
        setToBeSignedData(res.data);
      });
    }
  },[ userInfo, hasPermission ]);

  const update = () => {
    getEmployeeSigningInfo().then((res: IEmployeeSigningResponse) => {
      setToBeSignedData(res.data);
    });
  };

  return (
    <Table
      columns={getEmployeeSigningDetailColumns(update)}
      dataSource={ToBeSignedData}
      rowKey="employeeId"
      pagination={{ defaultCurrent: 1, defaultPageSize: 20, total: totalCount, showSizeChanger: false }}
      scroll={{ y: 'calc(100vh - 470px)' }}
      locale={{ emptyText: <Empty description={'暂无数据'}/> } }
    />
  );
};
export default ToBeSignedTableInfo;
