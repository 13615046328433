import './public-path';
import ReactDOM from 'react-dom';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import store from './store';
import { message } from 'antd';
import { eventBus } from './utils/eventBus';
import { matomoInstance } from './matomo';

message.config({ top: 72 });

function getEsignRootContainer(container) {
  return container ? container.querySelector('#e-sign-web-root') : document.querySelector('#e-sign-web-root');
}

function render(props) {
  const { container } = props;
  const EsignRootContainer = createRoot(getEsignRootContainer(container));
  EsignRootContainer.render(
    <MatomoProvider value={matomoInstance}>
      <Provider store={store}>
        <App store={{ ...props }} />
      </Provider>
    </MatomoProvider>
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export async function bootstrap() {
}

export async function mount(props) {
  eventBus.instance = props.eventBus;
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  eventBus.instance = null;
  ReactDOM.unmountComponentAtNode(getEsignRootContainer(container));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
