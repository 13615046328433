import { Button, Drawer, FormInstance, Popconfirm, Tabs, UploadFile } from 'antd';
import {
  IExitEmployeeResponse
} from '@/models/employee';
import { EmployeeInfoForm } from '@/components/employee-form/EmployeeForm';
import './WithTabDrawer.scss';
import {
  ExitEmployeeFieldConfig,
  NoneTypeFieldValidatorConfig,
  WithCompensationTypeFieldValidatorConfig,
  WithoutCompensationTypeFieldValidatorConfig
} from '@/constants/config/employee-form/exit-employee-table-info/FieldValidatorConfig';
import { HOME_OFFICE, EXIT_AGREEMENT_TYPE } from '@/constants/employee';
import { ShanghaiLayoffSlipUpload } from '@/components/shanghai-layoff-slip-upload/ShanghaiLayoffSlipUpload';
import { useEffect, useState } from 'react';

interface ButtonWithDrawerProps {
  form: FormInstance;
  employeeInfo: IExitEmployeeResponse;
  drawerTitle: string;
  supplementTitle: string;
  drawerOpen: boolean;
  closeDrawer: () => void;
  drawerConfirm: () => void;
  popOpen: boolean;
  displayPop: () => void;
  closePop: () => void;
  InfoFieldValidatorConfig: ExitEmployeeFieldConfig[][];
  TypeFieldValidatorConfig: ExitEmployeeFieldConfig[][];
  subTitle?: string;
  fileList: UploadFile[];
  setShanghaiLayoffFileList: (newFileList: UploadFile[]) => void;
  shanghaiLayoffSlipUploaderOpen: boolean;
  setFileUploaderOpen: (isOpen: boolean) => void;
}


export const WithTabDrawer = ({
  form,
  employeeInfo,
  drawerTitle,
  supplementTitle,
  drawerOpen,
  closeDrawer,
  drawerConfirm,
  popOpen,
  displayPop,
  closePop,
  InfoFieldValidatorConfig,
  subTitle,
  fileList,
  setShanghaiLayoffFileList,
  shanghaiLayoffSlipUploaderOpen,
  setFileUploaderOpen
}: ButtonWithDrawerProps) => {
  const [ activeKey, setActiveKey ] = useState('none');

  useEffect(() => {
    setActiveKey(employeeInfo?.agreementType);
    setFileUploaderOpen(employeeInfo?.homeOffice === HOME_OFFICE.SHANGHAI);
  }, [ employeeInfo ]);

  const items = [
    {
      label: '无',
      key: EXIT_AGREEMENT_TYPE.NONE,
      children: (
        <EmployeeInfoForm employeeInfo={employeeInfo} form={form} fieldValidatorConfig={NoneTypeFieldValidatorConfig}/>
      )
    },
    {
      label: '被动离职有补偿',
      key: EXIT_AGREEMENT_TYPE.PASSIVE_WITH_COMPENSATION,
      children: (
        <EmployeeInfoForm employeeInfo={employeeInfo} form={form} fieldValidatorConfig={WithCompensationTypeFieldValidatorConfig}/>
      )
    },
    {
      label: '试用期被动离职无补偿',
      key: EXIT_AGREEMENT_TYPE.PASSIVE_WITHOUT_COMPENSATION_DURING_PROBATIONARY_PERIOD,
      children: (
        <EmployeeInfoForm employeeInfo={employeeInfo} form={form} fieldValidatorConfig={WithoutCompensationTypeFieldValidatorConfig}/>
      )
    },
    {
      label: '主动离职提前离场',
      key: EXIT_AGREEMENT_TYPE.LEAVE_EARLY,
      children: (
        <EmployeeInfoForm employeeInfo={employeeInfo} form={form} fieldValidatorConfig={WithoutCompensationTypeFieldValidatorConfig}/>
      )
    },
  ];

  const renderDrawerTitle = () => (
    <div>
      <span className="headline4 title">{drawerTitle}</span>
      <span className="medium-text employee-id">工号: {employeeInfo?.employeeId}</span>
    </div>
  );

  const renderDrawerExtra = () => (
    <div className="flex">
      <Button onClick={closeDrawer}>取消</Button>
      <Popconfirm
        title="确认标记审核通过？"
        open={popOpen}
        onCancel={closePop}
        onConfirm={drawerConfirm}
        cancelText={'取消'}
        okText={'确认'}
        placement="bottomRight">
        <Button type="primary" style={{ marginLeft: 8 }} onClick={displayPop}>通过</Button>
      </Popconfirm>
    </div>
  );

  const handleTabChange = (key: string) => {
    const formData = form.getFieldsValue(true);
    formData.agreementType = key;
    setActiveKey(key);
  };

  return (
    <Drawer
      width={720}
      title={renderDrawerTitle()}
      placement="right"
      closable={false}
      open={drawerOpen}
      destroyOnClose={true}
      style={{ marginTop: 64 }}
      extra={renderDrawerExtra()}
      className='drawer-content'>
      <EmployeeInfoForm employeeInfo={employeeInfo} form={form} fieldValidatorConfig={InfoFieldValidatorConfig} subTitle={subTitle}/>
      <span className="headline4 supplement-title">{supplementTitle}</span>
      <Tabs activeKey={activeKey} items={items} onChange={handleTabChange} destroyInactiveTabPane={true}/>
      {shanghaiLayoffSlipUploaderOpen &&
        <ShanghaiLayoffSlipUpload employeeInfo={employeeInfo} fileList={fileList} setShanghaiLayoffFileList={setShanghaiLayoffFileList}/>}
    </Drawer>
  );
};
