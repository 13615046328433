// eslint-disable-next-line no-use-before-define
import React, { useEffect, useMemo, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import './SideMenuBar.scss';
import {
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_EMPLOYEE_MENU_CATEGORY,
  MATOMO_MENU_NAME_MAP,
} from '@/constants/matomo';
import { useCustomMatomo } from '@/matomo';
import ChangeRoleToggle from '@/components/change-role-toggle/ChangeRoleToggle';
import { shallowEqual, useSelector } from 'react-redux';
import { isUserHasAdminRole, selectCurrentUserMenu } from '@/store/userInfo';
import { find, map } from 'lodash-es';
import { Link } from 'react-router-dom';
import { useRouterGuard } from '@/utils/useRouterGuard';

type MenuItem = Required<MenuProps>['items'][number];

const SideMenuBar = () => {
  const { trackEvent } = useCustomMatomo();
  const { location, matchedRoutes } = useRouterGuard();
  const [ openKeys, setOpenKeys ] = useState([]);
  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const menuConfigs = useSelector(selectCurrentUserMenu, shallowEqual);
  const showChangeRoleToggle = useSelector(isUserHasAdminRole, shallowEqual);

  const rootSubmenuKeys = useMemo(() =>
    menuConfigs.filter(config => config.children).map(config => config.path), [ menuConfigs ]);

  const clickMenu = key => {
    trackEvent({
      category: MATOMO_EMPLOYEE_MENU_CATEGORY.includes(key) ? MATOMO_CATEGORY.EmployeeMenu : MATOMO_CATEGORY.HroMenu ,
      action: MATOMO_ACTION.Navigation,
      name: MATOMO_MENU_NAME_MAP[key],
    });
  };

  const getSelectedKeys = (menuItem, location) => {
    if (!menuItem?.children) {
      return [ menuItem?.path ];
    }
    const splitStr = location.pathname.split('/');

    const childrenWithMatchedItem = menuItem.children.find(item =>!!item.matchedPath);
    const childrenWithMatchedPath = childrenWithMatchedItem?.matchedPath.find(path => (location.pathname || '').startsWith(path));
    return childrenWithMatchedPath && childrenWithMatchedItem.path
      ? [ childrenWithMatchedItem.path ]
      : [ `/${splitStr[splitStr.length - 1]}` ];
  };

  useEffect(() => {
    const menuItem = find(menuConfigs, item => (location.pathname || '').startsWith(item.path));
    setSelectedKeys(getSelectedKeys(menuItem, location));
    const routesHasChildren = matchedRoutes.find(matchedRoute => rootSubmenuKeys.includes(matchedRoute.pathname));
    if (routesHasChildren) {
      setOpenKeys([ routesHasChildren.pathname ]);
    } else {
      setOpenKeys([]);
    }
  }, [ location.pathname, menuConfigs ]);

  const menuItems: MenuItem[] = useMemo(() => {
    return map(menuConfigs, menuConfig =>
      ({
        label: !menuConfig.children ? <Link to={menuConfig.path}>{menuConfig.text}</Link> : menuConfig.text,
        key: menuConfig.path,
        icon: menuConfig.icon,
        children: menuConfig.children?.map(menuChildren => ({
          label: <Link to={`${menuConfig.path}${menuChildren.path}`}>{menuChildren.text}</Link>,
          key: menuChildren.path
        }))
      })
    );
  }, [ location.pathname, menuConfigs ]);

  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [ latestOpenKey ] : []);
    }
  };

  return (
    <div className="side-bar">
      <Menu
        selectedKeys={selectedKeys} openKeys={openKeys}
        onOpenChange={onOpenChange} mode="inline" items={menuItems} theme="light" onClick={e => clickMenu(e.key)}
      />
      <div className="change-role-toggle-container">
        {showChangeRoleToggle && <ChangeRoleToggle />}
      </div>
    </div>
  );
};

export default SideMenuBar;
