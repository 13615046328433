import { Button, Popconfirm } from 'antd';

interface ButtonWithStampProps {
  hroSignUrl: string;
  disable: boolean;
  text: string;
  popupContainerId?: string;
}

const ButtonWithStamp = (props: ButtonWithStampProps) => {
  const { hroSignUrl, disable, text, popupContainerId } = props;

  const confirmStamp = () => {
    window.open(hroSignUrl);
  };

  return (
    <Popconfirm
      title="确认打开新页面登录e签宝进行落章"
      disabled={disable}
      onConfirm={confirmStamp}
      cancelText={'取消'}
      okText={'确认'}
      placement="bottomRight"
      overlayStyle={{ width: 340 }}
      getPopupContainer={triggerNode => popupContainerId ? document.getElementById(popupContainerId) : triggerNode}>
      <Button
        size="small"
        disabled={disable}
      >
        {text}
      </Button>
    </Popconfirm>
  );
};

export default ButtonWithStamp;
