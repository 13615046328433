import { ColumnsType } from 'antd/es/table';
import { IEmployeeSignedDoneDetail } from '@/models/employee';
import ButtonWithStamp from '@/components/button-with-stamp/ButtonWithStamp';
import { EMPLOYEE_CONTRACT_SIGN_STATUS } from '@/constants/status';
// eslint-disable-next-line no-use-before-define
import React from 'react';


export const employeeSignedDoneDetailColumns: ColumnsType<IEmployeeSignedDoneDetail> = [
  {
    title: '办公地点',
    dataIndex: 'homeOfficeChinese',
    className: 'align-center',
    width: '10%',
  },
  {
    title: '工号',
    dataIndex: 'employeeId',
    className: 'align-center',
    width: '12%',
  },
  {
    title: '姓名',
    dataIndex: 'chineseName',
    className: 'show-ellipsis align-center',
    width: '12%',
  },
  {
    title: '入职日期',
    dataIndex: 'hireDate',
    className: 'align-center',
    width: '12%',
  },
  {
    title: '员工类型',
    dataIndex: 'employmentType',
    className: 'align-center',
    width: '16%',
  },
  {
    title: '盖公章',
    dataIndex: 'contractSignStatus',
    width: '16%',
    className: 'last-td align-center',
    render: (_, record) => {
      return <ButtonWithStamp hroSignUrl={record.hroSignUrl}
        disable={record.contractSignStatus !== EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_SIGNING}
        text={record.contractSignStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_SIGNING ? '盖公章' : '已盖章'}
        popupContainerId={null}/>;
    }
  },
];
