import { Tabs } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IIssueCertificateResponse } from '@/models/employee';
import TotalNumber from '@/components/total-number/TotalNumber';
import StampedDoneTableInfo from '@/pages/hro/issue-certificate/stamped-done-table-info/StampedDoneTableInfo';
import ToBeStampedTableInfo from '@/pages/hro/issue-certificate/to-be-stamped-table-info/ToBeStampedTableInfo';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY } from '@/constants/matomo';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { CERTIFICATE_PROCESS_STATUS } from '@/constants/status';
import { getIssueCertificateInfo } from '@/api/certificateApi';

const TAB_COUNT_MAPPER = {
  [CERTIFICATE_PROCESS_STATUS.PENDING_CONFIRM]: 'toBeStampedUserCount',
  [CERTIFICATE_PROCESS_STATUS.CONFIRMED]: 'stampedDoneUserCount',
};

const context = [
  {
    title: '待盖章员工',
    value: CERTIFICATE_PROCESS_STATUS.PENDING_CONFIRM,
    matomoName: 'To be stamped'
  },
  {
    title: '完成盖章员工',
    value: CERTIFICATE_PROCESS_STATUS.CONFIRMED,
    matomoName: 'Stamped done'
  }
];
const IssueCertificate = () => {
  const [ searchParams, setSearchParams ] = useSearchParams({});
  const [ issueCertificateData, setIssueCertificateData ] = useState<IIssueCertificateResponse>({} as IIssueCertificateResponse);
  const [ currentTab, setCurrentTab ] = useState<string>(CERTIFICATE_PROCESS_STATUS.PENDING_CONFIRM);
  const { trackEvent } = useCustomMatomo();
  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();

  const getTotalData = useCallback(() => {
    getIssueCertificateInfo(CERTIFICATE_PROCESS_STATUS.PENDING_CONFIRM).then((res: IIssueCertificateResponse) => {
      setIssueCertificateData(res);
    });
  }, []);

  useEffect(() => {
    if (userInfo && hasPermission) {
      getTotalData();
      if (document.querySelector('.ant-tabs-nav-more')) {
        document.querySelector('.ant-tabs-nav-more').setAttribute('role', 'tab');
      }
    }
  }, [ userInfo, hasPermission ]);

  const getTableInfo = (value: string) => {
    if (value === CERTIFICATE_PROCESS_STATUS.PENDING_CONFIRM) {
      return <ToBeStampedTableInfo totalCount={issueCertificateData && issueCertificateData.toBeStampedUserCount}
        toBeStampedData={issueCertificateData.issueCertificateListedInfoItem}/>;
    }
    return <StampedDoneTableInfo totalCount={issueCertificateData && issueCertificateData.stampedDoneUserCount}/>;
  };

  const items = useMemo(() => context.map(({ title, value }) => {
    return {
      label: <TotalNumber title={title} totalCount={issueCertificateData && issueCertificateData[TAB_COUNT_MAPPER[value]]}/>,
      key: value,
      children: getTableInfo(value)
    };
  }), [ issueCertificateData.toBeStampedUserCount ]);

  const clickTab = useCallback(activeKey => {
    if (activeKey !== currentTab) {
      trackEvent({
        category: MATOMO_CATEGORY.HroIssueCertificate,
        action: MATOMO_ACTION.SwitchTab,
        name: context.find(item => item.value === activeKey).matomoName,
      });
      setCurrentTab(activeKey);
      setSearchParams({ tab: activeKey });
      getTotalData();
    }
  }, [ currentTab ]);
  return (
    <div className="main-page">
      <h3 className="headline3">员工开具证明</h3>
      <div className="container">
        <h4 className="headline4">员工信息&证明材料审核</h4>
        <Tabs
          type="card"
          defaultActiveKey={searchParams.get('tab') || CERTIFICATE_PROCESS_STATUS.PENDING_CONFIRM}
          items={items}
          onTabClick={clickTab}
          destroyInactiveTabPane={true}/>
      </div>
    </div>
  );
};

export default IssueCertificate;
