import { Checkbox, Form } from 'antd';
import './Commitment.scss';

interface ICommitmentProps {
    content: string
    englishContent: string;
}

const Commitment = (props: ICommitmentProps) => {
  return (
    <div className="commitment">
      <div className="commitment-label large-text">个人信息承诺书</div>
      <div className="commitment-content">
        <p>{props.content}</p>
        <p className="english-content">{props.englishContent}</p>
        <Form.Item
          className="commitment-form-item"
          name="agree"
          valuePropName="checked"
          rules={[ {
            validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('请勾选')),
          } ]}
        >
          <Checkbox className="confirm-checkbox">
            <span className="confirm-text">本人已确认以上内容
              <span className="confirm-text config-text-en">(I agree)</span>
              <span className="confirm-symbol"> *</span>
            </span>
          </Checkbox>
        </Form.Item>
      </div>
    </div>);
};

export default Commitment;