import { Button, Form, message, Popconfirm, Upload, UploadFile } from 'antd';
import Icon from '@ant-design/icons';
import InfoCircleSvg from '@/assets/icons/info-circle.svg';
import UploadSvg from '@/assets/icons/upload.svg';
import Commitment from '@/components/commitment/Commitment';
import './EmployeeMaterialsUpload.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployeeMaterials, updateEmployeeMaterials } from '@/api/employeeApi';
import { getMaterialConfigs, IMaterialConfig } from './EmployeeMaterialsConfig';
import { isEmpty, noop } from 'lodash-es';
import { CHINA_NATIONAL_ID, CONTRACT_SIGN_TYPE } from '@/constants/employee';
import { IEmployeeFiles, IEmployeeMaterials, IUploadFile } from '@/models/employee';
import { AxiosError } from 'axios';
import { IErrorResponse, IInvalidFormFields } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import { PerformanceTracker, useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { alertErrorMessage, commonUploadConfig, generateFileName, normFile } from '@/utils/fileUpload';
import EmployeeInfoField from '@/components/employee-info-field/EmployeeInfoField';

const RequiredRule = {
  required: true,
  message: '请提供材料',
};

const EmployeeMaterialsUpload = () => {
  const [ form ] = Form.useForm();
  const [ employeeInfo, setEmployeeInfo ] = useState<IEmployeeMaterials>({} as IEmployeeMaterials);
  const navigate = useNavigate();
  const [ materialConfigs, setMaterialConfigs ] = useState<IMaterialConfig[]>([]);
  const [ popOpen, setPopOpen ] = useState<boolean>(false);
  const { trackEvent } = useCustomMatomo();
  const { processId } = useParams();
  const [ uploadDocs, setUploadDocs ] = useState([]);

  const validateForm = () => {
    if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
      trackEvent({
        category: MATOMO_CATEGORY.EmployeeOperateInductionMaterials,
        action: MATOMO_ACTION.Submit,
        name: MATOMO_NAME.InductionMaterial,
      });
    }
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  };

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    const newEmployeeMaterials: IEmployeeFiles = { agree: false, uploadItems: {}, processId };
    Object.keys(formValues).forEach(key => {
      const value = formValues[key];
      if (key !== 'agree') {
        newEmployeeMaterials.uploadItems[key] = value?.map(item => item.response);
      } else {
        newEmployeeMaterials[key] = value;
      }
    });
    if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
      trackEvent({
        category: MATOMO_CATEGORY.EmployeeOperateInductionMaterials,
        action: MATOMO_ACTION.Confirm,
        name: MATOMO_NAME.InductionMaterial,
        value: PerformanceTracker.stop(),
      });
    } else if (uploadDocs.length > 0 && employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE) {
      trackEvent({
        category: MATOMO_CATEGORY.EmployeeUploadExtensionDocuments,
        action: MATOMO_ACTION.Upload,
        name: MATOMO_NAME.UploadDocuments,
      });
    }
    updateEmployeeMaterials(newEmployeeMaterials).then(() => {
      message.success(employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE ? '已成功上传员工材料' : '已成功上传入职材料');
      navigate('/employee-home');
    }, (err: AxiosError<IErrorResponse> | IInvalidFormFields) => {
      if ((err as AxiosError<IErrorResponse>).response?.data.code ===
          (ERROR_CODE.hireDateIllegal || ERROR_CODE.contractExtensionDateIllegal)) {
        navigate('/employee-home');
      } else {
        alertErrorMessage(err as AxiosError<IErrorResponse>);
      }
    });
  };

  const getRequired = (config: IMaterialConfig, employeeInfo: IEmployeeMaterials) => {
    if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE) {
      return false;
    }
    return config.key === 'householdRegister' ? employeeInfo.nationId === CHINA_NATIONAL_ID : config.required;
  };

  useEffect(() => {
    PerformanceTracker.start();
    getEmployeeMaterials(processId).then(employeeInfo => {
      if (!employeeInfo) {
        return;
      }
      setEmployeeInfo(employeeInfo);
      const employeeInfoKeys = Object.keys(employeeInfo.uploadItems);
      const currentConfigs = getMaterialConfigs(employeeInfo.nationId, employeeInfo.contractSignType)
        .filter(config => employeeInfoKeys.includes(config.key))
        .map(config => {
          const defaultFileList = employeeInfo.uploadItems[config.key]?.map((fileItem: IUploadFile) => ({
            uid: fileItem.fileId,
            name: fileItem.fileName,
            status: 'done',
            response: fileItem
          }));
          return {
            ...config,
            initialValue: defaultFileList,
            required: getRequired(config, employeeInfo),
            uploadProps: {
              ...commonUploadConfig,
              ...config.uploadProps,
              beforeUpload: file => {
                const previousUploadCount = form.getFieldValue(config.key)?.length;
                const validateResult = config.validator(file, previousUploadCount, config.uploadProps.maxCount);
                if (validateResult !== true) {
                  return validateResult;
                }
                return new File([ file ], generateFileName(file, config.name, employeeInfo));
              },
              customRequest: args => {
                const customArgs = { ...args, data: { ...config, processId } };
                if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
                  trackEvent({
                    category: MATOMO_CATEGORY.EmployeeOperateInductionMaterials,
                    action: MATOMO_ACTION.Upload,
                    name: `${MATOMO_NAME.InductionMaterialFile}(${config.englishLabel})`,
                  });
                }
                uploadDocs.push('upload doc');
                setUploadDocs(uploadDocs);
                commonUploadConfig.customRequest(customArgs);
              },
              defaultFileList,
              onRemove: (file: UploadFile) => {
                if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
                  trackEvent({
                    category: MATOMO_CATEGORY.EmployeeOperateInductionMaterials,
                    action: MATOMO_ACTION.Remove,
                    name: `${MATOMO_NAME.InductionMaterialFile}(${config.englishLabel})`,
                  });
                }
                setUploadDocs(uploadDocs.pop());
                return commonUploadConfig.onRemove(file);
              }
            }
          };
        });
      setMaterialConfigs(currentConfigs);
    }, (err: AxiosError<IErrorResponse>) => {
      if (err.response.data.code === ERROR_CODE.hireDateIllegal) {
        navigate('/employee-home');
      }
    });
  }, []);

  const renderFormLabel = (label: string, subLabel: string, desc: string) => (
    <span className="form-label small-text">
      {label}<span className="sub-form-label small-text">({subLabel})</span>
      {desc && <div className="employee-material-desc">{desc}</div>}
    </span>
  );

  if (isEmpty(employeeInfo)) {
    return (
      <div className="empty-container"></div>
    );
  }

  const isContractExtensionEmployee = () => {
    return employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE;
  };

  return (
    <Form
      className="employee-materials-upload-form"
      name="employee-materials-upload"
      form={form}
      layout="vertical"
      validateTrigger={[ 'onChange' ]}
    >
      <div className="form-group">
        <div className="employee-info">
          <span className="employee-name">{employeeInfo.legalFullName}</span><i className="split-line"></i>
          {EmployeeInfoField('工号', 'Employee ID', employeeInfo.employeeId)}<i className="split-line"></i>
          {EmployeeInfoField('入职时间', 'Hire Date', employeeInfo.hireDate)}
          {isContractExtensionEmployee() && <i className="split-line"></i>}
          {isContractExtensionEmployee() && EmployeeInfoField('续签时间', 'Contract Extension Date', employeeInfo.extensionDate)}
        </div>
        <div className="employee-materials-tip">
          <Icon className="info-icon" component={InfoCircleSvg}/>
          <div className="employee-materials-tip-content">
            <p>如果下方的个人资料有更新，请将更新版上传。</p>
            <p>除照片（PNG 或 JPEG）以外所有上传文件的格式类型为 PDF。所有上传文件均要求为彩色扫描件，且单个文件大小不可超过10M。</p>
            {/* eslint-disable-next-line max-len */}
            <p>Only available if personal files need to be updated.</p>
            <p>Please note that acceptable photo type include PNG and JPEG and other acceptable file types are PDF. Your scans must be in
              color and each individual file (scanned document) must be no larger than 10 MB (megabytes).</p>
          </div>
        </div>
        {materialConfigs.map((materConfig, index) => (
          <Form.Item
            className={materConfig.desc ? 'form-item-with-desc' : ''}
            valuePropName="filelist"
            name={materConfig.key}
            label={renderFormLabel(`${index + 1}. ${materConfig.label}`, materConfig.englishLabel, materConfig.desc)}
            htmlFor=""
            rules={materConfig.required ? [ RequiredRule ] : null}
            initialValue={materConfig.initialValue}
            getValueFromEvent={normFile}
            key={materConfig.key}
          >
            <Upload {...materConfig.uploadProps} data-testid={index + 1}>
              <Button size="small" icon={<Icon className="upload-icon" component={UploadSvg}/>}>上传文件</Button>
              <span className="file-size-limit" onClick={e => e.stopPropagation()}>
                文件数限制 (Max files)：{materConfig.uploadProps.maxCount}
              </span>
            </Upload>
          </Form.Item>

        ))}
      </div>
      <Commitment
        /* eslint-disable-next-line max-len */
        content="本人已阅读并同意 Workday 系统中 Privacy Notice 所述的相关处理，同意亲自完成“电子签信息采集平台”（以下简称“平台”）上包括但不限于填写个人信息及上传资料等操作，同意以上个人信息将用于入职前准备工作以及入职后用工管理之用途，并郑重承诺在平台上提交的一切个人资料或影像，均真实有效。"
        /* eslint-disable-next-line max-len */
        englishContent='I have read and I consent to the processing as described in Privacy Notice in the Workday system and agree to personally complete the actions on the E-Sign Information Collection Platform (hereinafter referred to as the "Platform"), including but not limited to filling in personal information and uploading documents, and agree to the processing regard to above personal information and documents for the purpose of the onboarding preparation and employment management. I solemnly promise that all personal data or images submitted on the Platform are true and valid.'
      />
      <Form.Item className="submit-btn">
        <Popconfirm
          className="confirm-popover"
          placement="bottomLeft"
          open={popOpen}
          title="确认提交入职材料和承诺书？"
          onConfirm={onSubmit}
          onCancel={() => setPopOpen(false)}
          okText="确认"
          cancelText="取消"
          getPopupContainer={triggerNode => triggerNode}
          overlayStyle={{ width: 294 }}
        >
          <Button type="primary" onClick={validateForm}>提交</Button>
        </Popconfirm>
      </Form.Item>
    </Form>
  );
};

export default EmployeeMaterialsUpload;
