export const RequiredRule = {
  required: true,
  message: '信息不可为空',
};

export const IdRule = {
  pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  message: '证件号无效',
};

export const NotBlankRule = {
  pattern: /\S/,
  message: '信息不可为空',
};