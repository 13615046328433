import { Badge } from 'antd';
import './TotalNumber.scss';

interface TotalNumberProps {
    title:string
    totalCount:number
}

const TotalNumber = (props:TotalNumberProps) => {
  const { title, totalCount } = props;
  return (
    <>
      <a>{title}</a>
      <Badge className="tab-badge" count={totalCount} overflowCount={999} showZero />
    </>
  );
};

export default TotalNumber;
