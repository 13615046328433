import { ColumnType } from 'antd/es/table';
import { IIssueCertificateInfo, } from '@/models/employee';
import { Button, Empty, Form, Input, message, Modal, Popconfirm, Table } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import CertificateReview from '@/pages/hro/issue-certificate/certificate-review/CertificateReview';
import { modalText } from '@/constants/modalText';
import { noop } from 'lodash-es';
import './ToBeStampedTableInfo.scss';
import NotificationModal from '@/components/modal/NotificationModal';
import { NotBlankRule, RequiredRule } from '@/constants/validation';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import {
  issueCertificateInfoColumns
} from '@/constants/config/hro/issue-certificate/to-be-stamped-table-info/ToBeStampedTableInfoConfig';
import { CERTIFICATE_PROCESS_STATUS } from '@/constants/status';
import { getHroStampUrl, rejectCertificate } from '@/api/certificateApi';

interface TableInfoProps {
  totalCount: number,
  toBeStampedData: IIssueCertificateInfo[]
}

const ToBeStampedTableInfo = ({ totalCount, toBeStampedData }: TableInfoProps) => {
  const [ isCertificateReviewModalOpen, setIsCertificateReviewModalOpen ] = useState(false);
  const [ isCertificateRejectModalOpen, setIsCertificateRejectModalOpen ] = useState(false);
  const [ isCertificateRejectSuccessModalOpen, setIsCertificateRejectSuccessModalOpen ] = useState(false);
  const [ currentEmployeeId, setCurrentEmployeeId ] = useState(null);
  const [ currentFileUrl, setCurrentFileUrl ] = useState(null);
  const [ currentProcessId, setCurrentProcessId ] = useState(null);
  const [ currentRejectReason, setCurrentRejectReason ] = useState('');
  const { trackEvent } = useCustomMatomo();
  const [ form ] = Form.useForm();

  const redirectToHroStampUrl = (stampUrl: string) => {
    window.open(stampUrl);
  };

  const formItemConfig = {
    rules: [ RequiredRule, NotBlankRule ],
    component: <Input.TextArea
      onChange={event => {
        setCurrentRejectReason(event.target.value);
      }}
      onClick={() => {
        trackEvent({
          category: MATOMO_CATEGORY.HroToBeStamped,
          action: MATOMO_ACTION.InputRejectReason,
          name: MATOMO_NAME.RejectAudit,
        });}
      }
      placeholder="请输入驳回原因"
      autoSize={{ minRows: 4, maxRows: 4 }}
      maxLength={200}/>,
    name: 'rejectReason',
    validateTrigger: 'onChange'
  };

  const openCertificateReviewModal = (employeeId: string, processId: string, fileUrl: string) => {
    setCurrentEmployeeId(employeeId);
    setCurrentFileUrl(fileUrl);
    setCurrentProcessId(processId);
    setIsCertificateReviewModalOpen(true);
  };

  const onReject = useCallback(() => {
    const rejectRequest = {
      rejectReason: currentRejectReason,
      processId: currentProcessId
    };
    rejectCertificate(rejectRequest)
      .then(() => {
        setIsCertificateRejectModalOpen(false);
        form.resetFields([ 'rejectReason' ]);
        setIsCertificateRejectSuccessModalOpen(true);
      }).catch(() => {
        message.error('操作失败，请刷新一下');
      });
  }, [ currentProcessId, currentRejectReason ]);

  const validateForm = () => {
    trackEvent({
      category: MATOMO_CATEGORY.HroToBeStamped,
      action: MATOMO_ACTION.Confirm,
      name: MATOMO_NAME.RejectAudit,
    });
    form.validateFields().then(() => {
      onReject();
    }, noop);
  };

  const onConfirmPass = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroToBeStamped,
      action: MATOMO_ACTION.Confirm,
      name: MATOMO_NAME.AuditStamp,
    });
    getHroStampUrl(currentEmployeeId, currentProcessId)
      .then(hroStampUrl => {
        window.open(hroStampUrl);
        setIsCertificateReviewModalOpen(false);
      }).catch(() => {
        message.error('操作失败，请刷新一下');
      }).finally(() => {
        if (!isCertificateReviewModalOpen){
          window.location.reload();
        }
      });
  }, [ currentEmployeeId, currentProcessId ]);

  const onCancelPass = () => {
    trackEvent({
      category: MATOMO_CATEGORY.HroToBeStamped,
      action: MATOMO_ACTION.Cancel,
      name: MATOMO_NAME.AuditStamp,
    });
  };

  const tableColumns = useMemo(() => {
    const toBeStampedButtonCol = issueCertificateInfoColumns.find((col: ColumnType<IIssueCertificateInfo>) =>
      col.dataIndex === 'certificateProcessStatus');
    toBeStampedButtonCol.render = (_, { employeeId, processId, fileUrl, stampUrl, certificateProcessStatus }: IIssueCertificateInfo) => {
      const onClickMethod = () => {
        trackEvent({
          category: MATOMO_CATEGORY.HroToBeStamped,
          action: MATOMO_ACTION.Click,
          name: MATOMO_NAME.AuditStamp,
        });
        if (certificateProcessStatus === CERTIFICATE_PROCESS_STATUS.CONFIRMED) {
          redirectToHroStampUrl(stampUrl);
        } else {
          openCertificateReviewModal(employeeId, processId, fileUrl);
        }
      };

      return (
        <Button
          className="preview-materials"
          size="small"
          onClick={() => onClickMethod()}
        >
          审核盖章
        </Button>
      );
    };
    return issueCertificateInfoColumns;
  }, []);

  return (
    <>
      {
        isCertificateReviewModalOpen &&
        <Modal
          open={isCertificateReviewModalOpen}
          wrapClassName="certificate-check-modal"
          title="员工证明审核"
          width={1000}
          style={{ top: 20 }}
          destroyOnClose={true}
          onCancel={() => {
            trackEvent({
              category: MATOMO_CATEGORY.HroToBeStamped,
              action: MATOMO_ACTION.Close,
              name: MATOMO_NAME.AuditStamp,
            });
            setIsCertificateReviewModalOpen(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => {
              trackEvent({
                category: MATOMO_CATEGORY.HroToBeStamped,
                action: MATOMO_ACTION.RejectAudit,
                name: MATOMO_NAME.AuditStamp,
              });
              setIsCertificateRejectModalOpen(true);
            }}>驳回</Button>,
            <Popconfirm
              key="pass"
              className="confirm-popover"
              placement="topRight"
              title="确认标记审核通过？"
              onConfirm={onConfirmPass}
              onCancel={onCancelPass}
              okText="确认"
              cancelText="取消"
              overlayStyle={{ width: 222 }}
              getPopupContainer={triggerNode => triggerNode}
            >
              <Button type="primary" onClick={()=> {
                trackEvent({
                  category: MATOMO_CATEGORY.HroToBeStamped,
                  action: MATOMO_ACTION.PassAudit,
                  name: MATOMO_NAME.AuditStamp,
                });
              }
              }>通过</Button>
            </Popconfirm>
          ]}
        >
          <CertificateReview employeeId={currentEmployeeId} certificateFileUrl={currentFileUrl}/>
        </Modal>
      }
      {
        isCertificateRejectModalOpen &&
        <Modal
          title="请输入驳回原因"
          width={416}
          wrapClassName={'certificate-reject-reason-input-modal'}
          open={isCertificateRejectModalOpen}
          centered
          keyboard={false}
          destroyOnClose={true}
          maskClosable={false}
          okText="确认"
          cancelText="取消"
          onOk={validateForm}
          onCancel={() =>{
            trackEvent({
              category: MATOMO_CATEGORY.HroToBeStamped,
              action: MATOMO_ACTION.Close,
              name: MATOMO_NAME.RejectAudit,
            });
            setIsCertificateRejectModalOpen(false);
          }
          }>
          <Form form={form}>
            <Form.Item
              validateTrigger={formItemConfig.validateTrigger}
              rules={formItemConfig.rules}
              name={formItemConfig.name}
              preserve={false}
            >
              {formItemConfig.component}
            </Form.Item>
          </Form>
        </Modal>
      }
      {
        isCertificateRejectSuccessModalOpen &&
        <NotificationModal
          wrapClass={'certificate-reject-success-modal'}
          text={modalText.onRejectSuccess}
          onCancel={() => {
            setIsCertificateRejectModalOpen(false);
            setIsCertificateReviewModalOpen(false);
            setIsCertificateRejectSuccessModalOpen(false);
            window.location.reload();
          }}/>
      }
      <Table
        columns={tableColumns}
        dataSource={toBeStampedData}
        rowKey="processId"
        pagination={{ defaultCurrent: 1, defaultPageSize: 20, total: totalCount, showSizeChanger: false }}
        scroll={{ y: 'calc(100vh - 470px)' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
    </>
  );
};
export default ToBeStampedTableInfo;
