import { configureStore, createSlice } from '@reduxjs/toolkit';

import { reducer as employeeReducer } from '@/store/employee';
import { reducer as userReducer } from '@/store/userInfo';
import { reducer as hroReducer } from '@/store/hroInfo';

const loadingReducer = createSlice({
  name: 'loading',
  initialState: { spinning: false },
  reducers: {
    setLoading: (state, action) => {
      state.spinning = action.payload;
    }
  }
});


const store = configureStore({
  reducer: {
    employee: employeeReducer,
    loading: loadingReducer.reducer,
    user: userReducer,
    hro: hroReducer
  },
  devTools: true
});

export const setLoading = loadingReducer.actions.setLoading;

export const selectLoading = state => state.loading.spinning;

export default store;
