import { getEmployeeEditInfo, updateEmployeeEditInfo } from '@/api/employeeApi';
import { Button, Form, message, StepProps, Steps } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Commitment from '@/components/commitment/Commitment';
import { CHINA_NATION_IDS, EMPLOYMENT_TYPE, ID_TYPE } from '@/constants/employee';
import { IEmployeeEditInfo, IIdType, INation } from '@/models/employee';
import { AxiosError } from 'axios';
import { IErrorResponse, IInvalidFormFields } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import './EmployeeInfoEdit.scss';
import EducationInfo from './education-info/EducationInfo';
import WorkInfo from './work-info/WorkInfo';
import SkillInfo from './skill-info/SkillInfo';
import {
  CONTRACT_EXTENSION_EDIT_STEP,
  EDIT_STEP,
  handleEducationValueChange,
  initEducationDataForForm,
  saveFullEducationFormValue,
  transformRangeStrToDateStr
} from './education-info/EducationUtil';
import { localStorageUtils } from '@/utils/localStorageUtils';
import PersonalInfo from '@/pages/employee/employee-info-edit/personal-info/PersonalInfo';
import { handleWorkInfoValueChange, initWorkExperienceDataForForm, saveFullWorkExperienceFormValue } from './work-info/WorkInfoUtil';
import { handleSkillInfoValueChange, initSkillInfoData, transformSkillInfo } from './skill-info/SkillInfoUtil';
import { handlePersonalInfoValueChange, initPersonalInfoData, transformPersonalInfo } from './personal-info/PersonalInfoUtil';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { PerformanceTracker, useCustomMatomo } from '@/matomo';
import FormSubmitButton from '@/components/form-submit-button/FormSubmitButton';

let StepsConfig: StepProps[] = [
  {
    title: '个人信息',
    description: 'Personal Information',
  },
  {
    title: '教育信息',
    description: 'Education Experience',
  },
  {
    title: '工作信息',
    description: 'Work Experience',
  },
  {
    title: '技能信息',
    description: 'Personal Skills',
  },
];

function saveFullPersonalInfoFormValue(employeeId: string, values: IEmployeeEditInfo) {
  localStorageUtils.setCacheItem(
    `${employeeId}-${EDIT_STEP[EDIT_STEP.PERSONAL]}-full-form-value`,
    values && values.personalInfo,
  );
}

function saveFullSkillInfoFormValue(employeeId: string, values: IEmployeeEditInfo) {
  localStorageUtils.setCacheItem(
    `${employeeId}-${EDIT_STEP[EDIT_STEP.SKILL]}-full-form-value`,
    values && values.skillInfo
  );
}

function getAllStepsFormValue(employeeId: string) {
  const cachePersonalInfo = localStorageUtils.getCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.PERSONAL]}-full-form-value`);
  const cacheEducationInfo = localStorageUtils.getCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-full-form-value`);
  const cacheWorkExperienceInfo = localStorageUtils.getCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-full-form-value`);
  const ret = {
    personalInfo: cachePersonalInfo,
    educationalInfo: {
      ...cacheEducationInfo,
      educationalInfoItems: cacheEducationInfo?.educationalInfoItems.map(transformRangeStrToDateStr),
    },
    workExperienceInfo: {
      ...cacheWorkExperienceInfo,
      workExperienceInfoItems: cacheWorkExperienceInfo?.workExperienceInfoItems.map(transformRangeStrToDateStr)
    }
  };
  ret.personalInfo.hasWorkExperience = ret.workExperienceInfo.workExperienceInfoItems?.length > 0;
  return ret;
}

const StepChangeHandler = {
  [EDIT_STEP.PERSONAL]: saveFullPersonalInfoFormValue,
  [EDIT_STEP.EDUCATION]: saveFullEducationFormValue,
  [EDIT_STEP.WORK]: saveFullWorkExperienceFormValue,
  [EDIT_STEP.SKILL]: saveFullSkillInfoFormValue,
};

const ContractExtensionStepChangeHandler = {
  [CONTRACT_EXTENSION_EDIT_STEP.PERSONAL]: saveFullPersonalInfoFormValue,
  [CONTRACT_EXTENSION_EDIT_STEP.EDUCATION]: saveFullEducationFormValue,
  [CONTRACT_EXTENSION_EDIT_STEP.SKILL]: saveFullSkillInfoFormValue,
};

interface EmployeeInfoEditProps {
  isEdit?: boolean;
}

const EmployeeInfoEdit = ({ isEdit }: EmployeeInfoEditProps) => {
  const navigate = useNavigate();
  const { processId } = useParams();
  const [ form ] = Form.useForm();
  const [ employeeInfo, updateEmployeeInfo ] = useState<IEmployeeEditInfo>(null);
  const [ nations, updateNations ] = useState<INation[]>([]);
  const [ idTypes, updateIdTypes ] = useState<IIdType[]>([]);
  const [ stepsConfig, setStepsConfig ] = useState<StepProps[]>([]);
  const contractSignType = useRef('');
  const [ popOpen, setPopOpen ] = useState<boolean>(false);
  const isContractExtensionEmployee = () => {
    return contractSignType.current === 'contract_extension_employee';
  };

  const isHireEmployee = () => {
    return contractSignType.current === 'hire_employee';
  };

  function getStepsStatus(employeeId: string, activeIndex: number) {
    const stepCache = [];
    return StepsConfig.map((config: StepProps, index: number) => {
      const newConfig = { ...config };
      stepCache[index] = isContractExtensionEmployee()
        ? localStorageUtils.getCacheItem(`${employeeId}-${EDIT_STEP[index]}-full-form-value`)
        : localStorageUtils.getCacheItem(`${employeeId}-${CONTRACT_EXTENSION_EDIT_STEP[index]}-full-form-value`);
      if (index === activeIndex) {
        newConfig.status = 'process';
        return newConfig;
      }
      if (stepCache[index]) {
        newConfig.status = 'finish';
      } else {
        newConfig.status = 'wait';
      }
      // some previous step(not last) has incomplete form value.
      if (index > activeIndex && stepCache.slice(activeIndex + 1, index).some(item => !item)) {
        newConfig.disabled = true;
      }
      return newConfig;
    });
  }

  useEffect(() => {
    PerformanceTracker.start();
    getEmployeeEditInfo(processId).then(employeeEditInfo => {
      if (!employeeEditInfo) {
        return;
      }
      contractSignType.current = employeeEditInfo.contractSignType;
      if (isContractExtensionEmployee()) {
        StepsConfig = StepsConfig.filter(stepConfig => stepConfig.title != '工作信息');
        setStepsConfig(StepsConfig);
      }
      initEducationDataForForm(employeeEditInfo, isEdit);
      if (isHireEmployee()) {
        initWorkExperienceDataForForm(employeeEditInfo, isEdit);
      }
      initSkillInfoData(employeeEditInfo, isEdit);
      initPersonalInfoData(employeeEditInfo, isEdit);

      setStepsConfig(getStepsStatus(employeeEditInfo.personalInfo?.employeeId, 0));
      updateEmployeeInfo(employeeEditInfo);
      const { personalInfo } = employeeEditInfo;
      updateNations([ {
        nationId: personalInfo.nationId,
        nationality: CHINA_NATION_IDS.includes(personalInfo.nationId) ? '中国' : personalInfo.nationality,
      } ]);
      updateIdTypes([ {
        idType: personalInfo.idType,
        idTypeName: ID_TYPE[personalInfo.idType],
      } ]);
    }, (err: AxiosError<IErrorResponse>) => {
      if (err.response.data.code === ERROR_CODE.hireDateIllegal || err.response.data.code === ERROR_CODE.contractExtensionDateIllegal) {
        navigate('/employee-home');
      }
    });
  }, []);

  const { trackEvent } = useCustomMatomo();
  let step = stepsConfig.findIndex(stepConfig => stepConfig.status === 'process');
  step = step === -1 ? 0 : step;
  const employeeId = employeeInfo?.personalInfo?.employeeId;
  const isEmployee = employeeInfo?.personalInfo?.employeeType === EMPLOYMENT_TYPE.EMPLOYEE;

  const onSubmit = async () => {
    form.validateFields()
      .then(values => {
        const stepsFormValue = isEmployee ? getAllStepsFormValue(employeeId) : {};
        const skillInfoValue = transformSkillInfo(values);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formValues = { ...skillInfoValue, ...stepsFormValue } as any;
        transformPersonalInfo(formValues);
        trackEvent({
          category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
          action: MATOMO_ACTION.Confirm,
          name: MATOMO_NAME.PersonalInfo,
          value: PerformanceTracker.stop(),
        });
        formValues.processId = processId;
        return updateEmployeeEditInfo(formValues);
      })
      .then(() => {
        message.success('已成功提交员工信息');
        navigate('/employee-home');
        localStorageUtils.removeEmployeeCacheItem(employeeId);
      }, (err: AxiosError<IErrorResponse> | IInvalidFormFields) => {
        if ((err as AxiosError<IErrorResponse>).response?.data.code === ERROR_CODE.hireDateIllegal ||
          (err as AxiosError<IErrorResponse>).response?.data.code === ERROR_CODE.contractExtensionDateIllegal) {
          navigate('/employee-home');
        } else if ((err as AxiosError<IErrorResponse>).response?.data.code === ERROR_CODE.userOperationIllegal) {
          message.error('上传失败：个人信息已审核通过，需更新请联系People Support Team');
        }
      });
  };

  const handleStepChange = useCallback((currentStep: number, newStep: number) => {
    form.validateFields()
      .then(values => { // save to localStorage when form value is valid
        if (isContractExtensionEmployee()) {
          ContractExtensionStepChangeHandler[currentStep](employeeId, values);
        } else {
          StepChangeHandler[currentStep](employeeId, values);
        }
        setStepsConfig(getStepsStatus(employeeId, newStep));
      }, () => { // remove from localStorage when form value is invalid
        if (isContractExtensionEmployee()) {
          ContractExtensionStepChangeHandler[currentStep](employeeId, undefined);
        } else {
          StepChangeHandler[currentStep](employeeId, undefined);
        }
        if (newStep < currentStep) { // can always go to previous steps
          setStepsConfig(getStepsStatus(employeeId, newStep));
        }
      })
      .finally(() => {
        trackEvent({
          category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
          action: MATOMO_ACTION.Step,
          name: StepsConfig[newStep].description as string,
        });
      });
  }, [ form, employeeId ]);

  const goToPrevious = useCallback(() => {
    handleStepChange(step, step - 1);
  }, [ step, form, employeeId ]);
  const goToNext = useCallback(() => {
    handleStepChange(step, step + 1);
  }, [ step, form, employeeId ]);
  const stepChange = useCallback((current: number) => {
    handleStepChange(step, current);
  }, [ step, form, employeeId ]);


  const handleValuesChange = useCallback(changedValues => {
    handlePersonalInfoValueChange(employeeId, form, changedValues);
    handleEducationValueChange(employeeId, form, changedValues);
    if (isHireEmployee()) {
      handleWorkInfoValueChange(employeeId, form, changedValues);
    }
    handleSkillInfoValueChange(employeeId, form, changedValues);
  }, [ form, employeeInfo ]);

  const isShowSubmit = !isEmployee || step === EDIT_STEP.SKILL && isHireEmployee() ||
    isContractExtensionEmployee() && step === CONTRACT_EXTENSION_EDIT_STEP.SKILL;

  return (
    employeeInfo &&
    <Form
      className="employee-edit-form"
      name="employee-edit"
      form={form}
      layout="vertical"
      validateTrigger={[ 'onBlur', 'onChange' ]}
      onValuesChange={handleValuesChange}
      initialValues={employeeInfo}
    >
      {isEmployee && <Steps current={step} items={stepsConfig} onChange={stepChange}/>}
      {(!isEmployee || step === 0) &&
        <PersonalInfo isEmployee={isEmployee} nations={nations} employeeId={employeeId} idTypes={idTypes}
          processId={processId} isContractExtensionEmployee={isContractExtensionEmployee()}/>
      }
      {isEmployee && isHireEmployee() && (
        <>
          {step === EDIT_STEP.EDUCATION && <EducationInfo form={form}/>}
          {step === EDIT_STEP.WORK && <WorkInfo/>}
          {step === EDIT_STEP.SKILL && <SkillInfo personalInfo={employeeInfo.personalInfo}
            processId={processId}/>}
        </>
      )}

      {isEmployee && isContractExtensionEmployee() && (
        <>
          {step === CONTRACT_EXTENSION_EDIT_STEP.EDUCATION && <EducationInfo form={form}/>}
          {step === CONTRACT_EXTENSION_EDIT_STEP.SKILL && <SkillInfo personalInfo={employeeInfo.personalInfo}
            processId={processId}/>}
        </>
      )}
      {isShowSubmit && <Commitment
        /* eslint-disable-next-line max-len */
        content="本人已阅读并同意 Workday 系统中 Privacy Notice 所述的相关处理，同意亲自完成“电子签信息采集平台”（以下简称“平台”）上包括但不限于填写个人信息及上传资料等操作，同意以上个人信息将用于入职前准备工作以及入职后用工管理之用途，并郑重承诺在平台上提交的一切个人资料或影像，均真实有效。"
        /* eslint-disable-next-line max-len */
        englishContent='I have read and I consent to the processing as described in Privacy Notice in the Workday system and agree to personally complete the actions on the E-Sign Information Collection Platform (hereinafter referred to as the "Platform"), including but not limited to filling in personal information and uploading documents, and agree to the processing regard to above personal information and documents for the purpose of the onboarding preparation and employment management. I solemnly promise that all personal data or images submitted on the Platform are true and valid.'
      />}
      <Form.Item className="submit-btn">
        {isEmployee && isHireEmployee() && (
          <>
            {step > EDIT_STEP.PERSONAL && <Button className="previous-btn" onClick={goToPrevious}>上一步</Button>}
            {step < EDIT_STEP.SKILL && <Button className="next-btn" type="primary" onClick={goToNext}>下一步</Button>}
          </>
        )}
        {isEmployee && isContractExtensionEmployee() && (
          <>
            {step > CONTRACT_EXTENSION_EDIT_STEP.PERSONAL && <Button className="previous-btn" onClick={goToPrevious}>上一步</Button>}
            {step < CONTRACT_EXTENSION_EDIT_STEP.SKILL && <Button className="next-btn" type="primary" onClick={goToNext}>下一步</Button>}
          </>
        )}
        {isShowSubmit && (
          <FormSubmitButton form={form} onSubmit={onSubmit} setPopOpen={setPopOpen} popOpen={popOpen}></FormSubmitButton>
        )}
      </Form.Item>
    </Form>
  );
};

export default EmployeeInfoEdit;
