import './ExitApprovalCommentDrawer.scss';
import { IExitApprovalCommentDetail, IExitApprovalCommentResponse, IExitApprovalListDetail } from '@/models/employee';
import useForm from 'antd/lib/form/hooks/useForm';
// eslint-disable-next-line no-use-before-define
import React, { createRef, forwardRef, useEffect, useState } from 'react';
import { Button, Col, DatePicker, DatePickerProps, Divider, Drawer, Empty, Form, Input, message, Radio, Row, Timeline } from 'antd';
import { RequiredRule } from '@/constants/validation';
import zhCN from 'antd/es/date-picker/locale/zh_CN';
import { ClockCircleOutlined } from '@ant-design/icons';
import { createExitApprovalComment, getExitApprovalComment } from '@/api/exitApi';
import { DATE_FORMATTER, DATE_HOUR_MIN_FORMATTER } from '@/constants/date';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import { EXIT_APPROVAL_COMMENT_ADD, EXIT_APPROVAL_COMMENT_READ } from '@/constants/permission';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import { TimelineItemProps } from 'antd/lib/timeline/TimelineItem';

interface IExitApprovalCommentDrawerProps {
  record: IExitApprovalListDetail;
  drawerOpen: boolean;
  closeDrawer: () => void;
}

const TimelineItemWrapper = forwardRef((props: TimelineItemProps, ref) => (
  <div ref={ref as React.Ref<HTMLDivElement>}>
    <Timeline.Item {...props}>
      {props.children}
    </Timeline.Item>
  </div>
));

const ExitApprovalCommentDrawer = ({ record, drawerOpen, closeDrawer }: IExitApprovalCommentDrawerProps) => {
  const hasExitApprovalCommentAddPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_COMMENT_ADD ]));
  const hasExitApprovalCommentReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_COMMENT_READ ]));

  const defaultEmptyFormData: IExitApprovalCommentDetail = {
    employeeId: '',
    chineseName: '',
    isAssetsReturn: undefined,
    isRemote: undefined,
    isDefaultTerminationDate: undefined,
    terminationDate: moment().startOf('day'),
    otherComment: '',
    exitSignProcessId: '',
    createdBy: '',
    createdAt: moment('')
  };

  const defaultEmptyHistoryData: IExitApprovalCommentResponse = {
    content: []
  };

  const HRO_WITH_DATE_PICKER_LIST_HEIGHT = 304;
  const HRO_WITHOUT_DATE_PICKER_HEIGHT = 390;
  const BP_HEIGHT = 820;
  const ref = createRef();
  const [ form ] = useForm();
  const [ commentData, setCommentData ] = useState<IExitApprovalCommentDetail>(defaultEmptyFormData);
  const [ commentHistoryData, setCommentHistoryData ] = useState<IExitApprovalCommentResponse>(defaultEmptyHistoryData);
  const [ showDatePicker, setShowDatePicker ] = useState(false);
  const [ showNoHistory, setShowNoHistory ] = useState(false);
  const [ commentListHeight, setCommentListHeight ] =
    useState(hasExitApprovalCommentAddPermission ? HRO_WITHOUT_DATE_PICKER_HEIGHT : BP_HEIGHT);

  const initialFormData = (defaultEmptyFormData: IExitApprovalCommentDetail, record: IExitApprovalListDetail) => {
    const data: IExitApprovalCommentDetail = {
      ...defaultEmptyFormData,
      employeeId: record?.employeeId,
      chineseName: record?.chineseName,
      exitSignProcessId: record?.exitSignProcessId,
      terminationDate: moment(record?.terminationDate)
    };
    return data;
  };

  useEffect(() => {
    if (record && drawerOpen) {
      const data = initialFormData(defaultEmptyFormData, record);
      setCommentData(data);
      setCommentHistoryData(defaultEmptyHistoryData);
      setShowDatePicker(false);
      setShowNoHistory(false);
      setCommentListHeight(hasExitApprovalCommentAddPermission ? HRO_WITHOUT_DATE_PICKER_HEIGHT : BP_HEIGHT);
      form.setFieldsValue(data);

      getExitApprovalComment(record?.employeeId, record?.exitSignProcessId).then(response => {
        setCommentHistoryData(response);
        setShowNoHistory(response.content.length < 1);
      }).catch(() => {
        message.error('获取留言列表失败');
      });
    }
  }, [ record ]);

  useEffect(() => {
    setCommentData(form.getFieldsValue(true));
  }, [ form.getFieldsValue(true) ]);

  const handleOnClose = () => {
    closeDrawer();
  };

  const drawerConfirm = async () => {
    const formData: IExitApprovalCommentDetail = form.getFieldsValue(true);
    try {
      await form.validateFields();
      await createExitApprovalComment(formData);
    } catch (err) {
      if ((err as AxiosError<IErrorResponse>).response?.data.code === ERROR_CODE.userOperationIllegal) {
        message.error('添加留言失败');
      }
      return;
    }
    closeDrawer();
  };

  const handleSubmit = () => {
    if (!hasExitApprovalCommentAddPermission) {
      closeDrawer();
      return;
    }

    drawerConfirm();
  };

  const renderDrawerExtra = () => (
    <div className="flex">
      {hasExitApprovalCommentAddPermission && <Button onClick={handleOnClose}>取消</Button>}
      <Button type="primary" style={{ marginLeft: 8 }} onClick={handleSubmit}>确定</Button>
    </div>
  );

  const renderDrawerTitle = () => (
    <div>
      <span className="headline4 title">留言</span>
    </div>
  );

  const handleIsDefaultTerminationDateChange = event => {
    const showFlag = event.target.value === false;
    const pickDate = showFlag ? moment().startOf('day') : moment(record?.terminationDate);
    setCommentData(prevState => {
      return {
        ...prevState,
        terminationDate: pickDate
      };
    });
    const formData = form.getFieldsValue();
    const newFormData = {
      ...formData,
      terminationDate: pickDate
    };
    form.setFieldsValue(newFormData);
    setShowDatePicker(showFlag);
    setCommentListHeight(showFlag ? HRO_WITH_DATE_PICKER_LIST_HEIGHT : HRO_WITHOUT_DATE_PICKER_HEIGHT);
  };

  const disabledDate: DatePickerProps['disabledDate'] = current => {
    return current < moment().startOf('day') || current.isSame(moment(record?.terminationDate), 'day');
  };

  return (
    <Drawer
      className="exit-approval-comment-drawer"
      width={460}
      title={renderDrawerTitle()}
      placement="right"
      open={drawerOpen}
      destroyOnClose={true}
      style={{ marginTop: 64 }}
      closable={false}
      extra={renderDrawerExtra()}
    >
      {
        hasExitApprovalCommentAddPermission &&
        <>
          <Form
            className="exit-approval-comment-form"
            form={form}
            layout="vertical"
            validateTrigger={[ 'onChange' ]}
            initialValues={commentData}>
            <div className="exit-approval-comment-edit">
              <div className="form-group">
                <Row gutter={8}>
                  <Col span={20}>
                    <Form.Item name="isAssetsReturn"
                      label="员工是否在离职日期前交还公司资产？" rules={[ RequiredRule ]}>
                      <Radio.Group>
                        <Radio value={true} data-testid="isAssetsReturnTrueRadio">是</Radio>
                        <Radio value={false}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={20}>
                    <Form.Item name="isRemote" label="员工是否远程办理？" rules={[ RequiredRule ]}>
                      <Radio.Group>
                        <Radio value={true} data-testid="isRemoteTrueRadio">是</Radio>
                        <Radio value={false}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={20}>
                    <Form.Item name="isDefaultTerminationDate"
                      label="员工是否在离职当天办理离职流程？"
                      rules={[ RequiredRule ]}>
                      <Radio.Group onChange={handleIsDefaultTerminationDateChange}>
                        <Radio value={true} data-testid="isDefaultTerminationDateTrueRadio">是</Radio>
                        <Radio value={false} data-testid="isDefaultTerminationDateFalseRadio">否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {
                  showDatePicker &&
                  <Row gutter={8}>
                    <Col span={16}>
                      <Form.Item name="terminationDate"
                        label="员工办理离职日期" rules={[ RequiredRule ]}>
                        <DatePicker
                          locale={zhCN}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                }
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item name="otherComment"
                      label="其他补充？"
                      rules={[ { required: false } ]}>
                      <Input.TextArea
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        maxLength={200}/>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
          <Divider className="exit-approval-comment-drawer-driver"/>
        </>
      }
      {
        hasExitApprovalCommentReadPermission && commentHistoryData.content && commentHistoryData.content.length > 0 && 
        <Timeline pending=" " pendingDot={<span></span>}>
          {
            commentHistoryData.content.map((comment: IExitApprovalCommentDetail) =>
              <TimelineItemWrapper
                key={comment.createdAt.toString()}
                dot={<ClockCircleOutlined style={{ fontSize: '14px', color: 'black' }}/>}
                ref={ref}
              >
                <p className="exit-approval-comment-history-title">
                  <span style={{ marginRight: 4 }}>
                    {comment.createdBy}
                  </span>
                      更新信息 {moment(comment.createdAt).format(DATE_HOUR_MIN_FORMATTER)}
                </p>
                {
                  comment.isAssetsReturn
                    ? <p className="exit-approval-comment-history-item">员工是在离职日期前交还公司资产</p>
                    : <p className="exit-approval-comment-history-item">员工不是在离职日期前交还公司资产</p>
                }
                {
                  comment.isRemote
                    ? <p className="exit-approval-comment-history-item">员工是远程办理</p>
                    : <p className="exit-approval-comment-history-item">员工不是远程办理</p>

                }
                {
                  comment.isDefaultTerminationDate
                    ? <p className="exit-approval-comment-history-item">员工是在离职当天办理离职流程</p>
                    : <>
                      <p className="exit-approval-comment-history-item">员工不是在离职当天办理离职流程</p>
                      <p
                        className="exit-approval-comment-history-item">办理离职流程日期：{moment(comment.terminationDate).format(DATE_FORMATTER)}
                      </p>
                    </>
                }
                {
                  comment.otherComment && <p className="exit-approval-comment-history-item">其他：{comment.otherComment}</p>
                }
              </TimelineItemWrapper>)
          }
        </Timeline>
      }
      {
        showNoHistory &&
        <Empty className="exit-approval-comment-history-empty" description={'暂无历史数据'} style={{ height: commentListHeight }}/>
      }
    </Drawer>
  );
};

export default ExitApprovalCommentDrawer;