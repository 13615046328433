import { requiredMessage } from '@/constants/error';
import { Input } from 'antd';
import { validateIdNumber } from '@/utils/validateUtils';
import { IContractExtensionNotSignInformationResponse, IOption, Options } from '@/models/employee';
import { ReactElement } from 'react';
import { Rule } from 'rc-field-form/lib/interface';
import CustomSelect from '@/components/custom-select/CustomSelect';
import CustomDatePicker from '@/components/custom-date-picker/CustomDatePicker';

interface ComponentProps {
  optionKey?: string;
  showSearch?: boolean;
  optionFilter?: (options: IOption[], formValues: IContractExtensionNotSignInformationResponse) => IOption[];
}

interface FieldComponentFun<T> {
  (config: T, options:Options, getFormFieldsValue: () => IContractExtensionNotSignInformationResponse): ReactElement;
}


export interface ContractExtensionNotSignFieldConfig {
  colSpan: number;
  name: string;
  label: string;
  rules: Rule[];
  component: ReactElement | FieldComponentFun<ContractExtensionNotSignFieldConfig>
  componentProps?: ComponentProps
  visibleCondition?: (formValues: IContractExtensionNotSignInformationResponse) => boolean;
  validateTrigger?: string | string[];
}

export const fieldValidatorConfig: Array<ContractExtensionNotSignFieldConfig[]> = (
  [
    [
      {
        colSpan: 8,
        name: 'legalFullName',
        label: 'Legal Name',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100}/>
      },
      {
        colSpan: 8,
        name: 'chineseName',
        label: '姓名',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100}/>
      }
    ],
    [
      {
        colSpan: 8,
        name: 'idType',
        label: '证件类型',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'idTypesOptions'
        }
      },
      {
        colSpan: 8,
        name: 'idNumber',
        label: '证件号码',
        rules: [ { required: true, message: requiredMessage }, validateIdNumber ],
        validateTrigger: 'onBlur',
        component: <Input maxLength={100}/>
      },
    ],
    [
      {
        colSpan: 8,
        name: 'contractExpirationDate',
        label: '劳动合同到期日',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker(true)
      }
    ],
    [
      {
        colSpan: 6,
        name: 'contractDuration',
        label: '新劳动合同期限年数',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled maxLength={100}/>,
        visibleCondition: formValue => formValue.contractDuration !== null
      },
      {
        colSpan: 8,
        name: 'startDate',
        label: '开始日期',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker(true),
        visibleCondition: formValue => formValue.startDate !== null
      },
      {
        colSpan: 8,
        name: 'endDate',
        label: '截止日期',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker(true),
        visibleCondition: formValue => formValue.endDate !== null
      }
    ],
    [
      {
        colSpan: 8,
        name: 'latestReplyDate',
        label: '员工回复确认是否接受续签时间',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker(),
        visibleCondition: formValue => formValue.latestReplyDate !== null
      }
    ],
  ]
);