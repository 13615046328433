import { ReactNode } from 'react';
import '@/components/button-icon/ButtonIcon.scss';

interface ButtonProps {
  icon: ReactNode;
  disabled: boolean;
  onClick: () => void;
  testId?: string;
}

const ButtonIcon = ({ icon, disabled, onClick, testId }: ButtonProps) => {
  const  handleOnClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };
  return (
    <div className={`button-icon ${disabled ? 'disabled' : ''}`} onClick={handleOnClick} data-testid={testId}>
      {icon}
    </div>
  );
};

export default ButtonIcon;
