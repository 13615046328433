import { RouterObjectExtend } from '@/models/common';
import { CONTRACT_EXTENSION_TAB_STATUS, EMPLOYEE_TAB_STATUS } from '@/constants/status';

export const breadCrumbConfig = {
  'notification-review/:employeeId/:processId': {
    pathname: '/contract-extension-home/contract-extension-process',
    route: {
      title: '不续签人员',
      tabParam: CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION
    } as RouterObjectExtend
  },
  'contract-extension-review/:employeeId/:processId':
    {
      pathname: '/contract-extension-home/contract-extension-process',
      route: {
        title: '续签人员',
        tabParam: EMPLOYEE_TAB_STATUS.SIGNATURE
      } as RouterObjectExtend
    },
};
