// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IContractExtensionDetail, IContractExtensionResponse, IContractExtensionStatusAndReasonRequest } from '@/models/employee';
import { getContractExtensionList, updateContractExtensionStatusAndReason } from '@/api/employeeApi';
import { Button, Empty, Form, Input, message, Modal, Radio, Table } from 'antd';
import { map } from 'lodash-es';
import { NotBlankRule, RequiredRule } from '@/constants/validation';
import './ContractExtensionList.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import {
  CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME,
  CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME,
  CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME
} from '@/constants/processTaskName';
import { contractTypeInfo } from '@/constants/employee';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { useCustomMatomo } from '@/matomo';
import UploadAttachment from '@/pages/hro/contract-extension-list/upload-attachment/UploadAttachment';
import TableColumnFilter from '@/components/table-column-filter/TableColumnFilter';
import { AppDispatch } from '@/models/store';
import TableColumnDateFilter from '@/components/table-column-date-filter/TableColumnDateFilter';
import {
  fetchContractExtensionListFilterCandidate,
  selectContractExtensionColumnCandidates,
  updateContractExtensionTableSearchFilters
} from '@/store/hroInfo';

const ContractExtensionList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [ contractExtensionData, setContractExtensionData ] = useState<IContractExtensionDetail[]>([]);
  const [ isContractExtensionConfirmModalOpen, setIsContractExtensionConfirmModalOpen ] = useState(false);
  const [ currentContractExtensionStatus, setCurrentContractExtensionStatus ] = useState('');
  const [ currentContractExtensionReason, setCurrentContractExtensionReason ] = useState('');
  const [ currentContractExtensionAttachment, setCurrentContractExtensionAttachment ] = useState([]);
  const [ currentEmployeeId, setCurrentEmployeeId ] = useState(null);
  const [ isConfirmButtonDisabled, setIsConfirmButtonDisabled ] = useState(false);
  const [ queryKeyWordValue, setQueryKeyWordValue ] = useState<string>('');
  const queryKeyWordRef = useRef('');
  const { hasPermission } = useRouterGuard();
  const userInfo = useSelector(selectUserInfo);
  const [ form ] = Form.useForm();
  const { trackEvent } = useCustomMatomo();
  const [ tablePagination, setTablePagination ] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false
  });

  const updateContractExtensionList = (page: number, size: number) => {
    getContractExtensionList(page, size).then((response: IContractExtensionResponse) => {
      setContractExtensionData(response.content);
      setTablePagination({
        current: response.pageable.pageNumber + 1,
        pageSize: response.pageable.pageSize,
        total: response.totalElements,
        showSizeChanger: false
      });
    });
  };

  useEffect(() => {
    if (userInfo && hasPermission) {
      dispatch(fetchContractExtensionListFilterCandidate());
      updateContractExtensionList(0, 20);
    }
  }, [ userInfo, hasPermission ]);

  const onConfirm = useCallback((employeeId: string) => {
    setIsContractExtensionConfirmModalOpen(true);
    setCurrentEmployeeId(employeeId);
  }, []);

  const renderFilterIcon = () => {
    return <i className="ri-filter-3-line"></i>;
  };

  const handleSearchBarChange = event => {
    const currentInputKeyWord = event.target.value;
    setQueryKeyWordValue(currentInputKeyWord);
    queryKeyWordRef.current = currentInputKeyWord;
    dispatch(updateContractExtensionTableSearchFilters({ keyWord: currentInputKeyWord }));
  };

  const handleInitiateSearch = () => {
    updateContractExtensionList(0, 20);
  };

  const handleQueryFilterConfirm = () => {
    dispatch(fetchContractExtensionListFilterCandidate());
    updateContractExtensionList(0, 20);
  };
  const getTableFilterCommonProps = (filterFieldKey, close) => {
    return {
      close,
      filterFieldKey,
      handleConfirm: handleQueryFilterConfirm,
      filterCandidatesReduxQueryMethod: selectContractExtensionColumnCandidates,
      filterReduxUpdateMethod: updateContractExtensionTableSearchFilters
    };
  };

  const tableColumns = useMemo(() => {
    const columns = [];
    columns.push({
      title: '合同到期日',
      dataIndex: 'contractExpirationDate',
      className: 'align-center',
      width: '13%',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnDateFilter
        filterFieldKey={'contractExpirationDate'}
        handleConfirm={handleQueryFilterConfirm}
        filterReduxUpdateMethod={updateContractExtensionTableSearchFilters}
        close={close}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '城市',
      dataIndex: 'homeOffice',
      className: 'align-center',
      width: '7%',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('homeOffice', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '中文名',
      dataIndex: 'chineseName',
      className: 'show-ellipsis align-center',
      width: '7%',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('chineseName', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '工号',
      dataIndex: 'employeeId',
      className: 'align-center',
      width: '7%',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('employeeId', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '部门',
      dataIndex: 'supervisoryOrgName',
      className: 'align-center',
      width: '15%',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('supervisoryOrgName', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '角色',
      dataIndex: 'position',
      className: 'align-center',
      width: '20%',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('position', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '职级',
      dataIndex: 'grade',
      className: 'align-center',
      width: '18%',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('grade', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '操作',
      dataIndex: 'action',
      className: 'last-td align-center',
      width: '12%',
      render: (_, { employeeId, edit }: IContractExtensionDetail) => {
        return (
          <Button
            size="small"
            disabled={!edit}
            onClick={() => onConfirm(employeeId)}
          >
              待确认
          </Button>
        );
      }
    });
  
    return columns;
  }, []);

  const validateFormThenConfirm = () => {
    form.validateFields().then(() => {
      const request: IContractExtensionStatusAndReasonRequest = {
        employeeId: currentEmployeeId,
        willingnessStatus: currentContractExtensionStatus,
        willingnessReason: currentContractExtensionReason,
        willingnessAttachment: currentContractExtensionAttachment.map(file => file.response?.fileId),
      };
      updateContractExtensionStatusAndReason(request).then(() => {
        setIsContractExtensionConfirmModalOpen(false);
        updateContractExtensionList(0, 20);
        trackEvent({
          category: MATOMO_CATEGORY.HRBPContractExtensionWillingness,
          action: currentContractExtensionStatus === CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME
            ? MATOMO_ACTION.Extension : MATOMO_ACTION.NoExtension,
          name: MATOMO_NAME.ExtensionConfirm,
        });
        message.success(`员工已进入${contractTypeInfo[currentContractExtensionStatus]}流程`);
      }).catch(() => {
        message.error('操作失败，请刷新一下');
      });
    })
      .catch(() => setIsConfirmButtonDisabled(true));
  };

  const handleContractExtensionStatusChange = useCallback(event => {
    setCurrentContractExtensionStatus(event.target.value);
  }, []);

  const handleContractExtensionReasonInput = useCallback(event => {
    setCurrentContractExtensionReason(event.target.value);
  }, []);

  const handleContractExtensionAttachmentInput = useCallback(value => {
    setCurrentContractExtensionAttachment(value);
  }, []);

  const formItemConfigs = [
    {
      label: <span className="contract-extension-form-label">1.选择状态</span>,
      rules: [ RequiredRule ],
      name: 'contractExtensionStatus',
      component: <Radio.Group onChange={handleContractExtensionStatusChange}>
        <Radio value={CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME}>
          {contractTypeInfo[CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME]}
        </Radio>
        <Radio value={CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME}>
          {contractTypeInfo[CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME]}
        </Radio>
        <Radio value={CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME}>
          {contractTypeInfo[CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME]}
        </Radio>
      </Radio.Group>,
    },
    {
      label: <span className="contract-extension-form-label">2.请输入确定续签或不续签原因，并上传审批截图</span>,
      rules: [ RequiredRule, NotBlankRule ],
      name: 'contractExtensionReason',
      component: <Input.TextArea
        onChange={handleContractExtensionReasonInput}
        placeholder="请输入原因"
        autoSize={{ minRows: 3, maxRows: 3 }}
        maxLength={200}/>,
    },
    {
      rules: [ RequiredRule ],
      name: 'contractExtensionAttachment',
      component:
      <UploadAttachment employeeId={currentEmployeeId}
        onChange={value => handleContractExtensionAttachmentInput(value)}/>,
    }
  ];

  const handleTableChange = useCallback(pagination => {
    getContractExtensionList(pagination.current - 1, pagination.pageSize).then((response: IContractExtensionResponse) => {
      setContractExtensionData(response.content);
      setTablePagination({
        ...pagination,
        current: response.pageable.pageNumber + 1,
        total: response.totalElements,
      });
    });
  }, [ tablePagination ]);

  return (
    <div className="main-page">
      <h3 className="contract-extension-list-header-headline3 headline3">未来6个月员工待确认续签名单</h3>
      <div className="contract-extension-list-table-common-header">
        <Input.Search className="contract-extension-table-common-search-bar"
          placeholder="请在此处输入搜索内容"
          value={queryKeyWordValue}
          onChange={handleSearchBarChange}
          onSearch={handleInitiateSearch}
        />
        <Button className="contract-extension-table-common-search-btn" onClick={handleInitiateSearch}>搜索</Button>
      </div>
      <div className="container">
        <Table
          columns={tableColumns}
          dataSource={contractExtensionData}
          rowKey="employeeId"
          pagination={tablePagination}
          onChange={handleTableChange}
          scroll={{ y: 'calc(100vh - 470px)', x: '1100px' }}
          locale={{ emptyText: <Empty description={'暂无数据'}/> }}
        />
        <Modal
          title="请完成以下操作"
          width={486}
          wrapClassName={'contract-extension-confirm-modal'}
          open={isContractExtensionConfirmModalOpen}
          centered
          keyboard={false}
          destroyOnClose={true}
          maskClosable={false}
          onOk={validateFormThenConfirm}
          onCancel={() => setIsContractExtensionConfirmModalOpen(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsContractExtensionConfirmModalOpen(false)}
            >
              取消
            </Button>,
            <Button
              className={classNames({ 'contract-extension-confirm-button-disabled': isConfirmButtonDisabled })}
              key="confirm"
              type="primary"
              disabled={!(currentContractExtensionStatus && currentContractExtensionReason &&
                currentContractExtensionAttachment.length > 0)}
              onClick={validateFormThenConfirm}
              onMouseLeave={() => setIsConfirmButtonDisabled(false)}
            >
              确认
            </Button>
          ]}>
          <Form form={form} layout="vertical" validateTrigger={[ 'onChange' ]}>
            {
              map(formItemConfigs, formItemConfig => (
                <Form.Item
                  label={formItemConfig.label}
                  rules={formItemConfig.rules}
                  name={formItemConfig.name}
                  preserve={false}
                  key={formItemConfig.name}
                >
                  {formItemConfig.component}
                </Form.Item>
              ))
            }
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default ContractExtensionList;
