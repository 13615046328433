import { useEffect, useState } from 'react';
import { IHireInfo } from '@/models/employee';
import { getEmployeeHireInfo } from '@/api/employeeApi';
import FilePreviewer from '@/components/file-previewer/FilePreviewer';
import './CertificateReview.scss';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';

interface CertificateReviewProps {
  employeeId: string,
  certificateFileUrl: string
}


const CertificateReview = (props: CertificateReviewProps) => {
  const { employeeId, certificateFileUrl } = props;
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>(null);
  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();

  useEffect(() => {
    if (userInfo && hasPermission) {
      getEmployeeHireInfo(employeeId).then(employeeInfo => setEmployeeInfo(employeeInfo));
    }
  }, [ userInfo, hasPermission ]);

  return (
    <>
      {employeeInfo &&
        <div className="certificate-check-container">
          <div className="employee-basic-info">
            <span className="employee-name">{employeeInfo.legalFullName}</span>
            <span className="employee-id">{employeeInfo.employeeId}</span>
            <span className="employee-info-item">{employeeInfo.homeOffice}</span>
            <span className="employee-info-item">{employeeInfo.nationalityCn}</span>
            <span className="employee-info-item">{employeeInfo.employmentType}</span>
            <span className="employee-info-item">{employeeInfo.hireType}</span>
            <span className="employee-info-item last-item">{employeeInfo.position}</span>
          </div>
          <div className="certificate-previewer-container">
            <div className="certificate-previewer-item">
              <FilePreviewer
                fileName={'Certificate.pdf'}
                storageUrl={certificateFileUrl}/>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CertificateReview;
