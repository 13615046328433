// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Tabs, Input } from 'antd';
import TotalNumber from '@/components/total-number/TotalNumber';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  fetchExitApprovalList,
  fetchExitEmployeeList, fetchExitFinishList, fetchExitToBeSignedList,
  fetchExitTotal,
  resetExitTableSearchFilters,
  selectExitApprovalData,
  selectExitCount, updateExitTableSearchFilters
} from '@/store/hroInfo';
import { AppDispatch } from '@/models/store';
import ExitEmployeeTableInfo from '@/pages/hro/exit-process/exit-employee-table-info/ExitEmployeeTableInfo';
import { EXIT_PROCESS_TAB_STATUS } from '@/constants/status';
import {
  EXIT_APPROVAL_LIST_READ_PERMISSION, EXIT_HISTORY_READ, EXIT_INVOLUNTARY_EMPLOYEE_ADD_PERMISSION,
  EXIT_LIST_READ_PERMISSION,
  EXIT_SIGNING_LIST_READ_PERMISSION
} from '@/constants/permission';
import ExitApprovalTableInfo from '@/pages/hro/exit-process/exit-approval-table-info/ExitApprovalTableInfo';
import ExitToBeSignedTableInfo from '@/pages/hro/exit-process/exit-to-be-signed-table-info/ExitToBeSignedTableInfo';
import './ExitProcess.scss';
import { IExitApprovalListResponse } from '@/models/employee';
import ExitAddEmployeeModal from '@/pages/hro/exit-process/exit-add-employee-modal/ExitAddEmployeeModal';
import ExitFinishedTableInfo from '@/pages/hro/exit-process/exit-finished-table-info/ExitFinishedTableInfo';

const TAB_COUNT_MAPPER = {
  [EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST]: 'exitEmployeeCount',
  [EXIT_PROCESS_TAB_STATUS.IN_PROGRESS]: 'exitProcessCount',
  [EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE]: 'toBeSignedEmployeeCount',
  [EXIT_PROCESS_TAB_STATUS.FINISHED]: 'finishedEmployeeCount'
};

const ExitProcess = () => {
  const [ searchParams, setSearchParams ] = useSearchParams({});
  const total = useSelector(selectExitCount);
  const [ currentTab, setCurrentTab ] = useState<string>();
  const [ isModalOpen, setIsModalOpen ] = useState(null);
  const [ queryKeyWordValue, setQueryKeyWordValue ] = useState<string>('');
  const queryKeyWordRef = useRef('');

  const dispatch = useDispatch<AppDispatch>();

  const hasPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_LIST_READ_PERMISSION, EXIT_APPROVAL_LIST_READ_PERMISSION, EXIT_SIGNING_LIST_READ_PERMISSION ]));
  const hasExitListReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_LIST_READ_PERMISSION ]));
  const hasExitApprovalListReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_LIST_READ_PERMISSION ]));
  const hasExitSigningListReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_SIGNING_LIST_READ_PERMISSION ]));
  const hasExitInvoluntaryEmployeeAddPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_INVOLUNTARY_EMPLOYEE_ADD_PERMISSION ]));
  const hasExitHistoryReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_HISTORY_READ ]));

  const items = useMemo(() => {
    return [
      {
        key: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST,
        label: <TotalNumber title={'离职员工名单'} totalCount={total && total[TAB_COUNT_MAPPER[EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST]]}/>,
        children: <ExitEmployeeTableInfo/>,
        disabled: !hasExitListReadPermission,
      },
      {
        key: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS,
        label: <TotalNumber title={'离职流转'} totalCount={total && total[TAB_COUNT_MAPPER[EXIT_PROCESS_TAB_STATUS.IN_PROGRESS]]}/>,
        children: <ExitApprovalTableInfo/>,
        disabled: !hasExitApprovalListReadPermission,
      },
      {
        key: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE,
        label: <TotalNumber title={'待签署员工'}
          totalCount={total && total[TAB_COUNT_MAPPER[EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE]]}/>,
        children: <ExitToBeSignedTableInfo/>,
        disabled: !hasExitSigningListReadPermission,
      },
      {
        key: EXIT_PROCESS_TAB_STATUS.FINISHED,
        label: <TotalNumber title={'完成签署员工'}
          totalCount={total && total[TAB_COUNT_MAPPER[EXIT_PROCESS_TAB_STATUS.FINISHED]]}/>,
        children: <ExitFinishedTableInfo/>,
        disabled: !hasExitHistoryReadPermission,
      }
    ];
  }, [ total ]);

  const getActiveItems = useCallback(() => {
    return items.filter(item => !item.disabled);
  }, [ items ]);

  useEffect(() => {
    if (hasPermission) {
      const activeItems = getActiveItems();
      const firstItem = activeItems[0];
      setCurrentTab(firstItem.key);
      dispatch(fetchExitTotal({ currentTab: firstItem.key }));
    }
  }, [ hasPermission ]);

  const dataStoredInRedux = useSelector(selectExitApprovalData) as IExitApprovalListResponse;
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
    showSizeChanger: false,
  };

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback((needRefreshData: boolean) => {
    setIsModalOpen(false);
    if (needRefreshData) {
      dispatch(fetchExitEmployeeList({ page: tablePagination.current - 1, size: tablePagination.pageSize }));
      dispatch(fetchExitTotal({ currentTab }));
    }
  }, []);

  const handleSearchBarChange = event => {
    const currentInputKeyWord = event.target.value;
    setQueryKeyWordValue(currentInputKeyWord);
    queryKeyWordRef.current = currentInputKeyWord;
    dispatch(updateExitTableSearchFilters({ keyWord: currentInputKeyWord }));
  };

  const handleInitiateSearch = () => {
    if (currentTab === EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST) {
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST }));
      dispatch(fetchExitEmployeeList({ page: 0, size: 20 }));
    } else if (currentTab === EXIT_PROCESS_TAB_STATUS.IN_PROGRESS) {
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS }));
      dispatch(fetchExitApprovalList({ page: 0, size: 20 }));
    } else if (currentTab === EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE) {
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
      dispatch(fetchExitToBeSignedList({ page: 0, size: 20 }));
    } else if (currentTab === EXIT_PROCESS_TAB_STATUS.FINISHED) {
      dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.FINISHED }));
      dispatch(fetchExitFinishList({ page: 0, size: 20 }));
    }
  };

  const getEveryTabCount = useCallback(activeKey => {
    dispatch(fetchExitTotal({ currentTab: activeKey }));
  }, []);

  const clickTab = useCallback(activeKey => {
    if (activeKey !== currentTab) {
      dispatch(resetExitTableSearchFilters({ keyWord: queryKeyWordRef.current }));
      setCurrentTab(activeKey);
      setSearchParams({ tab: activeKey });
      getEveryTabCount(activeKey);
    }
  }, [ currentTab ]);

  return (
    <div className="main-page">
      <div className="container exit-container">
        <div className="exit-table-common-header">
          <Input.Search className="exit-table-common-search-bar"
            placeholder="请在此处输入搜索内容"
            value={queryKeyWordValue}
            onChange={handleSearchBarChange}
            onSearch={handleInitiateSearch}
          />
          <Button className="exit-table-common-search-btn" onClick={handleInitiateSearch}>搜索</Button>
          <Button
            className="add-exit-btn"
            onClick={() => openModal()}
            hidden={!(currentTab === EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST && hasExitInvoluntaryEmployeeAddPermission)}
          >
            <i className="ri-user-add-fill"/>
            <p>添加离职员工</p>
          </Button>
        </div>
        <ExitAddEmployeeModal isModalOpen={isModalOpen} closeModal={closeModal}/>
        <Tabs
          type="card"
          defaultActiveKey={searchParams.get('tab') || EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST}
          items={getActiveItems()}
          onTabClick={clickTab}
          destroyInactiveTabPane={true}/>
      </div>
    </div>
  );
};
export default ExitProcess;
