import { Button, Empty, Popconfirm, Table } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import { IContractFinishStatusInfo } from '@/models/employee';
import './ArchiveTable.scss';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { EMPLOYEE_CONTRACT_SIGN_STATUS } from '@/constants/status';
import {
  EXIT_CONTRACT_SIGNING_PROCESS_TASK_NAME, HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME,
} from '@/constants/processTaskName';
import { EMPLOYMENT_TYPE } from '@/constants/employee';
import { useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import { CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION, EXIT_HISTORY_EDIT } from '@/constants/permission';

interface ArchiveTableProps {
  // eslint-disable-next-line
  columns: any[];
  // eslint-disable-next-line
  dataSource: any[];
  tablePagination: object;
  handleTableChange?: (pagination) => void;
  confirmBatchArchive: (reqBody) => void;
  contractSignTaskName?: string;
}

const ArchiveTable = (props: ArchiveTableProps) => {
  const { columns, dataSource, tablePagination, handleTableChange, confirmBatchArchive, contractSignTaskName } = props;
  const [ contractFinishData, setContractFinishData ] = useState<IContractFinishStatusInfo[]>([]);
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<string[]>([]);
  const [ selectedRows, setSelectedRows ] = useState<IContractFinishStatusInfo[]>([]);
  const { trackEvent } = useCustomMatomo();
  const hasExitHistoryExitPermission = useSelector(state =>
    isUserHasPermissions( state, [ EXIT_HISTORY_EDIT ]));
  const hasExtensionProcessEditPermission = useSelector(state =>
    isUserHasPermissions( state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ]));
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    setContractFinishData(dataSource);
  }, [ dataSource, tablePagination ]);

  const cancelSelection = useCallback(() => {
    setSelectedRowKeys([]);
    trackEvent({
      category: MATOMO_CATEGORY.HroEmployeeContract,
      action: `${MATOMO_ACTION.Cancel} ${MATOMO_ACTION.Archive}`,
      name: MATOMO_NAME.Contract,
    });
  }, []);

  const onConfirm = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroEmployeeContract,
      action: MATOMO_ACTION.BatchArchive,
      name: MATOMO_NAME.Contract,
    });
    const reqBody = selectedRows.map((row: IContractFinishStatusInfo) => {
      return {
        employeeId: row.employeeId,
        taskName: row.contractSignTaskName || contractSignTaskName,
      };
    });
    setSelectedRowKeys([]);
    confirmBatchArchive(reqBody);
  }, [ selectedRows, tablePagination ]);

  const renderFooter = () => {
    return <div className="footer-container">
      <p className="selected-employee-number">已选择{selectedRowKeys.length}位员工</p>
      <div>
        <Button
          className="cancel-selection"
          size="small"
          onClick={cancelSelection}>
          取消
        </Button>
        <Popconfirm
          title="确认批量上传合同至Google Drive？"
          cancelText={'取消'}
          okText={'确认'}
          placement="topRight"
          overlayStyle={{ width: 348 }}
          getPopupContainer={triggerNode => triggerNode}
          onConfirm={onConfirm}>
          <Button size="small" type="primary">批量归档</Button>
        </Popconfirm>
      </div>
    </div>;
  };

  const onSelectChange = (currentSelectedRowKeys: React.Key[], currentSelectedRows: IContractFinishStatusInfo[]) => {
    setSelectedRowKeys(currentSelectedRowKeys as string[]);
    setSelectedRows(currentSelectedRows);
  };

  const isExitContractorOrIntern = (record: IContractFinishStatusInfo) => {
    return record.contractSignTaskName === EXIT_CONTRACT_SIGNING_PROCESS_TASK_NAME &&
      (record.employmentType === EMPLOYMENT_TYPE.CONTRACTOR || record.employmentType === EMPLOYMENT_TYPE.INTERN);
  };

  const isExitUneditableRecord = (record: IContractFinishStatusInfo) => {
    return record.contractSignTaskName === EXIT_CONTRACT_SIGNING_PROCESS_TASK_NAME && !record.editable;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 56,
    getCheckboxProps: (record: IContractFinishStatusInfo) => {
      return {
        disabled: (record.contractSignStatus || record.exitSignProcessStatus) !== EMPLOYEE_CONTRACT_SIGN_STATUS.FINISH ||
          isExitContractorOrIntern(record) || isExitUneditableRecord(record)
      };
    }
  };

  const onChange = useCallback(pagination => {
    if (handleTableChange) {
      handleTableChange(pagination);
    }
  }, [ tablePagination ]);

  return (
    <Table
      columns={columns}
      dataSource={contractFinishData}
      rowKey="employeeId"
      rowSelection={(hasExitHistoryExitPermission || hasExtensionProcessEditPermission ||
        contractSignTaskName === HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME) && rowSelection}
      pagination={tablePagination}
      onChange={onChange}
      scroll={{ y: 'calc(100vh - 470px)' }}
      locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      footer={hasSelected ? renderFooter : undefined}
    />
  );
};
export default ArchiveTable;
