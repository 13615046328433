import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { get, includes, isEmpty, isString, omit, pick, pickBy } from 'lodash-es';
import { getToken } from '@/utils/localStorageUtils';
import { loading } from '@/utils/loading.utils';
import { message } from 'antd';
import { eventBus } from '@/utils/eventBus';


const getAxiosBaseUrl = () => process.env.NODE_ENV === 'development'
  // local backend http://localhost:8080
  ? 'http://localhost:3001'
  : process.env.REACT_APP_API_ADDRESS;

export const getWebBaseUrl = () => process.env.NODE_ENV === 'development'
  // local backend http://localhost:8080
  ? 'http://localhost:3001' : process.env.REACT_APP_ADDRESS;

interface AxiosExtraRequestConfig {
  noNeedLoading?: boolean;
}

export const pickParams = [ 'url', 'method', 'params', 'data' ];

interface IAxiosInstance extends AxiosInstance {
  // eslint-disable-next-line
  get<T = any, R = T, D = any>(url: string, config?: AxiosRequestConfig<D> & AxiosExtraRequestConfig): Promise<R>;

  // eslint-disable-next-line
  post<T = any, R = T, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D> & AxiosExtraRequestConfig): Promise<R>;
}

const axiosApi: IAxiosInstance = axios.create({
  baseURL: getAxiosBaseUrl(),
  timeout: 120000
});

export const isStreamFileResp = (contentType: string): boolean => {
  const contentTypeList = [ 'application/octet-stream' ];
  return includes(contentTypeList, contentType);
};

const pickData = item => {
  const data = pick(item, pickParams);
  return pickBy(data, value => !isEmpty(value));
};

const canDeactivateLoading = config => {
  const request = pickData(config);
  if (!isEmpty(request.data) && isString(request.data)) {
    request.data = JSON.parse(request.data);
  }
  loading.stop({ url: request.url, method: request.method });
};

axiosApi.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }

    if (!get(config, 'noNeedLoading')) {
      const data = pickData(config);
      loading.start({ url: data.url, method: data.method });
    }

    return omit(config, 'noNeedLoading');
  },
  error => {
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  response => {
    canDeactivateLoading(response.config);
    if (isStreamFileResp(response.headers['content-type'])) {
      return response;
    }
    return response.data;
  },
  (error: AxiosError) => {
    const { config, response } = error;
    if (response.status >= 500) {
      message.error('系统异常，请联系管理员！');
    }
    canDeactivateLoading(config);
    if (response.status === 401) {
      eventBus.instance?.emit('unauthorized');
    }
    if (response.status === 412) {
      message.error('已重新提交材料，请审核最新的员工材料');
    }
    return Promise.reject(error);
  }
);

export default axiosApi;
