export enum EMPLOYEE_CONTRACT_STATUS {
  SIGN_EMPLOYEE_ENTRY_CONTRACT = 'signEmployeeEntryContract',
  SIGN_EXTENSION_CONTRACT = 'signExtensionContract',
  CHECK_SIGN_EXIT_APPROVAL = 'checkSignExitApproval',
  SIGN_EXIT_FILES = 'signExitFiles',
}


export enum EMPLOYEE_TAB_STATUS {
  PENDING = 'PENDING',
  SIGNATURE = 'SIGNATURE',
  CONFIRMED = 'CONFIRMED',
  FINISHED = 'FINISHED'
}

export enum EMPLOYEE_INFO_STATUS {
  UN_SUBMITTED = 'UN_SUBMITTED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REVIEW_FAILED = 'REVIEW_FAILED',
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  REVIEWED = 'REVIEWED'
}

export enum EMPLOYEE_MATERIALS_STATUS {
  UN_SUBMITTED = 'UN_SUBMITTED',
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  REVIEWED = 'REVIEWED'
}

export enum EMPLOYEE_CONTRACT_SIGN_STATUS {
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  WAITING_HRO_INITIATE = 'WAITING_HRO_INITIATE',
  WAITING_EMPLOYEE_SIGNING = 'WAITING_EMPLOYEE_SIGNING',
  WAITING_HRO_SIGNING = 'WAITING_HRO_SIGNING',
  HRO_SIGNED = 'HRO_SIGNED',
  FINISH = 'FINISH',
  ARCHIVING = 'ARCHIVING',
  ARCHIVED = 'ARCHIVED'
}


export enum CONTRACT_EXTENSION_TAB_STATUS {
  WILL_EXTENSION = 'will_extension',
  WILL_NOT_EXTENSION = 'will_not_extension',
}


export enum CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS {
  EMPLOYEE_INFO_TO_BE_REVIEWED = 'EMPLOYEE_INFO_TO_BE_REVIEWED',
  EMPLOYEE_INFO_UNDER_REVIEW = 'EMPLOYEE_INFO_UNDER_REVIEW',
  EMPLOYEE_INFO_REVIEW_FAILED = 'EMPLOYEE_INFO_REVIEW_FAILED',
  NOTIFICATION_TO_BE_REVIEWED = 'NOTIFICATION_TO_BE_REVIEWED',
  WAITING_HRO_INITIATE = 'WAITING_HRO_INITIATE',
  WAITING_EMPLOYEE_SIGNING = 'WAITING_EMPLOYEE_SIGNING',
  WAITING_HRO_SIGNING = 'WAITING_HRO_SIGNING',
  HRO_SIGNED = 'HRO_SIGNED',
}


export enum CERTIFICATE_PROCESS_STATUS {
  PENDING_CONFIRM = 'PENDING_CONFIRM',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
  SIGNED = 'SIGNED',
  FINISH = 'FINISH'
}


export enum EXIT_PROCESS_TAB_STATUS {
  EMPLOYEE_LIST = 'EMPLOYEE_LIST',
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  FINISHED = 'FINISHED'
}

export enum EXIT_INFO_PROCESS_STATUS {
  UN_SUBMITTED = 'UN_SUBMITTED',
  SUBMITTED = 'SUBMITTED',
  FINISH = 'FINISH'
}

export enum EXIT_SIGN_PROCESS_STATUS {
  AGREEMENT_TO_BE_SUBMITTED = 'AGREEMENT_TO_BE_SUBMITTED',
  AGREEMENT_TO_BE_REVIEWED = 'AGREEMENT_TO_BE_REVIEWED',
  WAITING_HRBP_FH_REVIEW = 'WAITING_HRBP_FH_REVIEW',
  WAITING_HRBP_FH_INITIATE_SIGNING = 'WAITING_HRBP_FH_INITIATE_SIGNING',
  WAITING_HRBP_FH_PREVIEW_EXIT_FILES = 'WAITING_HRBP_FH_PREVIEW_EXIT_FILES',
  WAITING_INVOLUNTARY_EMPLOYEE_SIGNING = 'WAITING_INVOLUNTARY_EMPLOYEE_SIGNING',
  WAITING_HRO_INITIATE_APPROVAL = 'WAITING_HRO_INITIATE_APPROVAL',
  UNDER_APPROVAL = 'UNDER_APPROVAL',
  WAITING_HRO_INITIATE_SIGNING = 'WAITING_HRO_INITIATE_SIGNING',
  WAITING_EMPLOYEE_SIGNING = 'WAITING_EMPLOYEE_SIGNING',
  WAITING_HRO_SIGNING = 'WAITING_HRO_SIGNING',
  HRO_SIGNED = 'HRO_SIGNED',
  FINISH = 'FINISH',
  ARCHIVING = 'ARCHIVING',
  ARCHIVED = 'ARCHIVED'
}

export enum EXIT_REVIEW_PROCESS_STATUS {
  TO_BE_SUBMITTED = 'TO_BE_SUBMITTED',
  UNDER_GENERATE = 'UNDER_GENERATE',
  GENERATE_FAILED = 'GENERATE_FAILED',
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  REVIEWED = 'REVIEWED',
}

export enum EXIT_APPROVAL_PROCESS_STATUS {
  TO_BE_ACTIVE = 'TO_BE_ACTIVE',
  UNAPPROVED = 'UNAPPROVED',
  APPROVED = 'APPROVED',
}

export enum EVENT_PROCESS_TAG {
  EVENT_NEED_DEAL = 'event-need-deal',
}

export const HRBP_CAN_REVIEW_EXIT_FILES_STATUS_LIST = [
  EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_INITIATE_SIGNING,
  EXIT_SIGN_PROCESS_STATUS.WAITING_INVOLUNTARY_EMPLOYEE_SIGNING,
  EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_PREVIEW_EXIT_FILES,
  EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_REVIEW,
  EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_APPROVAL,
];

export enum ExitApprovalHroStatusFilterEnum {
  LEAVE_TO_BE_ACTIVE = 'LEAVE_TO_BE_ACTIVE',
  LEAVE_UNAPPROVED = 'LEAVE_UNAPPROVED',
  LEAVE_APPROVED = 'LEAVE_APPROVED',
  OFFICE_TO_BE_ACTIVE = 'OFFICE_TO_BE_ACTIVE',
  OFFICE_UNAPPROVED = 'OFFICE_UNAPPROVED',
  POWER_TO_BE_ACTIVE = 'POWER_TO_BE_ACTIVE',
  POWER_UNAPPROVED = 'POWER_UNAPPROVED',
  POWER_APPROVED = 'POWER_APPROVED'
}
