// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import './TableColumnFilter.scss';
import { useDispatch, useSelector } from 'react-redux';
import { HOME_OFFICE_EN_CHINESE_MAP } from '@/constants/employee';
import { IExitTableDataQueryFilter } from '@/models/employee';
import { AnyAction } from '@reduxjs/toolkit';

interface TableColumnFilterProps {
  filterFieldKey: string
  filters?: { text: string, value: string}[],
  close: () => void,
  filterCandidatesReduxQueryMethod: (state) => IExitTableDataQueryFilter,
  filterReduxUpdateMethod: (state) => AnyAction,
  handleConfirm: () => void,
}

const TableColumnFilter = ({
  filterFieldKey,
  filters,
  close,
  filterCandidatesReduxQueryMethod,
  filterReduxUpdateMethod,
  handleConfirm
}: TableColumnFilterProps) => {
  const [ options, setOptions ] = useState([]);
  const [ value, setValue ] = useState([]);

  const dispatch = useDispatch();
  const allColumnCandidateObject = useSelector(filterCandidatesReduxQueryMethod);

  useEffect(() => {
    if (!allColumnCandidateObject) {
      return;
    }
    const currentColumnCandidateList = allColumnCandidateObject[filterFieldKey];
    const tempOptions = [];
    if (currentColumnCandidateList) {
      currentColumnCandidateList.forEach(candidateStr => tempOptions.push({
        label: filterFieldKey === 'homeOffice' ? HOME_OFFICE_EN_CHINESE_MAP[candidateStr] : candidateStr,
        value: candidateStr
      }));
    } else {
      filters?.forEach(filterObject => tempOptions.push({
        label: filterObject.text,
        value: filterObject.value
      }));
    }
    setOptions(tempOptions);
  }, [ allColumnCandidateObject ]);

  const handleChange = (newValue: string[]) => {
    const finalValue = newValue;
    setValue(finalValue);
    dispatch(filterReduxUpdateMethod({ [filterFieldKey]: finalValue }));
  };

  const handlePopConfirm = () => {
    close();
    handleConfirm();
  };

  const handlePopCancel = () => {
    close();
    setValue([]);
    dispatch(filterReduxUpdateMethod({ [filterFieldKey]: null }));
  };

  return (
    <div className="table-column-filter">
      <div className="table-column-filter-content-component">
        <Select
          className='table-column-filter-search-input'
          data-testid={`${filterFieldKey}-filter-select-bar`}
          mode="multiple"
          placeholder='搜索'
          value={value}
          options={options}
          onChange={handleChange}
          maxTagCount="responsive"
          allowClear
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
        />
        <Button className="table-column-filter-close-btn" onClick={handlePopCancel} size="small">
          取 消
        </Button>
        <Button className="table-column-filter-confirm-btn" type="primary" onClick={handlePopConfirm} size="small">
          确 认
        </Button>
      </div>
    </div>
  );
};

export default TableColumnFilter;