import { ColumnsType } from 'antd/es/table';
import { IContractExtensionNotSignDetail } from '@/models/employee';

export const contractExtensionNotSignDetailColumns: ColumnsType<IContractExtensionNotSignDetail> = [
  {
    title: '合同到期日',
    dataIndex: 'contractExpirationDate',
    className: 'align-center',
    width: '11%',
    fixed: 'left',
  },
  {
    title: '城市',
    dataIndex: 'homeOfficeChinese',
    className: 'align-center',
    width: '6%',
    fixed: 'left',
  },
  {
    title: '中文名',
    dataIndex: 'chineseName',
    className: 'align-center show-ellipsis',
    width: '7%',
    fixed: 'left',
  },
  {
    title: '工号',
    className: 'align-center',
    dataIndex: 'employeeId',
    width: '8%',
  },
  {
    title: '部门',
    className: 'align-center',
    dataIndex: 'supervisoryOrgName',
    width: '11%',
  },
  {
    title: '角色',
    className: 'align-center',
    dataIndex: 'role',
    width: '12%'
  },
  {
    title: '职级',
    className: 'align-center',
    dataIndex: 'grade',
    width: '10%',
  },
  {
    title: '类型',
    className: 'align-center',
    dataIndex: 'contractNonExtensionProcessName',
    width: '10%',
  },
  {
    title: '操作',
    colSpan: 2,
    className: 'align-center',
    dataIndex: 'contractNonExtensionProcessStatus',
    width: '13%',
  },
  {
    colSpan: 0,
    className: 'align-center',
    width: '6%',
    dataIndex: 'operation',
  }
];
