import { message, Modal, Select, SelectProps } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import './InitiateApprovalModal.scss';
import { searchUser } from '@/api/employeeApi';
import { initiateExitApproval } from '@/api/exitApi';

interface InitiateApprovalModalProps {
  processId: string,
  isModalOpen: boolean,
  closeModal: (needRefreshData: boolean) => void
}

const InitiateApprovalModal = ({ processId, isModalOpen, closeModal }: InitiateApprovalModalProps) => {
  const [ data, setData ] = useState<SelectProps['options']>([]);
  const [ value, setValue ] = useState<string>(null);

  const handleSearch = async (newValue: string) => {
    if (newValue.length === 5) {
      const user = await searchUser(newValue);
      if (user) {
        setData([ { value: user.employeeId, label: `${user.employeeId} ${user.chineseName}` } ]);
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  };

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleOk = async () => {
    try {
      await initiateExitApproval({ businessTeamEmployeeId: value, processId });
      setValue(null);
      setData([]);
      closeModal(true);
      message.success('员工已被发起离职流转');
    } catch (e) {
      message.error('发起离职流转失败，请重试！');
    }
  };

  const handleCancel = async () => {
    setValue(null);
    setData([]);
    closeModal(false);
  };

  return (
    <Modal
      wrapClassName="initiate-approval-modal"
      width={492}
      open={isModalOpen}
      centered
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      title="发起离职流转"
      okText="确认"
      okButtonProps={{ 'disabled': !value }}
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className="input-label"><span>* </span>请添加业务守关人员</div>
      <div className="select">
        <Select
          showSearch
          suffixIcon={<i className="ri-search-line" />}
          style={{ width: '100%' }}
          value={value}
          placeholder="请输入员工ID"
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          options={data}
        />
      </div>
    </Modal>
  );
};

export default InitiateApprovalModal;
