import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentUserMenu,
  selectCurrentUserRole,
  setUserProcess,
} from '@/store/userInfo';
import { ROLE_USER } from '@/constants/role';
import {
  isCurrentRouteMatchMenuFirstItem,
  isUserHasRouteAuthRole,
} from '@/utils/authUtils';
import { AppDispatch } from '@/models/store';
import { getESignProcess } from '@/api/authApi';
import { useRouterGuard } from '@/utils/useRouterGuard';

export const useDefaultRoute = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUserRole = useSelector(selectCurrentUserRole);
  const navigate = useNavigate();
  const menuConfigs = useSelector(selectCurrentUserMenu, shallowEqual);
  const { location, matchedRoutes, matchedCurrentRoute } = useRouterGuard();

  const getFirstChildRouter = menuConfigs => {
    let firstChild = menuConfigs[0];
    let path = firstChild.path;
    while (firstChild.children) {
      firstChild = firstChild.children[0];
      path += firstChild.path;
    }
    return path;
  };

  useEffect(() => {
    if (!currentUserRole || isEmpty(menuConfigs)) {
      return;
    }
    const isRootRoute = matchedRoutes.length === 0;
    const isCurrentUserChangeRole = () => {
      return !isCurrentRouteMatchMenuFirstItem(menuConfigs, location) && isUserHasRouteAuthRole(currentUserRole, matchedCurrentRoute);
    };
    if (isRootRoute || (!isRootRoute && isCurrentUserChangeRole())) {
      if (currentUserRole === ROLE_USER) {
        getESignProcess()
          .then(data => {
            dispatch(setUserProcess(data));
            if (isEmpty(data)) {
              navigate('/my-certification/apply-certification');
            } else {
              navigate(menuConfigs[0].path);
            }
          })
          .catch(() => {
            navigate(menuConfigs[0].path);
          });}
      navigate(getFirstChildRouter(menuConfigs));
    }
  }, [ location.pathname, currentUserRole, menuConfigs ]);
};
