// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { Button, DatePicker } from 'antd';
import 'src/components/table-column-filter/TableColumnFilter.scss';
import { useDispatch } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';

interface TableColumnFilterProps {
  filterFieldKey: string
  close: () => void,
  filterReduxUpdateMethod: (state) => AnyAction,
  handleConfirm: () => void,
}

const TableColumnDateFilter = ({
  filterFieldKey,
  close,
  filterReduxUpdateMethod,
  handleConfirm
}: TableColumnFilterProps) => {
  const [ datePickerKey, setDatePickerKey ] = useState(new Date());

  const dispatch = useDispatch();

  const handleChange = (newValue: string[]) => {
    const finalValue = newValue;
    const dateStartFiledKey = `${filterFieldKey}Start`;
    const dateEndFiledKey = `${filterFieldKey}End`;
    dispatch(filterReduxUpdateMethod({
      [dateStartFiledKey]: finalValue[0],
      [dateEndFiledKey]: finalValue[1]
    }));
  };

  const handlePopConfirm = () => {
    close();
    handleConfirm();
  };

  const handlePopCancel = () => {
    close();
    setDatePickerKey(new Date());
    const dateStartFiledKey = `${filterFieldKey}Start`;
    const dateEndFiledKey = `${filterFieldKey}End`;
    dispatch(filterReduxUpdateMethod({
      [dateStartFiledKey]: null,
      [dateEndFiledKey]: null
    }));
  };

  return (
    <div className="table-column-filter">
      <div className="table-column-filter-content-component">
        <DatePicker.RangePicker
          key={datePickerKey.toString()}
          onChange={(_, startEndString) => handleChange(startEndString)}
        />
        <Button className="table-column-filter-close-btn" onClick={handlePopCancel} size="small">
          取 消
        </Button>
        <Button className="table-column-filter-confirm-btn" type="primary" onClick={handlePopConfirm} size="small">
          确 认
        </Button>
      </div>
    </div>
  );
};

export default TableColumnDateFilter;