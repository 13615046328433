import { useState, useEffect, useRef } from 'react';

export const useCountDown = () => {
  const [ count, setCount ] = useState(0);
  const [ flag, setFlag ] = useState(true);
  const [ timeText, setTimeText ] = useState('同意并继续');
  const timeIdRef = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(timeIdRef.current);
    };
  }, []);

  useEffect(() => {
    if (count < 1) {
      setFlag(true);
      clearInterval(timeIdRef.current);
      setTimeText('同意并继续');
    } else {
      setTimeText('同意并继续（' + count + 's）');
    }
  }, [ count ]);

  const startCountDown = (num = 8) => {
    if (!flag) {
      return;
    }
    setFlag(false);
    setCount(num);
    timeIdRef.current = setInterval(() => {
      setCount(count => count - 1);
    }, 1000);
  };

  return { count, startCountDown, flag, timeText };
};