import { Modal } from 'antd';
import { ReactNode, useState } from 'react';
import './NotificationModal.scss';

interface NotificationModalProps {
  text: ReactNode;
  wrapClass?: string;
  onCancel?: () => void;
}

const NotificationModal = ({ text, onCancel, wrapClass }: NotificationModalProps) => {
  const [ isModalOpen, setIsModalOpen ] = useState(true);
  const [ modelClass, setModelClass ] = useState(wrapClass);
  if (modelClass === undefined) {
    setModelClass('notification-model');
  }
  return (
    <Modal
      wrapClassName={modelClass}
      width={452}
      open={isModalOpen}
      centered
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={() => {
        setIsModalOpen(false);
        onCancel();
      }}
      footer={null}>
      <div>
        <i className="ri-check-double-line"></i>
      </div>
      <p>{text}</p>
    </Modal>
  );
};

export default NotificationModal;
