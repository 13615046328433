import JSZip from 'jszip';
import * as FileSaver from 'file-saver';

let fileNameCountMap;
const replaceLastDot = (inputString, replacement) => {
  const lastDotIndex = inputString.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return inputString.substring(0, lastDotIndex) + replacement + inputString.substring(lastDotIndex + 1);
  }
  return inputString;
};

const renameIfDuplicate = (fileName: string) => {
  let uniqueFileName = fileName;
  if (fileNameCountMap.has(fileName)) {
    const currentNameCount = fileNameCountMap.get(fileName);
    uniqueFileName = replaceLastDot(fileName, `(${currentNameCount}).`);
    fileNameCountMap.set(fileName, currentNameCount + 1);
  } else {
    fileNameCountMap.set(fileName, 1);
  }
  return uniqueFileName;
};

export function saveFilesAsZip(files: { blob: File, name: string }[], zipName: string): Promise<void> {
  fileNameCountMap = new Map();
  const zip = new JSZip();
  files.forEach(file => {
    const uniqueFileName = renameIfDuplicate(file.name);
    zip.file(uniqueFileName, file.blob);
  });
  return zip.generateAsync({ type: 'blob' })
    .then(content => {
      FileSaver.saveAs(content, `${zipName}.zip`);
    });
}
