import { ColumnsType } from 'antd/es/table';
import { IContractExtensionEmployeeToBeSignedDetail } from '@/models/employee';

export const contractExtensionEmployeeToBeSignedDetailColumns: ColumnsType<IContractExtensionEmployeeToBeSignedDetail> = [
  {
    title: '合同到期日',
    dataIndex: 'contractExpirationDate',
    className: 'align-center',
    width: '11%',
    fixed: 'left',
  },
  {
    title: '城市',
    dataIndex: 'homeOfficeChinese',
    className: 'align-center',
    width: '6%',
    fixed: 'left',
  },
  {
    title: '中文名',
    dataIndex: 'chineseName',
    className: 'align-center show-ellipsis',
    width: '8%',
    fixed: 'left',
  },
  {
    title: '工号',
    className: 'align-center',
    dataIndex: 'employeeId',
    width: '8%',
  },
  {
    title: '部门',
    className: 'align-center',
    dataIndex: 'supervisoryOrgName',
    width: '9%',
  },
  {
    title: '角色',
    className: 'align-center',
    dataIndex: 'role',
    width: '12%'
  },
  {
    title: '职级',
    className: 'align-center',
    dataIndex: 'grade',
    width: '10%',
  },
  {
    title: '操作',
    colSpan: 3,
    className: 'align-center',
    dataIndex: 'employeeContractReviewedStatus',
    width: '10%',
  },
  {
    colSpan: 0,
    className: 'align-center',
    dataIndex: 'employeeContractSigningStatus',
    width: '10%',
  },
  {
    colSpan: 0,
    className: 'align-center',
    width: '6%',
    dataIndex: 'operation',
  }
];
