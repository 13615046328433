import { Col, Form, FormInstance, Row } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'dayjs/locale/zh-cn';
import {
  IContractExtensionInformationResponse,
  IContractExtensionNotSignInformationResponse, IEmployeeExitInfo,
  IExitEmployeeResponse,
  IHireInformationResponse
} from '@/models/employee';
import { isFunction, map } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOptions } from '@/store/employee';
import { ContractExtensionNotSignFieldConfig } from '@/constants/config/employee-form/will-not-extension-table/FieldValidatorConfig';
import {
  ContractExtensionFieldConfig
} from '@/constants/config/employee-form/will-extension-table-to-be-audited-table/FieldValidatorConfig';
import {
  HireFieldConfig
} from '@/constants/config/employee-form/entry-contract-process-to-be-audited-table/FieldValidatorConfig';
import { hasWorkExperienceOption, hiredTypesOptions, trainingTypesOptions } from '@/constants/employeeOptions';
import { ExitEmployeeFieldConfig } from '@/constants/config/employee-form/exit-employee-table-info/FieldValidatorConfig';
import { ExitEmployeePreviewFieldConfig } from '@/constants/config/employee-form/exit-employee-info-preview/FieldValidatorConfig';
import { HOME_OFFICE } from '@/constants/employee';

interface EmployeeFormProps {
    form: FormInstance;
    employeeInfo: IContractExtensionNotSignInformationResponse |
        IContractExtensionInformationResponse |
        IHireInformationResponse |
        IExitEmployeeResponse |
        IEmployeeExitInfo;
    fieldValidatorConfig:  ContractExtensionNotSignFieldConfig[][] |
        ContractExtensionFieldConfig[][] |
        HireFieldConfig[][] |
        ExitEmployeeFieldConfig[][] |
        ExitEmployeePreviewFieldConfig[][];
    subTitle?: string;
}

const KEYS_AFFECT_FORM_FIELDS = [ 'trainingType', 'position' ];

moment.locale('zh-cn');

const renderForm = ({ form, employeeInfo, fieldValidatorConfig, subTitle }: EmployeeFormProps) => {
  const options = useSelector(selectOptions);
  const allOptions = useMemo(() => (
    {
      ...options,
      hiredTypesOptions,
      trainingTypesOptions,
      hasWorkExperienceOption
    }
  ), [ options ]);

  function filterFormFields(employeeInfo: IContractExtensionNotSignInformationResponse
      | IContractExtensionInformationResponse | IHireInformationResponse | IExitEmployeeResponse | IEmployeeExitInfo) {
    return fieldValidatorConfig.map(rowGroup => rowGroup.filter(field => {
      return field.visibleCondition ? field.visibleCondition(employeeInfo) : true;
    })).filter(rowGroup => rowGroup.length > 0);
  }
  const [ formFields, setFormFields ] = useState(filterFormFields(employeeInfo));

  const getFormFieldsValue = useCallback(() => ({
    ...employeeInfo,
    ...form.getFieldsValue(),
  }), [ form, employeeInfo ]);

  const handleValuesChange = useCallback(changedValues => {
    const changedKeys = Object.keys(changedValues);
    if (KEYS_AFFECT_FORM_FIELDS.some(fieldKey => changedKeys.includes(fieldKey))) {
      setFormFields(filterFormFields(getFormFieldsValue()));
    }
    if (changedValues.trainingType) {
      form.setFieldValue('trainingLocation', null);
    }

    const homeOffice = form.getFieldsValue().homeOffice;
    if (changedValues.terminationDate) {
      let date = form.getFieldsValue().terminationDate;
      form.setFieldValue('lastDateOfWork', moment(date));
      switch (homeOffice) {
      case HOME_OFFICE.XIAN:
        break;
      case HOME_OFFICE.SHANGHAI:
      case HOME_OFFICE.BEIJING:
      case HOME_OFFICE.WUHAN:
      case HOME_OFFICE.SHENZHEN:
        form.setFieldValue('socialSecurityTerminationDate', moment(date));
        form.setFieldValue('providentFundTerminationDate', moment(date));
        break;
      case HOME_OFFICE.CHENGDU:
        if (moment(date).date() < 15) {
          date = moment(date).format('YYYY-MM');
          date = moment(date).subtract(1, 'months');
        }
        form.setFieldValue('socialSecurityTerminationDate', moment(date));
        form.setFieldValue('providentFundTerminationDate', moment(date));
        break;
      default:
        break;
      }
    }
  }, [ form ]);

  return (
    <>
      {subTitle && <div className="headline5 sub-title">{subTitle}</div>}
      <Form layout="vertical" form={form} autoComplete="off" onValuesChange={handleValuesChange}>
        {
          map(formFields, (row , index) =>
            <Row gutter={8} key={index}>
              {
                map(row, (config, $index) => (
                  <Col span={config.colSpan} key={config.name + `${$index}`}>
                    <Form.Item
                      name={config.name}
                      label={config.label}
                      validateTrigger={config.validateTrigger}
                      rules={config.rules}
                    >
                      {isFunction(config.component)
                        ? config.component(config, allOptions, getFormFieldsValue, employeeInfo)
                        : config.component}
                    </Form.Item>
                  </Col>
                ))
              }
            </Row>
          )
        }
      </Form>
    </>
  );
};
export const EmployeeInfoForm = (props: EmployeeFormProps) => {
  return renderForm(props);
};
