import { every, isEmpty, some } from 'lodash-es';
import { identifyRegex, mobileRegex } from '@/constants/regex';
import { FILE_ERROR, invalidIdentify, invalidMobile } from '@/constants/error';

const validateOtherId = () => ({
  validator() {
    return Promise.resolve();
  }
});

export const validateIdentify = () => ({
  validator(_, value) {
    if (isEmpty(value) || identifyRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(invalidIdentify));
  }
});

export const validateMobileRegex = () => ({
  validator(_, value) {
    if (isEmpty(value) || mobileRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(invalidMobile));
  }
});

export const validateIdNumber = ({ getFieldValue }) => {
  if (getFieldValue('idType') === 'idCard') {
    return validateIdentify();
  }
  return validateOtherId();
};

export const validateUploadFiles =  () => ({
  validator(_, value) {
    if (isEmpty(value)) {
      return Promise.reject(new Error(FILE_ERROR.uploadHint));
    }
    if (some(value, item => item.status === 'error')) {
      return Promise.reject(new Error(FILE_ERROR.uploadFailed));
    }
    return Promise.resolve();
  }
});

export const isCertificateMatchedFiles = index => ({ getFieldValue }) => ({
  validator(_, value) {
    const attachments = getFieldValue([ 'skillInfo', 'certificateInfos', index, 'attachments' ]);
    if (isEmpty(attachments) || every(attachments, attachment => attachment.name.includes(value))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('证书类型与上传的文件不符，请删除文件，或者选择对应的类型'));
  }
});

export const validateMoney = () => ({
  validator(_, value) {
    if (value < 0) {
      return Promise.reject(new Error('金额不可为负数'));
    }

    if (value == 0) {
      return Promise.reject(new Error('金额不可为零'));
    }

    if (String(value).includes('.')) {
      const numberOfDecimalPlaces = String(value).length - String(value).indexOf('.') - 1;
      if (numberOfDecimalPlaces > 2) {
        return Promise.reject(new Error('不超过2位小数点'));
      }
    }
    return Promise.resolve();
  }
});
