import { Checkbox, Col, Form, Input, message, Radio, Row } from 'antd';
import InfoCircleSvg from '@/assets/icons/info-circle.svg';
import Icon from '@ant-design/icons';
import EmployeeFormLabel from '@/pages/employee/employee-info-edit/employee-form-label/EmployeeFormLabel';
import { RequiredRule } from '@/constants/validation';
import { useCallback, useEffect, useState } from 'react';
import FormSubmitButton from '@/components/form-submit-button/FormSubmitButton';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationModal from '@/components/modal/NotificationModal';
import './ExitInformation.scss';
import { ERROR_CODE, invalidFormat, invalidLength } from '@/constants/error';
import { getExitEmployeeInfo, updateExitEmployeeInfo } from '@/api/exitApi';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';

interface employeeExitInfo {
  chineseName: string;
  employeeId: string;
  personalEmail: string;
  phoneNumber: string;
  bankCard: string;
  accountsBank: string;
  contactAddress: string;
  otherContactInformation: string;
  suitableJobNotification: string;
  fieldOfStudyOrExpertise: string | string[];
  other: string,
}

const ExitInformation = () => {
  const navigate = useNavigate();
  const [ form ] = Form.useForm();
  const { processId } = useParams();
  const [ willingness, setWillingness ] = useState(false);
  const [ employeeExitInfo, setEmployeeExitInfo ] = useState<employeeExitInfo>(null);
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [ clickOther, setClickOther ] = useState<boolean>();
  const [ popOpen, setPopOpen ] = useState<boolean>(false);

  useEffect(() => {
    getExitEmployeeInfo(processId).then(employeeEditExitInfo => {
      if (!employeeEditExitInfo) {
        return;
      }
      // TODO 待重构，fieldOfStudyOrExpertise和other应该分两个字段存储到数据库中
      if (employeeEditExitInfo.fieldOfStudyOrExpertise != null) {
        const findOthersIndex = employeeEditExitInfo.fieldOfStudyOrExpertise.indexOf('other');
        if (findOthersIndex >= 0) {
          const others = employeeEditExitInfo.fieldOfStudyOrExpertise.slice(findOthersIndex + 6);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          employeeEditExitInfo.fieldOfStudyOrExpertise = employeeEditExitInfo.fieldOfStudyOrExpertise
            .slice(0, findOthersIndex + 5).split(',');
          setEmployeeExitInfo({ ...employeeEditExitInfo, other: others });
          setClickOther(true);
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          employeeEditExitInfo.fieldOfStudyOrExpertise = employeeEditExitInfo.fieldOfStudyOrExpertise.split(',');
          setEmployeeExitInfo({ ...employeeEditExitInfo, other: null });
        }
      } else {
        setEmployeeExitInfo({ ...employeeEditExitInfo, other: null });
      }
      setWillingness((employeeEditExitInfo.suitableJobNotification === 'true'));
    },
    (err: AxiosError<IErrorResponse>) => {
      if (err.response.data.code === ERROR_CODE.userOperationIllegal) {
        navigate('/employee-home');
      }
    });
  }, []);

  const handleSuitableJobNotificationChange = useCallback(event => {
    setWillingness((event.target.value === 'true'));
  }, []);

  const handleOtherChange = useCallback(event => {
    setClickOther(event.target.checked);
  }, []);

  const onSubmit = async () => {
    form.validateFields().then(formValues => {
      formValues.processId = processId;
      let findIndex = -1;
      if (formValues.fieldOfStudyOrExpertise != undefined) {
        findIndex = formValues.fieldOfStudyOrExpertise.findIndex(item => item === 'other');
        if (findIndex >= 0) {
          formValues.fieldOfStudyOrExpertise.splice(findIndex, 1);
          formValues.fieldOfStudyOrExpertise.push('other').toString();
          formValues.fieldOfStudyOrExpertise = formValues.fieldOfStudyOrExpertise + ',' + formValues.other;
        } else {
          formValues.fieldOfStudyOrExpertise = formValues.fieldOfStudyOrExpertise.toString();
        }
      }
      return updateExitEmployeeInfo(formValues);
    }).then(() => {
      setPopOpen(false);
      setIsModalOpen(true);
    }, (err: AxiosError<IErrorResponse>) => {
      if ((err as AxiosError<IErrorResponse>).response?.data.code === ERROR_CODE.userOperationIllegal) {
        message.error('更新失败，已发起流转，需更新请联系People Support Team');
      }
    });
  };

  return (
    <> {employeeExitInfo &&
      <Form
        className="exit-personal-information-form"
        form={form}
        layout="vertical"
        validateTrigger={[ 'onChange' ]}
        initialValues={employeeExitInfo}>
        <div className="exit-personal-information-edit">
          <div className="exit-personal-information-header-tip">
            <Icon className="info-icon" component={InfoCircleSvg} aria-label="Info-icon"/>
            <span className="info-text-span">
              <p>请尽量提供多种联系方式，以便有薪资、社保、税务、股票、灵活用工等相关事宜，我们可以及时联系！<br/>
              Please include as much contact information as you can so that we may get in touch with you as soon as possible regarding
              payroll, social security, taxes, stocks, flexible labor, and other related issues!
              </p>
              <p>请准确填写个人邮箱地址，此邮箱将用于接收离职文件的签署<br/>
              Kindly provide your personal email address to get the resignation documents.</p>
            </span>
          </div>
          <div className="form-group">
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item name="chineseName"
                  label={<EmployeeFormLabel label="姓名" bracketSubLabel="Name"/>} rules={[ RequiredRule ]}>
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="employeeId"
                  label={<EmployeeFormLabel label="员工ID" bracketSubLabel="Employee ID No."/>} rules={[ RequiredRule ]}>
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="personalEmail"
                  label={<EmployeeFormLabel label="个人邮箱" bracketSubLabel="Personal E-mail" placement="top"/>}
                  rules={[ RequiredRule, { type: 'email', message: invalidFormat } ]}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="phoneNumber"
                  label={<EmployeeFormLabel label="联系电话" bracketSubLabel="Phone Number"/>} rules={[ RequiredRule ]}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item name="bankCard"
                  label={<EmployeeFormLabel
                    label="银行卡号"
                    bracketSubLabel="Card No."
                    tip={<label>请保证该卡在离职半年内可以收款<br/> Make sure the card can be paid for within six months of the
                               resignation.</label>}
                    placement="top"/>}
                  rules={[ RequiredRule, {
                    type: 'number',
                    required: false,
                    message: invalidFormat,
                    transform: value => {
                      return Number(value);
                    }
                  }, ]}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="accountsBank"
                  label={<EmployeeFormLabel
                    label="银行卡开户行" bracketSubLabel="Accounts Bank"
                    tip={<label>请保证该卡在离职半年内可以收款，格式为：XX银行XX分行XX支行<br/>
                               Please make sure that the card is able to receive funds within six months after the resignation.
                               The format is: XX Sub-branch of XX bank XX branch</label>}
                    placement="top"/>}
                  rules={[ RequiredRule ]}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item name="contactAddress"
                  label={<EmployeeFormLabel label="联系地址" bracketSubLabel="Contact Address" placement="top"/>}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="otherContactInformation"
                  label={<EmployeeFormLabel label="其它联系方式" bracketSubLabel="Contact Information"/>}>
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-group">
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="suitableJobNotification"
                  label={<EmployeeFormLabel label="你是否愿意作为一名freelancer参与到TW项目中，并在相关工作机会的时候，收到我们的消息推送？"
                    subLabel="Would you be willing to work as a freelancer on the TW project
                  and be the first to know when suitable job opportunities come up?" needBr={true}/>}
                  rules={[ RequiredRule ]}>
                  <Radio.Group data-testid="suitableJobNotification" onChange={handleSuitableJobNotificationChange}>
                    <Radio value="true">是<span className="sub-form-label small-text">Yes</span></Radio>
                    <Radio value="false">否<span className="sub-form-label small-text">No</span></Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {willingness &&
              <>
                <div className="description">
                  <EmployeeFormLabel label="感谢你在TW期间的付出，我们十分重视你的宝贵经验，未来TW将探索多样化的灵活用工工作机会，
                  具体的工作内容包括但不限于开发、售前咨询培训、运营等。"
                  subLabel="Thank you for contributing to TW. We appreciate your insightful experience.
                    TW will continue exploring various flexible work options. The particular work content consists of,
                    but is not restricted to, training, development, operation, and pre-sales consultation."
                  needBr={true}/>
                </div>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item>
                      <Form.Item
                        name="fieldOfStudyOrExpertise"
                        label={<EmployeeFormLabel label="你感兴趣/擅长的领域？"
                          subLabel="What field of study or expertise are you interested in?" needBr={true}/>}
                        rules={[ RequiredRule ]}>
                        <Checkbox.Group >
                          <Checkbox value="developer"><EmployeeFormLabel label="开发" subLabel="Developer"/></Checkbox>
                          <Checkbox value="pre-salesConsultant"><EmployeeFormLabel label="售前咨询"
                            subLabel="Pre-sales Consultant"/></Checkbox>
                          <Checkbox value="operation"><EmployeeFormLabel label="运营" subLabel="Operation"/></Checkbox>
                          <Checkbox value="training"><EmployeeFormLabel label="培训" subLabel="Training"/></Checkbox>
                          <Checkbox value="other" onChange={handleOtherChange} data-testid="checkBoxGroup">
                            <EmployeeFormLabel label="其它" subLabel="Other"/>
                          </Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                      <Row gutter={[ 12, 6 ]}>
                        <Col span={6}>
                          {clickOther &&
                            <Form.Item name="other" rules={[ RequiredRule, { max: 200, message: invalidLength } ]}>
                              <Input data-testid="otherInput"
                                className="other-input" placeholder="Please input other study or expertise"></Input>
                            </Form.Item>
                          }
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }
          </div>
        </div>
        <Form.Item className="submit-btn-form-item">
          <FormSubmitButton form={form} onSubmit={onSubmit} setPopOpen={setPopOpen} popOpen={popOpen}></FormSubmitButton>
        </Form.Item>

      </Form>
    }
    {isModalOpen &&
        <NotificationModal text={<EmployeeFormLabel label="信息已提交\n请等待管理员发起流程"
          subLabel="Submitted\nPlease wait for the administrator to initiate the process"
          needBr={true}/>}
        onCancel={() => {
          navigate('/employee-home');
        }}></NotificationModal>}
    </>
  );
};
export default ExitInformation;
