import { Outlet, Navigate, useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import '@/pages/root-element/RootElement.scss';
import LegalValidityPopupWindow from '@/components/modal/LegalValidityPopupWindow';
import BreadcrumbRouter from '@/components/breadcrumb-router/BreadcrumbRouter';
import SideMenuBar from '@/components/side-menu-bar/SideMenuBar';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, setUserInfo } from '@/store/userInfo';
import { useDefaultRoute } from '@/utils/useDefaultRoute';
import { getUserInfo } from '@/api/authApi';
import { AppDispatch } from '@/models/store';

const RootElement = () => {
  const { hasPermission } = useRouterGuard();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  useDefaultRoute();

  useEffect(() => {
    getUserInfo().then(data => dispatch(setUserInfo(data)))
      .catch(() => navigate('/no-permission'));
  }, []);

  const userInfo = useSelector(selectUserInfo);
  return (
    <div className="employee">
      {
        !userInfo || hasPermission
          ? <>
            <div className="employee-sider">
              <SideMenuBar />
            </div>
            <div className="employee-container">
              <BreadcrumbRouter></BreadcrumbRouter>
              {userInfo && <Outlet />}
            </div>
          </> : <Navigate to={'/no-permission'} />
      }
      <LegalValidityPopupWindow />
    </div>
  );
};

export default RootElement;
