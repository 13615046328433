import { IExitEmployeeResponse, IOption, Options } from '@/models/employee';
import { ReactElement } from 'react';
import { Rule } from 'rc-field-form/lib/interface';
import { requiredMessage } from '@/constants/error';
import { DatePicker, Input, InputNumber } from 'antd';
import CustomDatePicker from '@/components/custom-date-picker/CustomDatePicker';
import zhCN from 'antd/es/date-picker/locale/zh_CN';
import { currencyFormat } from '@/utils/commonUtils';
import { validateMoney } from '@/utils/validateUtils';

interface ComponentProps {
  optionKey?: string;
  showSearch?: boolean;
  optionFilter?: (options: IOption[], formValues: IExitEmployeeResponse) => IOption[];
}

interface FieldComponentFun<T> {
  (config: T, options: Options, getFormFieldsValue: () => IExitEmployeeResponse): ReactElement;
}

export interface ExitEmployeeFieldConfig {
  colSpan: number;
  name: string;
  label?: string;
  rules?: Rule[];
  component: ReactElement | FieldComponentFun<ExitEmployeeFieldConfig>
  componentProps?: ComponentProps
  visibleCondition?: (formValues: IExitEmployeeResponse) => boolean;
  validateTrigger?: string | string[];
}

export const InfoFieldValidatorConfig: Array<ExitEmployeeFieldConfig[]> = (
  [
    [
      {
        colSpan: 8,
        name: 'legalFullName',
        label: 'Legal Name',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100}/>
      },
      {
        colSpan: 8,
        name: 'chineseName',
        label: '姓名',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100}/>
      }
    ],
    [
      {
        colSpan: 8,
        name: 'idType',
        label: '证件类型',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>
      },
      {
        colSpan: 8,
        name: 'idNumber',
        label: '证件号码',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>
      },
    ],
    [
      {
        colSpan: 8,
        name: 'hireDate',
        label: '入职日期',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker(true)
      },
      {
        colSpan: 8,
        name: 'terminationDate',
        label: '离职日期',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker()
      }
    ],
    [
      {
        colSpan: 8,
        name: 'position',
        label: 'Position',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>
      },
    ],
    [
      {
        colSpan: 0,
        name: 'homeOffice',
        label: '城市',
        component: null
      },
    ]
  ]
);
const DatePickerWithYearAndMonth = () => {
  return (
    <DatePicker
      locale={zhCN}
      picker={'month'}
      getPopupContainer={triggerNode => triggerNode}/>
  );
};


export const NoneTypeFieldValidatorConfig: Array<ExitEmployeeFieldConfig[]> = [];

export const WithCompensationTypeFieldValidatorConfig: Array<ExitEmployeeFieldConfig[]> =
  [
    [
      {
        colSpan: 8,
        name: 'lastDateOfWork',
        label: '最后工作日',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker()
      },
      {
        colSpan: 8,
        name: 'socialSecurityTerminationDate',
        label: '社保终止年月',
        rules: [ { required: true, message: requiredMessage } ],
        component: DatePickerWithYearAndMonth()
      },
      {
        colSpan: 8,
        name: 'providentFundTerminationDate',
        label: '公积金终止年月',
        rules: [ { required: true, message: requiredMessage } ],
        component: DatePickerWithYearAndMonth()
      }
    ],
    [
      {
        colSpan: 8,
        name: 'amountOfCompensation',
        label: '经济补偿金额',
        rules: [ { required: true, message: requiredMessage } , validateMoney ],
        component: <InputNumber
          className='ant-input'
          disabled={false}
          controls={false}
          formatter={currencyFormat}
        />
      }
    ]
  ];

export const WithoutCompensationTypeFieldValidatorConfig: Array<ExitEmployeeFieldConfig[]> =
  [
    [
      {
        colSpan: 8,
        name: 'lastDateOfWork',
        label: '最后工作日',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker()
      },
      {
        colSpan: 8,
        name: 'socialSecurityTerminationDate',
        label: '社保终止年月',
        rules: [ { required: true, message: requiredMessage } ],
        component: DatePickerWithYearAndMonth()
      },
      {
        colSpan: 8,
        name: 'providentFundTerminationDate',
        label: '公积金终止年月',
        rules: [ { required: true, message: requiredMessage } ],
        component: DatePickerWithYearAndMonth()
      }
    ]
  ];





