const _ = require('lodash');
export function filterSelectOptions(input: string, option: { children: string; }): boolean {
  return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
}

export const currencyFormat = value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const flatObjectListFields = obj => {
  const clonedObject = _.cloneDeep(obj);

  function recursiveFlat(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (Array.isArray(value)) {
          obj[key] = value.flatMap(item => item.split(','));
        } else if (typeof value === 'object' && value !== null) {
          recursiveFlat(value);
        }
      }
    }
  }

  recursiveFlat(clonedObject);
  return clonedObject;
};
