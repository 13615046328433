import { ColumnsType } from 'antd/es/table';
import { IEmployeeToBeAuditedDetail } from '@/models/employee';

export const employeeToBeAuditedDetailColumns: ColumnsType<IEmployeeToBeAuditedDetail> = [
  {
    title: '办公地点',
    dataIndex: 'homeOfficeChinese',
    className: 'align-center',
    width: 110,
  },
  {
    title: '工号',
    dataIndex: 'employeeId',
    className: 'align-center',
    width: 100,
  },
  {
    title: '姓名',
    dataIndex: 'chineseName',
    className: 'show-ellipsis align-center',
  },
  {
    title: '入职日期',
    dataIndex: 'hireDate',
    className: 'align-center',
  },
  {
    title: '员工类型',
    dataIndex: 'employmentType',
    className: 'align-center',
  },
  {
    title: '工作经历',
    dataIndex: 'workExperience',
    className: 'align-center',
    width: 90,
  },
  {
    title: '员工信息',
    dataIndex: 'employeeInformationProcessStatus',
    className: 'align-center individual-info-status',
    width: 142
  },
  {
    title: '入职材料',
    dataIndex: 'employeeMaterialsProcessStatus',
    className: 'last-td align-center',
    width: 142,
  }
];
