import { Badge, Button, Popconfirm } from 'antd';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { EMPLOYEE_CONTRACT_SIGN_STATUS } from '@/constants/status';
import Icon from '@ant-design/icons';
import ArchiveSvg from '@/assets/icons/archive.svg';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { useCustomMatomo } from '@/matomo';

interface ButtonWithArchiveProps {
  employeeId: string;
  contractSignTaskName: string;
  contractSignStatus: string;
  disabled?: boolean,
  confirmBatchArchive: (reqBody) => void;
}

const ArchiveComponent = (props: ButtonWithArchiveProps) => {
  const { employeeId, contractSignTaskName, contractSignStatus, disabled, confirmBatchArchive } = props;
  const { trackEvent } = useCustomMatomo();

  const confirmArchive = () => {
    trackEvent({
      category: MATOMO_CATEGORY.HroEmployeeContract,
      action: MATOMO_ACTION.Archive,
      name: MATOMO_NAME.Contract,
    });
    confirmBatchArchive( [ { employeeId, taskName: contractSignTaskName } ]);
  };


  const renderArchiveComponent = () => {
    if (contractSignStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.ARCHIVING) {
      return <Badge className="archiving-badge" color="#47A1AD" text="归档中"/>;
    }
    if (contractSignStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.ARCHIVED) {
      return <Badge className="archived-badge" color="#6B9E78" text="已归档"/>;
    }
    return <Popconfirm
      title="确认上传合同至Google Drive？"
      cancelText={'取消'}
      okText={'确认'}
      disabled={disabled}
      placement="bottomRight"
      overlayStyle={{ width: 312 }}
      getPopupContainer={triggerNode => triggerNode}
      onConfirm={() => confirmArchive()}>
      <Button
        disabled={disabled}
        size="small"
        type="text"
        icon={<Icon className="archive-icon" component={ArchiveSvg}/>}
      >
        归档
      </Button>
    </Popconfirm>;
  };

  return (<>
    {renderArchiveComponent()}
  </>);
};

export default ArchiveComponent;
