import { Button, message, Modal, Popconfirm, Tabs } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import { IHireInfo } from '@/models/employee';
import { employeeContractInfo } from '@/constants/employee';
import {
  confirmPreviewCheck,
  getEmployeeHireInfo,
  getUserContractByProcessId,
  rejectPreviewCheck,
} from '@/api/employeeApi';
import { filter, includes } from 'lodash-es';
import FilePreviewer from '@/components/file-previewer/FilePreviewer';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import './ContractPreviewModal.scss';

interface ContractPreviewModalProps {
  processId: string,
  employeeId: string,
  isModalOpen: boolean,
  showButtons: boolean,
  closeModal: (needRefreshData: boolean) => void
}

const ContractPreviewModal = ({ processId, employeeId, isModalOpen, closeModal,
  showButtons }:
  ContractPreviewModalProps) => {
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>(null);
  const [ contracts, setContracts ] = useState([]);
  const [ popRejectOpen, setPopRejectOpen ] = useState(false);
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    if (!userInfo || !isModalOpen) {
      return;
    }
    setEmployeeInfo(null);
    Promise.all([
      getEmployeeHireInfo(employeeId),
      getUserContractByProcessId(processId),
    ]).then(
      ([ hireInfo, contractMaterials ]) => {
        setEmployeeInfo(hireInfo);
        const materialKeys = Object.keys(contractMaterials || []);
        const tabItems = filter(employeeContractInfo, config => includes(materialKeys, config.key)).map(config => ({
          ...config,
          key: config.key,
          children: (
            <div className="materials" key={config.key}>
              {contractMaterials[config.key].map(fileInfo => (
                <div key={fileInfo.contractTemplateCode}>
                  <FilePreviewer
                    key={fileInfo.contractTemplateCode}
                    fileName={fileInfo.contractFileName}
                    storageUrl={fileInfo.fileUrl}/>
                </div>
              ))}
            </div>
          )
        }));
        setContracts(tabItems);
      });
    setContracts(contracts);
  }, [ employeeId ]);

  const popRejectTitle = () => {
    return (<div>
      <span style={{ display: 'block' }}>确认签署文件信息有误并驳回？</span>
      <span className="reject-info-pop">驳回后需再次审核补充离职信息</span>
    </div>);
  };

  const rejectSubmit = useCallback(() => {
    rejectPreviewCheck(processId).then(() => {
      message.info('签署文件已被驳回，请重新审核该员工');
      closeModal(true);
    });
  }, [ employeeInfo ]);

  const confirmSubmit = useCallback(() => {
    confirmPreviewCheck(processId).then(() => {
      message.success('签署文件已审核通过');
      closeModal(true);
    }).catch(() => message.error('通过审核失败'));
  }, [ employeeInfo ]);

  return (
    <Modal
      open={isModalOpen}
      wrapClassName="contract-check-modal"
      title={showButtons ? '员工（未签署）离职信息审核' : '员工离职信息预览'}
      width={1000}
      destroyOnClose={true}
      onCancel={() => {
        closeModal(false);
        setPopRejectOpen(false);
      }}
      footer={showButtons ? [
        <>
          <Popconfirm
            title={popRejectTitle}
            open={popRejectOpen}
            onCancel={() => setPopRejectOpen(false)}
            onConfirm={rejectSubmit}
            cancelText={'取消'}
            okText={'确认'}
            placement="bottomRight"
            overlayStyle={{ width: 312 }}
            getPopupContainer={triggerNode => triggerNode}>
            <Button className="contract-sign-reject-button" onClick={() => setPopRejectOpen(true)}>驳回</Button>
          </Popconfirm>
          <Popconfirm
            key="pass"
            className="confirm-popover"
            placement="topRight"
            title="确认标记审核通过？"
            onConfirm={confirmSubmit}
            okText="确认"
            cancelText="取消"
            overlayStyle={{ width: 222 }}
            getPopupContainer={triggerNode => triggerNode}
          >
            <Button type="primary">通过</Button>
          </Popconfirm>
        </>
      ] : null}
    >
      {employeeInfo &&
        <div className="preview-check-container">
          <div className="preview-check-content">
            <div className="employee-basic-info-container">
              <div className="employee-basic-info">
                <span className="employee-name">{employeeInfo.legalFullName?.trim()}</span>
                <span className="employee-id">{employeeInfo.employeeId}</span>
                <span className="employee-info-item">{employeeInfo.homeOffice}</span>
                <span className="employee-info-item">{employeeInfo.nationalityCn}</span>
                <span className="employee-info-item">{employeeInfo.employmentType}</span>
                <span className="employee-info-item last-item">{employeeInfo.position}</span>
              </div>
            </div>
            <Tabs items={contracts}/>
          </div>
        </div>
      }
    </Modal>
  );
};

export default ContractPreviewModal;
