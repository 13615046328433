import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
// eslint-disable-next-line no-use-before-define
import React, { useMemo } from 'react';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { isFunction, map } from 'lodash-es';
import { convertParamObjToString, getSearchParams } from '@/utils/url';
import { breadCrumbConfig } from '@/constants/breadCrumbConfig';

const BreadcrumbRouter = () => {
  const { location, matchedRoutes, matchedCurrentRoute } = useRouterGuard();

  const breadCrumbItems = useMemo(() => {
    const matchedCurrentRoutePath = matchedCurrentRoute?.route?.path;

    const breadCrumbRoutes = matchedCurrentRoutePath && breadCrumbConfig[matchedCurrentRoutePath]
      ? [
        breadCrumbConfig[matchedCurrentRoutePath],
        matchedCurrentRoute
      ]
      : matchedRoutes;


    return map(breadCrumbRoutes, ({ pathname, route }) => {
      const tabParam = matchedCurrentRoute?.route?.tabParam;
      const paramsObj = tabParam ? {
        tab: route.tabParam
      } : getSearchParams(location?.search);
      return ({
        title: isFunction(route.getTitle) ? route.getTitle() : route.title,
        link: pathname + convertParamObjToString(paramsObj, tabParam ? [ 'tab' ] : route.searchParams),
        notClickable: route.breadCrumbNotClickable,
      });
    });
  }, [ matchedRoutes, location.search ]);

  const hasBreadCrumb = matchedCurrentRoute?.route?.hasBreadCrumb;

  return (<>
    {hasBreadCrumb && <Breadcrumb className='breadcrumb'>
      {breadCrumbItems.map(menuItem => (
        <Breadcrumb.Item key={menuItem.link}>
          {
            menuItem.notClickable ? <span className='breadcrumb-title'>{menuItem.title}</span>
              : <Link to={menuItem.link} relative='path'>
                {menuItem.title}
              </Link>
          }
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>}
  </>);
};

export default BreadcrumbRouter;
