import { Button, Popconfirm } from 'antd';
import { addHroSignAreaAndGetHroStampUrl } from '@/api/employeeApi';
import { fetchExitToBeSignedList, fetchExitTotal } from '@/store/hroInfo';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/models/store';
import { EXIT_PROCESS_TAB_STATUS } from '@/constants/status';

interface ButtonWithStampProps {
  employeeId: string;
  processId: string;
  text: string;
  hroSignUrl?: string;
  hidden: boolean;
  popupContainerId?: string;
  disabled?: boolean;
}

const InvoluntaryHroButtonWithStamp = (props: ButtonWithStampProps) => {
  const { employeeId, processId, hroSignUrl, hidden, popupContainerId, disabled, text } = props;
  const dispatch = useDispatch<AppDispatch>();

  const confirmStamp = () => {
    if (hroSignUrl == null) {
      addHroSignAreaAndGetHroStampUrl(employeeId, processId).then((response: string) => {
        window.open(response);
        dispatch(fetchExitToBeSignedList({ page: 0, size: 20 }));
        dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE }));
      });
    } else {
      window.open(hroSignUrl);
    }
  };

  return (
    <Popconfirm
      title="确认打开新页面登录e签宝进行落章"
      onConfirm={confirmStamp}
      cancelText={'取消'}
      okText={'确认'}
      disabled={disabled}
      placement="bottomRight"
      overlayStyle={{ width: 340 }}
      getPopupContainer={triggerNode => popupContainerId ? document.getElementById(popupContainerId) : triggerNode}>
      <Button
        size="small"
        className={`${hidden ? 'hidden' : ''}`}
        disabled={disabled}
      >
        {text}
      </Button>
    </Popconfirm>
  );
};

export default InvoluntaryHroButtonWithStamp;
