import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllOptionConfig,
  getCompanyIndustryOptions, getContractExtensionEmployeeList, getContractExtensionNotSignList,
  getEmployeeToBeAuditList,
  getMajorOptions,
  getNationOptions,
  getUniversityOptions
} from '@/api/employeeApi';
import {
  IContractExtensionEmployeeToBeAuditedResponse,
  IContractExtensionNotSignResponse,
  IEmployeeToBeAuditedResponse,
  IOption,
  Options
} from '@/models/employee';
import { get, isEmpty } from 'lodash-es';
import { StoreState } from '@/models/store';


const initialState = {
  employeeList: [],
  contractExtensionToBeAuditData: {
    content: [],
    pageable: {
      pageNumber: null,
      pageSize: null,
    },
    totalElements: null
  },
  contractExtensionNotSignData: {
    content: [],
    pageable: {
      pageNumber: null,
      pageSize: null,
    },
    totalElements: null
  },
  options: {},
  nationOptions: null,
  universityOptions: null,
  majorOptions: null,
  companyIndustryOptions: null,

};

export const fetchEmployeeToBeAuditList = createAsyncThunk<IEmployeeToBeAuditedResponse>(
  'employee/getEmployeeList',
  async () => {
    return await getEmployeeToBeAuditList();
  }
);

export const fetchContractExtensionToBeAuditList = createAsyncThunk<
IContractExtensionEmployeeToBeAuditedResponse, { page: number, size: number }>(
  'employee/getContractExtensionToBeAuditList',
  async arg => {
    const { page, size } = arg;
    return await getContractExtensionEmployeeList(page, size);
  }
);

export const fetchContractExtensionNotSignEmployeeList = createAsyncThunk<
    IContractExtensionNotSignResponse, { page: number, size: number }>(
      'employee/getContractExtensionNotSignList',
      async arg => {
        const { page, size } = arg;
        return await getContractExtensionNotSignList(page, size);
      }
    );

export const fetchOptions = createAsyncThunk<Options>(
  'employee/options',
  async (_, { getState }) => {
    const options = get(getState(), 'employee.options');
    if (isEmpty(options)) {
      return await getAllOptionConfig();
    }
    return options;
  }
);

export const fetchNationOptions = createAsyncThunk<IOption[]>(
  'employee/nationOptions',
  async (_, { getState }) => {
    const options = get(getState(), 'employee.nationOptions');
    if (isEmpty(options)) {
      return await getNationOptions();
    }
    return options;
  }
);

export const fetchUniversityOptions = createAsyncThunk<IOption[]>(
  'employee/universityOptions',
  async (_, { getState }) => {
    const options = get(getState(), 'employee.universityOptions');
    if (isEmpty(options)) {
      return await getUniversityOptions();
    }
    return options;
  }
);

export const fetchMajorOptions = createAsyncThunk<IOption[]>(
  'employee/majorOptions',
  async (_, { getState }) => {
    const options = get(getState(), 'employee.majorOptions');
    if (isEmpty(options)) {
      return await getMajorOptions();
    }
    return options;
  }
);

export const fetchCompanyIndustryOptions = createAsyncThunk<IOption[]>(
  'employee/companyIndustryOptions',
  async (_, { getState }) => {
    const options = get(getState(), 'employee.companyIndustryOptions');
    if (isEmpty(options)) {
      return await getCompanyIndustryOptions();
    }
    return options;
  }
);

const employeeReducer = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEmployeeToBeAuditList.fulfilled, (state, action: PayloadAction<IEmployeeToBeAuditedResponse>) => {
      state.employeeList = action.payload.data;
    }).addCase(fetchContractExtensionToBeAuditList.fulfilled,
      (state, action: PayloadAction<IContractExtensionEmployeeToBeAuditedResponse>) => {
        state.contractExtensionToBeAuditData = action.payload;
      }).addCase(fetchContractExtensionNotSignEmployeeList.fulfilled, (state, action: PayloadAction<IContractExtensionNotSignResponse>) => {
      state.contractExtensionNotSignData = action.payload;
    }).addCase(fetchOptions.fulfilled, (state, action: PayloadAction<Options>) => {
      state.options = action.payload;
    }).addCase(fetchNationOptions.fulfilled, (state, action: PayloadAction<IOption[]>) => {
      state.nationOptions = action.payload;
    }).addCase(fetchUniversityOptions.fulfilled, (state, action: PayloadAction<IOption[]>) => {
      state.universityOptions = action.payload;
    }).addCase(fetchMajorOptions.fulfilled, (state, action: PayloadAction<IOption[]>) => {
      state.majorOptions = action.payload;
    }).addCase(fetchCompanyIndustryOptions.fulfilled, (state, action: PayloadAction<IOption[]>) => {
      state.companyIndustryOptions = action.payload;
    });
  }
});

export const selectEmployeeList = (state: StoreState) => state.employee.employeeList;

export const selectContractExtensionEmployeeList = (state: StoreState) => state.employee.contractExtensionToBeAuditData;

export const selectContractExtensionNotSignData = (state: StoreState) => state.employee.contractExtensionNotSignData;

export const selectOptions = (state: StoreState) => state.employee.options;

export const selectNationOptions = (state: StoreState) => state.employee.nationOptions;

export const selectUniversityOptions = (state: StoreState) => state.employee.universityOptions;

export const selectMajorOptions = (state: StoreState) => state.employee.majorOptions;

export const selectCompanyIndustryOptions = (state: StoreState) => state.employee.companyIndustryOptions;

export const { reducer } = employeeReducer;


