import { createSlice } from '@reduxjs/toolkit';
import { ROLE_ADMIN, ROLE_USER } from '@/constants/role';
import { getAdminUserMenuConfig, getEmployeeUserMenuConfig } from '@/utils/authUtils';
import { StoreState } from '@/models/store';
import { intersection, size } from 'lodash-es';

const initialState = {
  userInfo: null,
  currentUserRole: null,
  menuList: null,
  eSingProcess: null,
  contractSignUrl: null,
  employeeList: null
};

const userReducer = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserProcess: (state, action) => {
      const EMPLOYEE_TODO_DISPLAY_ORDER = [ 'information', 'materials', 'sign' ];
      state.eSingProcess = action.payload?.data
        ? EMPLOYEE_TODO_DISPLAY_ORDER
          .flatMap(key => {
            const lowerCaseKey = key.toLowerCase();
            return action.payload.data.filter(item => item.key.toLowerCase().includes(lowerCaseKey));
          }) : [];
      state.contractSignUrl = action.payload?.contractSignUrl || null;
    },
    setCurrentUserRole: (state, action) => {
      state.currentUserRole = action.payload;
    }
  }
});

export const selectUserInfo = (state: StoreState) => state.user.userInfo;

export const selectUserPermissions = (state: StoreState) => state.user.userInfo.permissions;

export const isUserHasPermissions = (state, permissions: string[]) => {
  return state.user.userInfo?.permissions && size(intersection(state.user.userInfo.permissions, permissions)) > 0;
};

export const selectUserESignProcess = (state: StoreState) => state.user.eSingProcess;

export const selectContractSignUrl = (state: StoreState) => state.user.contractSignUrl;

export const isUserHasAdminRole = (state: StoreState) => state.user.userInfo?.permissions?.length > 0;

export const selectCurrentUserRole = (state: StoreState) => state.user.currentUserRole;

export const selectCurrentUserMenu = (state: StoreState) => {
  switch (state.user.currentUserRole){
  case ROLE_USER:
    return getEmployeeUserMenuConfig();
  case ROLE_ADMIN:
    return getAdminUserMenuConfig(state.user?.userInfo);
  default:
    return [];
  }
};

export const { reducer } = userReducer;

export const { setUserInfo, setCurrentUserRole, setUserProcess } = userReducer.actions;
