// eslint-disable-next-line no-use-before-define
import React, { useCallback } from 'react';
import { Tabs, TabsProps } from 'antd';
import './CustomTabs.scss';
import { useSearchParams } from 'react-router-dom';

interface TabProps {
  defaultActiveKey: string;
  items: TabsProps['items'];
}

const CustomTabs = (props: TabProps) => {
  const { defaultActiveKey, items } = props;
  const [ searchParams, setSearchParams ] = useSearchParams({});

  const clickTab = useCallback(activeKey => {
    setSearchParams({ tab: activeKey });
  }, []);

  const getActiveItems = useCallback(() => {
    return items.filter(item => !item.disabled);
  }, [ items ]);

  return (
    <div className="main-page">
      <Tabs
        defaultActiveKey={searchParams.get('tab') || defaultActiveKey}
        items={getActiveItems()}
        onTabClick={clickTab}
        destroyInactiveTabPane={true}
      />
    </div>
  );
};
export default CustomTabs;
