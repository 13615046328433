import { Options } from '@/models/employee';
import { Radio } from 'antd';
import { get, map } from 'lodash-es';
import {
  HireFieldConfig
} from '@/constants/config/employee-form/entry-contract-process-to-be-audited-table/FieldValidatorConfig';

const getOptions = (allOptions: Options, config:HireFieldConfig) => {
  const key = get(config, 'componentProps.optionKey');
  return get(allOptions, key, []);
};

const CustomRadioGroup = (config: HireFieldConfig, allOptions: Options) => {
  const options = getOptions(allOptions, config);
  return (
    <Radio.Group>
      {
        map(options, option => (
          <Radio key={option.value} value={option.value}>{option.text}</Radio>
        ))
      }
    </Radio.Group>
  );
};
export default CustomRadioGroup;