import { Button, Upload, UploadFile, UploadProps } from 'antd';
import Icon from '@ant-design/icons';
import UploadSvg from '@/assets/icons/upload.svg';
import { RcFile } from 'antd/lib/upload';
import { commonUploadConfig, FileSizeLimit10M, UploadAccept } from '@/utils/fileUpload';
// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { IExitEmployeeResponse } from '@/models/employee';
import './ShanghaiLayoffSlipUpload.scss';

interface IShanghaiLayoffSlipConfig {
  key: string;
  label: string;
  name: string;
  required?: boolean;
  initialValue?: UploadFile[];
  // englishLabel: string;
  desc?: string;
  uploadProps: UploadProps;
  validator?: (file: RcFile, previousUploadCount: number, uploadLimit: number) => boolean | string;
}

interface ShanghaiLayoffSlipProps {
  employeeInfo: IExitEmployeeResponse;
  fileList: UploadFile[];
  setShanghaiLayoffFileList: (newFileList: UploadFile[]) => void;
}

export const ShanghaiLayoffSlipUpload = ({ employeeInfo, fileList, setShanghaiLayoffFileList }: ShanghaiLayoffSlipProps) => {
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ showError, setShowError ] = useState(false);

  const pdfValidator = (file: RcFile, previousUploadCount: number, uploadLimit: number) => {
    const isPdf = file.type === 'application/pdf';
    const isLessThanSizeLimit = file.size < FileSizeLimit10M;
    if (!isPdf || !isLessThanSizeLimit) {
      setErrorMsg('请上传不超过10MB的PDF文件');
      setShowError(true);
    }
    const isLessThanUploadLimit = previousUploadCount < uploadLimit;
    if (!isLessThanUploadLimit) {
      setErrorMsg('只能上传1份文件');
      setShowError(true);
    }
    return (isPdf && isLessThanSizeLimit && isLessThanUploadLimit) || Upload.LIST_IGNORE;
  };

  const shanghaiLayoffSlipConfig: IShanghaiLayoffSlipConfig = {
    key: 'shanghaiLayoffSlip',
    name: '上海市单位退工证明',
    label: '上海退工单',
    desc: '上海地区人员须上传上海退工单',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  };

  const handleChange: UploadProps['onChange'] = info => {
    let newFileList = [ ...info.fileList ];
    newFileList = newFileList.slice(-1);
    setShowError(false);
    setShanghaiLayoffFileList(newFileList);
  };

  const uploadProps = {
    ...commonUploadConfig,
    accept: UploadAccept.PDF,
    beforeUpload: file => {
      // const previousUploadCount = form.getFieldValue(config.key)?.length;
      const previousUploadCount = fileList.length;
      const validateResult = shanghaiLayoffSlipConfig.validator(file, previousUploadCount, shanghaiLayoffSlipConfig.uploadProps.maxCount);
      if (validateResult !== true) {
        return validateResult;
      }
      const generatedFileName = `${(employeeInfo.employeeId)}-${(employeeInfo.legalFullName)}-${shanghaiLayoffSlipConfig.name}.pdf`;
      return new File([ file ], generatedFileName);
    },
    customRequest: args => {
      const customArgs = {
        ...args,
        data: { ...shanghaiLayoffSlipConfig, processId: employeeInfo.exitContractSigningProcessId, employeeId: employeeInfo.employeeId },
        action: '/api/user/shanghai-layoff-slip'
      };
      commonUploadConfig.customRequest(customArgs);
    },
    onChange: handleChange,
  };


  return (
    <Upload {...uploadProps} fileList={fileList}>
      <Button size="small" icon={<Icon className="upload-icon" component={UploadSvg}/>}>上传上海市退工单</Button>
      <span className="file-size-limit" onClick={e => e.stopPropagation()}>
        文件数限制 (Max files): {shanghaiLayoffSlipConfig.uploadProps.maxCount}
      </span>
      {(fileList.length < 1 && !showError) &&
        <div className="file-list-info" onClick={event => event.stopPropagation()}>请上传不超过10MB的PDF文件</div>}
      {showError && <div className="file-list-error" onClick={event => event.stopPropagation()}>{errorMsg}</div>}
    </Upload>
  );
};
