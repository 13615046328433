import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import InfoCircleSvg from '@/assets/icons/info-circle.svg';
// eslint-disable-next-line no-use-before-define
import React, { ReactNode } from 'react';
import './EmployeeFormLabel.scss';

interface IEmployeeFormLabelProps {
  label: string;
  bracketSubLabel?: string;
  subLabel?: string;
  needBr?: boolean;
  tip?: ReactNode;
  placement?: string;
}

const tipLocation = {
  ['top']: 'top',
  ['left']: 'left',
  ['right']: 'right',
  ['bottom']: 'bottom',
  ['topLeft']: 'topLeft',
  ['topRight']: 'topRight',
  ['bottomLeft']: 'bottomLeft',
  ['bottomRight']: 'bottomRight',
  ['leftTop']: 'leftTop',
  ['leftBottom']: 'leftBottom',
  ['rightTop']: 'rightTop',
  ['rightBottom']: 'rightBottom',
};
const EmployeeFormLabel = ({ label, bracketSubLabel, subLabel, needBr, tip, placement }: IEmployeeFormLabelProps) => {
  const labelsSplit = label.split('\\n');
  const subLabelsSplit = subLabel?.split('\\n');
  return (
    <span className="form-label small-text">
      {bracketSubLabel &&
        <>
          {label}{needBr && <br/>}<span className="sub-form-label small-text">({bracketSubLabel})</span>
        </>
      }
      {subLabel &&
        <>
          {labelsSplit.map((line, index) => (
            <span key={index}>
              {<span className="main-form-label"><span dangerouslySetInnerHTML={{ __html: line }}></span></span>}
              {labelsSplit.length > 1 && index !== labelsSplit.length - 1 && <br />}
            </span>
          ))}
          {needBr && <br/>}
          {
            subLabelsSplit.map((line, index) => (
              <span key={index}>
                {<span className="sub-form-label small-text"><span dangerouslySetInnerHTML={{ __html: line }}></span></span>}
                {subLabelsSplit.length > 1 && index !== subLabelsSplit.length - 1 && <br />}
              </span>
            ))
          }
        </>
      }
      {tip && (
        <Tooltip
          overlayClassName="label-tip"
          placement={placement ? tipLocation[placement] : 'bottomLeft'}
          title={tip}
          getPopupContainer={triggerNode => triggerNode}
          arrowPointAtCenter
          autoAdjustOverflow={false}>
          <Icon className="info-icon" component={InfoCircleSvg}/>
        </Tooltip>
      )}
    </span>
  );
};
export default EmployeeFormLabel;
