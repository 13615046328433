import { ColumnsType, ColumnType } from 'antd/es/table';
import { IIssueCertificateInfo, IIssueCertificateResponse } from '@/models/employee';
import { Badge, Button, Empty, message, Modal, Table, Tooltip } from 'antd';
import { CertificateType } from '@/constants/employee';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useMemo, useState } from 'react';
import { downloadFile } from '@/api/employeeApi';
import { CERTIFICATE_TYPE_OPTIONS } from '@/constants/employeeOptions';
import Icon from '@ant-design/icons';
import DownloadSvg from '@/assets/icons/download.svg';
import MessageSvg from '@/assets/icons/message.svg';
import * as FileSaver from 'file-saver';
import { debounce } from 'lodash-es';
import PreviewIcon from '@/assets/icons/preview.svg';
import './StampedDoneTableInfo.scss';
import CertificateReview from '@/pages/hro/issue-certificate/certificate-review/CertificateReview';
import CustomToolTipTitle from '@/components/custom-tooltip-title/CustomToolTipTitle';
import { CERTIFICATE_PROCESS_STATUS } from '@/constants/status';
import { getIssueCertificateInfo } from '@/api/certificateApi';

interface TableInfoProps {
  totalCount: number,
}

const downloadCertificate = debounce(
  async ({ fileUrl, employeeId, certificateType }: IIssueCertificateInfo) => {

    const hideMessage = message.loading('下载中，请稍等', 0);
    const response = await downloadFile(fileUrl);
    try {
      FileSaver.saveAs(response, `${employeeId}-${CertificateType[certificateType]}.pdf`);
      message.success('下载已完成');
    } catch (e) {
      message.error('下载失败，请重试');
    }
    hideMessage();
  }, 1000, { leading: true, trailing: false });


const statusComponent = (record: IIssueCertificateInfo) => {
  if (record.certificateProcessStatus === CERTIFICATE_PROCESS_STATUS.REJECTED) {
    return <Badge color="#F2617A" className="rejected-badge" text="已驳回"/>;
  }
  return <Badge color="#6B9E78" className="finished-badge" text="已盖章"/>;
};

const toolTipComponent = (record: IIssueCertificateInfo) => {
  if (record.certificateProcessStatus === CERTIFICATE_PROCESS_STATUS.REJECTED) {
    return <Tooltip
      className="rejected-tooltip"
      overlayClassName="label-tip"
      placement="bottomLeft"
      align={{ offset: [ -24, 0 ] }}
      title={CustomToolTipTitle(record.rejectReason)}
      getPopupContainer={triggerNode => triggerNode}
      arrowPointAtCenter>
      <Icon className="message-icon" component={MessageSvg}/>
    </Tooltip>;
  }
};


const columns: ColumnsType<IIssueCertificateInfo> = [
  {
    title: '办公地点',
    dataIndex: 'homeOfficeChinese',
    width: 110,
    className: 'align-center',
  },
  {
    title: '工号',
    dataIndex: 'employeeId',
    width: 110,
    className: 'align-center',
  },
  {
    title: '姓名',
    dataIndex: 'chineseName',
    className: 'show-ellipsis align-center',
  },
  {
    title: '员工类型',
    dataIndex: 'employmentType',
    className: 'align-center',
  },
  {
    title: '证明类型',
    dataIndex: 'certificateType',
    className: 'align-center',
  },
  {
    title: '盖章时间',
    dataIndex: 'stampedTime',
    className: 'align-center',
    width: 200,
  },
  {
    title: '证明管理',
    dataIndex: 'certificateProcessStatus',
    className: 'last-td align-center',
    width: 326,
  }
];


const StampedDoneTableInfo = ({ totalCount }: TableInfoProps) => {
  const [ isCertificateReviewModalOpen, setIsCertificateReviewModalOpen ] = useState(false);
  const [ currentEmployeeId, setCurrentEmployeeId ] = useState(null);
  const [ currentFileUrl, setCurrentFileUrl ] = useState(null);
  const [ stampedDoneData, setStampedDoneData ] = useState<IIssueCertificateResponse>({} as IIssueCertificateResponse);
  useEffect(() => {
    getIssueCertificateInfo(CERTIFICATE_PROCESS_STATUS.CONFIRMED).then((res: IIssueCertificateResponse) => {
      setStampedDoneData(res);
    });
  }, []);

  const openCertificateReviewModal = (employeeId: string, processId: string, fileUrl: string) => {
    setCurrentEmployeeId(employeeId);
    setCurrentFileUrl(fileUrl);
    setIsCertificateReviewModalOpen(true);
  };

  const tableColumns = useMemo(() => {
    const certificateTypeCol = columns.find((col: ColumnType<IIssueCertificateInfo>) => col.dataIndex === 'certificateType');
    certificateTypeCol.render = (_, record) => {
      return <div>
        {CERTIFICATE_TYPE_OPTIONS.find(item => item.value === (record.certificateType))?.label}
      </div>;
    };
    const certificateProcessStatusCol = columns.find((col: ColumnType<IIssueCertificateInfo>) =>
      col.dataIndex === 'certificateProcessStatus');
    certificateProcessStatusCol.render = (_, record) => {
      return <div className="certificate-management">
        <div className="status">
          {statusComponent(record)}
          {toolTipComponent(record)}
        </div>
        <Button
          size="small"
          type="text"
          icon={<Icon component={PreviewIcon}/>}
          onClick={() => openCertificateReviewModal(record.employeeId, record.processId, record.fileUrl)}
          disabled={!record.fileUrl}
        >
          预览
        </Button>
        <Button
          size="small"
          type="text"
          icon={<Icon className="download-icon" component={DownloadSvg}/>}
          onClick={() => downloadCertificate(record)}
          disabled={!record.fileUrl}
        >
          下载
        </Button>
      </div>;
    };
    return columns;
  }, []);

  return (
    <>
      {
        isCertificateReviewModalOpen &&
        <Modal
          open={isCertificateReviewModalOpen}
          wrapClassName="certificate-check-modal"
          title="员工证明"
          width={1000}
          style={{ top: 20 }}
          destroyOnClose={true}
          onCancel={() => {
            setIsCertificateReviewModalOpen(false);
          }}
          footer={null}
        >
          <CertificateReview employeeId={currentEmployeeId} certificateFileUrl={currentFileUrl}/>
        </Modal>
      }
      <Table
        columns={tableColumns}
        dataSource={stampedDoneData.issueCertificateListedInfoItem}
        rowKey="processId"
        pagination={{ defaultCurrent: 1, defaultPageSize: 20, total: totalCount, showSizeChanger: false }}
        scroll={{ y: 'calc(100vh - 470px)' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
    </>
  );

};

export default StampedDoneTableInfo;
