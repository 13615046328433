import { ColumnsType } from 'antd/es/table';
import { IEmployeeSigningDetail } from '@/models/employee';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import ButtonWithSign from '@/components/button-with-sign/ButtonWithSign';
import { EMPLOYEE_CONTRACT_SIGN_STATUS, EMPLOYEE_TAB_STATUS } from '@/constants/status';

export const getEmployeeSigningDetailColumns = update => {
  const columns: ColumnsType<IEmployeeSigningDetail> = [
    {
      title: '办公地点',
      dataIndex: 'homeOfficeChinese',
      className: 'align-center',
    },
    {
      title: '工号',
      dataIndex: 'employeeId',
      className: 'align-center',
    },
    {
      title: '姓名',
      dataIndex: 'chineseName',
      className: 'show-ellipsis align-center',
    },
    {
      title: '入职日期',
      dataIndex: 'hireDate',
      className: 'align-center',
    },
    {
      title: '员工类型',
      dataIndex: 'employmentType',
      className: 'align-center',
    },
    {
      title: '合同审核',
      dataIndex: 'employeeContractSigningStatus',
      width: 142,
      className: 'align-center',
      render: (_, record) => (
        <Button
          className="preview-materials"
          size="small"
          disabled={ record.employeeContractSigningStatus !== EMPLOYEE_CONTRACT_SIGN_STATUS.TO_BE_REVIEWED}
        >
          {/* eslint-disable-next-line max-len */}
          <Link to={`/hro-home/entry-contract-review/${record.employeeId}/${record.employeeContractSigningProcessId}?tab=${EMPLOYEE_TAB_STATUS.SIGNATURE}`}>
            { record.employeeContractSigningStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_INITIATE ? '审核通过' : '预览审核' }
          </Link>
        </Button>)
    },
    {
      title: '合同签署',
      dataIndex: 'employeeContractSigningStatus',
      width: 142,
      className: 'last-td align-center',
      render: ( _, record) => {
        return <ButtonWithSign
          hasPermission={true}
          update={update}
          employeeId={record.employeeId}
          employeeSigningStatus={record.employeeContractSigningStatus}
          employeeSigningProcessId={record.employeeContractSigningProcessId}
          popupContainerId={null}
        />;
      }
    },
  ];
  return columns;
};
