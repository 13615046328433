import {
  downloadFile,
  getAllEmployeeMaterials,
  getEmployeeDiscloseInvention,
  getEmployeeHireInfo,
  getUserContractMaterials
} from '@/api/employeeApi';
import noDownloadContract from '@/assets/no-download-contract.svg';
import { selectUserInfo } from '@/store/userInfo';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './MyContract.scss';
import { Button, Tabs } from 'antd';
import { saveFilesAsZip } from '@/utils/fileUtil';
import moment from 'moment';
import { IEmployeeContractMaterials, IHireInfo } from '@/models/employee';
import { debounce, filter, includes, isEmpty } from 'lodash-es';
import FilePreviewer from '@/components/file-previewer/FilePreviewer';
import { CONTRACT_SIGN_TYPE, employeeContractInfo } from '@/constants/employee';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import ImageDescription from '@/components/image-description/ImageDescription';
import EmployeeInfoField from '@/components/employee-info-field/EmployeeInfoField';


export const matomoName = [
  {
    label: '劳动合同',
    matomoName: 'Employment contract'
  },
  {
    label: '保密协议',
    matomoName: 'Confidentiality agreement'
  },
  {
    label: '行为准则',
    matomoName: 'Code of conduct'
  },
  {
    label: '社保确认书',
    matomoName: 'Social security confirmation'
  },
  {
    label: '培训协议',
    matomoName: 'Training agreement'
  },
  {
    label: '续签通知书',
    matomoName: 'Extension Notification Type'
  }
];
const MyContract = () => {
  const userInfo = useSelector(selectUserInfo);
  const [ hasContract, setHasContract ] = useState(false);
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>(null);
  const [ contracts, setContracts ] = useState([]);
  const [ contractMaterials, setContractMaterials ] = useState<IEmployeeContractMaterials>({} as IEmployeeContractMaterials);
  const { trackEvent } = useCustomMatomo();

  useEffect(() => {
    if (!userInfo?.user.employeeId) {
      return;
    }
    const employeeId = userInfo?.user.employeeId;
    Promise.all([
      getEmployeeHireInfo(employeeId),
      getUserContractMaterials(),
      getEmployeeDiscloseInvention(employeeId),
    ]).then(
      ([ employeeInfo, contractMaterials, discloseInventions ]) => {
        setEmployeeInfo(employeeInfo);
        setHasContract(!isEmpty(contractMaterials));
        setContractMaterials(contractMaterials);
        const materialKeys = Object.keys(contractMaterials || {});
        const tabItems = filter(employeeContractInfo, config => includes(materialKeys, config.key)).map((config, index) => ({
          ...config,
          children: (
            <div className="materials" key={index}>
              {contractMaterials[config.key].map(fileInfo => (
                <div key={fileInfo.contractFileName}>
                  <FilePreviewer
                    fileName={fileInfo.contractFileName}
                    storageUrl={fileInfo.fileUrl}/>
                </div>
              ))}
            </div>
          )
        }));
        if (discloseInventions?.length > 0) {
          tabItems.push({
            label: '发明或改进补充说明',
            key: 'additionalAttachment',
            children: (
              <div className="materials">
                {discloseInventions.map(fileInfo => (
                  <div key={fileInfo.fileId}>
                    <FilePreviewer
                      fileName={fileInfo.fileName}
                      storageUrl={fileInfo.fileUrl}/>
                  </div>
                ))}
              </div>
            )
          });
        }
        setContracts(tabItems);
      });
  }
  , [ userInfo ]);

  const handleTabChange = useCallback((activeKey: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeContract,
      action: `${MATOMO_ACTION.SwitchTab} to preview`,
      name: matomoName.find(name => name.label === employeeContractInfo.find(item => item.key === activeKey)?.label) != null
        ? matomoName.find(name => name.label === employeeContractInfo.find(item => item.key === activeKey)?.label).matomoName
        : 'Additional attachment',
    });
  }, []);

  const downloadContract = useCallback(debounce(() => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeContract,
      action: MATOMO_ACTION.Download,
      name: MATOMO_NAME.Contract
    });
    let allContractFiles = [];
    getAllEmployeeMaterials().then(({ materialsData: employeeMaterials }) => {
      allContractFiles = [];
      Object.values({ ...contractMaterials, ...employeeMaterials })
        .filter(item => !!item)
        .forEach(files => {
          allContractFiles.push(...files);
        });
      return Promise.all(allContractFiles.map(fileInfo => downloadFile(fileInfo.fileUrl)));
    }).then(files => {
      return saveFilesAsZip(
        files.map((file, index) => ({ blob: file, name: allContractFiles[index].contractFileName || allContractFiles[index].fileName })),
        `${userInfo?.user?.employeeId}-${employeeInfo.legalFullName}-合同及材料-${moment().format('YYYY-MM-DD-HH:mm:ss')}`
      );
    });
  }, 1000, { leading: true, trailing: false }), [ userInfo, contractMaterials, employeeInfo ]);

  if (isEmpty(employeeInfo)) {
    return;
  }

  const isShowContractExtensionDate = () => {
    return employeeInfo && employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE && !isEmpty(contractMaterials);
  };

  return (
    <div className="my-contract-container">
      <div className="preview-check-container">
        <div className="preview-check-content">
          <div className="employee-basic-info-container">
            <div className="employee-basic-info">
              <div className="employee-info">
                <span className="employee-name">{employeeInfo.legalFullName}</span><i className="split-line"></i>
                {EmployeeInfoField('工号', 'Employee ID', employeeInfo.employeeId)}<i className="split-line"></i>
                {EmployeeInfoField('入职时间', 'Hire Date', employeeInfo.hireDate)}
                {isShowContractExtensionDate() && <i className="split-line"></i>}
                {isShowContractExtensionDate() && EmployeeInfoField('续签时间', 'Contract Extension Date', employeeInfo.extensionDate)}
              </div>
            </div>
            {hasContract && <div><Button type="primary" onClick={downloadContract}>
              {employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE ? '下载通知书' : '下载合同'}
            </Button></div>}
          </div>
          {hasContract ? <Tabs items={contracts} onChange={handleTabChange}/>
            : <ImageDescription imgSrc={noDownloadContract}
              alt="There is currently no downloadable electronic contract" desc="暂无可下载电子合同"/>
          }
        </div>
      </div>
    </div>
  );
};
export default MyContract;
