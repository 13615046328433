import { message, UploadFile, UploadProps, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Icon from '@ant-design/icons';
import RemoveSvg from '@/assets/icons/remove.svg';
import { uploadFile } from '@/api/employeeApi';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';
import { ERROR_CODE, FILE_ERROR } from '@/constants/error';

export const normFile = (e: UploadFile[] | { fileList: UploadFile[] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

export const formatDate = (date: Date) => [
  date.getFullYear(),
  padTo2Digits(date.getMonth() + 1),
  padTo2Digits(date.getDate()),
  padTo2Digits(date.getHours()),
  padTo2Digits(date.getMinutes()),
  padTo2Digits(date.getSeconds()),
].join('');

export const generateFileName = (file: RcFile, materialName: string, { employeeId, legalFullName }) => {
  const fileNameParts = file.name.split('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1];
  return `${employeeId}-${legalFullName}-${materialName}.${fileExtension}`;
};

export const alertErrorMessage = (ret: AxiosError<IErrorResponse>) => {
  if (ret?.response?.data.code === ERROR_CODE.userOperationIllegal) {
    message.error('上传失败：入职材料已审核通过，需更新请联系People Support Team');
  }
};

export const FileSizeLimit10M = 1024 * 1024 * 10;
export enum FileType {
    PdfOrImage = 'PdfOrImage',
    Pdf = 'Pdf',
    Image = 'Image'
}
const AcceptFileTypes = {
  [FileType.PdfOrImage]: [ 'application/pdf', 'image/jpeg', 'image/png' ],
  [FileType.Pdf]: [ 'application/pdf' ],
  [FileType.Image]: [ 'image/jpeg', 'image/png' ],
};

export const fileTypeValidator = (
  file: RcFile, fileType: FileType, previousUploadCount: number, fileSizeLimit: number, uploadLimit: number) => {
  const isFileTypeMatched = AcceptFileTypes[fileType].includes(file.type);
  if (!isFileTypeMatched) {
    message.error(FILE_ERROR.isPdfOrImage);
  }
  const isLessThanSizeLimit = file.size < fileSizeLimit;
  if (!isLessThanSizeLimit) {
    message.error(FILE_ERROR.notExceed10M);
  }
  const isLessThanUploadLimit = previousUploadCount < uploadLimit;
  if (!isLessThanUploadLimit) {
    message.error(FILE_ERROR.fileCountExceed);
  }
  return (isFileTypeMatched && isLessThanSizeLimit && isLessThanUploadLimit) || Upload.LIST_IGNORE;
};

export const commonUploadConfig: UploadProps = {
  showUploadList: {
    showRemoveIcon: true,
    removeIcon: <Icon className="remove-icon" component={RemoveSvg}/>,
  },
  customRequest: ({ file, data, onError, onProgress, onSuccess, action }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('processId', (data as Record<string, string>).processId);
    formData.append('fileType', (data as Record<string, string>).key);
    formData.append('employeeId', (data as Record<string, string>).employeeId);
    onProgress({ percent: 0 });
    uploadFile(formData, onProgress, action)
      .then(ret => {
        onProgress({ percent: 100 });
        onSuccess(ret);
      }, err => {
        err.message = '上传失败';
        onError(err);
        if (!action) {
          alertErrorMessage(err);
        }
      });
  },
  onRemove: () => {
    return true;
  }
};


export enum UploadAccept {
    IMAGE = 'image/png, image/jpeg',
    IMAGE_AND_PDF = 'image/png, image/jpeg, application/pdf',
    PDF = 'application/pdf',
}


interface UploadPropsOption {
    accept?: UploadAccept;
    maxCount?: number;
}

export const getUploadProps = (options: UploadPropsOption = {}) => ({
  ...commonUploadConfig,
  accept: options.accept || UploadAccept.IMAGE_AND_PDF,
  maxCount: options?.maxCount || 5
});
