import { Button, message, Modal, Steps } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import { bpfhConfirmReviewCheck, bpfhPreviewUrl, } from '@/api/employeeApi';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import '../contract-preview-modal/ContractPreviewModal.scss';
import { EXIT_SIGN_PROCESS_STATUS } from '@/constants/status';
import './InvoluntaryBpFhReviewModal.scss';
import { getInvoluntaryExitFlowUrl } from '@/api/exitApi';

interface InvoluntaryBpFhPreviewModalProps {
  processId: string,
  status: string,
  isModalOpen: boolean,
  closeModal: (needRefreshData: boolean) => void
}

const InvoluntaryBpFhReviewModal = ({ processId, status, isModalOpen, closeModal }: InvoluntaryBpFhPreviewModalProps) => {
  const [ flowUrl, setFlowUrl ] = useState<string>(null);
  const [ urlClicked, setUrlClicked ] = useState<boolean>(false);
  const [ current, setCurrent ] = useState<number>(0);

  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    if (!userInfo || !isModalOpen) {
      return;
    }
    getInvoluntaryExitFlowUrl(processId).then(({ url }) => {
      if (status === EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_REVIEW) {
        setUrlClicked(true);
        setCurrent(1);
      }
      setFlowUrl(url);
    }).catch(() => {
      message.error('获取文件链接失败');
      closeModal(true);
    });
  }, [ processId, status ]);

  const confirmSubmit = useCallback(() => {
    bpfhConfirmReviewCheck(processId).then(() => {
      message.success('签署文件已审核通过');
      closeModal(true);
    }).catch(() => message.error('通过审核失败'));
  }, [ processId, status ]);

  const handleCancel = async () => {
    closeModal(false);
  };

  const previewUrl = useCallback(() => {
    if (!urlClicked) {
      bpfhPreviewUrl(processId).then(() => {
        setUrlClicked(true);
        setCurrent(1);
      }).catch(() => message.error('更新预览状态失败'));
    }
  }, [ status, urlClicked ]);

  const stepOnChange = (value: number) => {
    setUrlClicked(true);
    setCurrent(value);
  };

  return (
    <Modal
      wrapClassName="hrbp-review-modal"
      width={560}
      open={isModalOpen}
      centered
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      title="请确认以下事项"
      okText="确认"
      onCancel={handleCancel}
      footer={null}>
      <div className="hrbp-review-checked">
        <Steps
          direction="vertical"
          current={current}
          onChange={stepOnChange}
          items={[
            {
              title: <div className="hrbp-preview">
                请跳转至e签宝预览员工（已签署）离职文件
                <div className="hrbp-preview-btn">
                  <Button className={`go-preview ${current === 1 ? 'previewed' : ''}`}
                    size='small' href={flowUrl} onClick={previewUrl} target='_blank'>
                    <i className="ri-external-link-line"></i>
                    链接
                  </Button>
                </div>
              </div>,
              status: current >= 0 ? 'process' : 'wait',
              disabled: true,
              className: `first-step ${current >= 0 ? 'process' : 'wait'}`,
            },
            {
              title: <div className="hrbp-review">
                请确认员工（已签署）离职文件审核是否通过
                <div className="hrbp-review-btn">
                  <Button
                    className="review-cancel"
                    size="small"
                    onClick={() => closeModal(false)}
                    disabled={!urlClicked}>
                  取消
                  </Button>
                  <Button
                    className="review-ok"
                    size="small"
                    onClick={() => confirmSubmit()}
                    disabled={!urlClicked}>
                  通过
                  </Button>
                </div>
              </div>,
              status: current >= 1 ? 'process' : 'wait',
              disabled: true,
              className: `${current >= 1 ? 'process' : 'wait'}`
            },
          ]}
        />
      </div>
    </Modal>
  );
};

export default InvoluntaryBpFhReviewModal;
