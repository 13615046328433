import { Checkbox, Col, Form, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '@ant-design/icons';
import InfoCircleSvg from '@/assets/icons/info-circle.svg';
import EmployeeFormLabel from '@/pages/employee/employee-info-edit/employee-form-label/EmployeeFormLabel';
import FormSubmitButton from '@/components/form-submit-button/FormSubmitButton';
// eslint-disable-next-line no-use-before-define
import React from 'react';

import NotificationModal from '@/components/modal/NotificationModal';
import './EmployeeExitApproval.scss';
import { exitEmployeeSelfApproval, getPersonForCommunicating } from '@/api/exitApi';
import { IExitEmployeePersonForCommunicatingResponse } from '@/models/employee';
import { getESignProcess } from '@/api/authApi';
import { selectUserInfo, setUserProcess } from '@/store/userInfo';
import { useDispatch, useSelector } from 'react-redux';
import { EMPLOYMENT_TYPE } from '@/constants/employee';

const WORK_HANDOVER_CHECKBOX_LENGTH = 4;
const REIMBURSEMENT_CHECKBOX_LENGTH = 1;
const RSU_CHECKBOX_LENGTH = 4;
const ASSETS_CHECKBOX_LENGTH = 1;
const TRAINING_CHECKBOX_LENGTH = 1;
const EmployeeExitApproval = () => {
  const navigate = useNavigate();
  const [ form ] = Form.useForm();
  const dispatch = useDispatch();
  const { processId } = useParams();
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [ popOpen, setPopOpen ] = useState<boolean>(false);
  const [ enableSubmitBtn, setEnableSubmitBtn ] = useState<boolean>(false);
  const userInfo = useSelector(selectUserInfo);

  const [ personForCommunicatingResponse, setPersonForCommunicatingResponse ] = useState<IExitEmployeePersonForCommunicatingResponse>({
    adminOffice: '',
    workspaceOffice: '',
    adminPocList: [ '' ],
    workspacePocList: [ '' ]
  });

  const onSubmit = async () => {
    form.validateFields().then(() => exitEmployeeSelfApproval(processId))
      .then(() => {
        setPopOpen(false);
        setIsModalOpen(true);
      }, (err: AxiosError<IErrorResponse>) => {
        if ((err as AxiosError<IErrorResponse>).response?.data.code === ERROR_CODE.userOperationIllegal) {
          message.error('更新失败，已发起四方流转，需更新请联系People Support Team');
        }
      });
  };

  const checkBoxGroupCheckedValidator = (rules, value, checkBoxLength) => {
    if (value && value.length === checkBoxLength) {
      return Promise.resolve();
    }
    return Promise.reject();
  };

  useEffect(() => {
    getPersonForCommunicating().then(response => setPersonForCommunicatingResponse(response));
  }, []);

  const formValues = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields().then(
      () => {
        setEnableSubmitBtn(true);
      },
      () => {
        setEnableSubmitBtn(false);
      },
    );
  }, [ formValues ]);

  return (<> {
    <Form
      className="exit-personal-approval-form"
      form={form}
      layout="vertical"
      validateTrigger={[ 'onChange' ]}
      initialValues={{}}
    >
      <div className="exit-personal-approval-edit">
        <div className="form-group-header">
          <div className="exit-personal-approval-header-title">
            <p>离职流转流程</p>
          </div>
          <div className="exit-personal-approval-header-tip">
            <Icon className="info-icon" component={InfoCircleSvg} aria-label="Info-icon"/>
            <p>亲爱的伙伴，感谢与您在Thoughtworks的这段同行，珍重再见！请确认完成以下事项<br/>
              Dear partner, thank you for your accompaniment in thoughtworks, goodbye! Please confirm to complete the following</p>
          </div>
        </div>

        <div className="form-group">
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                className="exit-personal-approval-first-form-item"
                name="fieldOfWorkHandOver"
                label={<label className="exit-personal-approval-form-item-label">完成工作交接（Work Handover）</label>}
                rules={[ {
                  required: true,
                  validator: (rules, value) => checkBoxGroupCheckedValidator(rules, value, WORK_HANDOVER_CHECKBOX_LENGTH)
                } ]}>
                <Checkbox.Group>
                  <Checkbox value="workHandover"><EmployeeFormLabel label="工作交接已完毕"
                    subLabel="The work handover has been completed"/></Checkbox> <br/>
                  <Checkbox value="pre-salesConsultant"><EmployeeFormLabel label="归还所属项目的 Ukey 和相关软件等"
                    subLabel="Return Ukey and the relevant software of the affiliated items"/></Checkbox>
                  <br/>
                  <Checkbox value="timecard"><EmployeeFormLabel label="Timecard 已填写到最后工作日"
                    subLabel="Timecard has been filled until the last workday"/></Checkbox>
                  <br/>
                  <Checkbox value="wechat"><EmployeeFormLabel label="退出各种微信工作群，并从企业微信通讯录外部联系人中删除所有外部联系人"
                    subLabel="Quit all Wechat work groups and delete all external contacts"/></Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                className="exit-personal-approval-normal-form-item"
                name="fieldOfCompleteReimbursement"
                label={<label className="exit-personal-approval-form-item-label">完成报销（Complete reimbursement）</label>}
                rules={[ {
                  required: true,
                  validator: (rules, value) => checkBoxGroupCheckedValidator(rules, value, REIMBURSEMENT_CHECKBOX_LENGTH)
                } ]}>
                <Checkbox.Group>
                  <Checkbox value="expense">
                    <EmployeeFormLabel
                      label="本人确认所有的Expense 和 Per Diem 均已正常提交，确保提交报销款项明细均符合公司相关政策制度。"
                      subLabel="I confirm that all Expenses and Per Diem have been normally submitted, and ensure that
                      all the reimbursement details are in compliance with the Thoughtworks's relevant policies." />
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          {userInfo.user.employmentType === EMPLOYMENT_TYPE.EMPLOYEE && <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                className="exit-personal-approval-normal-form-item"
                name="fieldOfUnderstandTheRelevantOperationsOfRSU"
                label={<label className="exit-personal-approval-form-item-label">
                  知晓RSU相关的操作流程（Understand the relevant operations of RSU）
                </label>}
                rules={[ {
                  required: true,
                  validator: (rules, value) => checkBoxGroupCheckedValidator(rules, value, RSU_CHECKBOX_LENGTH)
                } ]}>
                <Checkbox.Group>
                  <Checkbox value="unvestedRsu"><EmployeeFormLabel label="本人确认未Vest的RSU将会取消"
                    subLabel="On your exit date, your unvested awards would be canceled and those awards are forfeited"/></Checkbox><br/>
                  <Checkbox value="vestEMail"><EmployeeFormLabel
                    label="已Vest的RSU可通过个人邮箱登录UBS账户查看（建议在离职前进入UBS账户添加个人邮箱并尝试登录）"
                    subLabel="If you have shares in your account from a previous vest event, you will still be able to access your
                    UBS account after you leave through your personal email. You can add that information directly on your UBS One
                    Source Portal. We suggest setting up the log in with your non Thoughtworks email before your exit date"
                    needBr={true}/></Checkbox><br/>

                  <Checkbox value="vestExitDate"><EmployeeFormLabel
                    label="已Vest的股票从员工离职之日起60天内必须卖掉股票，如果员工没有按时售出，公司会强制售出"
                    subLabel="RSUs that have vested must be sold within 60 days of the employee's exit date, and if the employee does not
                  sell on time, the company will force the sale" needBr={true}/></Checkbox><br/>

                  <Checkbox value="vestAfterLeave"><EmployeeFormLabel label="离职后如有售卖，公司会协助结汇并将款项打款给员工工资账户，请注意在汇算清缴时申报个税\n
                Note：如果PT（Permanent Transfer）到其他Region，RSU不会取消，后续Vest、售卖等相关操作以新Region的规定为准"
                  subLabel="If you sell your shares after you leave Thoughtworks, Thoughtworks will assist you in foreign exchange
                  settlement and the money distribution to your bank account receiving salaries. Please note that you are obliged to declare
                   this fact at the time of annual tax settlement and payment. \nNote: If you permanently transfer to another region, RSU
                   shares will not be canceled, all operations will follow the new region"
                  needBr={true}/>
                  </Checkbox><br/>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>}

          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                className="exit-personal-approval-normal-form-item"
                name="fieldOfReturnTheCompanyAssets"
                label={<label className="exit-personal-approval-form-item-label">退还公司资产（Return the company assets）</label>}
                rules={[ {
                  required: true,
                  validator: (rules, value) => checkBoxGroupCheckedValidator(rules, value, ASSETS_CHECKBOX_LENGTH)
                } ]}>
                <Checkbox.Group>
                  <Checkbox value="hardwareAssets"><EmployeeFormLabel
                    label="本人已从 <a href='https://my.thoughtworks.net/software'>myTW</a> 提交公司提供的付费软件退还，且已将绑定到公司邮箱/信息的个人付费的服务或订阅解绑。
                    本人将按
                    <a href='https://docs.google.com/document/d/1xqLf37-awIODy704SesFnPe1PbGbsmQYcDgBWJg2Kz8/edit#heading=h.ai2adyevdy2f'>
                    离职设备归还检查事项</a> 检查并归还电脑及其他IT资产，同时将按要求退还门卡、锁柜钥匙、书籍等其他公司资产"
                    subLabel="I have submitted the refund of paid software provided by the
                    company from <a href='https://my.thoughtworks.net/software'>myTW</a> and have canceled
                    personally paid services or subscriptions tied to company email/information.
                     I will inspect and return computers and other IT assets in accordance
                     with
                     <a href='https://docs.google.com/document/d/1xqLf37-awIODy704SesFnPe1PbGbsmQYcDgBWJg2Kz8/edit#heading=h.ai2adyevdy2f'>
                     the equipment return inspection items</a>.
                      I will also return access cards, locker key, books and other company assets as required."
                    needBr={true}/></Checkbox><br/>
                  <div className="exit-personal-approval-contact-person-span">
                    对接人：<br/>
                    （{personForCommunicatingResponse.adminOffice}）Admin: {personForCommunicatingResponse.adminPocList.join('，')} <br/>
                    （{personForCommunicatingResponse.workspaceOffice}）Workspace: {personForCommunicatingResponse.workspacePocList.join('，')}
                  </div>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          {userInfo.user.employmentType === EMPLOYMENT_TYPE.EMPLOYEE && <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                className="exit-personal-approval-last-form-item"
                name="fieldOfTrainingRelatedAgreements"
                label={<label className="exit-personal-approval-form-item-label">确认培训相关约定（Training-related agreements）</label>}
                rules={[ {
                  required: true,
                  validator: (rules, value) => checkBoxGroupCheckedValidator(rules, value, TRAINING_CHECKBOX_LENGTH)
                } ]}>
                <Checkbox.Group>
                  <Checkbox value="trainingAgreement"><EmployeeFormLabel
                    label="本人确认如果签订了服务培训协议，没有按照要求履行协议约定内容，需要按照协议约定标准相应支付违约金，违约金金额由本人转账到公司账户"
                    subLabel="I confirm that if I sign the training agreement but fail to perform the contents agreed in the agreement as
                    required, I will pay liquidated damages according to the standards agreed in the agreement, and the amount will be paid
                    to the company's account by me"
                    needBr={true}/></Checkbox><br/>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>}

        </div>
      </div>
      <Form.Item>
        <FormSubmitButton
          form={form}
          onSubmit={onSubmit}
          setPopOpen={setPopOpen}
          popOpen={popOpen}
          title="确认所有的事项都已经完成？"
          buttonText="确认"
          width={294}
          disabled={!enableSubmitBtn}></FormSubmitButton>
      </Form.Item>
    </Form>
  }

  {
    isModalOpen &&
      <NotificationModal
        text={<EmployeeFormLabel label="信息已提交\n业务、Workspace、Admin会进行下一步守关。待所有离职流转完成后，People Support Team会发起离职材料签署，请知晓"
          subLabel="The information has been submitted and the business side/Workspace/Admin will proceed to the next step. After all
          resignation transfers are complete, People Support Team will initiate the resignation document signing procedure."
          needBr={true}/>}
        onCancel={() => {
          getESignProcess().then(data => dispatch(setUserProcess(data)));
          navigate('/employee-home');
        }}></NotificationModal>
  }

  </>);
};

export default EmployeeExitApproval;
