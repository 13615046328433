import Button from '@/components/button/Button';
import '@/pages/employee/employee-entry/employeeEntry.scss';
import { useCallback, useEffect, useState } from 'react';
import { map, size } from 'lodash-es';
import noOperationItemIcon from '@/assets/no-operation-item.svg';
import { Link } from 'react-router-dom';
import { EMPLOYEE_NAME_PATH_MAP } from '@/constants/employee';
import { Tooltip } from 'antd';
import { MATOMO_ACTION, MATOMO_CATEGORY } from '@/constants/matomo';
import { useCustomMatomo } from '@/matomo';
import ImageDescription from '@/components/image-description/ImageDescription';
import { useDispatch, useSelector } from 'react-redux';
import { selectContractSignUrl, selectUserESignProcess, selectUserInfo, setUserProcess } from '@/store/userInfo';
import { getESignProcess } from '@/api/authApi';
import { EMPLOYEE_CONTRACT_STATUS } from '@/constants/status';
import ExitApprovalProcessDrawer
  from '@/pages/hro/exit-process/exit-approval-table-info/exit-approval-process-drawer/ExitApprovalProcessDrawer';

const MATOMO_CATEGORY_MAP = {
  completeEmployeeInformation: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
  updateEmployeeInformation: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
  submitEmployeeEntryMaterials: MATOMO_CATEGORY.EmployeeOperateInductionMaterials,
  updateEmployeeEntryMaterials: MATOMO_CATEGORY.EmployeeOperateInductionMaterials,
  signEmployeeEntryContract: MATOMO_CATEGORY.EmployeeOperateSign,
  signEmployeeEntryContractFinished: MATOMO_CATEGORY.EmployeeOperateSign,
};

const MATOMO_NAME_MAP = {
  completeEmployeeInformation: 'Complete employee information',
  updateEmployeeInformation: 'Update employee information',
  submitEmployeeEntryMaterials: 'Submit employee entry materials',
  updateEmployeeEntryMaterials: 'Update employee entry materials',
  signEmployeeEntryContract: 'Sign employee entry contract',
  signEmployeeEntryContractFinished: 'Sign employee entry contract finished',
};

const EmployeeEntry = () => {
  const userESignProcess = useSelector(selectUserESignProcess);
  const contractSignUrl = useSelector(selectContractSignUrl);
  const dispatch = useDispatch();
  const { trackEvent } = useCustomMatomo();
  const [ processDrawerOpen, setProcessDrawerOpen ] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const employeeId = userInfo?.user.employeeId;

  const openProcessDrawer = () => {
    setProcessDrawerOpen(true);
  };

  useEffect(() => {
    if (!userESignProcess) {
      getESignProcess().then(data => dispatch(setUserProcess(data)));
    }
  }, [ userESignProcess ]);

  const handleClickOperateBtn = useCallback((operationType: string) => {
    if (operationType in MATOMO_CATEGORY_MAP) {
      trackEvent({
        category: MATOMO_CATEGORY_MAP[operationType],
        action: MATOMO_ACTION.Navigation,
        name: MATOMO_NAME_MAP[operationType],
      });
    }
  }, []);

  const renderButtonList = () => {
    return (
      <div className="operate-button-list">
        {map(userESignProcess, item => {
          switch (item.key) {
          case EMPLOYEE_CONTRACT_STATUS.SIGN_EMPLOYEE_ENTRY_CONTRACT:
          case EMPLOYEE_CONTRACT_STATUS.SIGN_EXTENSION_CONTRACT:
            return <Tooltip
              placement='bottom'
              title={item.disable ? 'People Support Team暂未发起签署，请稍后查看' : ''}
              getPopupContainer={triggerNode => triggerNode}
              overlayStyle={{ width: 214 }}
              key={item.key}>
              <div className="anchor-wrapper">
                <a className="operation-button-wrapper"
                  href={contractSignUrl} target="_blank" rel="noreferrer"
                  onClick={() => handleClickOperateBtn(item.key)}>
                  <Button disabled={item.disable} title={item.text}/>
                </a>
              </div>
            </Tooltip>;
          case EMPLOYEE_CONTRACT_STATUS.CHECK_SIGN_EXIT_APPROVAL:
            return <Button disabled={item.disable} title={item.text} onClick={() => openProcessDrawer()}/>;
          case EMPLOYEE_CONTRACT_STATUS.SIGN_EXIT_FILES:
            return <div className="anchor-wrapper">
              <a className="operation-button-wrapper"
                href={contractSignUrl} target="_blank" rel="noreferrer" key={item.key}
                onClick={() => handleClickOperateBtn(item.key)}>
                <Button disabled={item.disable} title={item.text}/>
              </a>
            </div>;
          default:
            return <Link className="operation-button-wrapper"
              to={`${EMPLOYEE_NAME_PATH_MAP[item.key]}/${item.processId}`}
              key={item.key} relative="path"
              onClick={() => handleClickOperateBtn(item.key)}>
              <Button disabled={item.disable} title={item.text}/>
            </Link>;
          }
        })}
      </div>
    );
  };

  return (
    <>
      <div className="to-be-operated-container">
        <div className="flex-center" style={{ width: '272px' }}>
          <div className="operate-title">{userESignProcess && '待操作事项'}</div>
          { userESignProcess && size(userESignProcess) === 0
            ? <ImageDescription imgSrc={noOperationItemIcon} alt="No pending operations" desc="暂无待操作事项"/>
            : renderButtonList()}
        </div>
      </div>
      <ExitApprovalProcessDrawer employeeId={employeeId}
        drawerOpen={processDrawerOpen} closeDrawer={() => setProcessDrawerOpen(false)}/>
    </>

  );
};

export default EmployeeEntry;
