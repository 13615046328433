import axiosApi from '@/api/axiosApi';
import { IESignProcessResponse, UserInfo } from '@/models/auth';

export const getUserInfo = (): Promise<UserInfo> => {
  return axiosApi.get('/api/security/acl');
};

export const getESignProcess = async (): Promise<IESignProcessResponse> => {
  return axiosApi.get('/api/employee-todo');
};
