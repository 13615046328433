import { IEmployeeEditInfo, IUploadFile } from '@/models/employee';
import { localStorageUtils } from '@/utils/localStorageUtils';
import { FormInstance } from 'antd';
import { EDIT_STEP } from '../education-info/EducationUtil';
import { isEmpty } from 'lodash-es';


export const defaultPublicInventionInfo = {
  description: '',
  descriptionEnglish: '',
  hasAdditionalNotes: null,
};

export function initPersonalInfoData(employeeData: IEmployeeEditInfo, isEdit = false) {
  const { personalInfo } = employeeData;
  if (!personalInfo) {
    return;
  }
  const { employeeId, publicInventionInfo } = personalInfo;

  personalInfo.hasInvention = false;
  if (publicInventionInfo?.disclosedInventions) {
    publicInventionInfo.disclosedInventions = publicInventionInfo.disclosedInventions.map((fileItem: IUploadFile) => ({
      uid: fileItem.fileId,
      name: fileItem.fileName,
      status: 'done',
      response: fileItem
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any));
  }
  if (isEdit) {
    personalInfo.hasInvention = !isEmpty(publicInventionInfo);
    localStorageUtils.setCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.PERSONAL]}-full-form-value`, personalInfo);
  } else {
    const cachePersonalInfo = localStorageUtils.getCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.PERSONAL]}`) || {};
    employeeData.personalInfo = { ...personalInfo, ...cachePersonalInfo };
    employeeData.personalInfo.hasInvention = !isEmpty(employeeData.personalInfo.publicInventionInfo);
  }
}

export function transformPersonalInfo(values: IEmployeeEditInfo) {
  if (!values?.personalInfo?.hasInvention) {
    values.personalInfo.publicInventionInfo = null;
    values.personalInfo.privateInventionInfo = [];
    delete values.personalInfo.hasInvention;
  }
  const publicInventionInfo = values.personalInfo.publicInventionInfo;
  if (publicInventionInfo?.hasAdditionalNotes) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    publicInventionInfo.disclosedInventions = publicInventionInfo.disclosedInventions.map(attachment => (attachment as any).response);
  }
}

export function handlePersonalInfoValueChange(employeeId: string, form: FormInstance, { personalInfo }: IEmployeeEditInfo) {
  if (!personalInfo) {
    return;
  }

  localStorageUtils.setCacheItem(
    `${employeeId}-${EDIT_STEP[EDIT_STEP.PERSONAL]}`,
    form.getFieldValue([ 'personalInfo' ]),
  );
}
