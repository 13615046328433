import {
  CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME,
  CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME,
  CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME,
} from '@/constants/processTaskName';

export enum EMPLOYEE_NAME_PATH_MAP {
  'completeEmployeeInformation' = 'upload-personal-info',
  'updateEmployeeInformation' = 'edit-personal-info',
  'submitEmployeeEntryMaterials' = 'upload-hire-material',
  'updateEmployeeEntryMaterials' = 'edit-hire-material',
  'updateContractExtensionEmployeeInformation' = 'update-personal-info',
  'updateContractExtensionEmployeeEntryMaterials' = 'upload-personal-material',
  'completeExitEmployeeInformation' = 'upload-personal-exit-information',
  'enterSignExitApproval' = 'enter-exit-approval',
}

export const CHINA_NATIONAL_ID = 'CHN';
export const CHINA_NATIONAL_HKG_ID = 'HKG';
export const CHINA_NATIONAL_MAC_ID = 'MAC';
export const CHINA_NATIONAL_TWN_ID = 'TWN';

export const CHINA_NATION_IDS = [ CHINA_NATIONAL_ID, CHINA_NATIONAL_HKG_ID, CHINA_NATIONAL_MAC_ID, CHINA_NATIONAL_TWN_ID ];

export const CHINA_IDTYPE = 'idCard';
export const PASSPORT_IDTYPE = 'passport';
export const MAINLAND_VISA = 'mainlandVisa';

export enum CertificateType {
  INCOME_CERTIFICATE_CN = '收入证明-中文',
  INCOME_CERTIFICATE_EN = '收入证明-英文',
  EMPLOYMENT_CERTIFICATE_CN = '在职证明-中文',
  EMPLOYMENT_CERTIFICATE_EN = '在职证明-英文'
}

export const ID_TYPE = {
  [CHINA_IDTYPE]: '身份证',
  [PASSPORT_IDTYPE]: '护照',
  [MAINLAND_VISA]: '居民来往大陆通行证'
};

export enum EMPLOYMENT_TYPE {
  INTERN = 'Intern',
  EMPLOYEE = 'Employee',
  CONTRACTOR = 'Contractor',
}

export enum EMPLOYEE_EDUCATION_DEGREE {
  COLLEGE = 'College',
  BACHELOR = 'Bachelor',
  MASTER = 'Master',
  DOCTOR = 'Doctor',
}

export const MONTH_FORMAT = 'YYYY-MM';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const contractTypeInfo = {
  [CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME]: '续签',
  [CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME]: '公司不续签',
  [CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME]: '员工不续签'
};

export const employeeContractInfo = [
  {
    label: '劳动合同',
    key: 'employmentContract'
  },
  {
    label: '续签合同',
    key: 'employmentExtensionContract'
  },
  {
    label: '保密协议',
    key: 'confidentialityAgreement'
  },
  {
    label: '行为准则',
    key: 'codeOfConduct'
  },
  {
    label: '社保确认书',
    key: 'socialSecurityConfirmation'
  },
  {
    label: '培训协议',
    key: 'trainingAgreement'
  },
  {
    label: '续签通知书',
    key: 'extensionNotificationType'
  },
  {
    label: '不续签通知书',
    key: 'unContractExtensionNotification'
  },
  {
    label: '离职协议',
    key: 'contractExitSupplement'
  },
  {
    label: '离职证明',
    key: 'exitCertificate'
  },
  {
    label: '上海市退工单',
    key: 'ShanghaiLayoffSlipFile'
  },
];

export const MAINLAND_HOME_OFFICE = [ 'Shenzhen', 'Beijing', 'Chengdu', 'Wuhan', 'Shanghai', 'Xi\'an' ];

export enum HOME_OFFICE {
  SHENZHEN = 'Shenzhen',
  BEIJING = 'Beijing',
  CHENGDU = 'Chengdu',
  WUHAN = 'Wuhan',
  SHANGHAI = 'Shanghai',
  XIAN = 'Xi\'an'
}

export const HOME_OFFICE_EN_CHINESE_MAP = {
  'Shenzhen': '深圳',
  'Beijing': '北京',
  'Chengdu': '成都',
  'Wuhan': '武汉',
  'Shanghai': '上海',
  'Xi\'an': '西安',
};

export enum CONTRACT_SIGN_TYPE {
  HIRE_EMPLOYEE = 'hire_employee',
  CONTRACT_EXTENSION_EMPLOYEE = 'contract_extension_employee',
  CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE = 'contract_extension_not_sign_employee',
  EXIT_EMPLOYEE = 'exit_employee'
}

export enum EMPLOYEE_CONTRACT_SIGN_STATUS {
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  WAITING_HRO_INITIATE = 'WAITING_HRO_INITIATE',
  WAITING_EMPLOYEE_SIGNING = 'WAITING_EMPLOYEE_SIGNING',
  WAITING_HRO_SIGNING = 'WAITING_HRO_SIGNING',
  HRO_SIGNED = 'HRO_SIGNED',
  FINISH = 'FINISH',
  ARCHIVING = 'ARCHIVING',
  ARCHIVED = 'ARCHIVED'
}

export enum EXIT_AGREEMENT_TYPE {
  NONE = 'none',
  PASSIVE_WITH_COMPENSATION = 'passive-resignation-with-compensation',
  PASSIVE_WITHOUT_COMPENSATION_DURING_PROBATIONARY_PERIOD = 'passive-resignation-during-probationary-period-without-compensation',
  LEAVE_EARLY = 'take-the-initiative-to-resign-and-leave-early'
}

export enum APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE {
  EXIT_BUSINESS_TEAM_APPROVER = 'EXIT_BUSINESS_TEAM_APPROVER',
  WORKSPACE = 'WORKSPACE',
  OFFICE_ADMIN = 'OFFICE_ADMIN',
  LEAVE_HRO = 'LEAVE_HRO',
  OFFICE_HRO = 'OFFICE_HRO',
}
