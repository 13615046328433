import { Badge, Button, Empty, message, Modal, Popconfirm, Table, Tooltip } from 'antd';
import {
  IContractExtensionEmployeeToBeAuditedDetail,
  IContractExtensionEmployeeToBeAuditedResponse,
  IContractExtensionInformationResponse,
  IEmployeeMaterialsRequest,
} from '@/models/employee';
import './ToBeAuditedTableInfo.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import { EMPLOYMENT_TYPE } from '@/constants/employee';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContractExtensionEmployeeInformation,
  reviewedContractExtensionInformation,
  reviewedEmployeeMaterials
} from '@/api/employeeApi';
import { find, noop, omit } from 'lodash-es';
import { isUserHasPermissions } from '@/store/userInfo';
import { PerformanceTracker, useCustomMatomo } from '@/matomo';
import MaterialCheck from '@/components/material-check/MaterialCheck';
import { fetchContractExtensionToBeAuditList, fetchOptions, selectContractExtensionEmployeeList, } from '@/store/employee';
import { AppDispatch } from '@/models/store';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import moment from 'moment/moment';
import useForm from 'antd/lib/form/hooks/useForm';
import { CustomDrawer } from '@/components/custom-drawer/CustomDrawer';
import { DATE_FORMATTER } from '@/constants/date';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import { fieldValidatorConfig } from '@/constants/config/employee-form/will-extension-table-to-be-audited-table/FieldValidatorConfig';
import { CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION, CONTRACT_EXTENSION_PROCESS_READ_PERMISSION, } from '@/constants/permission';
import {
  contractExtensionEmployeeToBeAuditedDetailColumns
} from '@/constants/config/hro/contract-extension-process/will-extension-table/to-be-audited-table-info/ToBeAuditedTableInfoConfig';
import { API_CONTRACT_EXTENSION } from '@/api/consts';
import { fetchContractExtensionEmployeeTotal } from '@/store/hroInfo';
import { EMPLOYEE_INFO_STATUS, EMPLOYEE_MATERIALS_STATUS } from '@/constants/status';
import OperationItems from '@/pages/hro/operation-items/OperationItems';

interface TableInfoProps {
  updateTotalCount: () => void;
}

const ToBeAuditedTableInfo = ({ updateTotalCount }: TableInfoProps) => {
  const hasContractExtensionProcessEditPermission = useSelector(state =>
    isUserHasPermissions(state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ]));

  const hasContractExtensionProcessReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ]));
  const { trackEvent } = useCustomMatomo();

  const [ form ] = useForm();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ popOpen, setPopOpen ] = useState(false);
  const [ employeeInfo, setEmployeeInfo ] = useState<IContractExtensionInformationResponse>();
  const [ currentEmployeeId, setCurrentEmployeeId ] = useState(null);
  const [ materialCheckModalOpen, setMaterialCheckModalOpen ] = useState(false);
  const [ employeeInformationProcessId, setEmployeeInformationProcessId ] = useState('');
  const [ employeeMaterialsProcessId, setEmployeeMaterialsProcessId ] = useState('');
  const [ employeeContractSignProcessId, setEmployeeContractSignProcessId ] = useState('');
  const [ updateDate, setUpdateDate ] = useState(null);

  const dispatch = useDispatch<AppDispatch>();
  const dataStoredInRedux = useSelector(selectContractExtensionEmployeeList) as IContractExtensionEmployeeToBeAuditedResponse;
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
    showSizeChanger: false
  };

  const contractExtensionNotSignEmployeeList = dataStoredInRedux.content;
  useEffect(() => {
    if (!hasContractExtensionProcessReadPermission) {
      return;
    }
    dispatch(fetchContractExtensionToBeAuditList({ page: 0, size: 20 }));
    dispatch(fetchOptions());
  }, [ hasContractExtensionProcessReadPermission ]);

  const openMaterialCheckModal = useCallback((employeeId: string,
    employeeInformationProcessId: string, employeeMaterialsProcessId: string, employeeContractSignProcessId: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.HroAuditDocumentsForExtension,
      action: MATOMO_ACTION.Preview,
      name: MATOMO_NAME.AuditDocuments,
    });
    PerformanceTracker.start();
    setMaterialCheckModalOpen(true);
    setCurrentEmployeeId(employeeId);
    setEmployeeInformationProcessId(employeeInformationProcessId);
    setEmployeeMaterialsProcessId(employeeMaterialsProcessId);
    setEmployeeContractSignProcessId(employeeContractSignProcessId);
  }, []);

  const closeMaterialCheckModal = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroAuditDocumentsForExtension,
      action: MATOMO_ACTION.Cancel,
      name: MATOMO_NAME.AuditDocuments,
    });
    setMaterialCheckModalOpen(false);
  }, []);

  const displayPop = () => {
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  };

  const closePop = () => {
    setPopOpen(false);
  };

  const contractorNoNeedFields = [
    'basePay',
    'basePayCn',
    'variablePay',
    'variablePayCn',
    'annualSalary',
    'annualSalaryCn',
    'currency',
    'currencyCn',
  ];
  const displayDrawer = useCallback((employeeId: string, contractExtensionInformationProcessId: string,
    contractExtensionMaterialsProcessId: string, contractExtensionSignProcessId: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.HroAuditInfoForExtension,
      action: MATOMO_ACTION.Preview,
      name: MATOMO_NAME.AuditInfo,
    });
    setEmployeeInformationProcessId(contractExtensionInformationProcessId);
    setEmployeeMaterialsProcessId(contractExtensionMaterialsProcessId);
    setEmployeeContractSignProcessId(contractExtensionSignProcessId);
    getContractExtensionEmployeeInformation(employeeId).then(employeeInfo => {
      setDrawerOpen(true);
      PerformanceTracker.start();

      setEmployeeInfo(employeeInfo);
      employeeInfo.hireDate = moment(employeeInfo.hireDate);
      employeeInfo.latestReplyDate = moment(employeeInfo.latestReplyDate);

      const data = employeeInfo.employmentType === EMPLOYMENT_TYPE.EMPLOYEE
        ? {
          ...employeeInfo,
          hireDate: moment(employeeInfo.hireDate),
          latestReplyDate: moment(employeeInfo.latestReplyDate)
        }
        : omit(employeeInfo, contractorNoNeedFields);
      form.setFieldsValue(data);
    });
  }, []);

  const closeDrawer = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroAuditInfoForExtension,
      action: MATOMO_ACTION.Cancel,
      name: MATOMO_NAME.AuditInfo,
    });
    setDrawerOpen(false);
    form.resetFields();
  }, []);

  const drawerConfirm = useCallback(() => {
    const formData = form.getFieldsValue(true);
    const data = {
      ...formData,
      hireDate: formData.hireDate.format(DATE_FORMATTER),
      latestReplyDate: formData.latestReplyDate.format(DATE_FORMATTER),
      employeeInfoStatus: EMPLOYEE_INFO_STATUS.REVIEWED,
      contractExtensionInformationProcessId: employeeInformationProcessId,
      contractExtensionMaterialsProcessId: employeeMaterialsProcessId,
      contractExtensionSignProcessId: employeeContractSignProcessId
    };
    reviewedContractExtensionInformation(data).then(() => {
      closePop();
      setDrawerOpen(false);
      message.loading('员工信息审核处理中', 3);
      dispatch(fetchContractExtensionToBeAuditList({ page: 0, size: 20 }));
    }, (err: AxiosError<IErrorResponse>) => {
      if (err.response.data.code === ERROR_CODE.eSignCreateFailed) {
        message.error('合同创建失败：请检查填充合同字段');
      }
    });
  }, [ employeeInformationProcessId ]);

  const tableColumns = useMemo(() => {
    const contractExtensionInformationProcessStatusCol = contractExtensionEmployeeToBeAuditedDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeToBeAuditedDetail>) =>
        col.dataIndex === 'contractExtensionInformationProcessStatus');
    const contractExtensionMaterialsProcessStatusCol = contractExtensionEmployeeToBeAuditedDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeToBeAuditedDetail>) =>
        col.dataIndex === 'contractExtensionMaterialsProcessStatus');
    const operationCol = contractExtensionEmployeeToBeAuditedDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeToBeAuditedDetail>) =>
        col.dataIndex === 'operation');

    contractExtensionInformationProcessStatusCol.render = (_, {
      employeeId, contractExtensionInformationProcessStatus, contractExtensionInformationProcessId,
      contractExtensionMaterialsProcessId, contractExtensionSignProcessId
    }) => {
      if (contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.UNDER_REVIEW) {
        return <Badge color="#47A1AD" text="审核中"/>;
      } else if (contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEWED) {
        return <Badge color="#6B9E78" text="已通过"/>;
      }
      return <Tooltip
        placement="bottom"
        title={contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? '员工信息审核出错，请重新审核' : null}
        getPopupContainer={triggerNode => triggerNode}
        overlayStyle={{ width: 216 }}
      >
        <Button
          data-testid="review-employee-info"
          size="small"
          disabled={contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.UN_SUBMITTED ||
            !hasContractExtensionProcessEditPermission}
          type={contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? 'primary' : 'default'}
          onClick={() => displayDrawer(employeeId, contractExtensionInformationProcessId,
            contractExtensionMaterialsProcessId, contractExtensionSignProcessId)}>
          {contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? '重新审核' : '员工信息'}
        </Button>
      </Tooltip>;
    };

    contractExtensionMaterialsProcessStatusCol.render = (_, {
      employeeId,
      contractExtensionMaterialsProcessStatus,
      contractExtensionInformationProcessId,
      contractExtensionSignProcessId,
      contractExtensionMaterialsProcessId,
    }: IContractExtensionEmployeeToBeAuditedDetail) => {
      if (contractExtensionMaterialsProcessStatus === EMPLOYEE_MATERIALS_STATUS.REVIEWED) {
        return <Badge color="#6B9E78" text="已通过"/>;
      }
      return (
        <Button
          data-testid="preview-materials"
          size="small"
          disabled={!hasContractExtensionProcessEditPermission ||
            contractExtensionMaterialsProcessStatus === EMPLOYEE_MATERIALS_STATUS.UN_SUBMITTED}
          onClick={() => openMaterialCheckModal(employeeId,
            contractExtensionInformationProcessId, contractExtensionMaterialsProcessId, contractExtensionSignProcessId)}>
          续签材料
        </Button>
      );
    };

    operationCol.render = (_, record) => {
      return (
        <>
          <OperationItems employeeId={record.employeeId}
            comment={record.comment}
            contractSignProcessId={record.contractExtensionSignProcessId}
            edit={record.edit}
            matomoName={MATOMO_NAME.Extension}
            callbackFn={() => {
              dispatch(fetchContractExtensionToBeAuditList({ page: 0, size: 20 }));
              dispatch(fetchContractExtensionEmployeeTotal());
            }}/>
        </>
      );
    };


    return contractExtensionEmployeeToBeAuditedDetailColumns;
  }, []);

  const popConfirmMaterial = useCallback(() => {
    const employeeMaterialsRequest: IEmployeeMaterialsRequest = {
      employeeInformationProcessId,
      employeeMaterialsProcessId,
      employeeContractSignProcessId,
      updateDate,
    };
    reviewedEmployeeMaterials(API_CONTRACT_EXTENSION, employeeMaterialsRequest)
      .then(() => {
        const employeeInfo = find(contractExtensionNotSignEmployeeList, item => item.employeeId === currentEmployeeId);
        if (employeeInfo?.contractExtensionInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEWED) {
          updateTotalCount();
        }
        setMaterialCheckModalOpen(false);
        message.success('续签材料已审核通过');
        dispatch(fetchContractExtensionToBeAuditList({ page: 0, size: 20 }));
      }).catch(() => {
        setMaterialCheckModalOpen(false);
      });
  }, [ employeeMaterialsProcessId, updateDate ]);

  const handleTableChange = useCallback(pagination => {
    dispatch(fetchContractExtensionToBeAuditList({ page: pagination.current - 1, size: pagination.pageSize }));
  }, [ tablePagination ]);

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={contractExtensionNotSignEmployeeList}
        rowKey="employeeId"
        pagination={tablePagination}
        onChange={handleTableChange}
        scroll={{ y: 'calc(100vh - 470px)', x: '1100px' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
      <CustomDrawer form={form} employeeInfo={employeeInfo}
        drawerTitle="员工信息审核" drawerOpen={drawerOpen} closeDrawer={closeDrawer} drawerConfirm={drawerConfirm}
        popOpen={popOpen} displayPop={displayPop} closePop={closePop} fieldValidatorConfig={fieldValidatorConfig}/>
      <Modal
        open={materialCheckModalOpen}
        wrapClassName="material-check-modal"
        title="员工续签上传材料审核"
        width={1000}
        destroyOnClose={true}
        onCancel={closeMaterialCheckModal}
        footer={[
          <Button key="cancel" onClick={closeMaterialCheckModal}>取消</Button>,
          <Popconfirm
            key="pass"
            className="confirm-popover"
            placement="topRight"
            title="确认标记审核通过？"
            onConfirm={popConfirmMaterial}
            okText="确认"
            cancelText="取消"
            overlayStyle={{ width: 222 }}
            getPopupContainer={triggerNode => triggerNode}
          >
            <Button type="primary">通过</Button>
          </Popconfirm>
        ]}
      >
        <MaterialCheck employeeId={currentEmployeeId} isContractExtension={true} updateTime={setUpdateDate}/>
      </Modal>
    </>
  );
};
export default ToBeAuditedTableInfo;
