import { Empty, Table } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IContractExtensionEmployeeSignedDoneDetail,
  IContractExtensionEmployeeSignedDoneResponse,
} from '@/models/employee';
import { ColumnType } from 'antd/es/table';
import { getContractExtensionSignedDoneInfo } from '@/api/employeeApi';
import {
  contractExtensionEmployeeSignedDoneDetailColumns
} from '@/constants/config/hro/contract-extension-process/will-extension-table/signed-done-table-info/SignedDoneTableInfoConfig';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_READ_PERMISSION
} from '@/constants/permission';
import ButtonWithStamp from '@/components/button-with-stamp/ButtonWithStamp';
import { AppDispatch } from '@/models/store';
import OperationItems from '@/pages/hro/operation-items/OperationItems';
import { MATOMO_NAME } from '@/constants/matomo';
import { fetchContractExtensionToBeAuditList } from '@/store/employee';
import { fetchContractExtensionEmployeeTotal } from '@/store/hroInfo';
import { EMPLOYEE_CONTRACT_SIGN_STATUS } from '@/constants/status';

const SignedDoneTableInfo = () => {
  const [ employeeList, setEmployeeList ] = useState<IContractExtensionEmployeeSignedDoneDetail[]>([]);
  const [ tablePagination, setTablePagination ] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false
  });

  const dispatch = useDispatch<AppDispatch>();

  const hasContractExtensionProcessReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ]));
  const hasContractExtensionProcessEditPermission = useSelector(state =>
    isUserHasPermissions(state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ]));

  const initContractExtensionSignedDoneInfo = () => {
    getContractExtensionSignedDoneInfo(0, 20)
      .then((response: IContractExtensionEmployeeSignedDoneResponse) => {
        setEmployeeList(response.content);
        setTablePagination({
          current: response.pageable.pageNumber + 1,
          pageSize: response.pageable.pageSize,
          total: response.totalElements,
          showSizeChanger: false
        });
      });
  };

  useEffect(() => {
    if (!hasContractExtensionProcessReadPermission) {
      return;
    }
    initContractExtensionSignedDoneInfo();
  }, [ hasContractExtensionProcessReadPermission ]);

  const tableColumns = useMemo(() => {
    const contractExtensionSignProcessStatusCol = contractExtensionEmployeeSignedDoneDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeSignedDoneDetail>) =>
        col.dataIndex === 'contractExtensionSignProcessStatus');

    const operationCol = contractExtensionEmployeeSignedDoneDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeSignedDoneDetail>) =>
        col.dataIndex === 'operation');

    contractExtensionSignProcessStatusCol.render = (_, record) => {
      return <ButtonWithStamp hroSignUrl={record.stampLink}
        disable={record.contractExtensionSignProcessStatus !== EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_SIGNING ||
                                !hasContractExtensionProcessEditPermission}
        text={record.contractExtensionSignProcessStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_SIGNING ? '盖电子章' : '已盖章'}
        popupContainerId={null}/>;
    };

    operationCol.render = (_, record) => {
      return (
        <>
          <OperationItems employeeId={record.employeeId}
            comment={record.comment}
            contractSignProcessId={record.contractExtensionSignProcessId}
            edit={record.edit}
            matomoName={MATOMO_NAME.Extension}
            callbackFn={() => {
              dispatch(fetchContractExtensionToBeAuditList({ page: 0, size: 20 }));
              dispatch(fetchContractExtensionEmployeeTotal());
            }}/>
        </>
      );
    };

    return contractExtensionEmployeeSignedDoneDetailColumns;
  }, []);

  const handleTableChange = useCallback(pagination => {
    getContractExtensionSignedDoneInfo(pagination.current - 1, pagination.pageSize)
      .then((response: IContractExtensionEmployeeSignedDoneResponse) => {
        setEmployeeList(response.content);
        setTablePagination({
          ...pagination,
          current: response.pageable.pageNumber + 1,
          total: response.totalElements,
        });
      });
  }, [ tablePagination ]);

  return (
    <Table
      columns={tableColumns}
      dataSource={employeeList}
      rowKey="employeeId"
      onChange={handleTableChange}
      pagination={tablePagination}
      scroll={{ y: 'calc(100vh - 470px)' }}
      locale={{ emptyText: <Empty description={'暂无数据'}/> }}
    />
  );
};
export default SignedDoneTableInfo;
