// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import TotalNumber from '@/components/total-number/TotalNumber';
import './WillNotExtensionTable.scss';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_READ_PERMISSION,
} from '@/constants/permission';
import {
  fetchContractNotExtensionEmployeeTotal,
  selectWillNotExtensionTotalCount
} from '@/store/hroInfo';
import { AppDispatch } from '@/models/store';
import { EMPLOYEE_TAB_STATUS } from '@/constants/status';
import { API_CONTRACT_NOT_EXTENSION } from '@/api/consts';
import WillNotExtensionPendingTable
  from '@/pages/hro/contract-extension-process/will-not-extension-table/will-not-extension-pending-table/WillNotExtensionPendingTable';
import ContractList from '@/pages/hro/contract-extension-process/contract-list/ContractList';

const TAB_COUNT_MAPPER = {
  [EMPLOYEE_TAB_STATUS.PENDING]: 'toBeAuditedUserCount',
  [EMPLOYEE_TAB_STATUS.FINISHED]: 'finishedUserCount'
};

const WillNotExtensionTable = () => {
  const [ searchParams, setSearchParams ] = useSearchParams({});
  const total = useSelector(selectWillNotExtensionTotalCount);
  const [ currentTab, setCurrentTab ] = useState<string>(EMPLOYEE_TAB_STATUS.PENDING);
  const dispatch = useDispatch<AppDispatch>();
  const hasPermission = useSelector(state =>
    isUserHasPermissions( state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION, CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ]));
  const getTotalCount = useCallback(() => {
    dispatch(fetchContractNotExtensionEmployeeTotal());
  }, []);

  useEffect(() => {
    if (hasPermission) {
      dispatch(fetchContractNotExtensionEmployeeTotal());
    }
  }, [ hasPermission ]);

  const items = useMemo(() => {
    return [
      {
        key: EMPLOYEE_TAB_STATUS.PENDING,
        label: <TotalNumber title={'流程中员工'} totalCount={total && total[TAB_COUNT_MAPPER[EMPLOYEE_TAB_STATUS.PENDING]]}/>,
        children: <WillNotExtensionPendingTable />,
        disabled: !hasPermission,
      },
      {
        key: EMPLOYEE_TAB_STATUS.FINISHED,
        label: <TotalNumber title={'完成签署员工'}
          totalCount={total && total[TAB_COUNT_MAPPER[EMPLOYEE_TAB_STATUS.FINISHED]]}/>,
        children: <ContractList apiUrl={API_CONTRACT_NOT_EXTENSION}/>,
        disabled: !hasPermission,
      }
    ];
  }, [ total ]);

  const getActiveItems = useCallback(() => {
    return items.filter(item => !item.disabled);
  }, [ items ]);

  const clickTab = useCallback(activeKey => {
    if (activeKey !== currentTab) {
      setCurrentTab(activeKey);
      setSearchParams({ tab: activeKey });
      getTotalCount();
    }
  }, [ currentTab ]);

  return (
    <div className="main-page">
      <div className="container">
        <Tabs
          type="card"
          defaultActiveKey={searchParams.get('tab') || EMPLOYEE_TAB_STATUS.PENDING}
          items={getActiveItems()}
          onTabClick={clickTab}
          destroyInactiveTabPane={true}/>
      </div>
    </div>
  );
};
export default WillNotExtensionTable;
