import {
  IContractExtensionInformationResponse,
  IContractExtensionNotSignInformationResponse, IExitEmployeeResponse,
  IHireInformationRequest,
  Options
} from '@/models/employee';
import { get, map } from 'lodash-es';
import { Empty, Select } from 'antd';
import {
  ContractExtensionFieldConfig
} from '@/constants/config/employee-form/will-extension-table-to-be-audited-table/FieldValidatorConfig';
import {
  ContractExtensionNotSignFieldConfig
} from '@/constants/config/employee-form/will-not-extension-table/FieldValidatorConfig';
import {
  HireFieldConfig
} from '@/constants/config/employee-form/entry-contract-process-to-be-audited-table/FieldValidatorConfig';
import { ExitEmployeeFieldConfig } from '@/constants/config/employee-form/exit-employee-table-info/FieldValidatorConfig';

const getOptions = (
  allOptions: Options,
  config:
      ContractExtensionFieldConfig |
      ContractExtensionNotSignFieldConfig |
      HireFieldConfig |
      ExitEmployeeFieldConfig
) => {
  const key = get(config, 'componentProps.optionKey');
  return get(allOptions, key, []);
};

const CustomSelect = (
  config:
      ContractExtensionFieldConfig |
      ContractExtensionNotSignFieldConfig |
      HireFieldConfig |
      ExitEmployeeFieldConfig,
  allOptions: Options,
  getFormFieldsValue: () =>
      IContractExtensionInformationResponse &
      IContractExtensionNotSignInformationResponse &
      IHireInformationRequest &
      IExitEmployeeResponse
) => {
  let options = getOptions(allOptions, config);
  if (config.componentProps.optionFilter) {
    options = config.componentProps.optionFilter(options, getFormFieldsValue());
  }
  const showSearch = get(config, 'componentProps.showSearch', true);
  return (
    <Select
      showSearch={showSearch}
      optionFilterProp='children'
      getPopupContainer={triggerNode => triggerNode}
      notFoundContent={<Empty description={'暂无数据'}/>}>
      {
        map(options, option => (
          <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
        ))
      }
    </Select>
  );
};
export default CustomSelect;
