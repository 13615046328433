// eslint-disable-next-line no-use-before-define
import React from 'react';
import { TabsProps } from 'antd';
import WillExtensionTable from '@/pages/hro/contract-extension-process/will-extension-table/WillExtensionTable';
import CustomTabs from '@/components/custom-tabs/CustomTabs';
import WillNotExtensionTable
  from '@/pages/hro/contract-extension-process/will-not-extension-table/WillNotExtensionTable';
import { CONTRACT_EXTENSION_TAB_STATUS } from '@/constants/status';

const ContractExtensionProcess = () => {
  const items: TabsProps['items'] = [
    {
      key: CONTRACT_EXTENSION_TAB_STATUS.WILL_EXTENSION,
      label: '续签人员',
      children: <WillExtensionTable/>,
    },
    {
      key: CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION,
      label: '不续签人员',
      children: <WillNotExtensionTable/>,
    },
  ];

  return <CustomTabs defaultActiveKey={CONTRACT_EXTENSION_TAB_STATUS.WILL_EXTENSION} items={items} />;
};
export default ContractExtensionProcess;
