import './ImageDescription.scss';

interface ImageDescriptionProps {
  imgSrc: string;
  alt: string;
  title?: string;
  desc?: string;
  contactDesc?: string;
  anchorHref?: string;
  anchorDes?: string;
}

const ImageDescription = ({ imgSrc, alt, title, desc, contactDesc, anchorHref, anchorDes }: ImageDescriptionProps) => {
  return (
    <div className="flex-center">
      <img className="image-desc" src={imgSrc} alt={alt}/>
      {title && <p className="image-title">{title}</p>}
      {desc && <p className="image-content">{desc}</p>}
      {contactDesc && <p className="contact-us-des">{contactDesc}
        <a className="contact-email" href={anchorHref}>{anchorDes}</a>
      </p>}
    </div>
  );
};

export default ImageDescription;