import { forEach } from 'lodash-es';
import { RouterObjectExtend } from '@/models/common';

export const getPageTitle = routes => {
  if (window.location.pathname === '/e-sign/no-permission') {
    return 'No Permission';
  }
  const parentRoute = window.location.pathname.split('/')[2];
  if (!parentRoute) {
    return;
  }
  const matchedRoute = routes[0].children.find(route => route.path === parentRoute) as RouterObjectExtend;
  const formattedLocationPathname = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    .replaceAll('-', ' ').toLowerCase().replace(/( |^)[a-z]/g, L => L.toUpperCase());
  return matchedRoute.matomoPageTitle.indexOf(formattedLocationPathname) > -1
    ? formattedLocationPathname : matchedRoute.matomoPageTitle[0];
};

export const getSearchParams = (searchPart: string) => {
  const paramsObj = {};
  if (searchPart.indexOf('?') !== -1) {
    const realSearchParams:string = searchPart.slice(1);
    const paramArray:string[] = realSearchParams.split('&');
    forEach(paramArray, item => {
      const entryArray = item.split('=');
      paramsObj[entryArray[0]] = entryArray[1];
    });
  }
  return paramsObj;
};

export const convertParamObjToString = (paramsObj, searchParams: Array<string>) => {
  let searchUrl = '';
  forEach(searchParams, key => {
    if (paramsObj[key]) {
      searchUrl += searchUrl ? `&${key}=${paramsObj[key]}` : `${key}=${paramsObj[key]}`;
    }
  });
  return searchUrl  = searchUrl ? '?' + searchUrl : '';
};
