import { EMPLOYEE_EDUCATION_DEGREE, MONTH_FORMAT } from '@/constants/employee';
import { IEmployeeEditInfo } from '@/models/employee';
import { localStorageUtils } from '@/utils/localStorageUtils';
import { FormInstance } from 'antd';
import moment from 'moment';

const EDUCATION_DEGREE_ORDER = [
  EMPLOYEE_EDUCATION_DEGREE.DOCTOR,
  EMPLOYEE_EDUCATION_DEGREE.MASTER,
  EMPLOYEE_EDUCATION_DEGREE.BACHELOR,
  EMPLOYEE_EDUCATION_DEGREE.COLLEGE
];
const DEFAULT_EDUCATION_ITEM = {
  universityId: null,
  majorId: null,
  dateRange: null,
  degree: null,
};
export enum EDIT_STEP {
    PERSONAL = 0,
    EDUCATION = 1,
    WORK = 2,
    SKILL = 3,
}

export enum CONTRACT_EXTENSION_EDIT_STEP {
  PERSONAL = 0,
  EDUCATION = 1,
  SKILL = 2,
}

function getDefaultEducationalInfoItems(highestDegree: EMPLOYEE_EDUCATION_DEGREE) {
  switch (highestDegree) {
  case EMPLOYEE_EDUCATION_DEGREE.COLLEGE:
    return [ { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.COLLEGE } ];
  case EMPLOYEE_EDUCATION_DEGREE.BACHELOR:
    return [ { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.BACHELOR } ];
  case EMPLOYEE_EDUCATION_DEGREE.MASTER:
    return [
      { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.MASTER },
      { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.BACHELOR }
    ];
  case EMPLOYEE_EDUCATION_DEGREE.DOCTOR:
    return [
      { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.DOCTOR },
      { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.MASTER },
      { ...DEFAULT_EDUCATION_ITEM, degree: EMPLOYEE_EDUCATION_DEGREE.BACHELOR }
    ];
  default:
    return [];
  }
}

export const transformMomentRangeToStrRange = item => {
  return {
    ...item,
    dateRange: item.dateRange && item.dateRange.map(date => date?.format(MONTH_FORMAT))
  };
};

export const transformStrRangeToMomentRange = item => {
  return {
    ...item,
    dateRange: item.dateRange && item.dateRange.map(dateStr => dateStr ? moment(dateStr, MONTH_FORMAT) : null),
  };
};

export const transformDateStrToMomentRange = item => {
  return {
    ...item,
    dateRange: item.startDate ? [ moment(item.startDate, MONTH_FORMAT), moment(item.endDate, MONTH_FORMAT) ] : null,
  };
};

export const transformRangeStrToDateStr = item => {
  const ret = { ...item };
  if (ret.dateRange) {
    ret.startDate = ret.dateRange[0];
    ret.endDate = ret.dateRange[1];
  }
  delete ret.dateRange;
  return ret;
};

export function initEducationDataForForm(employeeEditInfo: IEmployeeEditInfo, isEdit: boolean) {
  const { educationalInfo, personalInfo } = employeeEditInfo;
  /* istanbul ignore next */
  if (!educationalInfo) {
    return;
  }
  const { employeeId } = personalInfo;
  if (isEdit) { // already have education info
    educationalInfo.educationalInfoItems = educationalInfo.educationalInfoItems
      .map(transformDateStrToMomentRange)
      .sort((item1, item2) => {
        return EDUCATION_DEGREE_ORDER.indexOf(item1.degree) - EDUCATION_DEGREE_ORDER.indexOf(item2.degree);
      });
    const educationalInfoItemsForStore = educationalInfo.educationalInfoItems.map(transformMomentRangeToStrRange);
    localStorageUtils.setCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-${educationalInfo.highestDegree}`,
      educationalInfoItemsForStore,
    );
    // store the init form value
    localStorageUtils.setCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-full-form-value`,
      {
        ...educationalInfo,
        educationalInfoItems: educationalInfoItemsForStore
      },
    );
  } else { // read from localStorage
    educationalInfo.highestDegree = EMPLOYEE_EDUCATION_DEGREE.BACHELOR;
    const cachedEducationalInfoItems = localStorageUtils.getCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-${educationalInfo.highestDegree}`
    );
    educationalInfo.educationalInfoItems = (cachedEducationalInfoItems || getDefaultEducationalInfoItems(educationalInfo.highestDegree))
      .map(transformStrRangeToMomentRange);

  }
}

export function handleEducationValueChange(employeeId: string, form: FormInstance, { educationalInfo }: IEmployeeEditInfo) {
  if (!educationalInfo) {
    return;
  }
  if (educationalInfo.highestDegree) {
    form.resetFields([ [ 'educationalInfo', 'educationalInfoItems' ] ]);
    let cachedEducationalInfoItems = localStorageUtils.getCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-${educationalInfo.highestDegree}`
    );
    if (!cachedEducationalInfoItems) {
      cachedEducationalInfoItems = getDefaultEducationalInfoItems(educationalInfo.highestDegree);
    }
    form.setFieldValue([ 'educationalInfo', 'educationalInfoItems' ], cachedEducationalInfoItems.map(transformStrRangeToMomentRange));
  }
  if (educationalInfo.educationalInfoItems) {
    const highestEducationalDegreeFormValue = form.getFieldValue([ 'educationalInfo','highestDegree' ]);
    localStorageUtils.setCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-${highestEducationalDegreeFormValue}`,
      (form.getFieldValue([ 'educationalInfo', 'educationalInfoItems' ]) || []).map(transformMomentRangeToStrRange),
    );
  }
}

export function saveFullEducationFormValue(employeeId: string, values: IEmployeeEditInfo) {
  localStorageUtils.setCacheItem(
    `${employeeId}-${EDIT_STEP[EDIT_STEP.EDUCATION]}-full-form-value`,
    values && {
      ...values.educationalInfo,
      educationalInfoItems: values.educationalInfo.educationalInfoItems.map(transformMomentRangeToStrRange),
    }
  );
}
