import { getWebBaseUrl } from '@/api/axiosApi';
import { downloadFile } from '@/api/employeeApi';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `${getWebBaseUrl()}/pdf.worker.min.js`;

interface CertificatePreviewProps {
  fileUrl: string;

}

const CertificatePreviewer = ({ fileUrl }: CertificatePreviewProps) => {
  const [ objectUrl, setObjectUrl ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(() => {
    let url = null;
    downloadFile(fileUrl)
      .then(data => {
        url = URL.createObjectURL(data);
        setObjectUrl(url);
      }).catch(() => {
        setErrorMessage('Fail to load PDF file.');
      });
    return () => {
      URL.revokeObjectURL(url);
    };
  }, []);

  if (!objectUrl && !errorMessage) {
    return <Spin className="file-loading" size="large"/>;
  }

  return (
    <Document
      file={objectUrl}
      loading={<Spin size="large"/>}
      noData={errorMessage}
      options={{
        cMapUrl: `${getWebBaseUrl()}/cmaps/`,
        cMapPacked: true,
      }}>
      <Page
        renderAnnotationLayer={false}
        renderTextLayer={false}
        width={902}
        pageNumber={1}
      />
    </Document>

  );
};

export default CertificatePreviewer;
