import { IEmployeeEditInfo } from '@/models/employee';
import { localStorageUtils } from '@/utils/localStorageUtils';
import { FormInstance } from 'antd';
import {
  EDIT_STEP,
  transformDateStrToMomentRange,
  transformMomentRangeToStrRange,
  transformStrRangeToMomentRange
} from '../education-info/EducationUtil';

export const DEFAULT_WORK_EXPERIENCE_ITEM = {
  companyName: null,
  companyIndustryId: null,
  dateRange: null,
  nationId: null,
  position: null,
  worldTopFiveHundred: null,
};

export function initWorkExperienceDataForForm(employeeEditInfo: IEmployeeEditInfo, isEdit: boolean) {
  const { workExperienceInfo, personalInfo } = employeeEditInfo;
  if (!workExperienceInfo) {
    return;
  }
  const { employeeId } = personalInfo;
  if (isEdit) {
    workExperienceInfo.workExperienceInfoItems = workExperienceInfo.workExperienceInfoItems
      .map(transformDateStrToMomentRange);
    workExperienceInfo.numberOfWorkExperience = workExperienceInfo.numberOfWorkExperience > 3
      ? 3 : workExperienceInfo.numberOfWorkExperience;
    localStorageUtils.setCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-${workExperienceInfo.numberOfWorkExperience}`,
      workExperienceInfo.workExperienceInfoItems.map(transformMomentRangeToStrRange),
    );
    // store the init form value
    localStorageUtils.setCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-full-form-value`,
      {
        ...workExperienceInfo,
        workExperienceInfoItems: workExperienceInfo.workExperienceInfoItems.map(transformMomentRangeToStrRange)
      }
    );
  } else { // read from localStorage
    workExperienceInfo.numberOfWorkExperience = 0;
    const cachedWorkExperienceItems = localStorageUtils.getCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-${workExperienceInfo.numberOfWorkExperience}`
    );
    workExperienceInfo.workExperienceInfoItems = (cachedWorkExperienceItems || []).map(transformStrRangeToMomentRange);
  }
}

export function handleWorkInfoValueChange(employeeId: string, form: FormInstance, { workExperienceInfo }: IEmployeeEditInfo) {
  if (!workExperienceInfo) {
    return;
  }
  if (typeof workExperienceInfo.numberOfWorkExperience === 'number') {
    form.resetFields([ [ 'workExperienceInfo', 'workExperienceInfoItems' ] ]);
    let cachedWorkExperienceItems = localStorageUtils.getCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-${workExperienceInfo.numberOfWorkExperience}`
    );
    if (!cachedWorkExperienceItems) {
      cachedWorkExperienceItems = Array(workExperienceInfo.numberOfWorkExperience).fill({ ...DEFAULT_WORK_EXPERIENCE_ITEM });
    }
    form.setFieldValue([ 'workExperienceInfo', 'workExperienceInfoItems' ], cachedWorkExperienceItems.map(transformStrRangeToMomentRange));
  }
  if (workExperienceInfo.workExperienceInfoItems) {
    const jobNumbersFormValue = form.getFieldValue([ 'workExperienceInfo', 'numberOfWorkExperience' ]);
    localStorageUtils.setCacheItem(
      `${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-${jobNumbersFormValue}`,
      (form.getFieldValue([ 'workExperienceInfo', 'workExperienceInfoItems' ]) || []).map(transformMomentRangeToStrRange),
    );
  }
}

export function saveFullWorkExperienceFormValue(employeeId: string, values: IEmployeeEditInfo) {
  localStorageUtils.setCacheItem(
    `${employeeId}-${EDIT_STEP[EDIT_STEP.WORK]}-full-form-value`,
    values && {
      ...values.workExperienceInfo,
      workExperienceInfoItems: values.workExperienceInfo.workExperienceInfoItems.map(transformMomentRangeToStrRange),
    }
  );
}

