import { Badge, Button, Empty, message, Modal, Popconfirm, Table, Tooltip } from 'antd';
import { IEmployeeMaterialsRequest, IEmployeeToBeAuditedDetail, IHireInformationResponse } from '@/models/employee';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import { EMPLOYMENT_TYPE } from '@/constants/employee';
import MaterialCheck from '../../../../components/material-check/MaterialCheck';
import { getHireInformationByEmployeeId, reviewedEmployeeMaterials, reviewedHireInformation } from '@/api/employeeApi';
import { fetchEmployeeToBeAuditList, fetchOptions, selectEmployeeList } from '@/store/employee';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/models/store';
import { find, noop, omit } from 'lodash-es';
import { PerformanceTracker, useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import moment from 'moment/moment';
import { DATE_FORMATTER } from '@/constants/date';
import useForm from 'antd/lib/form/hooks/useForm';
import { CustomDrawer } from '@/components/custom-drawer/CustomDrawer';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import {
  fieldValidatorConfig
} from '@/constants/config/employee-form/entry-contract-process-to-be-audited-table/FieldValidatorConfig';
import {
  employeeToBeAuditedDetailColumns
} from '@/constants/config/hro/entry-contract-process/to-be-audited-table-info/ToBeAuditedTableInfoConfig';
import { selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { API_CONTRACT_HIRE } from '@/api/consts';
import { EMPLOYEE_INFO_STATUS, EMPLOYEE_MATERIALS_STATUS } from '@/constants/status';

interface TableInfoProps {
  totalCount: number,
  updateTotalCount: () => void
}

const ToBeAuditedTableInfo = ({ totalCount, updateTotalCount }: TableInfoProps) => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ currentEmployeeId, setCurrentEmployeeId ] = useState(null);
  const [ materialCheckModalOpen, setMaterialCheckModalOpen ] = useState(false);
  const [ employeeMaterialsProcessId, setEmployeeMaterialsProcessId ] = useState('');
  const [ employeeInformationProcessId, setEmployeeInformationProcessId ] = useState('');
  const [ employeeContractSignProcessId, setEmployeeContractSignProcessId ] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const dataStoredInRedux: IEmployeeToBeAuditedDetail[] = useSelector(selectEmployeeList);
  const { trackEvent } = useCustomMatomo();
  const [ form ] = useForm();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ popOpen, setPopOpen ] = useState(false);
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInformationResponse>();
  const [ updateDate, setUpdateDate ] = useState(null);
  const employeeList = dataStoredInRedux.map(user => {
    return {
      ...user,
      workExperience: user.workExperience ? '有' : '无',
    };
  });
  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();

  useEffect(() => {
    if (userInfo && hasPermission) {
      dispatch(fetchEmployeeToBeAuditList());
      dispatch(fetchOptions());
    }
  }, [ userInfo && hasPermission ]);

  const handleTableChange = useCallback(pagination => {
    setCurrentPage(pagination.current);
  }, []);

  const openMaterialCheckModal = useCallback((employeeId: string, employeeMaterialsProcessId: string,
    employeeInformationProcessId: string, employeeContractSignProcessId: string) => {
    PerformanceTracker.start();
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.Click,
      name: 'Preview audit',
    });
    setMaterialCheckModalOpen(true);
    setCurrentEmployeeId(employeeId);
    setEmployeeMaterialsProcessId(employeeMaterialsProcessId);
    setEmployeeContractSignProcessId(employeeContractSignProcessId);
    setEmployeeInformationProcessId(employeeInformationProcessId);
  }, []);

  const closeMaterialCheckModal = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.Close,
      name: MATOMO_NAME.InductionMaterial,
    });
    setMaterialCheckModalOpen(false);
  }, []);

  const displayPop = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.PassAudit,
      name: MATOMO_NAME.EmployeeInfo,
    });
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  }, []);

  const closePop = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: `${MATOMO_ACTION.Cancel} ${MATOMO_ACTION.Confirm}`,
      name: MATOMO_NAME.EmployeeInfo,
    });
    setPopOpen(false);
  }, []);

  const contractorNoNeedFields = [
    'basePay',
    'basePayCn',
    'variablePay',
    'variablePayCn',
    'annualSalary',
    'annualSalaryCn',
    'currency',
    'currencyCn',
    'trainingType',
    'trainingLocation'
  ];
  const displayDrawer = useCallback((employeeId: string, employeeInformationProcessId: string,
    employeeMaterialsProcessId: string, employeeContractSignProcessId: string, employeeInformationProcessStatus: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.Click,
      name: employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? MATOMO_NAME.AuditAgain : MATOMO_NAME.PreviewAudit,
    });
    setEmployeeInformationProcessId(employeeInformationProcessId);
    setEmployeeMaterialsProcessId(employeeMaterialsProcessId);
    setEmployeeContractSignProcessId(employeeContractSignProcessId);
    getHireInformationByEmployeeId(employeeId).then(employeeInfo => {
      setDrawerOpen(true);
      PerformanceTracker.start();

      setEmployeeInfo(employeeInfo);
      employeeInfo.hireDate = moment(employeeInfo.hireDate);

      const data = employeeInfo.employmentType === EMPLOYMENT_TYPE.EMPLOYEE
        ? {
          ...employeeInfo,
          trainingDateRange: employeeInfo.trainingStartDate ? [
            moment(employeeInfo.trainingStartDate, DATE_FORMATTER),
            moment(employeeInfo.trainingEndDate, DATE_FORMATTER),
          ] : undefined,
          hireDate: moment(employeeInfo.hireDate)
        }
        : omit(employeeInfo, contractorNoNeedFields);
      form.setFieldsValue(data);
    });
  }, []);

  const closeDrawer = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.Cancel,
      name: MATOMO_NAME.EmployeeInfo,
    });
    setDrawerOpen(false);
    form.resetFields();
  }, []);

  const drawerConfirm = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.Confirm,
      name: MATOMO_NAME.EmployeeInfo,
      value: PerformanceTracker.stop(),
    });

    const formData = form.getFieldsValue(true);
    const data = {
      ...formData,
      hireDate: formData.hireDate.format(DATE_FORMATTER),
      employeeInfoStatus: EMPLOYEE_INFO_STATUS.REVIEWED,
      employeeInformationProcessId,
      employeeMaterialsProcessId,
      employeeContractSignProcessId
    };
    if (formData.trainingType) {
      data.trainingStartDate = formData.trainingDateRange[0]?.format(DATE_FORMATTER);
      data.trainingEndDate = formData.trainingDateRange[1]?.format(DATE_FORMATTER);
    } else {
      delete data.trainingLocation;
    }
    delete data.trainingDateRange;
    reviewedHireInformation(data).then(() => {
      closePop();
      setDrawerOpen(false);
      message.loading('员工信息审核处理中', 3);
      dispatch(fetchEmployeeToBeAuditList());
    }, (err: AxiosError<IErrorResponse>) => {
      if (err.response.data.code === ERROR_CODE.eSignCreateFailed) {
        message.error('合同创建失败：请检查填充合同字段');
      }
    });
  }, [ employeeInformationProcessId ]);

  const tableColumns = useMemo(() => {
    const hireMaterialsProcessStatusCol = employeeToBeAuditedDetailColumns
      .find((col: ColumnType<IEmployeeToBeAuditedDetail>) => col.dataIndex === 'employeeMaterialsProcessStatus');
    const hireInformationProcessStatusCol = employeeToBeAuditedDetailColumns
      .find((col: ColumnType<IEmployeeToBeAuditedDetail>) => col.dataIndex === 'employeeInformationProcessStatus');
    hireInformationProcessStatusCol.render = (_, {
      employeeId, employeeInformationProcessStatus, employeeInformationProcessId,
      employeeMaterialsProcessId, employeeContractSignProcessId
    }) => {
      if (employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.UNDER_REVIEW) {
        return <Badge color="#47A1AD" text="审核中"/>;
      } else if (employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEWED) {
        return <Badge color="#6B9E78" text="已通过"/>;
      }
      return <Tooltip
        placement="bottom"
        title={employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? '员工信息审核出错，请重新审核' : null}
        getPopupContainer={triggerNode => triggerNode}
        overlayStyle={{ width: 216 }}>
        <Button
          data-testid="review-employee-info"
          size="small"
          disabled={employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.UN_SUBMITTED}
          type={employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? 'primary' : 'default'}
          onClick={() => displayDrawer(employeeId, employeeInformationProcessId,
            employeeMaterialsProcessId, employeeContractSignProcessId, employeeInformationProcessStatus)}>
          {employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEW_FAILED ? '重新审核' : '预览审核'}
        </Button>
      </Tooltip>;
    };
    hireMaterialsProcessStatusCol.render = (_, {
      employeeId,
      employeeMaterialsProcessStatus,
      employeeMaterialsProcessId,
      employeeInformationProcessId,
      employeeContractSignProcessId
    }: IEmployeeToBeAuditedDetail) => {
      if (employeeMaterialsProcessStatus === EMPLOYEE_MATERIALS_STATUS.REVIEWED) {
        return <Badge color="#6B9E78" text="已通过"/>;
      }
      return (
        <Button
          data-testid="preview-materials"
          size="small"
          disabled={employeeMaterialsProcessStatus === EMPLOYEE_MATERIALS_STATUS.UN_SUBMITTED}
          onClick={() => openMaterialCheckModal(employeeId, employeeMaterialsProcessId,
            employeeInformationProcessId, employeeContractSignProcessId)}>
          预览审核
        </Button>
      );
    };
    return employeeToBeAuditedDetailColumns;
  }, []);

  const popCancel = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: `${MATOMO_ACTION.Cancel} ${MATOMO_ACTION.Confirm}`,
      name: MATOMO_NAME.InductionMaterial,
    });
  }, []);

  const popConfirmMaterial = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.Confirm,
      name: MATOMO_NAME.InductionMaterial,
      value: PerformanceTracker.stop(),
    });
    const employeeMaterialsRequest: IEmployeeMaterialsRequest = {
      employeeMaterialsProcessId,
      employeeContractSignProcessId,
      employeeInformationProcessId,
      updateDate,
    };
    reviewedEmployeeMaterials(API_CONTRACT_HIRE, employeeMaterialsRequest)
      .then(() => {
        dispatch(fetchEmployeeToBeAuditList());
        const employeeInfo = find(dataStoredInRedux, item => item.employeeId === currentEmployeeId);
        if (employeeInfo?.employeeInformationProcessStatus === EMPLOYEE_INFO_STATUS.REVIEWED) {
          updateTotalCount();
        }
        setMaterialCheckModalOpen(false);
        setCurrentPage(1);
        message.success('入职材料已审核通过');
      }).catch(()=> {
        setMaterialCheckModalOpen(false);
      });
  }, [ employeeMaterialsProcessId, updateDate ]);

  const handlePassAudit = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeAudited,
      action: MATOMO_ACTION.PassAudit,
      name: MATOMO_NAME.InductionMaterial,
    });
  }, []);

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={employeeList}
        rowKey="employeeId"
        onChange={handleTableChange}
        pagination={{ current: currentPage, defaultPageSize: 20, total: totalCount, showSizeChanger: false }}
        scroll={{ y: 'calc(100vh - 470px)' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
      <CustomDrawer form={form} employeeInfo={employeeInfo}
        drawerTitle="员工信息审核" drawerOpen={drawerOpen} closeDrawer={closeDrawer} drawerConfirm={drawerConfirm}
        popOpen={popOpen} displayPop={displayPop} closePop={closePop} fieldValidatorConfig={fieldValidatorConfig}/>
      <Modal
        open={materialCheckModalOpen}
        wrapClassName="material-check-modal"
        title="员工入职材料审核"
        width={1000}
        style={{ top: 20 }}
        destroyOnClose={true}
        onCancel={closeMaterialCheckModal}
        footer={[
          <Button key="cancel" onClick={closeMaterialCheckModal}>取消</Button>,
          <Popconfirm
            key="pass"
            className="confirm-popover"
            placement="topRight"
            title="确认标记审核通过？"
            onCancel={popCancel}
            onConfirm={popConfirmMaterial}
            okText="确认"
            cancelText="取消"
            overlayStyle={{ width: 222 }}
            getPopupContainer={triggerNode => triggerNode}
          >
            <Button type="primary" onClick={handlePassAudit}>通过</Button>
          </Popconfirm>
        ]}
      >
        <MaterialCheck employeeId={currentEmployeeId} isContractExtension={false} updateTime={setUpdateDate}/>
      </Modal>
    </>
  );
};
export default ToBeAuditedTableInfo;
