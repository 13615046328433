import { ColumnsType } from 'antd/es/table';
import { IIssueCertificateInfo } from '@/models/employee';
import { CERTIFICATE_TYPE_OPTIONS } from '@/constants/employeeOptions';

export const issueCertificateInfoColumns: ColumnsType<IIssueCertificateInfo> = [
  {
    title: '办公地点',
    dataIndex: 'homeOfficeChinese',
    className: 'align-center',
    width: 110,
  },
  {
    title: '工号',
    dataIndex: 'employeeId',
    className: 'align-center',
    width: 110,
  },
  {
    title: '姓名',
    dataIndex: 'chineseName',
    className: 'show-ellipsis align-center',
  },
  {
    title: '员工类型',
    dataIndex: 'employmentType',
    className: 'align-center',
  },

  {
    title: '证明类型',
    dataIndex: 'certificateType',
    className: 'align-center',
    render: (_, record) => {
      return <div>
        {CERTIFICATE_TYPE_OPTIONS.find(item => item.value === (record.certificateType))?.label}
      </div>;
    }
  },
  {
    title: '申请时间',
    dataIndex: 'enrollmentTime',
    className: 'align-center',
    width: 200,
  },
  {
    title: '证明盖章审核',
    dataIndex: 'certificateProcessStatus',
    className: 'last-td align-center'
  }
];
