import { createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types';
import { debounce } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';

const BaseURL = 'https://thoughtworks.innocraft.cloud/';

const siteId = process.env.REACT_APP_MATOMO_SITE_ID || '121';

export const matomoInstance = createInstance({
  urlBase: BaseURL,
  siteId: parseInt(siteId),
  linkTracking: false, // optional, default value: true
});

export function useCustomMatomo() {
  const matomoFuncs = useMatomo();
  const userInfo = useSelector(selectUserInfo);
  const globalDimension = useRef(null);
  const isFirstInit = useRef(true);

  useEffect(() => {
    if (isFirstInit.current && !!userInfo) {
      matomoFuncs.pushInstruction('setUserId', userInfo.user?.employeeId);
      globalDimension.current = userInfo?.roles?.length > 0 ? { id: 1, value: userInfo?.roles[0].name } : null;
      isFirstInit.current = false;
    }
  }, [ userInfo ]);

  return {
    ...matomoFuncs,
    trackEvent: debounce((params: TrackEventParams) => {
      matomoFuncs.trackEvent({
        ...params,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customDimensions: params.customDimensions ? [ ...(params.customDimensions as any), globalDimension ] : [ globalDimension ],
      });
    }),
  };
}
class PerformanceTrack {
  timestamp: number;
  start() {
    this.timestamp = Date.now();
  }
  stop() {
    return Date.now() - this.timestamp;
  }
}

export const PerformanceTracker = new PerformanceTrack();
