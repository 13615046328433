import NoPermission from '@/pages/no-permission/NoPermission';
import EntryContractProcess from '@/pages/hro/entry-contract-process/EntryContractProcess';
import EmployeeEntry from '@/pages/employee/employee-entry/EmployeeEntry';
import RootElement from '@/pages/root-element/RootElement';
import { RouterObjectExtend } from '@/models/common';
import EmployeeInfoEdit from '@/pages/employee/employee-info-edit/EmployeeInfoEdit';
import EmployeeMaterialsUpload from '@/pages/employee/employee-materials-upload/EmployeeMaterialsUpload';
import PreviewCheck from '@/pages/hro/preview-check/PreviewCheck';
import { Navigate, Outlet } from 'react-router-dom';
import MyContract from '@/pages/employee/my-contract/MyContract';
import CertificationApply from '@/pages/employee/my-certification/apply-certification/CertificationApply';
import ApplicationHistory from '@/pages/employee/my-certification/application-history/ApplicationHistory';
import IssueCertificate from '@/pages/hro/issue-certificate/IssueCertificate';
import ContractExtensionProcess from '@/pages/hro/contract-extension-process/ContractExtensionProcess';
import ContractExtensionList from '@/pages/hro/contract-extension-list/ContractExtensionList';
import {
  CERTIFICATE_READ_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_READ_PERMISSION,
  CONTRACT_EXTENSION_WILLINGNESS_EDIT_PERMISSION,
  CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION,
  CONTRACT_INDUCTION_READ_PERMISSION,
  EXIT_APPROVAL_LIST_READ_PERMISSION,
  EXIT_HISTORY_READ,
  EXIT_LIST_READ_PERMISSION,
  EXIT_SIGNING_LIST_READ_PERMISSION,
} from '@/constants/permission';
import ExitProcess from '@/pages/hro/exit-process/ExitProcess';
import { CONTRACT_EXTENSION_TAB_STATUS, EMPLOYEE_TAB_STATUS } from '@/constants/status';
import ExitInformation from '@/pages/employee/exit-information/ExitInformation';
import EmployeeExitApproval from '@/pages/employee/employee-exit-approval/EmployeeExitApproval';

export const routerConfig: RouterObjectExtend[] = ([
  {
    path: '/',
    element: <RootElement/>,
    children: [
      {
        path: 'hro-home',
        element: <Outlet/>,
        matomoPageTitle: [ 'Hro Home' ],
        auth: [ CONTRACT_INDUCTION_READ_PERMISSION ],
        title: '入职办理',
        searchParams: [ 'tab' ],
        children: [
          {
            path: '',
            element: <EntryContractProcess/>,
            auth: [ CONTRACT_INDUCTION_READ_PERMISSION ]
          },
          {
            path: 'entry-contract-review/:employeeId/:processId',
            element: <PreviewCheck showAuditButton/>,
            hasBreadCrumb: true,
            auth: [ CONTRACT_INDUCTION_READ_PERMISSION ],
            title: '合同审核',
            searchParams: [ 'tab' ],
            tabParam: EMPLOYEE_TAB_STATUS.SIGNATURE
          },
          {
            path: 'contract-preview/:employeeId/:processId',
            hasBreadCrumb: true,
            title: '合同预览',
            auth: [ CONTRACT_INDUCTION_READ_PERMISSION ],
            element: <PreviewCheck showWillingnessConfirmAttachments/>,
            searchParams: [ 'tab' ],
          }
        ]
      },
      {
        path: 'issue-certification',
        element: <IssueCertificate/>,
        matomoPageTitle: [ 'Issue Certification' ],
        auth: [ CERTIFICATE_READ_PERMISSION ],
        title: '开具证明',
      },
      {
        path: 'contract-extension-home',
        element: <Outlet/>,
        title: '续签办理',
        matomoPageTitle: [ 'Contract Extension List', 'Contract Extension Process' ],
        auth: [ CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION, CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ],
        children: [
          {
            path: 'contract-extension-list',
            title: '续签名单',
            element: <ContractExtensionList/>,
            auth: [ CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION ],
          },
          {
            path: 'contract-extension-process',
            title: '续签流程',
            element: <Outlet/>,
            auth: [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION, CONTRACT_EXTENSION_WILLINGNESS_EDIT_PERMISSION ],
            searchParams: [ 'tab' ],
            children: [
              {
                path: '',
                element: <ContractExtensionProcess/>,
                auth: [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION, CONTRACT_EXTENSION_WILLINGNESS_EDIT_PERMISSION ],
                searchParams: [ 'tab' ],
              },
              {
                path: 'contract-preview/:employeeId/:processId',
                hasBreadCrumb: true,
                title: '合同预览',
                auth: [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ],
                element: <PreviewCheck showWillingnessConfirmAttachments/>,
                searchParams: [ 'tab' ],
                tabParam: EMPLOYEE_TAB_STATUS.FINISHED
              }
            ]
          },
          {
            path: 'contract-extension-review/:employeeId/:processId',
            element: <PreviewCheck showAuditButton/>,
            hasBreadCrumb: true,
            auth: [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ],
            title: '合同审核',
            searchParams: [ 'tab' ],
            tabParam: EMPLOYEE_TAB_STATUS.SIGNATURE
          },
          {
            path: 'notification-review/:employeeId/:processId',
            element: <PreviewCheck showAuditButton/>,
            hasBreadCrumb: true,
            auth: [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ],
            title: '通知书审核',
            searchParams: [ 'tab' ],
            tabParam: CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION
          },
        ]
      },
      {
        path: 'exit-process',
        element: <Outlet/>,
        matomoPageTitle: [ 'Exit Process' ],
        title: '离职办理',
        auth: [ EXIT_LIST_READ_PERMISSION, EXIT_APPROVAL_LIST_READ_PERMISSION, EXIT_SIGNING_LIST_READ_PERMISSION, EXIT_HISTORY_READ ],
        searchParams: [ 'tab' ],
        children: [
          {
            path: '',
            element: <ExitProcess/>,
            auth: [ EXIT_LIST_READ_PERMISSION, EXIT_APPROVAL_LIST_READ_PERMISSION, EXIT_SIGNING_LIST_READ_PERMISSION, EXIT_HISTORY_READ ],
          },
          {
            path: 'contract-preview/:employeeId/:processId',
            hasBreadCrumb: true,
            title: '合同预览',
            auth: [ EXIT_HISTORY_READ ],
            element: <PreviewCheck showWillingnessConfirmAttachments/>,
            searchParams: [ 'tab' ],
          }
        ]
      },
      {
        path: 'my-contract',
        element: <MyContract/>,
        matomoPageTitle: [ 'My Contract' ],
      },
      {
        path: 'my-certification',
        element: <Outlet/>,
        matomoPageTitle: [ 'Apply Certification', 'Application History' ],
        title: '我的证明',
        breadCrumbNotClickable: true,
        children: [
          {
            index: true,
            element: <Navigate to="/my-certification/apply-certification" replace/>
          },
          {
            path: 'apply-certification',
            element: <CertificationApply/>,
            title: '开具证明',
            hasBreadCrumb: true
          },
          {
            path: 'application-history',
            element: <ApplicationHistory/>,
            title: '开具历史',
            hasBreadCrumb: true
          }
        ]
      },
      {
        path: 'employee-home',
        element: <Outlet/>,
        matomoPageTitle: [
          'Employee Home',
          'Upload Personal Info',
          'Edit Personal Info',
          'Upload Hire Material',
          'Edit Hire Material'
        ],
        title: '待操作事项',
        children: [
          {
            path: '',
            element: <EmployeeEntry/>,
          },
          {
            path: 'upload-personal-info/:processId',
            element: <EmployeeInfoEdit/>,
            title: '完善员工信息',
            hasBreadCrumb: true
          },
          {
            path: 'edit-personal-info/:processId',
            element: <EmployeeInfoEdit isEdit/>,
            title: '更改员工信息',
            hasBreadCrumb: true
          },
          {
            path: 'update-personal-info/:processId',
            element: <EmployeeInfoEdit isEdit/>,
            title: '填写员工信息',
            hasBreadCrumb: true
          },
          {
            path: 'upload-hire-material/:processId',
            element: <EmployeeMaterialsUpload/>,
            title: '上传入职材料',
            hasBreadCrumb: true
          },
          {
            path: 'edit-hire-material/:processId',
            element: <EmployeeMaterialsUpload/>,
            title: '更改入职材料',
            hasBreadCrumb: true
          },
          {
            path: 'upload-personal-material/:processId',
            element: <EmployeeMaterialsUpload/>,
            title: '上传员工材料',
            hasBreadCrumb: true
          },
          {
            path: 'upload-personal-exit-information/:processId',
            element: <ExitInformation/>,
            title: '填写离职信息',
            hasBreadCrumb: true
          },
          {
            path: 'enter-exit-approval/:processId',
            element: <EmployeeExitApproval/>,
            title: '进入离职流转',
            hasBreadCrumb: true
          }
        ]
      }
    ]
  },
  {
    path: '/no-permission',
    element: <NoPermission/>,
  },
  {
    path: '*',
    element: <Navigate to="/"/>
  }
] as RouterObjectExtend[]);
