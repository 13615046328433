import { requiredMessage } from '@/constants/error';
import { Input, InputNumber } from 'antd';
import { validateIdNumber, validateMobileRegex } from '@/utils/validateUtils';
import { currencyFormat } from '@/utils/commonUtils';
import { EMPLOYMENT_TYPE } from '@/constants/employee';
import { IContractExtensionInformationResponse, IOption, Options } from '@/models/employee';
import { Rule } from 'rc-field-form/lib/interface';
import { ReactElement } from 'react';
import CustomSelect from '@/components/custom-select/CustomSelect';
import CustomDatePicker from '@/components/custom-date-picker/CustomDatePicker';

interface ComponentProps {
  optionKey?: string;
  showSearch?: boolean;
  optionFilter?: (options: IOption[], formValues: IContractExtensionInformationResponse) => IOption[];
}

interface FieldComponentFun<T> {
  (config: T, options:Options, getFormFieldsValue: () => IContractExtensionInformationResponse): ReactElement;
}

export interface ContractExtensionFieldConfig {
  colSpan: number;
  name: string;
  label: string;
  rules: Rule[];
  component: ReactElement | FieldComponentFun<ContractExtensionFieldConfig>
  componentProps?: ComponentProps
  visibleCondition?: (formValues: IContractExtensionInformationResponse) => boolean;
  validateTrigger?: string | string[];
}

const SALE_POSITIONS = [ 'Business Development Manager', 'Client Principal', 'Sales Lead' ];
const payFieldVisibleCondition = formValues => {
  return SALE_POSITIONS.includes(formValues.position);
};

const isEmployeeType = formValues => formValues.employmentType === EMPLOYMENT_TYPE.EMPLOYEE;

export const fieldValidatorConfig: Array<ContractExtensionFieldConfig[]> = (
  [
    [
      {
        colSpan: 8,
        name: 'legalFullName',
        label: 'Legal Name',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100}/>
      },
      {
        colSpan: 8,
        name: 'chineseName',
        label: '姓名',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100}/>
      }
    ],
    [
      {
        colSpan: 12,
        name: 'position',
        label: 'Position',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'positionOptions',
          optionFilter: (options, formValues) => {
            return isEmployeeType(formValues) ? options : options.filter(option => !SALE_POSITIONS.includes(option.value as string));
          },
        },
      },
      {
        colSpan: 8,
        name: 'positionCn',
        label: '岗位',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'positionCnOptions'
        }
      },
    ],
    [
      {
        colSpan: 8,
        name: 'hireDate',
        label: '入职日期',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker()
      },
      {
        colSpan: 8,
        name: 'homeOffice',
        label: 'Home Office',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'homeOfficeOptions'
        }
      },
      {
        colSpan: 8,
        name: 'homeOffice',
        label: '办公地点',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'homeOfficeCnOptions'
        }
      },
    ],
    [
      {
        colSpan: 8,
        name: 'nationId',
        label: 'Nationality',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'nationOptions'
        }
      },
      {
        colSpan: 8,
        name: 'nationId',
        label: '国籍',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'nationCnOptions'
        }
      },
    ],
    [
      {
        colSpan: 8,
        name: 'idType',
        label: '证件类型',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomSelect,
        componentProps: {
          optionKey: 'idTypesOptions'
        }
      },
      {
        colSpan: 8,
        name: 'idNumber',
        label: '证件号码',
        rules: [ { required: true, message: requiredMessage }, validateIdNumber ],
        validateTrigger: 'onBlur',
        component: <Input maxLength={100}/>
      },
      {
        colSpan: 8,
        name: 'phoneNumber',
        label: '手机号码',
        rules: [ { required: true, message: requiredMessage }, validateMobileRegex ],
        component: <Input maxLength={100}/>
      },
    ],
    [
      {
        colSpan: 4,
        name: 'currency',
        label: 'Currency',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
        visibleCondition: isEmployeeType
      },
      {
        colSpan: 5,
        name: 'annualSalary',
        label: 'Annual Salary',
        rules: [ { required: true, message: requiredMessage } ],
        component: <InputNumber
          disabled={true}
          min={0}
          controls={false}
          precision={2}
          formatter={currencyFormat}
        />,
        visibleCondition: isEmployeeType
      },
      {
        colSpan: 4,
        name: 'currencyCn',
        label: '货币',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
        visibleCondition: isEmployeeType
      },
      {
        colSpan: 11,
        name: 'annualSalaryCn',
        label: '年薪',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
        visibleCondition: isEmployeeType
      },
    ],
    [
      {
        colSpan: 4,
        name: 'basePay',
        label: 'Base Pay',
        rules: [ { required: true, message: requiredMessage } ],
        component: <InputNumber
          disabled={true}
          min={0}
          controls={false}
          precision={2}
          formatter={currencyFormat}
        />,
        visibleCondition: payFieldVisibleCondition
      },
      {
        colSpan: 11,
        name: 'basePayCn',
        label: '基本薪资',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
        visibleCondition: payFieldVisibleCondition
      },
    ],
    [
      {
        colSpan: 4,
        name: 'variablePay',
        label: 'Variable Pay',
        rules: [ { required: true, message: requiredMessage } ],
        component: <InputNumber
          disabled={true}
          min={0}
          controls={false}
          precision={2}
          formatter={currencyFormat}
        />,
        visibleCondition: payFieldVisibleCondition
      },
      {
        colSpan: 11,
        name: 'variablePayCn',
        label: '浮动薪资',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
        visibleCondition: payFieldVisibleCondition
      },
    ],
    [
      {
        colSpan: 12,
        name: 'homeAddress',
        label: 'Home Address',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input.TextArea showCount maxLength={180}/>
      },
      {
        colSpan: 12,
        name: 'homeAddressCn',
        label: '居住地址',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input.TextArea showCount maxLength={90}/>
      }
    ],
    [
      {
        colSpan: 8,
        name: 'latestReplyDate',
        label: '员工回复确认是否接受续签时间',
        rules: [ { required: true, message: requiredMessage } ],
        component: CustomDatePicker()
      }
    ]
  ]
);
