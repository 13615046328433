import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useState } from 'react';
import { alertErrorMessage } from '@/utils/fileUpload';
import { uploadWillingConfirmAttachments } from '@/api/employeeApi';
import './UploadAttachment.scss';
import { avatarValidator } from '@/pages/employee/employee-materials-upload/EmployeeMaterialsConfig';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

interface IUploadProps {
  employeeId: string;
  onChange: (...arg) => void;
}

const UploadAttachment = ({ employeeId, onChange }:IUploadProps) => {
  const [ previewOpen, setPreviewOpen ] = useState(false);
  const [ previewImage, setPreviewImage ] = useState('');
  const [ fileList, setFileList ] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadProps: UploadProps = {
    accept: 'image/png,image/jpeg',
    maxCount: 3,
    listType: 'picture-card',
    beforeUpload: file => {
      const validateResult = avatarValidator(file, 0, 3);
      if (validateResult !== true) {
        return validateResult;
      }
    },
    customRequest: ({ file, onError, onProgress, onSuccess }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileType', 'willingConfirmAttachment');
      formData.append('employeeId', employeeId);
      onProgress({ percent: 0 });
      uploadWillingConfirmAttachments(formData, onProgress)
        .then(fileInfo => {
          onProgress({ percent: 100 });
          onSuccess(fileInfo);
        }, err => {
          err.message = '上传失败';
          onError(err);
          alertErrorMessage(err);
        });
    },
    onChange: ({ file, fileList: newFileList }) => {
      setFileList(newFileList);
      if (file.status !== 'uploading') {
        const successList = newFileList.filter(file => file.response && file.status === 'done');
        onChange(successList);
      }
    },
  };

  return (
    <>
      <Upload
        {...uploadProps}
        onPreview={handlePreview}
        fileList={fileList}
        data-testid='upload-component'
      >
        {fileList.length >= 3 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title='预览附件' footer={null} onCancel={handleCancel}>
        <img alt="attachment" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadAttachment;
