import {
  ICertificateFile,
  ICertificateProcessStatusInfoResponse,
  ICertificateRejectRequest,
  IIssueCertificateResponse
} from '@/models/employee';
import axiosApi from '@/api/axiosApi';

export const getIssueCertificateInfo = (status: string): Promise<IIssueCertificateResponse> => {
  return axiosApi.get(`/api/certificate/issue-certificate/${status}`);
};

export const getHroStampUrl = (employeeId: string, processId: string): Promise<string> => {
  return axiosApi.get('/api/certificate/stampUrl', {
    params: { employeeId, processId },
  });
};

export const rejectCertificate = (rejectRequest: ICertificateRejectRequest): Promise<void> => {
  return axiosApi.post('/api/certificate/reject', rejectRequest);
};

export const getUserCertificateFile = (certificateType: string): Promise<ICertificateFile> => {
  return axiosApi.get(
    `/api/certificate/generation/${certificateType}`);
};

export const updateUserCertificate = (certificateFiles: ICertificateFile): Promise<void> => {
  return axiosApi.post('/api/certificate/review', certificateFiles);
};

export const getCertificateProcessStatusInfo = (): Promise<ICertificateProcessStatusInfoResponse> => {
  return axiosApi.get('/api/certificate/history');
};