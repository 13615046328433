import { useCustomMatomo } from '@/matomo';
import { initiateEmployeeSignProcess, initiateExitContractInvoluntarySignProcess } from '@/api/employeeApi';
import { Button, message, Popconfirm } from 'antd';
import { EMPLOYEE_CONTRACT_SIGN_STATUS } from '@/constants/employee';
import { isEmpty } from 'lodash-es';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { useCallback } from 'react';
import { EXIT_SIGN_PROCESS_STATUS } from '@/constants/status';

interface ButtonWithSignProps {
    employeeId: string;
    employeeSigningStatus: string;
    employeeSigningProcessId: string;
    update: () => void;
    popupContainerId?: string;
    hasPermission: boolean;
}


const ButtonWithSign = (props: ButtonWithSignProps) => {
  const { employeeId, employeeSigningStatus, update, employeeSigningProcessId, popupContainerId, hasPermission } = props;
  const hasButtonPermission = hasPermission;
  const { trackEvent } = useCustomMatomo();

  const confirmSubmit = () => {
    trackEvent({
      category: MATOMO_CATEGORY.HroInductionToBeSigned,
      action: `${MATOMO_ACTION.Confirm} ${MATOMO_ACTION.Initiate}`,
      name: MATOMO_NAME.Signing,
    });

    let promise;
    if (employeeSigningStatus === EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_INITIATE_SIGNING) {
      promise = initiateExitContractInvoluntarySignProcess(employeeId, employeeSigningProcessId);
    } else {
      promise = initiateEmployeeSignProcess(employeeId, employeeSigningProcessId);
    }

    promise.then(() => {
      update();
      message.success('已成功发起签署');
    }, () => {
      message.error('发起签署失败，请重试');
    });
  };

  const getButtonText = () => {
    return employeeSigningStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_EMPLOYEE_SIGNING ? '已发起签署' : '发起签署';
  };

  const disabled = useCallback(() => {
    const legalProcessStatusList: string[] = [
      EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_INITIATE,
      EXIT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE_SIGNING,
      EXIT_SIGN_PROCESS_STATUS.WAITING_HRBP_FH_INITIATE_SIGNING,
    ].map(processStatus => processStatus.toString());

    const canUse = hasButtonPermission && !isEmpty(employeeSigningStatus) && legalProcessStatusList.includes(employeeSigningStatus);
    return !canUse;
  }, [ hasButtonPermission, employeeSigningStatus ]);

  const cancelConfirm = () => trackEvent({
    category: MATOMO_CATEGORY.HroInductionToBeSigned,
    action: `${MATOMO_ACTION.Cancel} ${MATOMO_ACTION.Confirm} ${MATOMO_ACTION.Initiate}`,
    name: MATOMO_NAME.Signing,
  });

  const handleClickSign = () => trackEvent({
    category: MATOMO_CATEGORY.HroInductionToBeSigned,
    action: MATOMO_ACTION.Initiate,
    name: MATOMO_NAME.Signing,
  });

  return (
    <Popconfirm
      title="确认对该成员发起签署？"
      onCancel={cancelConfirm}
      onConfirm={confirmSubmit}
      cancelText={'取消'}
      okText={'确认'}
      disabled={disabled()}
      placement="bottomRight"
      overlayStyle={{ width: 258 }}
      getPopupContainer={triggerNode => popupContainerId ? document.getElementById(popupContainerId) : triggerNode}>
      <Button size="small" disabled={disabled()} onClick={handleClickSign}>{getButtonText()}</Button>
    </Popconfirm>
  );
};

export default ButtonWithSign;
