import { useEffect, useMemo } from 'react';
import { filter, find, map } from 'lodash-es';
import { matchRoutes, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserMenu, selectCurrentUserRole, selectUserInfo, setCurrentUserRole } from '@/store/userInfo';
import { ROLE_USER } from '@/constants/role';
import { routerConfig } from '@/router';
import { getAdminCurrentUserRole, isUserHasRoutePermission, isUserOnlyHasUserRole } from '@/utils/authUtils';
import { AppDispatch } from '@/models/store';

export const useRouterGuard = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(selectUserInfo);
  const currentUserRole = useSelector(selectCurrentUserRole);
  const roles = map(userInfo?.roles, 'name');

  const matchedRoutes = useMemo(
    () => {
      return filter(matchRoutes(routerConfig, location), item => item.pathname !== '/');
    },
    [ location.pathname ]
  );

  const matchedCurrentRoute = useMemo(
    () => find(matchedRoutes, route => location.pathname === route.pathname),
    [ matchedRoutes, location.pathname ]
  );

  const hasPermission = useMemo(() => {
    return isUserHasRoutePermission(userInfo, matchedCurrentRoute?.route);
  }, [ userInfo, location, matchedCurrentRoute ]);

  const menuConfigs = useSelector(selectCurrentUserMenu, shallowEqual);

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    if (!currentUserRole && !!userInfo) {
      const userRole = isUserOnlyHasUserRole(userInfo)
        ? ROLE_USER
        : getAdminCurrentUserRole(userInfo, matchedRoutes, matchedCurrentRoute);
      dispatch(setCurrentUserRole(userRole));
    }

  }, [ location.pathname, currentUserRole, userInfo ]);

  return { location, matchedRoutes, roles, menuConfigs, matchedCurrentRoute, hasPermission };
};
