import Icon from '@ant-design/icons';
import InfoCircleFillSvg from '@/assets/icons/info-circle-fill.svg';
import './CustomToolTipTitle.scss';

const CustomToolTipTitle = (comment: string) => {
  return <div className="custom-tooltip-title">
    <Icon className="alert-icon" component={InfoCircleFillSvg}/>
    <p className="tooltip-title-content">{comment}</p>
  </div>;
};
export default CustomToolTipTitle;