import { Drawer } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import './ExitApprovalProcessDrawer.scss';
import { EXIT_APPROVAL_PROCESS_STATUS } from '@/constants/status';
import { getExitApprovalProcessInfo } from '@/api/exitApi';

interface IExitApprovalProcessDrawerProps {
  title?: string;
  employeeId: string;
  drawerOpen: boolean;
  closeDrawer: () => void;
}

const ExitApprovalProcessDrawer = ({
  title, employeeId, drawerOpen, closeDrawer
}: IExitApprovalProcessDrawerProps) => {
  const [ processContent, setProcessContent ] = useState(null);

  useEffect(() => {
    setProcessContent(null);
    if (drawerOpen) {
      getExitApprovalProcessInfo(employeeId).then(response => {
        setProcessContent(response);
      });
    }
  }, [ drawerOpen ]);

  const handleOnClose = () => {
    closeDrawer();
  };

  const renderDrawerTitle = () => (
    <div className="drawer-title">
      <span className="headline4 title">{title ? title : '流转进度'}</span>
      <button className="drawer-close" onClick={handleOnClose}>确认</button>
    </div>
  );

  const processItems = [
    {
      title: '员工个人守关',
      taskName: 'EXIT_APPROVAL_EMPLOYEE',
    },
    {
      title: '业务守关',
      taskName: 'EXIT_APPROVAL_BUSINESS_TEAM',
    },
    {
      title: 'Workspace守关',
      taskName: 'EXIT_APPROVAL_WORKSPACE',
    },
    {
      title: 'Admin守关',
      taskName: 'EXIT_APPROVAL_ADMIN',
    },
    {
      title: '员工假期数据确认',
      taskName: 'EXIT_APPROVAL_LEAVE_HRO',
    },
    {
      title: 'Home Office People Support守关',
      taskName: 'EXIT_APPROVAL_OFFICE_HRO',
    } ];

  return (
    <Drawer
      width={460}
      title={renderDrawerTitle()}
      placement="right"
      closable={true}
      open={drawerOpen}
      closeIcon={false}
      destroyOnClose={true}
      style={{ marginTop: 64 }}
      onClose={handleOnClose}
    >
      {
        processContent && processItems.map(items => (
          <div key={items.taskName}>
            <div className="approval-drawer-content" >
              <div className="content-left">
                <div className="circle"
                  style={{
                    border: processContent[items.taskName]?.processStatus === EXIT_APPROVAL_PROCESS_STATUS.APPROVED
                      ? '2px solid #6b9e78' : '2px solid #f2617a'
                  }}/>
                <p className="vertical-line"/>
              </div>
              <div className="content-right">
                <p>{items.title}：{processContent[items.taskName]?.processStatus === EXIT_APPROVAL_PROCESS_STATUS.APPROVED
                  ? '已通过' : '暂未通过'}</p>
                <p>对接人：{processContent[items.taskName]?.interfacePerson || '暂无'}</p>
                <p>审核人：{processContent[items.taskName]?.reviewPerson || '暂无'}</p>
                <p>操作时间：{processContent[items.taskName]?.updatedAt || '暂无'}</p>
              </div>
            </div>
          </div>
        ))
      }
    </Drawer>
  );
};

export default ExitApprovalProcessDrawer;
