import Icon, { PlusOutlined } from '@ant-design/icons';
import { MONTH_FORMAT } from '@/constants/employee';
import { RequiredRule } from '@/constants/validation';
import { IOption } from '@/models/employee';
import { AppDispatch } from '@/models/store';
import { fetchCompanyIndustryOptions, fetchNationOptions, selectCompanyIndustryOptions, selectNationOptions } from '@/store/employee';
import { filterSelectOptions } from '@/utils/commonUtils';
import { Button, Col, DatePicker, Empty, Form, Input, Radio, Row, Select } from 'antd';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import EmployeeFormLabel from '../employee-form-label/EmployeeFormLabel';
import RemoveSvg from '@/assets/icons/remove.svg';
import { DEFAULT_WORK_EXPERIENCE_ITEM } from './WorkInfoUtil';
import { flushSync } from 'react-dom';
import { forEach, map } from 'lodash-es';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';

const WORK_EXPERIENCE_LABEL = {
  0: '最近一份工作 (Most Recent Job)',
  1: '最近第二份工作 (The Second Most Recent Job)',
  2: '最近第三份工作 (The Third Most Recent Job)',
  3: '其他工作经历 (Other Work Experience)',
  4: '其他工作经历 (Other Work Experience)',
  5: '其他工作经历 (Other Work Experience)',
  6: '其他工作经历 (Other Work Experience)',
  7: '其他工作经历 (Other Work Experience)',
  8: '其他工作经历 (Other Work Experience)',
  9: '其他工作经历 (Other Work Experience)',
};
const COMPANY_NAME = <span className="company-name-tip">公司名称一般有四部分组成：行政区域+字号+行业特点+组织形式，示例：思特沃克软件技术（北京）有限公司</span>;

const WorkInfo = () => {
  const nations: IOption[] = useSelector(selectNationOptions);
  const form = Form.useFormInstance();
  const companyIndustries: IOption[] = useSelector(selectCompanyIndustryOptions);
  const { trackEvent } = useCustomMatomo();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchNationOptions());
    dispatch(fetchCompanyIndustryOptions());
  }, []);
  const addItem = useCallback((add, newItem) => {
    add(newItem);
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Add,
      name: MATOMO_NAME.WorkExperience,
    });
  }, []);
  const removeItem = useCallback((remove, field) => {
    flushSync(() => {
      remove(field.name);
    });
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Remove,
      name: MATOMO_NAME.WorkExperience,
    });
    const values = form.getFieldValue([ 'workExperienceInfo', 'workExperienceInfoItems' ]);
    const workInfoKeys = [ 'companyIndustryId', 'companyName', 'dateRange', 'nationId', 'position', 'worldTopFiveHundred' ];
    forEach(values, (_, index) => {
      form.validateFields(map(workInfoKeys, key => [ 'workExperienceInfo', 'workExperienceInfoItems', index, key ]));
    });
  }, []);
  return (
    <>
      <div className="form-group job-numbers">
        <Form.Item
          name={[ 'workExperienceInfo', 'numberOfWorkExperience' ]}
          label={<EmployeeFormLabel label="您之前从事过几份全职工作？" bracketSubLabel="Number of full time jobs"/>}
          rules={[ RequiredRule ]}
        >
          <Radio.Group size="large">
            <Radio.Button value={0}>0</Radio.Button>
            <Radio.Button value={1}>1</Radio.Button>
            <Radio.Button value={2}>2</Radio.Button>
            <Radio.Button value={3}>3+</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <p className="item-desc">实习经历无需填写 (Internship experience is excluded)</p>
      </div>
      <Form.List name={[ 'workExperienceInfo', 'workExperienceInfoItems' ]}>
        {(fields, { add, remove }) => fields.map(field => (
          <div key={field.key}>
            {fields.length >= 3 && field.name === 0 &&
                <div className="work-experience-group-title">最近三段工作经历 (Three of Most Recent Work Experience)</div>}
            <div className="form-group work-experience-item">
              <label className="group-title">
                {fields.length === 1 ? '' : `${field.name + 1}. `}{WORK_EXPERIENCE_LABEL[field.name]}
                {field.name > 2 && <Icon onClick={removeItem.bind(null, remove, field)} className="remove-icon" component={RemoveSvg}/>}
              </label>
              <Row gutter={8}>
                <Col span={9}>
                  <Form.Item name={[ field.name, 'companyName' ]}
                    label={<EmployeeFormLabel label="公司名称" bracketSubLabel="Company Name" tip={COMPANY_NAME}/>} rules={[ RequiredRule ]}>
                    <Input maxLength={100} placeholder="请填写企业官方名称" />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  {companyIndustries &&
                      <Form.Item name={[ field.name, 'companyIndustryId' ]}
                        label={<EmployeeFormLabel label="公司性质" bracketSubLabel="Company Industry"/>} rules={[ RequiredRule ]}>
                        <Select
                          showSearch
                          filterOption={filterSelectOptions}
                          getPopupContainer={triggerNode => triggerNode}
                          notFoundContent={<Empty description={'暂无数据'}/>}
                        >
                          {companyIndustries.map(option => (
                            <Select.Option key={option.value as string} value={option.value}>{option.text}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                  }
                </Col>
                <Col span={6}>
                  {nations &&
                      <Form.Item name={[ field.name, 'nationId' ]}
                        label={<EmployeeFormLabel label="工作地点" bracketSubLabel="Work Location"/>} rules={[ RequiredRule ]}>
                        <Select
                          showSearch
                          filterOption={filterSelectOptions}
                          getPopupContainer={triggerNode => triggerNode}
                          notFoundContent={<Empty description={'暂无数据'}/>}
                        >
                          {nations.map(option => (
                            <Select.Option key={option.value as string} value={option.value}>{option.text}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                  }
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={9}>
                  <Form.Item name={[ field.name, 'position' ]}
                    label={<EmployeeFormLabel label="岗位名称" bracketSubLabel="Position" />} rules={[ RequiredRule ]}>
                    <Input maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item name={[ field.name, 'dateRange' ]}
                    label={<EmployeeFormLabel label="在职时间" bracketSubLabel="Start Date - End Date" />} rules={[ RequiredRule ]}>
                    <DatePicker.RangePicker getPopupContainer={triggerNode => triggerNode} picker="month" format={MONTH_FORMAT}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="last-row" gutter={8}>
                <Col span={10}>
                  <Form.Item
                    name={[ field.name, 'worldTopFiveHundred' ]}
                    label={<EmployeeFormLabel label="是否为世界500强企业？" bracketSubLabel="is it a Foutune 500 Company?"/>}
                    rules={[ RequiredRule ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {field.name === 2 &&
                <div className="work-experience-group-title">
                  除以上经历的其他海外或世界500强企业工作经历 (Other Overseas Work Experience or Work at Fortune 500 Company)
                </div>}
            {field.name >= 2 && fields.length - 1 === field.name && (
              <Button
                type="dashed"
                style={{ marginBottom: '24px' }}
                onClick={() => addItem(add, { ...DEFAULT_WORK_EXPERIENCE_ITEM })}
                disabled={fields.length >= 10}
                className="add-item-btn"
                icon={<Icon component={PlusOutlined} />}>
                添加
              </Button>
            )}
          </div>
        ))}
      </Form.List>
    </>
  );
};

export default WorkInfo;
