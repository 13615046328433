import { Button, Form, FormListFieldData, Input, Select, Upload, UploadFile } from 'antd';
import './SkillInfo.scss';
import { RequiredRule } from '@/constants/validation';
import Icon, { PlusOutlined } from '@ant-design/icons';
import DeleteIcon from '@/assets/icons/remove.svg';
import UploadSvg from '@/assets/icons/upload.svg';
import { useCallback, useEffect, useState } from 'react';
import { getForeignLanguageOption, getSkillCertificateOption } from '@/api/employeeApi';
import { IOption, PersonalInfo } from '@/models/employee';
import EmployeeFormLabel from '@/pages/employee/employee-info-edit/employee-form-label/EmployeeFormLabel';
import { forEach, isEmpty, map } from 'lodash-es';
import { commonUploadConfig, generateFileName, getUploadProps, normFile } from '@/utils/fileUpload';
import { RcFile } from 'antd/lib/upload';
import { isCertificateMatchedFiles, validateUploadFiles } from '@/utils/validateUtils';
import { skillCertificateValidator } from '@/pages/employee/employee-info-edit/skill-info/SkillInfoUtil';
import { flushSync } from 'react-dom';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { useCustomMatomo } from '@/matomo';

const GITHUB_TIP = <div className="github-tip">登录 GitHub，点击右上角的个人头像，在弹出的下拉菜单中出现的 “Signed in as qwertyu”，“qwertyu”就是你的 GitHub ID</div>;

interface SkillInfoProps {
    personalInfo: PersonalInfo;
    processId: string;
}

const uploadProps = getUploadProps();

const SkillInfo = ({ personalInfo, processId }: SkillInfoProps) => {
  const [ certificateOption, setCertificateOption ] = useState<IOption[]>([]);
  const [ languageOption, setLanguageOption ] = useState<IOption[]>();
  const [ skillFileDisableStatus, setSkillFileDisableStatus ] = useState([]);
  const { trackEvent } = useCustomMatomo();

  const form = Form.useFormInstance();

  useEffect(() => {
    Promise.all([ getSkillCertificateOption(), getForeignLanguageOption() ])
      .then(([ certificateOption, languageOption ]) => {
        setCertificateOption(certificateOption);
        setLanguageOption(languageOption);
      });

    setSkillFileDisableStatus(
      map(form.getFieldValue('skillInfo')?.certificateInfos, info => isEmpty(info?.certificateName)));
        
    uploadProps.customRequest = args => {
      const customArgs = { ...args, data: { key: 'certificateAttachment', processId } };
      trackEvent({
        category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
        action: MATOMO_ACTION.Upload,
        name: MATOMO_NAME.CertificateAttachment,
      });
      commonUploadConfig.customRequest(customArgs);
    };
    uploadProps.onRemove = (file: UploadFile) => {
      trackEvent({
        category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
        action: MATOMO_ACTION.Remove,
        name: MATOMO_NAME.CertificateAttachment,
      });
      return commonUploadConfig.onRemove(file);
    };
  }, []);

  const addItem = add => {
    add();
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Add,
      name: MATOMO_NAME.TechnicalCertificate,
    });
    setSkillFileDisableStatus([
      ...skillFileDisableStatus,
      true
    ]);
  };

  const removeItem = useCallback((remove, field) => {
    flushSync(() => {
      remove(field.name);
      skillFileDisableStatus.splice(field.name, 1);
      setSkillFileDisableStatus([
        ...skillFileDisableStatus,
      ]);
    });
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Remove,
      name: MATOMO_NAME.TechnicalCertificate,
    });
    const values = form.getFieldsValue().skillInfo.certificateInfos;
    forEach(values, (_, index) => {
      form.validateFields([
        [ 'skillInfo', 'certificateInfos', index, 'certificateName' ],
        [ 'skillInfo', 'certificateInfos', index, 'attachments' ]
      ]);
    });
  }, [ skillFileDisableStatus ]);


  const renderSkillItems = () => {
    const getBeforeUpload = (file: RcFile, field: FormListFieldData, maxCount: number) => {
      const currentUploadData = form.getFieldValue([ 'skillInfo', 'certificateInfos', field.name ]);
      const fileType = currentUploadData?.certificateName;
      const fileLength = currentUploadData?.attachments?.length || 0;
      const validateResult = skillCertificateValidator(file, fileLength, maxCount);

      if (validateResult !== true) {
        return validateResult;
      }

      return new File([ file ], generateFileName(file, fileType, {
        employeeId: personalInfo.employeeId,
        legalFullName: personalInfo.legalFullName
      }));
    };

    const certificateChange = () => {
      const certificateInfos = form.getFieldValue([ 'skillInfo', 'certificateInfos' ]);
      setSkillFileDisableStatus(map(certificateInfos, item => isEmpty(item?.certificateName)));
    };

    return (
      <Form.List name={[ 'skillInfo', 'certificateInfos' ]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <div key={field.key} className="skill-block">
                <Form.Item name={[ field.name, 'certificateName' ]}
                  label={<EmployeeFormLabel label="证书名称" bracketSubLabel="Certificate Name"/>}
                  rules={[ RequiredRule, isCertificateMatchedFiles(field.name) ]}>
                  <Select
                    showSearch
                    style={{ width: '328px' }}
                    options={certificateOption}
                    getPopupContainer={triggerNode => triggerNode}
                    onChange={certificateChange}/>
                </Form.Item>
                <Form.Item
                  {...field}
                  name={[ field.name, 'attachments' ]}
                  className="file-upload-item"
                  label={<EmployeeFormLabel label="证书附件" bracketSubLabel="Certificate Attachment"/>}
                  htmlFor=""
                  valuePropName="filelist"
                  required
                  getValueFromEvent={normFile}
                  rules={[ validateUploadFiles ]}>
                  <Upload
                    {...uploadProps}
                    onChange={() =>  form.validateFields([ [ 'skillInfo', 'certificateInfos', field.name, 'certificateName' ] ])}
                    defaultFileList={form.getFieldValue([ 'skillInfo', 'certificateInfos', field.name, 'attachments' ])}
                    beforeUpload={file => getBeforeUpload(file, field, uploadProps.maxCount)}>
                    <Button disabled={skillFileDisableStatus[field.name]} size="small"
                      icon={<Icon className="upload-icon"
                        component={UploadSvg}/>}>上传文件</Button>
                    <span className="file-size-limit" onClick={e => e.stopPropagation()}>
                      文件数限制 (Max files) {uploadProps.maxCount}；单个文件大小 (Max File Size)：5M；文件格式 (File Type): PDF, JPEG, PNG
                    </span>
                  </Upload>
                </Form.Item>
                <Icon data-testid="remove-icon" className="remove-block-icon"
                  onClick={removeItem.bind(null, remove, field)} component={DeleteIcon} />
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => addItem(add)}
                disabled={fields.length === 7}
                className="add-item-btn"
                icon={<Icon component={PlusOutlined} />}>
                添加
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <>
      <section className="add-skills-section skill-info-section">
        <div className="add-skill-title">获得的技能证书(Certificate of Technical Qualifications)</div>
        {renderSkillItems()}
      </section>
      <section className="other-skills-section skill-info-section">
        <div className="add-skill-title">其他技能(Other Skills)</div>
        <Form.Item className="other-skill-item"
          name={[ 'skillInfo', 'foreignLanguage' ]}
          label={<EmployeeFormLabel label="熟练掌握其他外语情况 - 可多选" bracketSubLabel="Other Foreign Language - Multiple Selection"/>}
        >
          <Select
            showSearch
            getPopupContainer={triggerNode => triggerNode}
            mode="multiple"
            style={{ width: '557px' }}
            options={languageOption}/>
        </Form.Item>
        <Form.Item className="github-item"
          required
          name={[ 'skillInfo', 'githubId' ]}
          label={<EmployeeFormLabel
            label="个人Github ID"
            bracketSubLabel="Personal Github ID"
            tip={GITHUB_TIP}
          />}
          rules={[ RequiredRule ]}>
          <Input maxLength={100}/>
        </Form.Item>
        <p className="hint">如无,请填无(if none, please fill in none)</p>
      </section>
    </>
  );
};

export default SkillInfo;
