import { Button, Form, message, Select } from 'antd';
import EmployeeFormLabel from '@/pages/employee/employee-info-edit/employee-form-label/EmployeeFormLabel';
import { RequiredRule } from '@/constants/validation';
import './CertificationApply.scss';
import InfoCircleSvg from '@/assets/icons/info-circle.svg';
import Icon from '@ant-design/icons';
import {
  EMAIL_TO_PEOPLE_SUPPORT_CHINA,
  PERSONNEL_SYSTEM_OPERATION_MANUAL_ADDRESS,
  WORK_DAY_ADDRESS
} from '@/constants/common';
import { CERTIFICATE_TYPE_OPTIONS } from '@/constants/employeeOptions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmployeeHireInfo } from '@/api/employeeApi';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { ICertificateFile, IHireInfo } from '@/models/employee';
import { EMPLOYMENT_TYPE, MAINLAND_HOME_OFFICE } from '@/constants/employee';
import noOperationItemIcon from '@/assets/no-operation-item.svg';
import ImageDescription from '@/components/image-description/ImageDescription';
import CertificatePreviewer from '@/components/file-previewer/certificate-Previewer/CertificatePreviewer';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { getUserCertificateFile, updateUserCertificate } from '@/api/certificateApi';

const CertificationApply = () => {
  const [ form ] = Form.useForm();
  const userInfo = useSelector(selectUserInfo);

  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>(null);
  const isMainLandEmployee = employeeInfo?.employmentType === EMPLOYMENT_TYPE.EMPLOYEE &&
    MAINLAND_HOME_OFFICE.includes(employeeInfo?.homeOffice);
  const isLta = employeeInfo?.isLta;
  const [ showButton, setShowButton ] = useState(true);
  const [ preview, setPreview ] = useState(false);
  const [ certificateType, setCertificateType ] = useState<string>(CERTIFICATE_TYPE_OPTIONS[0].value);
  const [ certificateFile, setCertificateFile ] = useState<ICertificateFile>();
  const navigate = useNavigate();
  const { trackEvent } = useCustomMatomo();

  useEffect(() => {
    if (!userInfo?.user?.employeeId) {
      return;
    }
    const employeeId = userInfo?.user.employeeId;
    getEmployeeHireInfo(employeeId).then(employeeInfo => setEmployeeInfo(employeeInfo));
  }, [ userInfo ]);

  useEffect(() => {
    const antSelect = document.querySelector('.ant-select');
    if (antSelect) {
      antSelect.setAttribute('role', 'combobox');
      antSelect.setAttribute('aria-expanded', 'false');
      antSelect.setAttribute('aria-controls', 'owned_ant_select');
    }
    if (document.querySelector('.ant-select-selector')) {
      document.querySelector('.ant-select-selector').setAttribute('id', 'owned_ant_select');
    }
    if (document.querySelector('#apply-certification_certificateType')) {
      document.querySelector('#apply-certification_certificateType').setAttribute('aria-expanded', 'false');
    }
    if (document.querySelector('.ant-select-selection-item')) {
      document.querySelector('.ant-select-selection-item').setAttribute('id', 'apply-certification_certificateType_list_0');
    }
  }, [ employeeInfo ]);

  const showPreview = (certificateType: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.CertificationPreview,
      action: MATOMO_ACTION.Preview
    });
    getUserCertificateFile(certificateType).then(certificate => {
      setCertificateFile(certificate);
      setShowButton(false);
      setPreview(true);
    }).catch(error => {
      if (error.response.data && error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error('生成失败！请重新点击预览');
      }
    });

  };

  const getCertificateType = (value: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.CertificationType,
      action: MATOMO_ACTION.SelectOption,
    });
    setCertificateType(value);
    setPreview(false);
    setShowButton(true);
  };

  const cancelPreview = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.CertificationPreview,
      action: MATOMO_ACTION.Cancel,
    });
    setPreview(false);
    setShowButton(true);
  };

  const onSubmit = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.CertificationPreview,
      action: MATOMO_ACTION.Submit,
    });
    certificateFile.certificateTemplateCode = certificateType;
    updateUserCertificate(certificateFile).then(() => {
      navigate('/my-certification/application-history', { state: { isModalOpen: true } });
    }).catch(() => {
      message.error('提交失败！请重新点击提交');
    });
  };

  const clickManual = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.ManualAddress,
      action: MATOMO_ACTION.Link,
    });
  };

  const clickWorkday = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.Workday,
      action: MATOMO_ACTION.Link,
    });
  };

  const clickEmail = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeCertificateApply,
      name: MATOMO_NAME.EmailSupport,
      action: MATOMO_ACTION.Link,
    });
  };

  return (employeeInfo &&
    <>
      {isMainLandEmployee && !isLta
        ? <div className="apply-certification">
          <Form
            name="apply-certification"
            form={form}
            layout="vertical"
          >
            <div className="apply-certification-form">
              <div className="apply-certification-title">请选择需要开具的证明类型 (Please select a certificate type)</div>
              <div className="apply-certification-tip">
                <Icon className="info-icon" component={InfoCircleSvg} aria-label="Info-icon"/>
                <div className="employee-materials-tip-content">
                  <p>如证明中的个人信息（姓名/身份证号）有误，请参照
                    <a href={PERSONNEL_SYSTEM_OPERATION_MANUAL_ADDRESS} target="_blank" rel="noreferrer"
                      onClick={clickManual}>指导手册</a>登录
                    <a href={WORK_DAY_ADDRESS} target="_blank" rel="noreferrer" onClick={clickWorkday}>Workday
                    </a>自行修改后，重新提交证明申请。
                  </p>
                  <p>如有其他任何疑问，请联系
                    <a href={EMAIL_TO_PEOPLE_SUPPORT_CHINA} onClick={clickEmail}>peoplesupport-china@thoughtworks.com</a>。
                  </p>
                </div>
              </div>
              <Form.Item
                className="certificate-type"
                name="certificateType"
                wrapperCol={{ span: 16 }}
                label={<EmployeeFormLabel label="证明类型" bracketSubLabel="Certificate Type"/>}
                rules={[ RequiredRule ]}
                initialValue={{ value: CERTIFICATE_TYPE_OPTIONS[0].value, label: CERTIFICATE_TYPE_OPTIONS[0].label }}>
                <Select
                  options={CERTIFICATE_TYPE_OPTIONS}
                  onChange={getCertificateType}
                  onDropdownVisibleChange={isOpen => {
                    if (isOpen) {
                      trackEvent({
                        category: MATOMO_CATEGORY.EmployeeCertificateApply,
                        name: MATOMO_NAME.CertificationType,
                        action: MATOMO_ACTION.Click,
                      });
                    }
                  }}
                  getPopupContainer={triggerNode => triggerNode}/>
              </Form.Item>
            </div>
            {showButton &&
              <Form.Item>
                <Button type="primary" onClick={() => showPreview(certificateType)}>预览</Button>
              </Form.Item>}
          </Form>
          {preview &&
            <div className="preview-check-container">
              <div className="preview-check-content">
                <div className="employee-basic-info-container">
                  <div className="employee-basic-info">
                    <span className="employee-name show-ellipsis">{employeeInfo.legalFullName?.trim()}</span>
                    <span className="employee-id">{employeeInfo.employeeId}</span>
                    <span className="employee-info-item">{employeeInfo.homeOffice}</span>
                    <span className="employee-info-item">{employeeInfo.nationalityCn}</span>
                    <span className="employee-info-item">{employeeInfo.employmentType}</span>
                    <span className="employee-info-item">{employeeInfo.hireType}</span>
                    <span className="employee-info-item last-item">{employeeInfo.position}</span>
                  </div>
                  <div style={{ flex: 'none' }}>
                    <Button className="contract-sign-reject-button" onClick={cancelPreview}>取消</Button>
                    <Button type="primary" onClick={onSubmit}>提交审核</Button>
                  </div>
                </div>
                <div className="materials">
                  <CertificatePreviewer fileUrl={certificateFile.fileUrl}/>
                </div>
              </div>
            </div>}
        </div>
        : <div className="image-desc-container">
          <ImageDescription
            imgSrc={noOperationItemIcon}
            alt="Features under development"
            title="Ops…团队已经在加班加点的路上持续开发系统功能中，敬请期待"
            contactDesc="如果有证明需求或其他疑问，请联系"
            anchorHref={EMAIL_TO_PEOPLE_SUPPORT_CHINA}
            anchorDes=" peoplesupport-china@thoughtworks.com"/>
        </div>
      }
    </>
  );
};

export default CertificationApply;
