import { Badge, Button, Empty, message, Table, Tooltip } from 'antd';
import {
  IContractExtensionNotSignDetail,
  IContractExtensionNotSignInformationResponse,
  IContractExtensionNotSignResponse,
} from '@/models/employee';
import './WillNotExtensionPendingTable.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { noop, omit } from 'lodash-es';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  fetchContractExtensionNotSignEmployeeList,
  fetchOptions,
  selectContractExtensionNotSignData,
} from '@/store/employee';
import { AppDispatch } from '@/models/store';
import {
  CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_READ_PERMISSION,
} from '@/constants/permission';
import { CustomDrawer } from '@/components/custom-drawer/CustomDrawer';
import {
  contractTypeInfo,
} from '@/constants/employee';
import ButtonWithSign from '@/components/button-with-sign/ButtonWithSign';
import ButtonWithStamp from '@/components/button-with-stamp/ButtonWithStamp';
import { Link } from 'react-router-dom';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import {
  getContractExtensionNotSignEmployeeInformation,
  reviewedContractExtensionNotSignInformation,
} from '@/api/employeeApi';
import { PerformanceTracker, useCustomMatomo } from '@/matomo';
import moment from 'moment/moment';
import useForm from 'antd/lib/form/hooks/useForm';
import { DATE_FORMATTER } from '@/constants/date';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@/models/common';
import { ERROR_CODE } from '@/constants/error';
import { fieldValidatorConfig } from '@/constants/config/employee-form/will-not-extension-table/FieldValidatorConfig';
import { CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME } from '@/constants/processTaskName';
import { contractExtensionNotSignDetailColumns }
  from '@/constants/config/hro/contract-extension-process/will-not-extension-table/WillNotExtensionTableInfoConfig';
import { fetchContractExtensionEmployeeTotal } from '@/store/hroInfo';
import OperationItems from '@/pages/hro/operation-items/OperationItems';
import { CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS, CONTRACT_EXTENSION_TAB_STATUS } from '@/constants/status';

const WillNotExtensionPendingTable = () => {
  const hasContractExtensionEditPermission = useSelector(state =>
    isUserHasPermissions(state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ]));
  const hasContractExtensionReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ]));
  const { trackEvent } = useCustomMatomo();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ form ] = useForm();
  const [ employeeInfo, setEmployeeInfo ] = useState<IContractExtensionNotSignInformationResponse>();
  const [ popOpen, setPopOpen ] = useState(false);
  const [ contractNonExtensionProcessId, setContractNonExtensionProcessId ] = useState('');
  const [ isEmployeeNotSign, setIsEmployeeNotSign ] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const dataStoredInRedux = useSelector(selectContractExtensionNotSignData) as IContractExtensionNotSignResponse;
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
  };

  const contractExtensionNotSignEmployeeList = dataStoredInRedux.content.map(user => {
    return {
      ...user,
      contractNonExtensionProcessName: contractTypeInfo[user.contractNonExtensionProcessName]
    };
  });


  useEffect(() => {
    if (hasContractExtensionReadPermission) {
      dispatch(fetchContractExtensionNotSignEmployeeList({ page: 0, size: 20 }));
      dispatch(fetchOptions());
    }
  }, [ hasContractExtensionReadPermission ]);

  const displayPop = useCallback(() => {
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  }, []);

  const closePop = useCallback(() => {
    setPopOpen(false);
  }, []);

  const companyNotSignNoNeedFields = [
    'contractDuration',
    'startDate',
    'endDate',
    'latestReplyDate',
  ];
  const displayDrawer = useCallback((employeeId: string, contractNonExtensionProcessId: string,
    contractNonExtensionProcessName: string) => {
    trackEvent({
      category: MATOMO_CATEGORY.HroAuditInfoForNoExtension,
      action: MATOMO_ACTION.Preview,
      name: MATOMO_NAME.AuditInfo,
    });

    setContractNonExtensionProcessId(contractNonExtensionProcessId);
    const employeeNotSign = contractNonExtensionProcessName === contractTypeInfo[CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME];
    setIsEmployeeNotSign(employeeNotSign);
    getContractExtensionNotSignEmployeeInformation(employeeId).then(employeeInfo => {
      setDrawerOpen(true);
      PerformanceTracker.start();

      setEmployeeInfo(employeeInfo);
      if (employeeNotSign) {
        employeeInfo.contractExpirationDate = moment(employeeInfo.contractExpirationDate);
        employeeInfo.startDate = moment(employeeInfo.startDate);
        employeeInfo.endDate = moment(employeeInfo.endDate);
        employeeInfo.latestReplyDate = moment(employeeInfo.latestReplyDate);
      }
      employeeInfo.contractExpirationDate = moment(employeeInfo.contractExpirationDate);

      const data = employeeNotSign ? {
        ...employeeInfo,
        contractExpirationDate: moment(employeeInfo.contractExpirationDate),
        startDate: moment(employeeInfo.startDate),
        endDate: moment(employeeInfo.endDate),
        latestReplyDate: moment(employeeInfo.latestReplyDate),
      } : omit(employeeInfo, companyNotSignNoNeedFields);
      form.setFieldsValue(data);
    });
  }, []);

  const closeDrawer = useCallback(() => {
    trackEvent({
      category: MATOMO_CATEGORY.HroAuditInfoForNoExtension,
      action: MATOMO_ACTION.Cancel,
      name: MATOMO_NAME.AuditInfo,
    });
    setDrawerOpen(false);
    form.resetFields();
  }, []);

  const openContract = () => trackEvent({
    category: MATOMO_CATEGORY.HroAuditNoExtensionContract,
    action: MATOMO_ACTION.Preview,
    name: MATOMO_NAME.AuditContract,
  });

  const drawerConfirm = useCallback(() => {
    const formData = form.getFieldsValue(true);
    const data = isEmployeeNotSign ? {
      ...formData,
      contractExpirationDate: formData.contractExpirationDate.format(DATE_FORMATTER),
      startDate: formData.startDate.format(DATE_FORMATTER),
      endDate: formData.endDate.format(DATE_FORMATTER),
      latestReplyDate: formData.latestReplyDate.format(DATE_FORMATTER),
      contractNonExtensionProcessId
    } : {
      ...formData,
      contractExpirationDate: formData.contractExpirationDate.format(DATE_FORMATTER),
      contractNonExtensionProcessId
    };
    reviewedContractExtensionNotSignInformation(data).then(() => {
      closePop();
      setDrawerOpen(false);
      message.loading('员工信息审核处理中', 3);
      dispatch(fetchContractExtensionNotSignEmployeeList({ page: 0, size: 20 }));
    }, (err: AxiosError<IErrorResponse>) => {
      if (err.response.data.code === ERROR_CODE.eSignCreateFailed) {
        message.error('通知书创建失败：请检查填充通知书字段');
      }
    });
  }, [ contractNonExtensionProcessId ]);

  const tableColumns = useMemo(() => {
    const contractNonExtensionProcessStatusCol = contractExtensionNotSignDetailColumns
      .find((col: ColumnType<IContractExtensionNotSignDetail>) =>
        col.dataIndex === 'contractNonExtensionProcessStatus');
    contractNonExtensionProcessStatusCol.render = (_,
      {
        employeeId,
        stampLink,
        contractNonExtensionProcessStatus,
        contractNonExtensionProcessId,
        contractNonExtensionProcessName
      }) => {
      switch (contractNonExtensionProcessStatus) {
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_TO_BE_REVIEWED:
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_REVIEW_FAILED:
        return <Tooltip placement="bottom"
          title={contractNonExtensionProcessStatus === CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_REVIEW_FAILED
            ? '员工信息审核出错，请重新审核' : null}
          getPopupContainer={triggerNode => triggerNode}
          overlayStyle={{ width: 216 }}
        >
          <Button
            disabled={!hasContractExtensionEditPermission}
            size="small"
            type={contractNonExtensionProcessStatus === CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_REVIEW_FAILED
              ? 'primary' : 'default'}
            onClick={() => displayDrawer(employeeId, contractNonExtensionProcessId, contractNonExtensionProcessName)}>
            审核员工信息
          </Button>
        </Tooltip>;
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_UNDER_REVIEW:
        return <Badge color="#47A1AD" text="审核中"/>;
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.NOTIFICATION_TO_BE_REVIEWED:
        return <Button size="small" type="default" disabled={!hasContractExtensionEditPermission} onClick={openContract}>
          {/* eslint-disable-next-line max-len */}
          <Link to={`/contract-extension-home/notification-review/${employeeId}/${contractNonExtensionProcessId}?tab=${CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION}`}>
            预览通知文件
          </Link>
        </Button>;
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.WAITING_HRO_INITIATE:
        return <ButtonWithSign
          hasPermission={hasContractExtensionEditPermission}
          update={() => {
            dispatch(fetchContractExtensionNotSignEmployeeList({ page: 0, size: 20 }));
          }}
          employeeId={employeeId}
          employeeSigningStatus={contractNonExtensionProcessStatus}
          employeeSigningProcessId={contractNonExtensionProcessId}
        />;
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.WAITING_EMPLOYEE_SIGNING:
        return <Badge color="#6B9E78" text="已发起签署"/>;
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING:
      case CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.HRO_SIGNED:
        return <ButtonWithStamp hroSignUrl={stampLink}
          disable={!hasContractExtensionEditPermission ||
                                  contractNonExtensionProcessStatus !== CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING}
          text={contractNonExtensionProcessStatus === CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.WAITING_HRO_SIGNING ? '盖电子章' : '已盖章'} />;
      default:
        return <Tooltip placement="bottom"
          title={contractNonExtensionProcessStatus === CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_REVIEW_FAILED
            ? '员工信息审核出错，请重新审核' : null}
          getPopupContainer={triggerNode => triggerNode}
          overlayStyle={{ width: 216 }}
        >
          <Button
            size="small"
            disabled={!hasContractExtensionEditPermission}
            type={contractNonExtensionProcessStatus === CONTRACT_EXTENSION_NOT_SIGN_PROCESS_STATUS.EMPLOYEE_INFO_REVIEW_FAILED
              ? 'primary' : 'default'}
            onClick={() => displayDrawer(employeeId, contractNonExtensionProcessId, contractNonExtensionProcessName)}>
            审核员工信息
          </Button>
        </Tooltip>;
      }
    };

    const operationCol = contractExtensionNotSignDetailColumns
      .find((col: ColumnType<IContractExtensionNotSignDetail>) =>
        col.dataIndex === 'operation');
    operationCol.render = (_, record) => {
      return (
        <>
          <OperationItems employeeId={record.employeeId}
            comment={record.comment}
            contractSignProcessId={record.contractNonExtensionProcessId}
            edit={record.edit}
            matomoName={MATOMO_NAME.NoExtension}
            callbackFn={() => {
              dispatch(fetchContractExtensionNotSignEmployeeList({ page: 0, size: 20 }));
              dispatch(fetchContractExtensionEmployeeTotal());
            }}/>
        </>
      );
    };
    return contractExtensionNotSignDetailColumns;
  }, []);

  const handleTableChange = useCallback(pagination => {
    dispatch(fetchContractExtensionNotSignEmployeeList({ page: pagination.current - 1, size: pagination.pageSize }));
  }, [ tablePagination ]);

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={contractExtensionNotSignEmployeeList}
        rowKey="employeeId"
        pagination={tablePagination}
        onChange={handleTableChange}
        scroll={{ y: 'calc(100vh - 470px)', x: '1400px' }}
        locale={{ emptyText: <Empty description={'暂无数据'}/> }}
      />
      <CustomDrawer form={form} employeeInfo={employeeInfo}
        drawerTitle="不续签信息审核" drawerOpen={drawerOpen} closeDrawer={closeDrawer}
        drawerConfirm={drawerConfirm}
        popOpen={popOpen} displayPop={displayPop} closePop={closePop}
        fieldValidatorConfig={fieldValidatorConfig}
        subTitle={isEmployeeNotSign ? '劳动合同续签通知书' : '劳动合同不续签通知书'}/>
    </>
  );
};
export default WillNotExtensionPendingTable;
