import { Badge, Button, Empty, Table } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IExitApprovalListDetail, IExitApprovalListResponse, } from '@/models/employee';
import { EXIT_APPROVAL_PROCESS_STATUS, EXIT_PROCESS_TAB_STATUS } from '@/constants/status';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  EXIT_APPROVAL_ADMIN,
  EXIT_APPROVAL_BUSINESS_TEAM,
  EXIT_APPROVAL_COMMENT_READ,
  EXIT_APPROVAL_LEAVE,
  EXIT_APPROVAL_LIST_READ_PERMISSION,
  EXIT_APPROVAL_OFFICE_HRO,
  EXIT_APPROVAL_PROGRESS_READ,
  EXIT_APPROVAL_WORKSPACE,
  EXIT_TYPE_READ_PERMISSION
} from '@/constants/permission';
import {
  fetchExitApprovalList,
  fetchExitTableColumnFilterCandidate,
  fetchExitTotal,
  selectExitApprovalData,
  selectExitColumnCandidates,
  updateExitTableSearchFilters
} from '@/store/hroInfo';
import { AppDispatch } from '@/models/store';
import './ExitApprovalTableInfo.scss';
import { EXIT_TYPE, EXIT_TYPE_CHINESE } from '@/constants/EXIT_TYPE_CHINESE';
import ExitApprovalReviewModal from '@/pages/hro/exit-process/exit-approval-table-info/exit-approval-review-modal/ExitApprovalReviewModal';
import { APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE } from '@/constants/employee';
import ExitApprovalProcessDrawer
  from '@/pages/hro/exit-process/exit-approval-table-info/exit-approval-process-drawer/ExitApprovalProcessDrawer';
import ExitApprovalCommentDrawer
  from '@/pages/hro/exit-process/exit-approval-table-info/exit-approval-comment-drawer/ExitApprovalCommentDrawer';
import TableColumnFilter from '@/components/table-column-filter/TableColumnFilter';
import {
  EXIT_APPROVAL_GATE_KEEP_OPTIONS,
  EXIT_APPROVAL_LEAVE_HRO_GATE_KEEP_OPTIONS,
  EXIT_APPROVAL_OFFICE_HRO_GATE_KEEP_OPTIONS,
  EXIT_APPROVAL_POWER_HRO_GATE_KEEP_OPTIONS,
  EXIT_EMPLOYEE_GATE_KEEP_OPTIONS,
  EXIT_EMPLOYMENT_TYPE_OPTIONS,
  EXIT_TYPE_OPTIONS
} from '@/constants/employeeOptions';
import TableColumnDateFilter from '@/components/table-column-date-filter/TableColumnDateFilter';

const ExitApprovalTableInfo = () => {

  const hasExitTypeReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_TYPE_READ_PERMISSION ]));
  const hasExitApprovalListPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_LIST_READ_PERMISSION, EXIT_APPROVAL_BUSINESS_TEAM ]));
  const hasExitApprovalAdminPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_ADMIN ]));
  const hasExitApprovalOfficeHroPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_OFFICE_HRO ]));
  const hasExitApprovalBusinessTeamPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_BUSINESS_TEAM ]));
  const hasExitApprovalWorkspacePermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_WORKSPACE ]));
  const hasExitApprovalLeavePermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_LEAVE ]));
  const hasExitApprovalProgressReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_PROGRESS_READ ]));
  const hasExitApprovalCommentReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_APPROVAL_COMMENT_READ ]));
  const [ isCommentDrawerOpen, setIsCommentDrawerOpen ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(null);
  const [ selectedColumn, setSelectedColumn ] = useState(null);
  const [ templateType, setTemplateType ] = useState(null);
  const [ processDrawerOpen, setProcessDrawerOpen ] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const dataStoredInRedux = useSelector(selectExitApprovalData) as IExitApprovalListResponse;
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
    showSizeChanger: false,
  };

  useEffect(() => {
    if (!hasExitApprovalListPermission) {
      return;
    }
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS }));
    dispatch(fetchExitApprovalList({ page: 0, size: 20 }));
  }, [ hasExitApprovalListPermission ]);

  const openModal = (columnItem: IExitApprovalListDetail, templateType: APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE) => {
    setIsModalOpen(true);
    setSelectedColumn(columnItem);
    setTemplateType(templateType);
  };

  const closeModal = useCallback((needRefreshData: boolean) => {
    setIsModalOpen(false);
    if (needRefreshData) {
      dispatch(fetchExitApprovalList({ page: tablePagination.current - 1, size: tablePagination.pageSize  }));
    }
  }, []);

  const openProcessDrawer = (columnItem: IExitApprovalListDetail) => {
    setSelectedColumn(columnItem);
    setProcessDrawerOpen(true);
  };

  const closeCommentDrawer = useCallback(() => {
    setIsCommentDrawerOpen(false);
  }, []);

  const openCommentDrawer = useCallback((columnItem: IExitApprovalListDetail) => {
    setIsCommentDrawerOpen(true);
    setSelectedColumn(columnItem);
  }, []);

  const exitEmployeeList = dataStoredInRedux.content.map(employee => {
    return {
      ...employee,
      type: employee.type === EXIT_TYPE.VOLUNTARY ? EXIT_TYPE_CHINESE.VOLUNTARY : EXIT_TYPE_CHINESE.INVOLUNTARY,
    };
  });

  const handleQueryFilterConfirm = () => {
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS }));
    dispatch(fetchExitApprovalList({ page: 0, size: 20 }));
    dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.IN_PROGRESS }));
  };

  const renderFilterIcon = () => {
    return <i className="ri-filter-3-line"></i>;
  };

  const getTableFilterCommonProps = (filterFieldKey, close) => {
    return {
      close,
      filterFieldKey,
      handleConfirm: handleQueryFilterConfirm,
      filterCandidatesReduxQueryMethod: selectExitColumnCandidates,
      filterReduxUpdateMethod: updateExitTableSearchFilters
    };
  };

  const reviewAreaComponent = (reviewStatus: EXIT_APPROVAL_PROCESS_STATUS,
    hasPermission: boolean,
    templateType: APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE,
    record: IExitApprovalListDetail,
    hidden: boolean) => {
    if (reviewStatus === EXIT_APPROVAL_PROCESS_STATUS.APPROVED) {
      return <div>
        <Badge className={`submitted-badge ${hidden ? 'hidden' : ''}`}
          color="#6B9E78"
          text="已审核"
          data-testid={`${templateType}-review-${record.employeeId}-badge`}
        />
      </div>;
    } else if (reviewStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED) {
      return <Button size="small" 
        className={`${hidden ? 'hidden' : ''}`}
        type="default"
        disabled={!hasPermission}
        data-testid={`${templateType}-review-${record.employeeId}-button`}
        onClick={() => openModal(record, templateType)}>
        待审核
      </Button>;
    } else if (reviewStatus === EXIT_APPROVAL_PROCESS_STATUS.TO_BE_ACTIVE) {
      return <Button size="small"
        className={`${hidden ? 'hidden' : ''}`}
        type="default"
        disabled={true}
        data-testid={`${templateType}-review-${record.employeeId}-button`}>
        待审核
      </Button>;
    }
    return '-';
  };

  const reviewAreaComponentForHro = (templateType: APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE,
    record: IExitApprovalListDetail,
    hidden: boolean) => {
    const hroReviewBtnClickable = (
      hasExitApprovalLeavePermission &&
        (record.exitApprovalLeaveHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED ||
          record.exitApprovalLeaveHroStatus === EXIT_APPROVAL_PROCESS_STATUS.APPROVED)) ||
      (hasExitApprovalOfficeHroPermission && record.canOfficeHroReview &&
        record.exitApprovalOfficeHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED);
    
    return <Button size="small"
      className={hidden ? 'hidden' : ''}
      type="default"
      disabled={!(record.exitApprovalEmployeeStatus === EXIT_APPROVAL_PROCESS_STATUS.APPROVED && hroReviewBtnClickable)}
      data-testid={`${templateType}-review-${record.employeeId}-button`}
      onClick={() => openModal(record, templateType)}>
        待审核
    </Button>;
  };

  const tableColumns = useMemo(() => {
    const columns = [];
    columns.push({
      title: '离职日期',
      dataIndex: 'terminationDate',
      className: 'align-center',
      width: '122px',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnDateFilter
        filterFieldKey={'terminationDate'}
        handleConfirm={handleQueryFilterConfirm}
        filterReduxUpdateMethod={updateExitTableSearchFilters}
        close={close}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '工号',
      className: 'align-center',
      dataIndex: 'employeeId',
      width: '88px',
      fixed: 'left',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('employeeId', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '中文名',
      dataIndex: 'chineseName',
      className: 'align-center',
      width: '100px',
      fixed: 'left',
      ellipsis: true,
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('chineseName', close)}
      />,
      filterIcon: renderFilterIcon
    });
    
    if (hasExitTypeReadPermission) {
      columns.push({
        title: '离职类型',
        className: 'align-center',
        dataIndex: 'type',
        key: 'type',
        width: '120px',
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_TYPE_OPTIONS}
          {...getTableFilterCommonProps('type', close)}
        />,
        filterIcon: renderFilterIcon
      });
    }
    
    columns.push( {
      title: '员工类型',
      className: 'align-center',
      dataIndex: 'employmentType',
      width: '120px',
      filterDropdown: ({ close }) => <TableColumnFilter
        filters={EXIT_EMPLOYMENT_TYPE_OPTIONS}
        {...getTableFilterCommonProps('employmentType', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '城市',
      dataIndex: 'homeOfficeChinese',
      className: 'align-center',
      width: '90px',
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('homeOffice', close)}
      />,
      filterIcon: renderFilterIcon
    },
    {
      title: '部门',
      className: 'align-center',
      dataIndex: 'supervisoryOrgName',
      width: '100px',
      ellipsis: true,
      filterDropdown: ({ close }) => <TableColumnFilter
        {...getTableFilterCommonProps('supervisoryOrgName', close)}
      />,
      filterIcon: renderFilterIcon
    },     
    {
      title: '员工确认',
      className: 'align-center',
      dataIndex: 'exitApprovalEmployeeStatus',
      width: '116px',
      render: (_, { exitApprovalEmployeeStatus }: IExitApprovalListDetail) => {
        if (exitApprovalEmployeeStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED) {
          return <Badge className="exit-approval-not-submitted-badge" color="#F2617A" text="未提交" />;
        } else if (exitApprovalEmployeeStatus === EXIT_APPROVAL_PROCESS_STATUS.APPROVED) {
          return <div className="exit-approval-submitted-wrapper">
            <Badge className="submitted-badge" color="#6B9E78" text="已提交" />
          </div>;
        }
        return '-';
      },
      filterDropdown: ({ close }) => <TableColumnFilter
        filters={EXIT_EMPLOYEE_GATE_KEEP_OPTIONS}
        {...getTableFilterCommonProps('exitApprovalEmployeeStatus', close)}
      />,
      filterIcon: renderFilterIcon
    });

    if (hasExitApprovalBusinessTeamPermission) {
      columns.push({
        title: <span>业务团队审核</span>,
        className: 'align-center',
        dataIndex: 'exitApprovalBusinessTeamStatus',
        width: '140px',
        render: (_, record: IExitApprovalListDetail) => {
          return reviewAreaComponent(
            record.exitApprovalBusinessTeamStatus,
            hasExitApprovalBusinessTeamPermission && record.canBusinessTeamReview,
            APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.EXIT_BUSINESS_TEAM_APPROVER,
            record,
            !hasExitApprovalBusinessTeamPermission);
        },
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_APPROVAL_GATE_KEEP_OPTIONS}
          {...getTableFilterCommonProps('exitApprovalBusinessTeamStatus', close)}
        />,
        filterIcon: renderFilterIcon
      });
    }

    if (hasExitApprovalWorkspacePermission) {
      columns.push({
        title: <span>Workspace审核</span>,
        className: 'align-center',
        dataIndex: 'exitApprovalWorkSpaceStatus',
        width: '116px',
        render: (_, record: IExitApprovalListDetail) => {
          return reviewAreaComponent(
            record.exitApprovalWorkSpaceStatus,
            hasExitApprovalWorkspacePermission && record.canWorkspaceReview,
            APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.WORKSPACE,
            record,
            !hasExitApprovalWorkspacePermission);
        },
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_APPROVAL_GATE_KEEP_OPTIONS}
          {...getTableFilterCommonProps('exitApprovalWorkSpaceStatus', close)}
        />,
        filterIcon: renderFilterIcon
      });
    }

    if (hasExitApprovalAdminPermission) {
      columns.push({
        title: 'Admin审核',
        className: 'align-center',
        dataIndex: 'exitApprovalAdminStatus',
        width: '116px',
        render: (_, record: IExitApprovalListDetail) => {
          return reviewAreaComponent(
            record.exitApprovalAdminStatus,
            hasExitApprovalAdminPermission && record.canAdminReview,
            APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_ADMIN,
            record,
            !hasExitApprovalAdminPermission);
        },
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_APPROVAL_GATE_KEEP_OPTIONS}
          {...getTableFilterCommonProps('exitApprovalAdminStatus', close)}
        />,
        filterIcon: renderFilterIcon
      });
    }

    if (hasExitApprovalOfficeHroPermission || hasExitApprovalLeavePermission) {
      columns.push({
        title: <span>People Support审核</span>,
        className: 'align-center',
        dataIndex: 'exitApprovalHroStatus',
        width: '140px',
        render: (_, record: IExitApprovalListDetail) => {
          return reviewAreaComponentForHro(
            hasExitApprovalLeavePermission && record.exitApprovalLeaveHroStatus === EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED
              ? APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.LEAVE_HRO : APPROVAL_REVIEW_MODAL_TEMPLATE_TYPE.OFFICE_HRO,
            record,
            !hasExitApprovalOfficeHroPermission && !hasExitApprovalLeavePermission);
        },
        filterDropdown: ({ close }) => {
          let filters;
          if (hasExitApprovalOfficeHroPermission) {
            filters = EXIT_APPROVAL_OFFICE_HRO_GATE_KEEP_OPTIONS;
          }
          if (hasExitApprovalLeavePermission) {
            filters = EXIT_APPROVAL_LEAVE_HRO_GATE_KEEP_OPTIONS;
          }
          if (hasExitApprovalOfficeHroPermission && hasExitApprovalLeavePermission) {
            filters = EXIT_APPROVAL_POWER_HRO_GATE_KEEP_OPTIONS;
          }
          return <TableColumnFilter
            filters={filters}
            {...getTableFilterCommonProps('exitApprovalHroStatus', close)}
          />;
        },
        filterIcon: renderFilterIcon
      });
    }

    if (hasExitApprovalProgressReadPermission || hasExitApprovalCommentReadPermission) {
      columns.push({
        title: '操作',
        className: 'align-center',
        dataIndex: 'exitSignProcessStatus',
        key: 'action',
        width: '220px',
        render: (_, record: IExitApprovalListDetail) => {
          return (
            <div className="exit-approval-action-wrapper">
              <Button
                className='exit-approval-action-btn'
                size="small"
                type="text"
                hidden={!hasExitApprovalProgressReadPermission}
                onClick={() => openProcessDrawer(record)}
              >
                <i className="ri-time-line"/>
                <span>流转进度</span>
              </Button>
              <Button
                className='exit-approval-action-btn'
                size="small"
                type="text"
                hidden={!hasExitApprovalCommentReadPermission}
                onClick={() => openCommentDrawer(record)}
              >
                <i className="ri-discuss-line"/>
                <span>留言</span>
              </Button>
            </div>
          );
        }
      });
    }
    return columns;
  }, []);


  const handleTableChange = useCallback(pagination => {
    dispatch(fetchExitApprovalList({ page: pagination.current - 1, size: pagination.pageSize }));
  }, [ tablePagination ]);

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={exitEmployeeList}
        rowKey="employeeId"
        pagination={tablePagination}
        onChange={handleTableChange}
        scroll={{ y: 'calc(100vh - 470px)', x: '1390px' }}
        locale={{ emptyText: <Empty description={'暂无数据'} /> }}
        className='exit-approval-table'
      />
      <ExitApprovalReviewModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        templateType={templateType}
        record={selectedColumn}
      />
      <ExitApprovalProcessDrawer employeeId={selectedColumn?.employeeId}
        drawerOpen={processDrawerOpen} closeDrawer={() => setProcessDrawerOpen(false)}/>
      <ExitApprovalCommentDrawer
        record={selectedColumn}
        drawerOpen={isCommentDrawerOpen}
        closeDrawer={closeCommentDrawer}/>
    </>
  );
};

export default ExitApprovalTableInfo;
