// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import TotalNumber from '@/components/total-number/TotalNumber';
import './WillExtensionTable.scss';
import ToBeAuditedTableInfo
  from '@/pages/hro/contract-extension-process/will-extension-table/to-be-audited-table-info/ToBeAuditedTableInfo';
import ToBeSignedTableInfo
  from '@/pages/hro/contract-extension-process/will-extension-table/to-be-signed-table-info/ToBeSignedTableInfo';
import { useSearchParams } from 'react-router-dom';
import SignedDoneTableInfo from './signed-done-table-info/SignedDoneTableInfo';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_READ_PERMISSION,
} from '@/constants/permission';
import { fetchContractExtensionEmployeeTotal, selectWillExtensionTotalCount } from '@/store/hroInfo';
import { AppDispatch } from '@/models/store';
import { EMPLOYEE_TAB_STATUS } from '@/constants/status';
import { API_CONTRACT_EXTENSION } from '@/api/consts';
import ContractList from '@/pages/hro/contract-extension-process/contract-list/ContractList';

const TAB_COUNT_MAPPER = {
  [EMPLOYEE_TAB_STATUS.PENDING]: 'toBeAuditedUserCount',
  [EMPLOYEE_TAB_STATUS.SIGNATURE]: 'toBeSignedUserCount',
  [EMPLOYEE_TAB_STATUS.CONFIRMED]: 'signedDoneUserCount',
  [EMPLOYEE_TAB_STATUS.FINISHED]: 'finishedUserCount'
};

const WillExtensionTable = () => {
  const [ searchParams, setSearchParams ] = useSearchParams({});
  const total = useSelector(selectWillExtensionTotalCount);
  const [ currentTab, setCurrentTab ] = useState<string>(EMPLOYEE_TAB_STATUS.PENDING);
  const dispatch = useDispatch<AppDispatch>();
  const hasPermission = useSelector(state =>
    isUserHasPermissions( state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION, CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ]));
  const getTotalCount = useCallback(() => {
    dispatch(fetchContractExtensionEmployeeTotal());
  }, []);

  useEffect(() => {
    if (hasPermission) {
      dispatch(fetchContractExtensionEmployeeTotal());
    }
  }, [ hasPermission ]);

  const items = useMemo(() => {
    return [
      {
        key: EMPLOYEE_TAB_STATUS.PENDING,
        label: <TotalNumber title={'待审核员工'} totalCount={total && total[TAB_COUNT_MAPPER[EMPLOYEE_TAB_STATUS.PENDING]]}/>,
        children: <ToBeAuditedTableInfo updateTotalCount={getTotalCount}/>,
        disabled: !hasPermission,
      },
      {
        key: EMPLOYEE_TAB_STATUS.SIGNATURE,
        label: <TotalNumber title={'待签署员工'} totalCount={total && total[TAB_COUNT_MAPPER[EMPLOYEE_TAB_STATUS.SIGNATURE]]}/>,
        children: <ToBeSignedTableInfo/>,
        disabled: !hasPermission,
      },
      {
        key: EMPLOYEE_TAB_STATUS.CONFIRMED,
        label: <TotalNumber title={'待盖章员工'} totalCount={total && total[TAB_COUNT_MAPPER[EMPLOYEE_TAB_STATUS.CONFIRMED]]}/>,
        children: <SignedDoneTableInfo />,
        disabled: !hasPermission,
      },
      {
        key: EMPLOYEE_TAB_STATUS.FINISHED,
        label: <TotalNumber title={'完成签署员工'}
          totalCount={total && total[TAB_COUNT_MAPPER[EMPLOYEE_TAB_STATUS.FINISHED]]}/>,
        children: <ContractList apiUrl={API_CONTRACT_EXTENSION}/>,
        disabled: !hasPermission,
      }
    ];
  }, [ total ]);

  const getActiveItems = useCallback(() => {
    return items.filter(item => !item.disabled);
  }, [ items ]);

  const clickTab = useCallback(activeKey => {
    if (activeKey !== currentTab) {
      setCurrentTab(activeKey);
      setSearchParams({ tab: activeKey });
      getTotalCount();
    }
  }, [ currentTab ]);

  return (
    <div className="main-page">
      <div className="container">
        <Tabs
          type="card"
          defaultActiveKey={searchParams.get('tab') || EMPLOYEE_TAB_STATUS.PENDING}
          items={getActiveItems()}
          onTabClick={clickTab}
          destroyInactiveTabPane={true}/>
      </div>
    </div>
  );
};
export default WillExtensionTable;
