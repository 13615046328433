// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import './LegalValidityPopupWindow.scss';
import { Button, Modal } from 'antd';
import { LEGAL_VALIDITY_OF_ELECTRONIC_SIGNATURE } from '@/constants/common';
import { ROLE_USER } from '@/constants/role';
import { localStorageUtils } from '@/utils/localStorageUtils';
import { useCountDown } from '@/utils/useCountDown';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { useCustomMatomo } from '@/matomo';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { selectCurrentUserRole } from '@/store/userInfo';

const LegalValidityPopupWindow = () => {

  const { location, matchedRoutes } = useRouterGuard();
  const isFirstTimeLogin = localStorageUtils.getCacheItem('firstTimeLogin');
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const { trackEvent } = useCustomMatomo();

  const { startCountDown, flag, timeText } = useCountDown();

  const currentUserRole = useSelector(selectCurrentUserRole, shallowEqual);

  useEffect(() => {
    if (currentUserRole === ROLE_USER && !isFirstTimeLogin) {
      setIsModalOpen(true);
      startCountDown();
      localStorageUtils.setCacheItem('firstTimeLogin', 'true');
    }
  }, [ location.pathname ]);

  const closeModal = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeLegalEffect,
      name: MATOMO_NAME.SignDescription,
      action: MATOMO_ACTION.Agree
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <div>{matchedRoutes.length === 0}</div>
      <Modal className='legal-validity-modal'
        open={isModalOpen} centered width={486}
        bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        keyboard={false} closable={false} maskClosable={false} destroyOnClose={true} footer={null}>
        <p className='legal-validity-modal-title'>电子签名/合同效力说明</p>
        <p className='legal-validity-modal-content'>
          《电子签名法》规定：如果电子签名属于签名人专有并仅由其控制，且文件或签名改动后可被发现，那么这就是一个可靠的电子签名，与传统签字盖章具备同等法律效力。通过e签宝对文件进行的盖章签署，属于可靠的电子签名，同样具有法律效力。
        </p>
        <p className='legal-validity-modal-link'>详见
          <a href={LEGAL_VALIDITY_OF_ELECTRONIC_SIGNATURE} target='_blank' rel='noreferrer'>《电子签名的法律有效性说明》</a>
        </p>
        <Button type='primary' onClick={closeModal} disabled={!flag}>{timeText}</Button>
      </Modal>
    </>
  );
};

export default LegalValidityPopupWindow;
