import { DatePicker } from 'antd';
import zhCN from 'antd/es/date-picker/locale/zh_CN';
import { Moment } from 'moment';

const CustomDatePicker = (disabled?: boolean, disabledDate?: (date: Moment) => boolean) => {
  return (
    <DatePicker
      locale={zhCN}
      disabledDate={disabledDate}
      disabled={disabled}
      getPopupContainer={triggerNode => triggerNode}/>
  );
};
export default CustomDatePicker;
