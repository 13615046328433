import { Button, Empty, Table } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IContractExtensionEmployeeToBeSignedDetail,
  IContractExtensionEmployeeToBeSignedResponse,
} from '@/models/employee';
import { ColumnType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { getContractExtensionToBeSignedInfo } from '@/api/employeeApi';
import ButtonWithSign from '@/components/button-with-sign/ButtonWithSign';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions } from '@/store/userInfo';
import {
  CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION, CONTRACT_EXTENSION_PROCESS_READ_PERMISSION,
} from '@/constants/permission';
import {
  contractExtensionEmployeeToBeSignedDetailColumns
} from '@/constants/config/hro/contract-extension-process/will-extension-table/to-be-signed-table-info/ToBeSignedTableConfig';
import { fetchContractExtensionEmployeeTotal } from '@/store/hroInfo';
import { AppDispatch } from '@/models/store';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { fetchContractExtensionToBeAuditList } from '@/store/employee';
import OperationItems from '@/pages/hro/operation-items/OperationItems';
import { EMPLOYEE_CONTRACT_SIGN_STATUS, EMPLOYEE_TAB_STATUS } from '@/constants/status';

const ToBeSignedTableInfo = () => {
  const [ employeeList, setEmployeeList ] = useState<IContractExtensionEmployeeToBeSignedDetail[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const hasContractExtensionProcessEditPermission = useSelector(state =>
    isUserHasPermissions( state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ]));
  const hasContractExtensionProcessReadPermission = useSelector(state =>
    isUserHasPermissions( state, [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ]));
  const [ tablePagination, setTablePagination ] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false
  });
  const { trackEvent } = useCustomMatomo();

  const fetchContractExtensionToBeSignedInfo = (page: number, size: number) => {
    getContractExtensionToBeSignedInfo(page, size).then((response: IContractExtensionEmployeeToBeSignedResponse) => {
      setEmployeeList(response.content);
      setTablePagination({
        current: response.pageable.pageNumber + 1,
        pageSize: response.pageable.pageSize,
        total: response.totalElements,
        showSizeChanger: false
      });
    });
  };

  useEffect(() => {
    if (hasContractExtensionProcessReadPermission) {
      fetchContractExtensionToBeSignedInfo(0, 20);
    }
  }, [ hasContractExtensionProcessReadPermission ]);

  const tableColumns = useMemo(() => {
    const employeeContractReviewedStatusCol = contractExtensionEmployeeToBeSignedDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeToBeSignedDetail>) =>
        col.dataIndex === 'employeeContractReviewedStatus');
    const employeeContractSigningStatusCol = contractExtensionEmployeeToBeSignedDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeToBeSignedDetail>) =>
        col.dataIndex === 'employeeContractSigningStatus');
    const operationCol = contractExtensionEmployeeToBeSignedDetailColumns
      .find((col: ColumnType<IContractExtensionEmployeeToBeSignedDetail>) =>
        col.dataIndex === 'operation');

    employeeContractReviewedStatusCol.render = (_, {
      employeeId,contractExtensionSignProcessId, contractExtensionSignProcessStatus
    }) => {
      return <Button
        className="preview-materials"
        size="small"
        disabled={!hasContractExtensionProcessEditPermission ||
        contractExtensionSignProcessStatus !== EMPLOYEE_CONTRACT_SIGN_STATUS.TO_BE_REVIEWED}
        onClick={() => {
          trackEvent({
            category: MATOMO_CATEGORY.HroAuditExtensionContract,
            action: MATOMO_ACTION.Preview,
            name: MATOMO_NAME.AuditContract,
          });
        }
        }
      >
        {/* eslint-disable-next-line max-len */}
        <Link to={`/contract-extension-home/contract-extension-review/${employeeId}/${contractExtensionSignProcessId}?tab=${EMPLOYEE_TAB_STATUS.SIGNATURE}`}>
          {contractExtensionSignProcessStatus === EMPLOYEE_CONTRACT_SIGN_STATUS.WAITING_HRO_INITIATE ? '审核通过' : '预览审核'}
        </Link>
      </Button>;
    };

    employeeContractSigningStatusCol.render = (_, {
      employeeId, contractExtensionSignProcessStatus, contractExtensionSignProcessId
    }) => {
      return <ButtonWithSign
        hasPermission={hasContractExtensionProcessEditPermission}
        update={() => {
          fetchContractExtensionToBeSignedInfo(0, 20);
        }}
        employeeId={employeeId}
        employeeSigningStatus={contractExtensionSignProcessStatus}
        employeeSigningProcessId={contractExtensionSignProcessId}
        popupContainerId={null}
      />;
    };

    operationCol.render = (_, record) => {
      return (
        <>
          <OperationItems employeeId={record.employeeId}
            comment={record.comment}
            contractSignProcessId={record.contractExtensionSignProcessId}
            edit={record.edit}
            matomoName={MATOMO_NAME.Extension}
            callbackFn={() => {
              dispatch(fetchContractExtensionToBeAuditList({ page: 0, size: 20 }));
              dispatch(fetchContractExtensionEmployeeTotal());
            }}/>
        </>
      );
    };

    return contractExtensionEmployeeToBeSignedDetailColumns;
  }, []);

  const handleTableChange = useCallback(pagination => {
    getContractExtensionToBeSignedInfo(pagination.current - 1, pagination.pageSize)
      .then((response: IContractExtensionEmployeeToBeSignedResponse) => {
        setEmployeeList(response.content);
        setTablePagination({
          ...pagination,
          current: response.pageable.pageNumber + 1,
          total: response.totalElements,
        });
      });
  }, [ tablePagination ]);

  return (
    <Table
      columns={tableColumns}
      dataSource={employeeList}
      rowKey="employeeId"
      onChange={handleTableChange}
      pagination={tablePagination}
      scroll={{ y: 'calc(100vh - 470px)' }}
      locale={{ emptyText: <Empty description={'暂无数据'}/> }}
    />
  );
};
export default ToBeSignedTableInfo;
