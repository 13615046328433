import '@/components/button/Button.scss';
import './PersonalInfo.scss';
import { Button, Col, Empty, Form, FormInstance, Input, Radio, Row, Select, Upload, UploadFile, UploadProps } from 'antd';
import { IdRule, RequiredRule } from '@/constants/validation';
import { CHINA_IDTYPE } from '@/constants/employee';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IIdType, INation } from '@/models/employee';
import EmployeeFormLabel  from '../employee-form-label/EmployeeFormLabel';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { forEach, map } from 'lodash-es';
import DeleteIcon from '@/assets/icons/remove.svg';
import InfoIcon from '@/assets/icons/info-circle.svg';
import { defaultPublicInventionInfo } from '@/pages/employee/employee-info-edit/personal-info/PersonalInfoUtil';
import { localStorageUtils } from '@/utils/localStorageUtils';
import { EDIT_STEP } from '@/pages/employee/employee-info-edit/education-info/EducationUtil';
import { flushSync } from 'react-dom';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { FileSizeLimit10M, FileType, fileTypeValidator, generateFileName, getUploadProps, normFile } from '@/utils/fileUpload';
import UploadSvg from '@/assets/icons/upload.svg';
import { EMAIL_TO_PEOPLE_SUPPORT_CHINA, PERSONNEL_SYSTEM_OPERATION_MANUAL_ADDRESS, WORK_DAY_ADDRESS } from '@/constants/common';

export const testIdPersonalInfoLegalFullName = 'personalInfo-legalFullName';
export const testIdPersonalInfoChineseName = 'personalInfo-chineseName';
export const testIdPersonalInfoIdNumber = 'personalInfo-idNumber';
export const testIdPersonalInfoPhoneNumber = 'personalInfo-phoneNumber';
export const testIdPersonalInfoHomeAddress = 'personalInfo-homeAddress';
export const testIdPersonalInfoHomeAddressChinese = 'personalInfo-homeAddressChinese';
export const testIdPersonalInfoPublicInventionInfoDescription = 'personalInfo-publicInventionInfo-description';
export const testIdPersonalInfoPublicInventionInfoDescriptionEnglish = 'personalInfo-publicInventionInfo-descriptionEnglish';

const { Option } = Select;

interface IPersonalInfoProps {
    form?: FormInstance;
    nations?: INation[];
    idTypes?: IIdType[];
    isEmployee?: boolean;
    isContractExtensionEmployee?: boolean;
    employeeId: string;
    processId: string;
}

const WORKDAY_TIP = <a className="workday-link" target="_blank" href={WORK_DAY_ADDRESS} rel="noreferrer">请进入 Workday 修改此信息</a>;

const options = [
  { label: '有 (Yes)', value: true },
  { label: '无 (No)', value: false }
];

const inventionConfigs = [
  [
    {
      name: 'name',
      label: '以前的发明或改进'
    },
    {
      name: 'nameEnglish',
      label: 'Prior Invention or Improvement'
    }
  ],
  [
    {
      name: 'party',
      label: '当事方'
    },
    {
      name: 'partyEnglish',
      label: 'Party'
    },
  ],
  [
    {
      name: 'relationship',
      label: '关系'
    },
    {
      name: 'relationshipEnglish',
      label: 'Relationship'
    },
  ]
];
const privateInventionKeys = [ 'name', 'nameEnglish',  'party', 'partyEnglish', 'relationship', 'relationshipEnglish' ];

export const RequiredAttachmentRule = {
  required: true,
  message: '请提供材料',
};

const uploadProps = getUploadProps();

const PersonalInfo = ({ nations, idTypes, isEmployee = false, employeeId, processId,
  isContractExtensionEmployee = false }: IPersonalInfoProps) => {
  const form = Form.useFormInstance();
  const [ hasInvention, setHasInvention ] = useState(false);
  const [ hasAdditionalNotes, setHasAdditionalNotes ] = useState(false);
  const [ disclosedInventionsUploadConfig, setDisclosedInventionsUploadConfig ] = useState<UploadProps>();
  const { trackEvent } = useCustomMatomo();

  const idRule = useMemo(() => ({
    validator: (_, value: string) => {
      const idType = form.getFieldValue([ 'personalInfo', 'idType' ]);
      if (value && idType === CHINA_IDTYPE) {
        return IdRule.pattern.test(value) ? Promise.resolve() : Promise.reject(new Error(IdRule.message));
      }
      return Promise.resolve();
    }
  }), [ form ] );

  useEffect(() => {
    if (form.getFieldValue('personalInfo')?.hasInvention) {
      setHasInvention(true);
    }
    setHasAdditionalNotes(!!form.getFieldValue([ 'personalInfo', 'publicInventionInfo', 'hasAdditionalNotes' ]));
    setDisclosedInventionsUploadConfig({
      ...uploadProps,
      customRequest: args => {
        const customArgs = { ...args, data: { key: 'disclosedInvention', processId } };
        trackEvent({
          category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
          action: MATOMO_ACTION.Upload,
          name: MATOMO_NAME.DisclosedInvention,
        });
        uploadProps.customRequest(customArgs);
      },
      onRemove: (file: UploadFile) => {
        trackEvent({
          category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
          action: MATOMO_ACTION.Remove,
          name: MATOMO_NAME.DisclosedInvention,
        });
        return uploadProps.onRemove(file);
      },
      beforeUpload: file => {
        const previousUploadCount = form.getFieldValue([ 'personalInfo', 'publicInventionInfo', 'disclosedInventions' ])?.length || 0;
        const validateResult = fileTypeValidator(file, FileType.PdfOrImage, previousUploadCount, FileSizeLimit10M, 5);
        if (validateResult !== true) {
          return validateResult;
        }
        const personalInfo = form.getFieldValue('personalInfo');
        return new File([ file ], generateFileName(file, '发明材料', {
          employeeId: personalInfo?.employeeId,
          legalFullName: personalInfo?.legalFullName,
        }));
      }
    });
  }, [ employeeId ]);

  const addItem = useCallback(add => {
    add();
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Add,
      name: MATOMO_NAME.PrivateInvention,
    });
  }, []);
  const removeItem = useCallback((remove, field) => {
    flushSync(() => {
      remove(field.name);
    });
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Remove,
      name: MATOMO_NAME.PrivateInvention,
    });
    const values = form.getFieldValue([ 'personalInfo', 'privateInventionInfo' ]);
    forEach(values, (_, index) => {
      form.validateFields(map(privateInventionKeys, key => [ 'personalInfo', 'privateInventionInfo', index, key ]));
    });
  }, []);

  const renderRow = (remove, field) => {

    return (
      <section className="invention-section" key={field.key}>
        {
          map(inventionConfigs, (rowItems, $index) => (
            <Row gutter={16} key={$index}>
              { map(rowItems, (item, index) => (
                <Col span={10} key={index}>
                  <Form.Item name={[ field.name, item.name ]}
                    label={item.label}
                    rules={[ RequiredRule ]}>
                    <Input maxLength={100}/>
                  </Form.Item>
                </Col>
              )) }

            </Row>
          ))
        }
        <Icon
          data-testid="remove-icon"
          className="remove-block-icon"
          onClick={removeItem.bind(null, remove, field)}
          component={DeleteIcon} />
      </section>
    );
  };

  const renderAddExtraDesc = () => {
    return (
      <Form.List name={[ 'personalInfo', 'privateInventionInfo' ]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => renderRow(remove, field))}
            <Form.Item className="form-add-button">
              <Button
                type="dashed"
                onClick={() => addItem(add)}
                disabled={fields.length === 3}
                icon={<Icon component={PlusOutlined} />}>
                添加
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  const changeHasInventionRadio = value => {
    if (!form.getFieldValue('personalInfo')?.publicInventionInfo) {
      const personalInfo = {
        ...form.getFieldValue('personalInfo'),
        publicInventionInfo: defaultPublicInventionInfo
      };
      form.setFieldValue('personalInfo', personalInfo);
      localStorageUtils.setCacheItem( `${employeeId}-${EDIT_STEP[EDIT_STEP.PERSONAL]}`, personalInfo);
    }
    setHasInvention(value);
  };

  const handleDisclosedInventionChange = useCallback(event => {
    setHasAdditionalNotes(event.target.value);
  }, []);

  const renderNationalityItem = () => {
    return <Col span={6}>
      <Form.Item name={[ 'personalInfo', 'nationId' ]}
        label={<EmployeeFormLabel label="国籍" bracketSubLabel="Nationality" tip={WORKDAY_TIP}/>} rules={[ RequiredRule ]}>
        <Select
          disabled
          className="nation-select"
          showSearch
          filterOption={(input, option: { children: string; }) =>
            (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
          }
          notFoundContent={<Empty description={'暂无数据'}/>}
        >
          {nations.map(nation => (
            <Option key={nation.nationId} value={nation.nationId}>{nation.nationality}</Option>
          ))}
        </Select>
      </Form.Item>
    </Col>;
  };

  return (
    <>
      <div className="form-group">
        {
          isContractExtensionEmployee &&
          <div className="tip-info-content">
            <p className="chinese-content">
              如个人信息（手机号/中文地址/英文地址）有更新，请参照
              <a href={PERSONNEL_SYSTEM_OPERATION_MANUAL_ADDRESS} target="_blank" rel="noreferrer">指导手册</a>
              登录
              <a href={WORK_DAY_ADDRESS} target="_blank" rel="noreferrer" >Workday</a>
              自行修改后，重新提交。如有其他疑问，请联系
              <a href={EMAIL_TO_PEOPLE_SUPPORT_CHINA}>peoplesupport-china@thoughtworks.com</a>!
            </p>
            <p className="english-content">
              If there are updates to personal information (Phone number/Chinese address/English address), please refer to the <a
                href={PERSONNEL_SYSTEM_OPERATION_MANUAL_ADDRESS} target="_blank" rel="noreferrer">guidance manual
              </a> and log in to <a href={WORK_DAY_ADDRESS} target="_blank" rel="noreferrer">
              Workday</a> to make modifications and resubmit. If you have any additional questions, please contact <a
                href={EMAIL_TO_PEOPLE_SUPPORT_CHINA}>peoplesupport-china@thoughtworks.com</a>!
            </p>
          </div>
        }
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item name={[ 'personalInfo', 'legalFullName' ]}
              label={<EmployeeFormLabel label="姓名" bracketSubLabel="English Legal Name" tip={WORKDAY_TIP}/>} rules={[ RequiredRule ]}>
              <Input data-testid={testIdPersonalInfoLegalFullName} disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[ 'personalInfo', 'chineseName' ]}
              label={<EmployeeFormLabel label="证件姓名" bracketSubLabel="Legal Name on ID"/>} rules={[ RequiredRule ]}>
              <Input data-testid={testIdPersonalInfoChineseName} maxLength={50} />
            </Form.Item>
          </Col>
          {
            isContractExtensionEmployee &&
            <>
              {renderNationalityItem()}
              <Col span={6}>
                <Form.Item name={[ 'personalInfo', 'phoneNumber' ]}
                  label={<EmployeeFormLabel label="手机号" bracketSubLabel="Phone Number" tip={WORKDAY_TIP}/>} rules={[ RequiredRule ]}>
                  <Input data-testid={testIdPersonalInfoPhoneNumber} disabled />
                </Form.Item>
              </Col>
            </>
          }
        </Row>
        <Row gutter={8}>
          {
            !isContractExtensionEmployee &&
            renderNationalityItem()
          }
          <Col span={6} style={{ paddingRight: 3 }}>
            <Form.Item name={[ 'personalInfo', 'idType' ]}
              label={<EmployeeFormLabel label="证件类型" bracketSubLabel="National ID Type" tip={WORKDAY_TIP}/>} rules={[ RequiredRule ]} >
              <Select
                disabled
                className="nation-id-type-select"
              >
                {idTypes.map(item => (
                  <Option key={item.idType} value={item.idType}>{item.idTypeName}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={[ 'personalInfo', 'idNumber' ]}
              label={<EmployeeFormLabel label="证件号码" bracketSubLabel="ID Number"/>}
              rules={[
                RequiredRule,
                idRule,
              ]}
              validateTrigger={'onBlur'}
            >
              <Input data-testid={testIdPersonalInfoIdNumber} maxLength={50}/>
            </Form.Item>
          </Col>
        </Row>
        {
          isContractExtensionEmployee &&
          <>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item name={[ 'personalInfo', 'homeAddressChinese' ]}
                  label={<EmployeeFormLabel label="中文地址" bracketSubLabel="Chinese Address" tip={WORKDAY_TIP}/>} rules={[ RequiredRule ]}>
                  <Input data-testid={testIdPersonalInfoHomeAddressChinese} disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item name={[ 'personalInfo', 'homeAddress' ]}
                  label={<EmployeeFormLabel label="英文地址" bracketSubLabel="English Address" tip={WORKDAY_TIP}/>} rules={[ RequiredRule ]}>
                  <Input data-testid={testIdPersonalInfoHomeAddress} disabled />
                </Form.Item>
              </Col>
            </Row>
          </>
        }
        {
          !isEmployee &&  <Form.Item
            className="work-experience"
            name={[ 'personalInfo', 'hasWorkExperience' ]}
            label={<EmployeeFormLabel label="入职前是否有全职工作经历？" bracketSubLabel="Any full-time experience before joining Thoughtworks?"/>}
            rules={[ RequiredRule ]}
          >
            <Radio.Group>
              <Radio value={true}>有</Radio>
              <Radio value={false}>无</Radio>
            </Radio.Group>
          </Form.Item>
        }

      </div>
      <header className="invention-header">发明或改进信息 (Inventions Or Improvements)</header>
      <div className="invention-header-tip">
        <Icon className="invention-header-tip-icon" component={InfoIcon}/>
        <div>
          <p>
            以下信息将会自动同步在正式入职签订的保密协议中，请确保以下信息真实、准确。
          </p>
          <p>
            Please make sure all the information below is true and accurate as the information will be filled in the contract.
          </p>
        </div>

      </div>
      <div className="form-group">
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              className="invention-radio-form"
              name={[ 'personalInfo', 'hasInvention' ]}
              /* eslint-disable-next-line max-len */
              label={<EmployeeFormLabel label="进入Thoughtworks工作之前是否有独自或与他人共同创作、 构想或首次实施、与为Thoughtworks工作的主题事项有关的所有发明或改进？" bracketSubLabel="Do you have any inventions or improvements relevant to the subject matter of your employment with Thoughtworks that have been made or conceived or first reduced to practice by you along or jointly with others prior to your employment with the Company?" />}
              rules={[ RequiredRule ]}
            >
              <Radio.Group options={options} optionType="button" buttonStyle="solid"
                onChange={({ target: { value } }) => changeHasInventionRadio(value)}/>
            </Form.Item>
          </Col>
        </Row>
        {
          hasInvention && (
            <>
              <label className="invention-label">可以披露的发明或改进 (Inventions or Improvements that can be disclosed)</label>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item className="form-sub-item" name={[ 'personalInfo', 'publicInventionInfo', 'description' ]}
                    label="请列举可以披露的所有相关发明或改进">
                    <Input.TextArea data-testid={testIdPersonalInfoPublicInventionInfoDescription} showCount maxLength={130}
                      placeholder="如可以，请用中文填写"/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className="form-sub-item" name={[ 'personalInfo', 'publicInventionInfo', 'descriptionEnglish' ]}
                    label="Please list all the inventions or improvements disclosure">
                    <Input.TextArea data-testid={testIdPersonalInfoPublicInventionInfoDescriptionEnglish} showCount maxLength={250}
                      placeholder="Please fill in English if it is possible"/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    className="form-sub-item"
                    name={[ 'personalInfo', 'publicInventionInfo', 'hasAdditionalNotes' ]}
                    label={<EmployeeFormLabel label="是否需要另附纸张补充说明可以披露的所有发明或改进？" bracketSubLabel="Whether additional pages attached?" />}
                  >
                    <Radio.Group data-testid="hasAdditionalNotes" onChange={handleDisclosedInventionChange}>
                      <Radio value={true}>需要</Radio>
                      <Radio value={false}>不需要</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {hasAdditionalNotes && <Row gutter={12}>
                <Col span={24}>
                  <Form.Item
                    className="form-sub-item"
                    valuePropName="filelist"
                    name={[ 'personalInfo', 'publicInventionInfo', 'disclosedInventions' ]}
                    label={<EmployeeFormLabel label="补充说明" bracketSubLabel="Additional Attachments" />}
                    htmlFor=""
                    rules={[ RequiredAttachmentRule ]}
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      {...disclosedInventionsUploadConfig}
                      defaultFileList={form.getFieldValue([ 'personalInfo', 'publicInventionInfo', 'disclosedInventions' ])}>
                      <Button size="small" icon={<Icon className="upload-icon" component={UploadSvg} />}>上传文件</Button>
                      <span className="file-size-limit" onClick={e => e.stopPropagation()}>
                        文件数限制 (Max files)：5；单个文件大小 (Max File Size)：10M；文件格式 (File Type): PDF, JPEG, PNG
                      </span>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>}
              <label className="invention-label private-invention">不便披露的发明或改进 (Inventions or Improvements that cannot be disclosed)</label>
              {/* eslint-disable-next-line max-len */}
              <div className="add-invention-description">添加由于在先法定义务，无法进行上述部分要求的披露的以前的发明或改进。对于该以前的发明或改进，以下各当事方享有专有权利并且我对其负有保密义务。（PS：在保密等义务允许的颗粒度下概括性的列明即可）</div>
              {/* eslint-disable-next-line max-len */}
              <div className="add-invention-description add-invention-description-en">Add prior inventions or improvements that you can’t complete the disclosures under above section with respect due to a prior legal obligation. For prior inventions or improvements, the proprietary rights and duty of confidentiality with respect to which I owe to the following parties. (PS: It can be listed in general under the obligation of confidentiality)</div>
              { renderAddExtraDesc() }
            </>
          )
        }
      </div>
    </>
  );
};

export default PersonalInfo;
