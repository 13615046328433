import { Button, Popconfirm } from 'antd';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { noop } from 'lodash-es';
import { useCustomMatomo } from '@/matomo';

interface IFormSubmitButtonProps {
  form,
  onSubmit: () => void,
  setPopOpen: (arg) => void,
  popOpen: boolean;
  title?: string
  width?: number
  disabled?: boolean;
  buttonText?: string;
}

const FormSubmitButton = ({ form, onSubmit, setPopOpen, popOpen, title, width, disabled, buttonText }: IFormSubmitButtonProps) => {
  const DEFAULT_TITLE = '确认提交员工信息？';
  const DEFAULT_WIDTH = 222;
  const DEFAULT_BUTTON_TEXT = '提交';

  const { trackEvent } = useCustomMatomo();
  const validateForm = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeOperateEmployeeInfo,
      action: MATOMO_ACTION.Submit,
      name: MATOMO_NAME.PersonalInfo,
    });
    form.validateFields().then(() => {
      setPopOpen(true);
    }, noop);
  };

  return (
    <Popconfirm
      className="confirm-popover"
      placement="bottomLeft"
      open={popOpen}
      title={title ? title : DEFAULT_TITLE}
      onConfirm={onSubmit}
      onCancel={() => setPopOpen(false)}
      okText="确认"
      cancelText="取消"
      getPopupContainer={triggerNode => triggerNode}
      overlayStyle={{ width: width ? width : DEFAULT_WIDTH }}
    >
      <span className="submit-btn">
        <Button type="primary" onClick={validateForm}
          disabled={disabled ? disabled : false}>{buttonText ? buttonText : DEFAULT_BUTTON_TEXT}</Button>
      </span>
    </Popconfirm>
  );
};
export default FormSubmitButton;
