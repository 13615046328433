import '@/components/button/Button.scss';

interface ButtonProps {
    title: string;
    disabled?: boolean;
    onClick?: () => void;
}

const Button = (props: ButtonProps) => {
  const { title, disabled = false, onClick } = props;
  return (
    <button disabled={disabled} className={'e-sign-button'} onClick={onClick}>
      {title}
    </button>
  );
};

export default Button;
