export const CONTRACT_INDUCTION_READ_PERMISSION = 'contract_induction.read';


export const CERTIFICATE_READ_PERMISSION = 'certificate.read';


export const CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION = 'contract_extension_willingness.read';
export const CONTRACT_EXTENSION_WILLINGNESS_EDIT_PERMISSION = 'contract_extension_willingness.edit';
export const CONTRACT_EXTENSION_PROCESS_READ_PERMISSION =   'contract_extension_process.read';
export const CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION = 'contract_extension_process.edit';


export const EXIT_LIST_READ_PERMISSION = 'exit_list.read';
export const EXIT_TYPE_READ_PERMISSION = 'exit_type.read';
export const EXIT_APPROVAL_LIST_READ_PERMISSION = 'exit_approval_list.read';
export const EXIT_SIGNING_LIST_READ_PERMISSION = 'exit_signing_list.read';
export const EXIT_SIGNING_PERMISSION = 'exit_signing';
export const EXIT_INITIATE_INVOLUNTARY_SIGNING_PERMISSION = 'exit_initiate_involuntary_signing';
export const EXIT_INITIATE_APPROVAL_PERMISSION = 'exit_initiate_approval';
export const EXIT_USER_ADDITIONAL_INFO_READ_PERMISSION = 'exit_user_additional_info.read';
export const EXIT_APPROVAL_ADMIN = 'exit_approval_admin';
export const EXIT_APPROVAL_OFFICE_HRO = 'exit_approval_office_hro';
export const EXIT_APPROVAL_BUSINESS_TEAM = 'exit_approval_business_team';
export const EXIT_APPROVAL_WORKSPACE = 'exit_approval_workspace';
export const EXIT_APPROVAL_LEAVE = 'exit_approval_leave_hro';
export const EXIT_APPROVAL_PROGRESS_READ = 'exit_approval_progress.read';
export const EXIT_APPROVAL_COMMENT_ADD = 'exit_comment.add';
export const EXIT_APPROVAL_COMMENT_READ = 'exit_comment.read';
export const EXIT_INVOLUNTARY_EMPLOYEE_ADD_PERMISSION = 'exit_involuntary_employee.add';
export const EXIT_SIGNED_CONTRACT_READ = 'exit_signed_contract.read';
export const EXIT_HISTORY_READ = 'exit_history.read';
export const EXIT_HISTORY_EDIT = 'exit_history.edit';
export const EXIT_UNSIGNED_CONTRACT_READ = 'exit_unsigned_contract.read';
export const EXIT_HISTORY_PREVIEW = 'exit_history_preview';
export const EXIT_HISTORY_DOWNLOAD = 'exit_history_download';

