import { useState } from 'react';
import { DatePicker, DatePickerProps, message, Modal, Select, SelectProps } from 'antd';
import { searchWorkingUser } from '@/api/employeeApi';
import moment, { Moment } from 'moment';
import { addExitInvoluntaryEmployee } from '@/api/exitApi';
import './ExitAddEmployeeModal.scss';

interface ExitAddEmployeeModalProps {
  isModalOpen: boolean,
  closeModal: (needRefreshData: boolean) => void
}

const ExitAddEmployeeModal = ({ isModalOpen, closeModal }: ExitAddEmployeeModalProps) => {
  const [ data, setData ] = useState<SelectProps['options']>([]);
  const [ employeeId, setEmployeeId ] = useState<string>(null);
  const [ date, setDate ] = useState<string>(null);

  const handleSearch = async (newValue: string) => {
    if (newValue.length === 5) {
      const user = await searchWorkingUser(newValue);
      if (user) {
        setData([ { value: user.employeeId, label: `${user.employeeId} ${user.chineseName}` } ]);
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  };

  const handleEmployeeIdChange = (value: string) => {
    setEmployeeId(value);
  };

  const handleOk = async () => {
    try {
      const requestData = {
        employeeId,
        terminationDate: date,
      };
      await addExitInvoluntaryEmployee(requestData);
      closeModal(true);
      message.success('添加成功');
    } catch (e) {
      closeModal(true);
      message.error('添加失败，请重试！');
    }
    setEmployeeId(null);
    setDate(null);
    setData([]);
  };

  const handleCancel = async () => {
    setEmployeeId(null);
    setDate(null);
    setData([]);
    closeModal(false);
  };

  const disabledDate: DatePickerProps['disabledDate'] = current => {
    const today = moment().format('YYYY-MM-DD');

    return current && current < moment().add(today, 'day').startOf('day');
  };

  const handleDateOnChange = (date: Moment, dateString: string) => {
    setDate(dateString);
  };

  return (
    <Modal
      wrapClassName="exit-add-employee-modal"
      width={492}
      open={isModalOpen}
      centered
      keyboard={false}
      destroyOnClose={true}
      maskClosable={false}
      title="请完成以下操作"
      okText="确认"
      okButtonProps={{ 'disabled': !(employeeId && date) }}
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className="input-label"><span>* </span>搜索员工</div>
      <div className="select">
        <Select
          showSearch
          suffixIcon={<i className="ri-search-line" />}
          style={{ width: '100%' }}
          value={employeeId}
          placeholder="请输入员工ID"
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleEmployeeIdChange}
          options={data}
        />
      </div>
      <div className="input-label"><span>* </span>离职日期</div>
      <div className="select">
        <DatePicker
          placeholder="请选择日期"
          disabledDate={disabledDate}
          onChange={handleDateOnChange}
          size='middle'
          style={{ width: '30%' }}
        />
      </div>
      <div className="input-label"><span>* </span>离职类型</div>
      <p>被动离职</p>
    </Modal>
  );
};

export default ExitAddEmployeeModal;
