// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import {
  IEmployeeSignedDoneDetail,
  IEmployeeSignedDoneResponse
} from '@/models/employee';
import { archiveEmployeeContract, getEmployeeSignedDoneInfo } from '@/api/employeeApi';
import {
  employeeSignedDoneDetailColumns
} from '@/constants/config/hro/entry-contract-process/signed-done-table-info/SignedDoneTableInfoConfig';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { API_CONTRACT_HIRE } from '@/api/consts';
import ArchiveTable from '@/components/archive-table/ArchiveTable';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import PreviewIcon from '@/assets/icons/preview.svg';
import ArchiveComponent from '@/components/archive-component/ArchiveComponent';
import DownloadContractButton from '@/components/download-contract-button/DownloadContractButton';
import { HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME } from '@/constants/processTaskName';

const employeeContractFinishStatus = [
  'FINISH',
  'ARCHIVING',
  'ARCHIVED',
];

interface TableInfoProps {
  totalCount: number,
}

const SignedDoneTableInfo = (props: TableInfoProps) => {
  const { totalCount } = props;
  const [ SignedDoneData, setSignedDoneData ] = useState<IEmployeeSignedDoneDetail[]>([]);
  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();

  useEffect(() => {
    if (userInfo && hasPermission) {
      getEmployeeSignedDoneInfo().then((res: IEmployeeSignedDoneResponse) => {
        setSignedDoneData(res.data);
      });
    }
  }, [ userInfo, hasPermission ]);

  const confirmBatchArchive = reqBody => {
    archiveEmployeeContract(API_CONTRACT_HIRE, reqBody).then(() => {
      getEmployeeSignedDoneInfo().then((res: IEmployeeSignedDoneResponse) => {
        setSignedDoneData(res.data);
      });
    });
  };

  const getColumns = () => {
    return [
      ...employeeSignedDoneDetailColumns,
      {
        title: '合同管理',
        dataIndex: 'contractSignStatus',
        width: 314,
        className: 'last-td align-center',
        render: (_, record) => {
          return (
            <div className='operation-button-column'>
              <div data-testid='operation-item-buttons'>
                {/* eslint-disable-next-line max-len */}
                <Link to={`/hro-home/contract-preview/${record.employeeId}/${record.contractSignProcessId}?tab=CONFIRMED`}>
                  <Button size="small" type="text" icon={<Icon className="preview-icon" component={PreviewIcon}/>}
                    disabled={!employeeContractFinishStatus.includes(record.contractSignStatus)}>
                    预览
                  </Button>
                </Link>
                <ArchiveComponent employeeId={record.employeeId} contractSignTaskName={HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME}
                  contractSignStatus={record.contractSignStatus}
                  disabled={!employeeContractFinishStatus.includes(record.contractSignStatus)}
                  confirmBatchArchive={confirmBatchArchive}/>
                <DownloadContractButton employeeId={record.employeeId} contractSignTaskName={HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME}
                  contractSignProcessId={record.contractSignProcessId} hireDate={record.hireDate}
                  legalFullName={record.legalFullName} disabled={!employeeContractFinishStatus.includes(record.contractSignStatus) }/>
              </div>
            </div>
          );
        },
      },
    ];
  };

  return (
    <ArchiveTable columns={getColumns()} dataSource={SignedDoneData} contractSignTaskName={HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME}
      tablePagination={{ defaultCurrent: 1, defaultPageSize: 20, total: totalCount, showSizeChanger: false }}
      confirmBatchArchive={confirmBatchArchive}/>
  );
};
export default SignedDoneTableInfo;
