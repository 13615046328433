import { getWebBaseUrl } from '@/api/axiosApi';
import { downloadFile } from '@/api/employeeApi';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './FilePreviewer.scss';
import FailedToLoadPic from '@/assets/failed_to_load.png';

pdfjs.GlobalWorkerOptions.workerSrc = `${getWebBaseUrl()}/pdf.worker.min.js`;

interface IFilePreviewerProps {
  storageUrl?: string;
  fileName: string;
}

const FilePreviewer = ({ fileName, storageUrl }: IFilePreviewerProps) => {
  const [ numPages, setNumPages ] = useState(null);
  const [ objectUrl, setObjectUrl ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ currentTime, setCurrentTime ] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const reload = () => {
    setErrorMessage('');
    setCurrentTime(Date.now());
  };
  useEffect(() => {
    let url = null;
    downloadFile(storageUrl,)
      .then(data => {
        url = URL.createObjectURL(data);
        setObjectUrl(url);
      }).catch(() => {
        setErrorMessage('Fail to load PDF file.');
      });
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [ storageUrl, currentTime ]);

  if (!objectUrl && !errorMessage) {
    return <Spin className="file-loading" size="large"/>;
  }

  if (!fileName.toLowerCase().endsWith('pdf')) {
    return <img className="preview-image" src={objectUrl}/>;
  }
  if (errorMessage) {
    return <div className={'file-load-failed'}>
      <div>
        <img src={FailedToLoadPic}/>
      </div>
      <div className={'file-load-failed-hint'}>
        <p>加载失败,请</p><a onClick={reload}>重新刷新</a>
      </div>
    </div>;
  }
  return (
    <Document
      file={objectUrl}
      loading={<Spin size="large"/>}
      noData={errorMessage}
      onLoadSuccess={onDocumentLoadSuccess}
      options={{
        cMapUrl: `${getWebBaseUrl()}/cmaps/`,
        cMapPacked: true,
      }}>
      {Array.from(
        new Array(numPages),
        (_, index) => (
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            key={index}
            pageNumber={index + 1}
          />
        ),
      )}
    </Document>
  );
};

export default FilePreviewer;
