import { Button, Drawer, FormInstance, Popconfirm } from 'antd';
import {
  IContractExtensionInformationResponse,
  IContractExtensionNotSignInformationResponse,
  IHireInformationResponse
} from '@/models/employee';
import { EmployeeInfoForm } from '@/components/employee-form/EmployeeForm';
import {
  ContractExtensionNotSignFieldConfig
} from '@/constants/config/employee-form/will-not-extension-table/FieldValidatorConfig';
import './CustomDrawer.scss';
import {
  ContractExtensionFieldConfig
} from '@/constants/config/employee-form/will-extension-table-to-be-audited-table/FieldValidatorConfig';
import {
  HireFieldConfig
} from '@/constants/config/employee-form/entry-contract-process-to-be-audited-table/FieldValidatorConfig';

interface ButtonWithDrawerProps {
  form: FormInstance;
  employeeInfo: IContractExtensionNotSignInformationResponse | IContractExtensionInformationResponse | IHireInformationResponse;
  drawerTitle: string;
  drawerOpen: boolean;
  closeDrawer: () => void;
  drawerConfirm: () => void;
  popOpen: boolean;
  displayPop: () => void;
  closePop: () => void;
  fieldValidatorConfig: ContractExtensionNotSignFieldConfig[][] | ContractExtensionFieldConfig[][] | HireFieldConfig[][];
  subTitle?: string;
}

export const CustomDrawer = ({ form, employeeInfo, drawerTitle, drawerOpen, closeDrawer, drawerConfirm,
  popOpen, displayPop, closePop, fieldValidatorConfig, subTitle
}: ButtonWithDrawerProps) => {

  const renderDrawerTitle = () => (
    <div>
      <span className="headline4 title">{drawerTitle}</span>
      <span className="medium-text employee-id">工号: {employeeInfo?.employeeId}</span>
    </div>
  );

  const renderDrawerExtra = () => (
    <div className="flex">
      <Button onClick={closeDrawer}>取消</Button>
      <Popconfirm
        title="确认标记审核通过？"
        open={popOpen}
        onCancel={closePop}
        onConfirm={drawerConfirm}
        cancelText={'取消'}
        okText={'确认'}
        placement="bottomRight">
        <Button type="primary" style={{ marginLeft: 8 }} onClick={displayPop}>通过</Button>
      </Popconfirm>
    </div>
  );

  return (
    <Drawer
      width={720}
      title={renderDrawerTitle()}
      placement="right"
      closable={false}
      open={drawerOpen}
      destroyOnClose={true}
      style={{ marginTop: 64 }}
      extra={renderDrawerExtra()}>
      <EmployeeInfoForm employeeInfo={employeeInfo} form={form} fieldValidatorConfig={fieldValidatorConfig} subTitle={subTitle}/>
    </Drawer>
  );
};
