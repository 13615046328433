import './ChangeRoleToggle.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserRole, setCurrentUserRole } from '@/store/userInfo';
import { ROLE_ADMIN, ROLE_USER } from '@/constants/role';

const ChangeRoleToggle = () => {
  const currentUserRole = useSelector(selectCurrentUserRole, shallowEqual);
  const dispatch = useDispatch();

  // todo change user role when path change
  return (
    <span className="change-role-toggle">
      <button className={ `role-button ${currentUserRole === ROLE_USER && 'active'}`}
        onClick={() => dispatch(setCurrentUserRole(ROLE_USER))}>
        <i className="ri-user-3-line"></i>
        <span>员工</span>
      </button>
      <button className={ `role-button ${currentUserRole === ROLE_ADMIN && 'active'}`}
        onClick={() => dispatch(setCurrentUserRole(ROLE_ADMIN))}>
        <i className="ri-admin-line"></i>
        <span>管理员</span>
      </button>
    </span>
  );
};

export default ChangeRoleToggle;
