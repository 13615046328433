import { Button, message } from 'antd';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { debounce } from 'lodash-es';
import { IEmployeeMaterialsAndUpdateDate } from '@/models/employee';
import Icon from '@ant-design/icons';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import {
  downloadFile,
  getAllEmployeeMaterials,
  getUserContractByProcessId, getWillingnessConfirmAttachmentByProcessId
} from '@/api/employeeApi';
import { useCustomMatomo } from '@/matomo';
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types';
import {
  CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME, CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME,
  CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME, EXIT_CONTRACT_SIGNING_PROCESS_TASK_NAME,
  HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME
} from '@/constants/processTaskName';
import { saveFilesAsZip } from '@/utils/fileUtil';
import DownloadSvg from '@/assets/icons/download.svg';

interface ButtonWithArchiveProps {
  employeeId: string;
  contractSignTaskName: string;
  contractSignProcessId: string;
  hireDate: string;
  contractExpirationDate?: string;
  terminationDate?: string;
  legalFullName: string,
  disabled?: boolean,
}

const DownloadContractButton = (props: ButtonWithArchiveProps) => {
  const { employeeId, contractSignTaskName, hireDate, legalFullName, terminationDate,
    contractExpirationDate, contractSignProcessId, disabled } = props;
  const { trackEvent } = useCustomMatomo();

  const generateZipFileName =
    () => {
      const shortHireDate = hireDate.replaceAll('-', '');
      const shortExpirationDate = contractExpirationDate?.replaceAll('-', '');
      const shortTerminationDate = terminationDate?.replaceAll('-', '');
      // eslint-disable-next-line default-case
      switch (contractSignTaskName) {
      case HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME:
        return `${employeeId}-${legalFullName}-入职文件-${shortHireDate}`;
      case CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME:
        return `${employeeId}-${legalFullName}-续签文件-${shortHireDate}-${shortExpirationDate}`;
      case CONTRACT_EXTENSION_COMPANY_NOT_SIGN_PROCESS_TASK_NAME:
      case CONTRACT_EXTENSION_EMPLOYEE_NOT_SIGN_PROCESS_TASK_NAME:
        return `${employeeId}-${legalFullName}-不续签文件-${shortHireDate}`;
      case EXIT_CONTRACT_SIGNING_PROCESS_TASK_NAME:
        return `${employeeId}-${legalFullName}-离职文件-${shortHireDate}-${shortTerminationDate}`;
      }
    };

  const downloadContract = debounce(
    (
      trackEvent: (params: TrackEventParams) => void,
    ) => {
      trackEvent({
        category: MATOMO_CATEGORY.HroEmployeeContract,
        action: MATOMO_ACTION.Download,
        name: MATOMO_NAME.Contract,
      });
      const hideMessage = message.loading('下载中，请稍等', 0);
      let allContractFiles = [];
      Promise.all([
        getUserContractByProcessId(contractSignProcessId, { noNeedLoading: true }),

        [ HIRE_CONTRACT_SIGN_PROCESS_TASK_NAME, CONTRACT_EXTENSION_SIGN_PROCESS_TASK_NAME ].includes(contractSignTaskName)
          ? getAllEmployeeMaterials({ employeeId, noNeedLoading: true })
          : Promise.resolve({} as IEmployeeMaterialsAndUpdateDate),

        getWillingnessConfirmAttachmentByProcessId(contractSignProcessId, { noNeedLoading: true }),
      ])
        .then(([ contractMaterials, { materialsData: employeeMaterials }, { userFileMappingInfo: attachmentMaterials } ]) => {
          allContractFiles = [];
          Object.values({ ...contractMaterials, ...employeeMaterials, ...attachmentMaterials })
            .filter(item => !!item)
            .forEach(files => {
              allContractFiles.push(...files);
            });
          return Promise.all(allContractFiles.map(fileInfo => downloadFile(fileInfo.fileUrl)));
        })
        .then(files => {
          return saveFilesAsZip(
            files.map((file, index) => ({ blob: file, name: allContractFiles[index].contractFileName ||
                allContractFiles[index].fileName })),
            generateZipFileName(),
          );
        }).then(() => {
          message.success('下载已完成');
        }).catch(() => {
          message.error('下载失败，请重试');
        }).finally(() => {
          hideMessage();
        });
    }, 1000, { leading: true, trailing: false });

  return (<>
    <Button
      disabled={disabled}
      size="small"
      type="text"
      icon={<Icon className="download-icon" component={DownloadSvg}/>}
      onClick={() => downloadContract(trackEvent)}
    >
      下载
    </Button>
  </>);
};

export default DownloadContractButton;
