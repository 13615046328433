import NoPermissionIcon from '@/assets/no-permission.svg';
import '@/pages/no-permission/noPermission.scss';

const NoPermission = () => {
  return (
    <div className="no-permission-align-center ">
      <img width="60%" height="50%" src={NoPermissionIcon}/>
      <div className="no-permission-hint">
        <span>抱歉，你无权访问该页面</span>
        <span>
            如有问题，请联系 <span className="hr-email">china-regional-it-wonder-it@thoughtworks.com</span>
        </span>
      </div>
    </div>
  );
};

export default NoPermission;
