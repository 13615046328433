import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RouterObjectExtend } from '@/models/common';
import { useSelector } from 'react-redux';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { useCustomMatomo } from './matomo';
import { routerConfig } from '@/router';
import { selectLoading } from '@/store';
import { getPageTitle } from '@/utils/url';

function App() {
  const [ routes ] = useState<RouterObjectExtend[]>([]);
  const spinning = useSelector(selectLoading);
  const { trackPageView } = useCustomMatomo();

  useEffect(() => {
    if (!routes.length) {
      return;
    }
    document.title = getPageTitle(routes);
    trackPageView();
  }, [ window.location.pathname ]);

  return (
    <ConfigProvider locale={zhCN}>
      <div className="app-main" style={{ height: '100%' }}>
        { spinning && <Spin className="global-spinning" size="large" /> }
        <RouterProvider router={
          createBrowserRouter(routerConfig, {
            basename: window.__POWERED_BY_QIANKUN__ ? '/e-sign' : '/'
          })
        } />
      </div>
    </ConfigProvider>
  );
}

export default App;
