import { Button, message, Popconfirm, Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { IHireInfo, IUploadAttachmentItems } from '@/models/employee';
import {
  confirmPreviewCheck,
  getEmployeeDiscloseInvention,
  getEmployeeHireInfo,
  getUserContractByProcessId, getWillingnessConfirmAttachmentByProcessId,
  rejectPreviewCheck
} from '@/api/employeeApi';
import { useNavigate, useParams } from 'react-router-dom';
import '@/pages/hro/preview-check/PreviewCheck.scss';
import FilePreviewer from '@/components/file-previewer/FilePreviewer';
import { filter, includes } from 'lodash-es';
import { CONTRACT_SIGN_TYPE, employeeContractInfo } from '@/constants/employee';
import { PerformanceTracker, useCustomMatomo } from '@/matomo';
import { contractMatomo, MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { matomoName } from '@/pages/employee/my-contract/MyContract';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { CONTRACT_EXTENSION_TAB_STATUS, EMPLOYEE_TAB_STATUS } from '@/constants/status';

interface PreviewCheckProps {
  showAuditButton?: boolean;
  showWillingnessConfirmAttachments?: boolean;
}

const PreviewCheck = ({ showAuditButton, showWillingnessConfirmAttachments }: PreviewCheckProps) => {
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>(null);
  const [ contracts, setContracts ] = useState([]);
  const { employeeId, processId } = useParams();
  const [ popOpen, setPopOpen ] = useState(false);
  const [ popRejectOpen, setPopRejectOpen ] = useState(false);
  const navigate = useNavigate();
  const { trackEvent } = useCustomMatomo();
  const { hasPermission } = useRouterGuard();
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    if (!userInfo || !hasPermission) {
      return;
    }
    Promise.all([
      getEmployeeHireInfo(employeeId),
      getUserContractByProcessId(processId),
      getEmployeeDiscloseInvention(employeeId, processId),
      showWillingnessConfirmAttachments
        ? getWillingnessConfirmAttachmentByProcessId(processId) : Promise.resolve({} as IUploadAttachmentItems),
    ]).then(
      ([ hireInfo, contractMaterials, discloseInventions, attachments ]) => {
        setEmployeeInfo(hireInfo);
        const materialKeys = Object.keys(contractMaterials || []);
        const tabItems = filter(employeeContractInfo, config => includes(materialKeys, config.key)).map(config => ({
          ...config,
          children: (
            <div className="materials">
              {contractMaterials[config.key].map(fileInfo => (
                <div key={fileInfo.contractTemplateCode}>
                  <FilePreviewer
                    fileName={fileInfo.contractFileName}
                    storageUrl={fileInfo.fileUrl}/>
                </div>
              ))}
            </div>
          )
        }));
        if (showWillingnessConfirmAttachments && attachments?.userFileMappingInfo?.willingConfirmAttachment?.length > 0) {
          tabItems.push({
            label: '附件',
            key: 'willingConfirmAttachment',
            children: (
              <div className="materials">
                {attachments.userFileMappingInfo?.willingConfirmAttachment.map(fileInfo => (
                  <div key={fileInfo.fileId}>
                    <FilePreviewer
                      fileName={fileInfo.fileName}
                      storageUrl={fileInfo.fileUrl}/>
                  </div>
                ))}
              </div>
            )
          });
        }
        if (discloseInventions?.length > 0) {
          tabItems.push({
            label: '发明或改进补充说明',
            key: 'additionalAttachment',
            children: (
              <div className="materials">
                {discloseInventions.map(fileInfo => (
                  <div key={fileInfo.fileId}>
                    <FilePreviewer
                      fileName={fileInfo.fileName}
                      storageUrl={fileInfo.fileUrl}/>
                  </div>
                ))}
              </div>
            )
          });
        }
        setContracts(tabItems);
      });
    setContracts(contracts);
  }, [ employeeId ]);

  useEffect(() => {
    PerformanceTracker.start();
  }, []);

  const displayPopConfirm = useCallback(() => {
    setPopOpen(true);
  }, []);

  const closePopConfirm = useCallback(() => {
    setPopOpen(false);
  }, []);

  const rejectSubmit = useCallback(() => {
    trackEvent(contractMatomo[employeeInfo.contractSignType]);

    rejectPreviewCheck(processId).then(() => {
      message.info('签署文件已被驳回，请重新审核该员工');
      if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
        navigate(`/hro-home?tab=${EMPLOYEE_TAB_STATUS.PENDING}`);
      } else if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE) {
        navigate(`/contract-extension-home/contract-extension-process?tab=${EMPLOYEE_TAB_STATUS.PENDING}`);
      } else if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE) {
        navigate(`/contract-extension-home/contract-extension-process?tab=${CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION}`);
      }
    }).catch(() => {
      message.error('签署文件驳回失败，请重新操作');
      if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
        navigate(`/hro-home?tab=${EMPLOYEE_TAB_STATUS.SIGNATURE}`);
      } else if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE) {
        navigate(`/contract-extension-home/contract-extension-process?tab=${EMPLOYEE_TAB_STATUS.SIGNATURE}`);
      } else if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE) {
        navigate(`/contract-extension-home/contract-extension-process?tab=${CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION}`);
      }
    });
  }, [ employeeInfo ]);

  const confirmSubmit = useCallback(() => {
    if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
      trackEvent({
        category: MATOMO_CATEGORY.HroInductionToBeSigned,
        action: `${MATOMO_ACTION.Confirm} ${MATOMO_ACTION.PassAudit}`,
        name: MATOMO_NAME.Contract,
        value: PerformanceTracker.stop(),
      });
    }
    confirmPreviewCheck(processId).then(() => {
      message.success('签署文件已审核通过');
      if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
        navigate(`/hro-home?tab=${EMPLOYEE_TAB_STATUS.PENDING}`);
      } else if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE) {
        navigate(`/contract-extension-home/contract-extension-process?tab=${EMPLOYEE_TAB_STATUS.SIGNATURE}`);
      } else if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE) {
        navigate(`/contract-extension-home/contract-extension-process?tab=${CONTRACT_EXTENSION_TAB_STATUS.WILL_NOT_EXTENSION}`);
      }
    });
  }, [ employeeInfo ]);

  const handleTabChange = useCallback((activeKey: string) => {
    if (employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE) {
      trackEvent({
        category: showAuditButton ? MATOMO_CATEGORY.HroInductionToBeSigned : MATOMO_CATEGORY.HroEmployeeContract,
        action: `${MATOMO_ACTION.SwitchTab} to preview`,
        name: matomoName.find(name => name.label === employeeContractInfo.find(item => item.key === activeKey)?.label) != null
          ? matomoName.find(name => name.label === employeeContractInfo.find(item => item.key === activeKey)?.label).matomoName
          : 'Additional attachment',
      });
    }
  }, [ employeeInfo ]);

  const popRejectTitle = () => {
    return (<div>
      <span style={{ display: 'block' }}>确认签署文件信息有误并驳回？</span>
      <span className="reject-info-pop">{employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE
        ? '驳回后需再次审核员工信息' : '驳回后需再次审核员工信息和入职材料'}</span>
    </div>);
  };

  return (
    <>
      {employeeInfo &&
        <div className="preview-check-container">
          <div className="preview-check-content">
            <div className="employee-basic-info-container">
              <div className="employee-basic-info">
                <span className="employee-name">{employeeInfo.legalFullName?.trim()}</span>
                <span className="employee-id">{employeeInfo.employeeId}</span>
                <span className="employee-info-item">{employeeInfo.homeOffice}</span>
                <span className="employee-info-item">{employeeInfo.nationalityCn}</span>
                <span className="employee-info-item">{employeeInfo.employmentType}</span>
                {employeeInfo.contractSignType === CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE &&
                  <span className="employee-info-item">{employeeInfo.hireType}</span>}
                <span className="employee-info-item last-item">{employeeInfo.position}</span>
              </div>
              {showAuditButton &&
                <div>
                  <Popconfirm
                    title={popRejectTitle}
                    open={popRejectOpen}
                    onCancel={() => setPopRejectOpen(false)}
                    onConfirm={rejectSubmit}
                    cancelText={'取消'}
                    okText={'确认'}
                    placement="bottomRight"
                    overlayStyle={{ width: 312 }}
                    getPopupContainer={triggerNode => triggerNode}>
                    <Button className="contract-sign-reject-button" onClick={() => setPopRejectOpen(true)}>驳回</Button>
                  </Popconfirm>
                  <Popconfirm
                    title="确认标记审核通过？"
                    open={popOpen}
                    onCancel={closePopConfirm}
                    onConfirm={confirmSubmit}
                    cancelText={'取消'}
                    okText={'确认'}
                    placement="bottomRight"
                    overlayStyle={{ width: 222 }}
                    getPopupContainer={triggerNode => triggerNode}>
                    <Button type="primary" onClick={displayPopConfirm}>通过</Button>
                  </Popconfirm>
                </div>}
            </div>
            <Tabs
              defaultActiveKey="hireContract"
              items={contracts}
              onChange={handleTabChange}
            />
          </div>
        </div>
      }
    </>
  );
};

export default PreviewCheck;
