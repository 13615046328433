import { MENU_KEY } from '@/constants/menuConfig';
import { CONTRACT_SIGN_TYPE } from '@/constants/employee';

export const MATOMO_CATEGORY = {
  EmployeeMenu: 'Employee: menu',
  EmployeeOperateEmployeeInfo: 'Employee: operation items(employee info)',
  EmployeeOperateInductionMaterials: 'Employee: operation items(induction materials)',
  EmployeeOperateSign: 'Employee: operation items(sign)',
  EmployeeContract: 'Employee: my contract',
  EmployeeCertificateApply: 'Employee: certificate apply',
  EmployeeApplicationHistory: 'Employee: application history',
  EmployeeLegalEffect: 'Employee: legal effect',
  EmployeeUploadExtensionDocuments: 'Employee: upload extension documents',
  HroMenu: 'Hro: menu',
  HroIssueCertificate: 'HRO: issue certificate',
  HroInductionToBeAudited: 'HRO: to be audited',
  HroInductionToBeSigned: 'HRO: to be signed',
  HroInductionSignedDone: 'HRO: signed done',
  HroEmployeeContract: 'HRO: employee contract',
  HroToBeStamped: 'HRO: to be stamped',
  HroStampedDone: 'HRO: stamped done',
  HroAuditInfoForExtension: 'HRO: audit info for extension',
  HroAuditDocumentsForExtension: ' HRO: audit documents for extension ',
  HroAuditExtensionContract: 'HRO: audit extension contract',
  HRBPContractExtensionWillingness: 'HRBP: contract extension willingness',
  HRBPWithdrawProcess: 'HRBP: withdraw process',
  HroAuditInfoForNoExtension: 'HRO: audit info for no extension',
  HroAuditNoExtensionContract: 'HRO: audit no extension contract',

  FrontEndError: 'Front end error',
};

export const MATOMO_ACTION = {
  Navigation: 'Navigation',
  Click: 'Click',
  Submit: 'Submit',
  PassAudit: 'Pass audit',
  RejectAudit: 'Reject audit',
  Confirm: 'Confirm',
  Add: 'Add',
  Upload: 'Upload',
  Download: 'Download',
  Remove: 'Remove',
  Step: 'Change step',
  SwitchTab: 'Switch tab',
  Preview: 'Preview',
  Archive: 'Archive',
  BatchArchive: 'Batch archive',
  Cancel: 'Cancel',
  Close: 'Close',
  Initiate: 'Initiate',
  Stamp: 'Stamp',
  Link: 'Link',
  Agree: 'Agree',
  ViewRejectReason: 'View reject reason',
  InputRejectReason: 'Input reject reason',
  SelectOption: 'Select option',
  Extension: 'Extension',
  NoExtension: 'No extension',
  Withdraw: 'Withdraw'

};

export const MATOMO_NAME = {
  PersonalInfo: 'Personal info',
  InductionMaterial: 'Induction materials',
  InductionMaterialFile: 'Induction material file',
  PrivateInvention: 'Private invention',
  WorkExperience: 'Work experience',
  TechnicalCertificate: 'Technical certificate',
  Contract: 'Contract',
  Certification: 'Certification',
  EmployeeInfo: 'Employee info',
  Signing: 'Signing',
  OfficialSeal: 'Official seal',
  CertificateAttachment: 'Certificate attachment',
  DisclosedInvention: 'Disclosed invention',
  CertificationType: 'Certification type',
  ManualAddress: 'Manual address',
  Workday: 'Workday',
  EmailSupport: 'Email support',
  CertificationPreview: 'Certification preview',
  SignDescription: 'Sign description',
  AuditStamp: 'Audit stamp',
  RejectAudit: 'Reject audit',
  PreviewAudit: 'Preview audit',
  AuditAgain: 'Audit again',
  ExtensionConfirm: 'Extension confirm',
  AuditInfo: ' Audit info',
  AuditDocuments: 'Audit documents',
  AuditContract: 'Audit contract',
  UploadDocuments: 'Upload documents',
  Extension: 'Extension',
  NoExtension: 'No extension'
};

export const MATOMO_MENU_NAME_MAP = {
  [MENU_KEY.EMPLOYEE_HOME]: 'Operation items',
  [MENU_KEY.EMPLOYEE_CONTRACT]: 'Employee contract',
  [MENU_KEY.APPLICATION_HISTORY]: 'Application history',
  [MENU_KEY.APPLY_CERTIFICATION]: 'Certificate apply',
  [MENU_KEY.HRO_HOME]: 'Induction',
  [MENU_KEY.MY_CONTRACT]: 'My contract',
  [MENU_KEY.ISSUE_CERTIFICATION]: 'Issue certificate',
};

export const MATOMO_EMPLOYEE_MENU_CATEGORY = [
  MENU_KEY.EMPLOYEE_HOME,
  MENU_KEY.MY_CONTRACT,
  MENU_KEY.APPLY_CERTIFICATION,
  MENU_KEY.APPLICATION_HISTORY,
];

export const withdrawMatomoCategory = {
  [MATOMO_NAME.NoExtension]: MATOMO_CATEGORY.HRBPWithdrawProcess,
  [MATOMO_NAME.Extension]: MATOMO_CATEGORY.HRBPWithdrawProcess,
};


export const withdrawMatomoAction = {
  [MATOMO_NAME.NoExtension]: MATOMO_ACTION.Withdraw,
  [MATOMO_NAME.Extension]: MATOMO_ACTION.Withdraw,
};

export const contractMatomo = {
  [CONTRACT_SIGN_TYPE.HIRE_EMPLOYEE]: {
    category: MATOMO_CATEGORY.HroInductionToBeSigned,
    action: `${MATOMO_ACTION.Confirm} ${MATOMO_ACTION.RejectAudit}`,
    name: MATOMO_NAME.Contract
  },
  [CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE]: {
    category: MATOMO_CATEGORY.HroAuditExtensionContract,
    action: MATOMO_ACTION.RejectAudit,
    name: MATOMO_NAME.AuditContract
  },
  [CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_NOT_SIGN_EMPLOYEE]: {
    category: MATOMO_CATEGORY.HroAuditNoExtensionContract,
    action: MATOMO_ACTION.RejectAudit,
    name: MATOMO_NAME.AuditContract
  }
};