import store from '@/store';
import { isEqual, pick, reject } from 'lodash-es';
import { pickParams } from '@/api/axiosApi';
import { setLoading } from '@/store';

interface Loading {
  requestList: Array<object>;
  start: (data: object) => void;
  stop: (data: object) => void;
  reset: () => void;
}

export const loading: Loading = {
  requestList: [],
  start(data) {
    if (this.requestList.length === 0) {
      store.dispatch(setLoading(true));
    }
    this.requestList.push(data);
  },
  stop(data) {
    this.requestList = reject(this.requestList, item => {
      return isEqual(pick(item, pickParams), pick(data, pickParams));
    });
    if (this.requestList.length === 0) {
      store.dispatch(setLoading(false));
    }
  },
  reset() {
    this.requestList = [];
    store.dispatch(setLoading(false));
  }
};
