// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import TotalNumber from '@/components/total-number/TotalNumber';
import './EntryContractProcess.scss';
import ToBeAuditedTableInfo from '@/pages/hro/entry-contract-process/to-be-audited-table-info/ToBeAuditedTableInfo';
import ToBeSignedTableInfo from '@/pages/hro/entry-contract-process/to-be-signed-table-info/ToBeSignedTableInfo';
import SignedDoneTableInfo from '@/pages/hro/entry-contract-process/signed-done-table-info/SignedDoneTableInfo';
import { useSearchParams } from 'react-router-dom';
import { getEmployeeTotal } from '@/api/employeeApi';
import { IEmployeeTotal } from '@/models/employee';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { EMPLOYEE_TAB_STATUS } from '@/constants/status';

const TAB_COUNT_MAPPER = {
  [EMPLOYEE_TAB_STATUS.PENDING]: 'toBeAuditedUserCount',
  [EMPLOYEE_TAB_STATUS.SIGNATURE]: 'toBeSignedUserCount',
  [EMPLOYEE_TAB_STATUS.CONFIRMED]: 'signedDoneUserCount'
};

const TITLE = {
  [EMPLOYEE_TAB_STATUS.PENDING]: '员工信息&入职材料审核',
  [EMPLOYEE_TAB_STATUS.SIGNATURE]: '合同审核&签署',
  [EMPLOYEE_TAB_STATUS.CONFIRMED]: '合同管理',
};

const context = [
  {
    title: '待审核员工',
    value: EMPLOYEE_TAB_STATUS.PENDING,
  },
  {
    title: '待签署员工',
    value: EMPLOYEE_TAB_STATUS.SIGNATURE
  },
  {
    title: '完成签署员工',
    value: EMPLOYEE_TAB_STATUS.CONFIRMED
  }
];

const EntryContractProcess = () => {
  const [ title,setTitle ] = useState('PENDING');
  const [ searchParams, setSearchParams ] = useSearchParams({});
  const [ total, setTotal ] = useState<IEmployeeTotal>({} as IEmployeeTotal);
  const [ currentTab, setCurrentTab ] = useState<string>(EMPLOYEE_TAB_STATUS.PENDING);
  const { hasPermission } = useRouterGuard();
  const userInfo = useSelector(selectUserInfo);

  const getTotalCount = useCallback(() => {
    getEmployeeTotal().then((res: IEmployeeTotal) => {
      setTotal(res);
    });
  }, []);

  useEffect(() => {
    if (userInfo && hasPermission) {
      getTotalCount();
    }
  }, [ userInfo, hasPermission ]);

  const getTableInfo = (value:string) => {
    if (value === EMPLOYEE_TAB_STATUS.PENDING){
      return <ToBeAuditedTableInfo updateTotalCount={getTotalCount} totalCount={total && total[value]} />;
    }
    else if (value === EMPLOYEE_TAB_STATUS.SIGNATURE) {
      return <ToBeSignedTableInfo totalCount={total && total[value]}/>;
    }
    else if (value === EMPLOYEE_TAB_STATUS.CONFIRMED) {
      return <SignedDoneTableInfo totalCount={total && total[value]}/>;
    }
  };

  const items = useMemo(() => context.map(({ title,value }) => {
    return {
      label: <TotalNumber title={title} totalCount={total && total[TAB_COUNT_MAPPER[value]]}/>,
      key: value,
      children: getTableInfo(value)
    };
  }), [ total ]);

  const clickTab = useCallback(activeKey => {
    if (activeKey !== currentTab) {
      setCurrentTab(activeKey);
      setSearchParams({ tab: activeKey });
      setTitle(activeKey);
      getTotalCount();
    }
  }, [ currentTab ]);

  return (
    <div className="main-page">
      <h3 className="headline3">下周员工入职名单</h3>
      <div className="container">
        <h4 className="headline4">{TITLE[title]}</h4>
        <Tabs
          type="card"
          defaultActiveKey={searchParams.get('tab') || EMPLOYEE_TAB_STATUS.PENDING}
          items={items}
          onTabClick={clickTab}
          destroyInactiveTabPane={true}/>
      </div>
    </div>
  );
};
export default EntryContractProcess;
