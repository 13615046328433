import axiosApi from '@/api/axiosApi';
import {
  IContractExtensionEmployeeSignedDoneResponse,
  IContractExtensionEmployeeToBeAuditedResponse,
  IContractExtensionEmployeeToBeSignedResponse,
  IContractExtensionInformationRequest,
  IContractExtensionInformationResponse,
  IContractExtensionNotSignInformationRequest,
  IContractExtensionNotSignInformationResponse,
  IContractExtensionNotSignResponse,
  IContractExtensionResponse,
  IContractExtensionStatusAndReasonRequest,
  IContractExtensionTableDataQueryFilter,
  IContractFinishStatusInfoResponse,
  IEmployeeContractMaterials,
  IEmployeeEditInfo,
  IEmployeeFiles,
  IEmployeeMaterials,
  IEmployeeMaterialsAndUpdateDate,
  IEmployeeMaterialsRequest, IEmployeeSignedDoneResponse, IEmployeeSigningResponse, IEmployeeToBeAuditedResponse,
  IEmployeeTotal,
  IEmployeeUpdateInfo,
  IHireInfo,
  IHireInformationRequest,
  IHireInformationResponse,
  IOption,
  IUploadAttachmentItems,
  Options,
} from '@/models/employee';
import { NO_NEED_LOADING } from '@/constants/common';
import { User } from '@/models/auth';
import store from '@/store';

export const getEmployeeToBeAuditList = (): Promise<IEmployeeToBeAuditedResponse> => {
  return axiosApi.get('/api/entry-contract/to-be-audited');
};

export const getContractExtensionEmployeeList = (page: number, size: number): Promise<IContractExtensionEmployeeToBeAuditedResponse> => {
  return axiosApi.get('/api/contract-extension/to-be-audited', {
    params: { page, size },
  });
};

export const getContractExtensionEmployeeInformation = (id: string): Promise<IContractExtensionInformationResponse> => {
  return axiosApi.get(`/api/contract-extension/user-information?employeeId=${id}`);
};

export const getEmployeeTotal = (): Promise<IEmployeeTotal> => {
  return axiosApi.get('/api/entry-contract/every-tab-user-count');
};

export const getContractExtensionEmployeeTotal = (): Promise<IEmployeeTotal> => {
  return axiosApi.get('/api/contract-extension/every-tab-user-count');
};

export const getContractNotExtensionEmployeeTotal = (): Promise<IEmployeeTotal> => {
  return axiosApi.get('/api/contract-extension-not-sign/every-tab-user-count');
};

export const getEmployeeHireInfo = (id: string): Promise<IHireInfo> => {
  return axiosApi.get(`/api/user/partial-hire-information?employeeId=${id}`);
};

export const getAllOptionConfig = (): Promise<Options> => {
  return axiosApi.get('/api/options/all');
};

export const getNationOptions = (): Promise<IOption[]> => {
  return axiosApi.get('/api/options/nation')
    .then(data => data.map(option => {
      if (option.text === '中国') {
        option.text = '中国大陆';
      }
      return option;
    }));
};

export const getUniversityOptions = (): Promise<IOption[]> => {
  return axiosApi.get('/api/options/university');
};

export const getMajorOptions = (): Promise<IOption[]> => {
  return axiosApi.get('/api/options/major');
};

export const getCompanyIndustryOptions = (): Promise<IOption[]> => {
  return axiosApi.get('/api/options/companyIndustry');
};

export const getEmployeeSigningInfo = (): Promise<IEmployeeSigningResponse> => {
  return axiosApi.get('api/entry-contract/to-be-signing');
};

export const getContractExtensionToBeSignedInfo = (page: number, size: number): Promise<IContractExtensionEmployeeToBeSignedResponse> => {
  return axiosApi.get('/api/contract-extension/to-be-signed', {
    params: { page, size },
  });
};

export const getContractExtensionSignedDoneInfo = (page: number, size: number): Promise<IContractExtensionEmployeeSignedDoneResponse> => {
  return axiosApi.get('/api/contract-extension/sign-off', {
    params: { page, size },
  });
};

export const getEmployeeSignedDoneInfo = (): Promise<IEmployeeSignedDoneResponse> => {
  return axiosApi.get('api/entry-contract/sign-off');
};

export const initiateEmployeeSignProcess = (employeeId: string, employeeSigningProcessId: string): Promise<void> => {
  return axiosApi.post(`/api/initiate?employeeId=${employeeId}&processId=${employeeSigningProcessId}`);
};
export const addHroSignAreaAndGetHroStampUrl = (employeeId: string, employeeSigningProcessId: string): Promise<string> => {
  return axiosApi.post(`/api/initiate/exit-involuntary/hro-area?employeeId=${employeeId}&processId=${employeeSigningProcessId}`);
};
export const initiateExitContractInvoluntarySignProcess = (employeeId: string, employeeSigningProcessId: string): Promise<void> => {
  return axiosApi.post(`/api/initiate/exit-involuntary?employeeId=${employeeId}&processId=${employeeSigningProcessId}`);
};
export const getHireInformationByEmployeeId = (id: string): Promise<IHireInformationResponse> => {
  return axiosApi.get(`/api/user/hire-information?employeeId=${id}`);
};
export const reviewedHireInformation = (data: IHireInformationRequest): Promise<void> => {
  return axiosApi.patch('/api/user/hire-information', data);
};
export const reviewedContractExtensionInformation = (data: IContractExtensionInformationRequest): Promise<void> => {
  return axiosApi.patch('/api/contract-extension/user-information', data);
};
export const reviewedEmployeeMaterials = (contractType: string, request: IEmployeeMaterialsRequest): Promise<void> => {
  return axiosApi.patch(`/api/${contractType}/audit-materials`, request);
};

export const getEmployeeEditInfo = (processId: string): Promise<IEmployeeEditInfo> => {
  return axiosApi.get(`/api/user/personal-info?processId=${processId}`);
};

export const updateEmployeeEditInfo = (employeeInfo: IEmployeeUpdateInfo): Promise<void> => {
  return axiosApi.put('/api/user/personal-info', employeeInfo);
};

export const uploadFile = (formData: FormData, onProgress: (event: { percent: number; }) => void, url: string) => {
  return axiosApi.post(
    url ? url : '/api/user/file',
    formData,
    {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    },
  );
};

export const uploadWillingConfirmAttachments = (formData: FormData, onProgress: (event: { percent: number; }) => void) => {
  return axiosApi.post(
    '/api/willingness/confirm-attachments',
    formData,
    {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: event => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    }
  );
};

export const getEmployeeMaterials = (processId: string): Promise<IEmployeeMaterials> => {
  return axiosApi.get(`/api/user/files?processId=${processId}`);
};

export const updateEmployeeMaterials = (employeeMaterials: IEmployeeFiles) => {
  return axiosApi.put('/api/user/files', employeeMaterials);
};

export const getUserContractMaterials = ({
  noNeedLoading
}: { employeeId?: string; noNeedLoading?: boolean; } = {}):
  Promise<IEmployeeContractMaterials> => {
  return axiosApi.get(
    '/api/user/employee-contract-file-types',
    noNeedLoading ? NO_NEED_LOADING : undefined,
  );
};

export const getUserContractByProcessId = (processId: string, { noNeedLoading }: { noNeedLoading?: boolean; } = {}):
  Promise<IEmployeeContractMaterials> => {
  return axiosApi.get(
    `/api/user/employee-contract-file-types/${processId}`,
    noNeedLoading ? NO_NEED_LOADING : undefined,
  );
};

export const getWillingnessConfirmAttachmentByProcessId = (processId: string, { noNeedLoading }: { noNeedLoading?: boolean; } = {}):
  Promise<IUploadAttachmentItems> => {
  return axiosApi.get(
    `/api/user/willingness-attachment-items/${processId}`,
    noNeedLoading ? NO_NEED_LOADING : undefined
  );
};

export const getEmployeeDiscloseInvention = (employeeId: string, processId?: string) => {
  return axiosApi.get(`/api/user/employee-disclose-invention-contracts-info/${employeeId}${processId ? '/' + processId : ''}`);
};

export const getAllEmployeeMaterials = ({
  employeeId,
  noNeedLoading
}: { employeeId?: string; noNeedLoading?: boolean; } = {})
  : Promise<IEmployeeMaterialsAndUpdateDate> => {
  return axiosApi.get(
    `/api/user/employee-materials-file-items${employeeId ? '/' + employeeId : ''}`,
    noNeedLoading ? NO_NEED_LOADING : undefined)
    .then(data => {
      return { materialsData: data.userFileMappingInfo, updateDate: data.updateDate };
    });
};

export const confirmPreviewCheck = (processId: string): Promise<void> => {
  return axiosApi.post(`/api/user/update-contract-review-status/${processId}`);
};

export const bpfhConfirmReviewCheck = (processId: string): Promise<void> => {
  return axiosApi.post(`/api/user/bpfh-update-contract-review-status/${processId}`);
};

export const bpfhPreviewUrl = (processId: string): Promise<void> => {
  return axiosApi.post(`/api/user/bpfh-preview-flow-url/${processId}`);
};

export const rejectPreviewCheck = (processId: string): Promise<void> => {
  return axiosApi.delete(`api/user/reject-contract/${processId}`);
};

export const getSkillCertificateOption = () => {
  return axiosApi.get('/api/options/skill-certificate');
};

export const getForeignLanguageOption = () => {
  return axiosApi.get('/api/options/foreign-language');
};

export const getContractFinishStatusInfo = (contractType: string, page: number, size: number):
  Promise<IContractFinishStatusInfoResponse> => {
  return axiosApi.get(`/api/${contractType}/to-be-archive`, {
    params: { page, size },
  });
};

export const archiveEmployeeContract = (
  contractTypeUrl,
  archiveContractRequest: { employeeId: string; taskName: string }[],
): Promise<void> => {
  return axiosApi.post(`/api/archive/${contractTypeUrl}`, archiveContractRequest);
};

export const downloadFile = (storageUrl: string, needLoading?: boolean) => {
  return axiosApi.get('/api/file', {
    params: { storageUrl },
    responseType: 'blob',
    noNeedLoading: !needLoading
  }).then(data => {
    return data;
  });
};

// move to extension api file
export const getContractExtensionList = (page: number, size: number): Promise<IContractExtensionResponse> => {
  const filter = store.getState().hro.contractExtensionSearchFilters;
  return axiosApi.post(`/api/willingness/contract-extension-to-be-confirmed/search?page=${page}&size=${size}`, { ...filter });
};

export const getContractExtensionListColumnFilterCandidate = (): Promise<IContractExtensionTableDataQueryFilter> => {
  return axiosApi.post('api/willingness/contract-extension-to-be-confirmed/alternative');
};

export const updateContractExtensionStatusAndReason = (request: IContractExtensionStatusAndReasonRequest): Promise<void> => {
  return axiosApi.post('/api/willingness/contract-extension-to-be-confirmed', request);
};

export const getContractExtensionNotSignList = (page: number, size: number): Promise<IContractExtensionNotSignResponse> => {
  return axiosApi.get('/api/contract-extension-not-sign/user-list', {
    params: { page, size },
  });
};

export const getContractExtensionNotSignEmployeeInformation = (id: string): Promise<IContractExtensionNotSignInformationResponse> => {
  return axiosApi.get(`/api/contract-extension-not-sign/user-information?employeeId=${id}`);
};

export const reviewedContractExtensionNotSignInformation = (data: IContractExtensionNotSignInformationRequest): Promise<void> => {
  return axiosApi.patch('/api/contract-extension-not-sign/user-information', data);
};

export const withdrawContractExtensionProcess = (employeeId: string, processId: string): Promise<void> => {
  return axiosApi.delete(`/api/contract-extension-withdraw/${employeeId}/${processId}`);
};

export const searchUser = (employeeId: string): Promise<User> => {
  return axiosApi.get(`/api/user/search/${employeeId}`);
};

export const searchWorkingUser = (employeeId: string): Promise<User> => {
  return axiosApi.get(`/api/user/search-working/${employeeId}`);
};


