import { CHINA_NATION_IDS, CONTRACT_SIGN_TYPE } from '@/constants/employee';
import { message, UploadProps, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FILE_ERROR } from '@/constants/error';
import { FileSizeLimit10M } from '@/utils/fileUpload';

export interface IMaterialConfig {
    key: string;
    label: string;
    name: string;
    required?: boolean;
    initialValue?: UploadFile[];
    englishLabel: string;
    desc?: string;
    uploadProps: UploadProps;
    validator?: (file: RcFile, previousUploadCount: number, uploadLimit: number) => boolean | string;
}

export const avatarValidator = (file: RcFile, previousUploadCount: number, uploadLimit: number) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error(FILE_ERROR.isImage);
  }
  const isLessThanSizeLimit = file.size < FileSizeLimit10M;
  if (!isLessThanSizeLimit) {
    message.error(FILE_ERROR.notExceed10M);
  }
  const isLessThanUploadLimit = previousUploadCount < uploadLimit;
  if (!isLessThanUploadLimit) {
    message.error(FILE_ERROR.fileCountExceed);
  }
  return (isJpgOrPng && isLessThanSizeLimit && isLessThanUploadLimit) || Upload.LIST_IGNORE;
};

const pdfValidator = (file: RcFile, previousUploadCount: number, uploadLimit: number) => {
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    message.error(FILE_ERROR.isPdf);
  }
  const isLessThanSizeLimit = file.size < FileSizeLimit10M;
  if (!isLessThanSizeLimit) {
    message.error(FILE_ERROR.notExceed10M);
  }
  const isLessThanUploadLimit = previousUploadCount < uploadLimit;
  if (!isLessThanUploadLimit) {
    message.error(FILE_ERROR.fileCountExceed);
  }
  return (isPdf && isLessThanSizeLimit && isLessThanUploadLimit) || Upload.LIST_IGNORE;
};

const MaterialConfigs: IMaterialConfig[] = [
  {
    key: 'avatar',
    name: '电子照',
    label: '一寸电子照片',
    englishLabel: 'Photo - 1 inch',
    required: true,
    uploadProps: {
      accept: 'image/png,image/jpeg',
      maxCount: 1,
    },
    validator: avatarValidator
  },
  {
    key: 'identityCard',
    name: '有效证件',
    label: '有效证件 - 正反面扫描到一页',
    englishLabel: 'Identity Card - Both side on one page',
    required: true,
    desc: '有效证件包括大陆身份证和港澳台居民来往大陆通行证',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'householdRegister',
    name: '户口本',
    label: '户口本 - 首页和本人页分别扫描',
    englishLabel: 'Household Register - home page & personal page',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 2,
      multiple: true,
    },
    validator: pdfValidator
  },
  {
    key: 'certificateOfDegree',
    label: '毕业证',
    name: '毕业证',
    englishLabel: 'Certificate of Degree',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'diploma',
    label: '学位证',
    name: '学位证',
    englishLabel: 'Diploma',
    required: true,
    desc: '海外学历需要提供教育部学历认证',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'bankCard',
    label: '招商银行卡',
    name: '银行卡',
    englishLabel: 'Bank Card of China Merchants Bank',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'salaryCertificate',
    label: '最近一家公司开具的薪水证明',
    name: '薪水证明',
    englishLabel: 'Salary Certificate',
    desc: '校招无需提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'certificateOfResignation',
    name: '离职证明',
    label: '最近一家公司的离职证明',
    englishLabel: 'Certificate of Resignation',
    desc: '校招无需提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'passport',
    name: '护照',
    label: '护照',
    englishLabel: 'Passport',
    desc: '如有请提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 2,
      multiple: true,
    },
    validator: pdfValidator
  },
  {
    key: 'disabilityCertificate',
    name: '残疾人证',
    label: '残疾人证',
    englishLabel: 'Disability Certificate',
    desc: '如有请提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 5,
      multiple: true,
    },
    validator: pdfValidator
  },
  {
    key: 'studentCard',
    name: '学生证',
    label: '学生证',
    englishLabel: 'Student Card',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
];

const ExtensionMaterialConfigs: IMaterialConfig[] = [
  {
    key: 'avatar',
    name: '电子照',
    label: '一寸电子照片',
    englishLabel: 'Photo - 1 inch',
    uploadProps: {
      accept: 'image/png,image/jpeg',
      maxCount: 1,
    },
    validator: avatarValidator
  },
  {
    key: 'identityCard',
    name: '有效证件',
    label: '有效证件 - 正反面扫描到一页',
    englishLabel: 'Identity Card - Both side on one page',
    desc: '有效证件包括大陆身份证和港澳台居民来往大陆通行证',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'householdRegister',
    name: '户口本',
    label: '户口本 - 首页和本人页分别扫描',
    englishLabel: 'Household Register - home page & personal page',
    desc: '港澳台无需提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 2,
      multiple: true,
    },
    validator: pdfValidator
  },
  {
    key: 'certificateOfDegree',
    label: '毕业证',
    name: '毕业证',
    englishLabel: 'Certificate of Degree',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'diploma',
    label: '学位证',
    name: '学位证',
    englishLabel: 'Diploma',
    desc: '海外学历需要提供教育部学历认证',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'bankCard',
    label: '招商银行卡',
    name: '银行卡',
    englishLabel: 'Bank Card of China Merchants Bank',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'passport',
    name: '护照',
    label: '护照',
    englishLabel: 'Passport',
    desc: '如有请提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 2,
      multiple: true,
    },
    validator: pdfValidator
  },
  {
    key: 'disabilityCertificate',
    name: '残疾人证',
    label: '残疾人证',
    englishLabel: 'Disability Certificate',
    desc: '如有请提供',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 5,
      multiple: true,
    },
    validator: pdfValidator
  },
];

const ForeignMaterialConfigs: IMaterialConfig[] = [
  {
    key: 'avatar',
    name: '电子照',
    label: '2寸白底彩色照片',
    englishLabel: 'Photo - 2 inches, and white background',
    required: true,
    uploadProps: {
      accept: 'image/png,image/jpeg',
      maxCount: 1,
    },
    validator: avatarValidator
  },
  {
    key: 'passport',
    name: '护照及中国签证',
    label: '护照及中国签证',
    englishLabel: 'Passport & China Visa',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 2,
      multiple: true,
    },
    validator: pdfValidator
  },
  {
    key: 'workPermit',
    name: '工作许可证',
    label: '工作许可证',
    englishLabel: 'Work Permit',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'residencePermit',
    name: '居留许可证',
    label: '居留许可证',
    englishLabel: 'Residence Permit',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'diploma',
    name: '学历/学位证',
    label: '学历学位证明',
    englishLabel: 'Diploma',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'bankCard',
    name: '银行卡',
    label: '招商银行卡',
    englishLabel: 'Bank Card of China Merchants Bank',
    required: true,
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'workExperienceCertificate',
    name: '工作证明',
    label: '毕业后2年工作经验证明',
    englishLabel: 'Certificate of 2 years work experiences after getting diploma',
    desc: 'Not required for graduate ',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'salaryCertificate',
    name: '薪水证明',
    label: '最近一家公司开具的薪水证明',
    englishLabel: 'Salary Certificate',
    desc: 'Not required for graduate',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
  {
    key: 'certificateOfResignation',
    name: '离职证明',
    label: '最近一家公司的离职证明',
    englishLabel: 'Certificate of Resignation',
    desc: 'Not required for graduate',
    uploadProps: {
      accept: 'application/pdf',
      maxCount: 1,
    },
    validator: pdfValidator
  },
];

export const MATERIALS_MATOMO_NAME = [
  {
    key: 'avatar',
    MatomoName: 'Avatar',
  },
  {
    key: 'identityCard',
    MatomoName: 'Identity card',
  },
  {
    key: 'householdRegister',
    MatomoName: 'Household register',
  },
  {
    key: 'certificateOfDegree',
    MatomoName: 'Certificate of degree',
  },
  {
    key: 'diploma',
    MatomoName: 'Diploma',
  },
  {
    key: 'bankCard',
    MatomoName: 'Bank card',
  },
  {
    key: 'salaryCertificate',
    MatomoName: 'Salary certificate',
  },
  {
    key: 'certificateOfResignation',
    MatomoName: 'Certificate of resignation',
  },
  {
    key: 'passport',
    MatomoName: 'Passport',
  },
  {
    key: 'disabilityCertificate',
    MatomoName: 'Disability certificate',
  },
  {
    key: 'studentCard',
    MatomoName: 'Student card',
  },
  {
    key: 'workPermit',
    MatomoName: 'Work permit',
  },
  {
    key: 'residencePermit',
    name: 'Residence permit',
  },
  {
    key: 'workExperienceCertificate',
    name: 'Work experience certificate',
  }
];

export const getMaterialConfigs = (nationId: string, contractSignType: string): IMaterialConfig[] => {
  if (contractSignType === CONTRACT_SIGN_TYPE.CONTRACT_EXTENSION_EMPLOYEE) {
    return ExtensionMaterialConfigs;
  }
  if (CHINA_NATION_IDS.includes(nationId)) {
    return MaterialConfigs;
  }
  return ForeignMaterialConfigs;
};
