// eslint-disable-next-line no-use-before-define
import React from 'react';
import Icon from '@ant-design/icons';
import MenuSvg from '@/assets/icons/menu.svg';
import {
  CERTIFICATE_READ_PERMISSION,
  CONTRACT_EXTENSION_PROCESS_READ_PERMISSION,
  CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION,
  CONTRACT_INDUCTION_READ_PERMISSION,
  EXIT_APPROVAL_LIST_READ_PERMISSION,
  EXIT_HISTORY_READ,
  EXIT_LIST_READ_PERMISSION,
  EXIT_SIGNING_LIST_READ_PERMISSION,
} from '@/constants/permission';
export const MENU_KEY = {
  EMPLOYEE_HOME: '/employee-home',
  MY_CONTRACT: '/my-contract',
  APPLY_CERTIFICATION: '/apply-certification',
  APPLICATION_HISTORY: '/application-history',
  HRO_HOME: '/hro-home',
  EMPLOYEE_CONTRACT: '/employee-contract',
  ISSUE_CERTIFICATION: '/issue-certification',
};
interface IMenuChildren {
  path: string,
  text: string,
  auth?: string[]
  matchedPath?: string[]
}

interface IMenuConfig {
  path: string,
  text: string,
  auth?: string[],
  icon: React.ReactNode,
  children?: IMenuChildren[]
}

export const menuConfig: IMenuConfig[] = [
  {
    path: '/employee-home',
    text: '待操作事项',
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>
  },
  {
    path: '/my-contract',
    text: '我的合同',
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>
  },
  {
    path: '/my-certification',
    text: '我的证明',
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>,
    children: [
      {
        path: '/apply-certification',
        text: '开具证明',
      },
      {
        path: '/application-history',
        text: '开具历史',
      }
    ]
  },
  {
    path: '/hro-home',
    text: '入职办理',
    auth: [ CONTRACT_INDUCTION_READ_PERMISSION ],
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>
  },
  {
    path: '/contract-extension-home',
    text: '续签办理',
    auth: [ CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION, CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ],
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>,
    children: [
      {
        path: '/contract-extension-list',
        auth: [ CONTRACT_EXTENSION_WILLINGNESS_READ_PERMISSION ],
        text: '续签名单',
      },
      {
        path: '/contract-extension-process',
        text: '续签流程',
        auth: [ CONTRACT_EXTENSION_PROCESS_READ_PERMISSION ],
        matchedPath: [
          '/contract-extension-home/contract-extension-review',
          '/contract-extension-home/notification-review'
        ],
      }
    ]
  },
  {
    path: '/exit-process',
    text: '离职办理',
    auth: [ EXIT_LIST_READ_PERMISSION, EXIT_APPROVAL_LIST_READ_PERMISSION, EXIT_SIGNING_LIST_READ_PERMISSION, EXIT_HISTORY_READ ],
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>
  },
  {
    path: '/issue-certification',
    text: '开具证明',
    auth: [ CERTIFICATE_READ_PERMISSION ],
    icon: <Icon className="menu-icon" component={MenuSvg} aria-label="Menu-item"/>
  },
];
