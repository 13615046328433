import { EXIT_TYPE, EXIT_TYPE_CHINESE } from '@/constants/EXIT_TYPE_CHINESE';
import { EMPLOYMENT_TYPE } from '@/constants/employee';
import { EXIT_APPROVAL_PROCESS_STATUS, EXIT_INFO_PROCESS_STATUS, ExitApprovalHroStatusFilterEnum } from '@/constants/status';

export const hiredTypesOptions = [
  {
    text: 'Hire',
    value: 'hire'
  },
  {
    text: 'Rehire',
    value: 'rehire'
  },

  {
    text: 'Conversion',
    value: 'conversion'
  }
];

export const trainingTypesOptions = [
  // {
  //     text: 'TWU-I',
  //     value: 'TWU-I'
  // },
  {
    text: 'TWU-G',
    value: 'TWU-G'
  },
  {
    text: 'TWU-C',
    value: 'TWU-C'
  },
  {
    text: '暂不参加',
    value: ''
  }
];

export const hasWorkExperienceOption = [
  {
    text: '是',
    value: true
  },
  {
    text: '否',
    value: false
  }
];

export const CERTIFICATE_TYPE_OPTIONS = [
  {
    label: '收入证明-中文',
    value: 'INCOME_CERTIFICATE_CN'
  },
  {
    label: '收入证明-英文',
    value: 'INCOME_CERTIFICATE_EN'
  },
  {
    label: '在职证明-中文',
    value: 'EMPLOYMENT_CERTIFICATE_CN'
  },
  {
    label: '在职证明-英文',
    value: 'EMPLOYMENT_CERTIFICATE_EN'
  },
];

export const EXIT_TYPE_OPTIONS = [
  {
    text: EXIT_TYPE_CHINESE.VOLUNTARY,
    value: EXIT_TYPE.VOLUNTARY
  },
  {
    text: EXIT_TYPE_CHINESE.INVOLUNTARY,
    value: EXIT_TYPE.INVOLUNTARY
  },
];

export const EXIT_EMPLOYMENT_TYPE_OPTIONS = [
  {
    text: EMPLOYMENT_TYPE.EMPLOYEE,
    value: EMPLOYMENT_TYPE.EMPLOYEE
  },
  {
    text: EMPLOYMENT_TYPE.INTERN,
    value: EMPLOYMENT_TYPE.INTERN
  },
  {
    text: EMPLOYMENT_TYPE.CONTRACTOR,
    value: EMPLOYMENT_TYPE.CONTRACTOR
  }
];

export const EXIT_EMPLOYEE_GATE_KEEP_OPTIONS = [
  {
    text: '已提交',
    value: EXIT_APPROVAL_PROCESS_STATUS.APPROVED
  },
  {
    text: '未提交',
    value: EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED
  },
];

export const EXIT_APPROVAL_GATE_KEEP_OPTIONS = [
  {
    text: '待审核(可审)',
    value: EXIT_APPROVAL_PROCESS_STATUS.UNAPPROVED
  },
  {
    text: '待审核(暂不可审)',
    value: EXIT_APPROVAL_PROCESS_STATUS.TO_BE_ACTIVE
  },
  {
    text: '已审核',
    value: EXIT_APPROVAL_PROCESS_STATUS.APPROVED
  }
];

export const EXIT_APPROVAL_LEAVE_HRO_GATE_KEEP_OPTIONS = [
  {
    text: '待审核(可审)',
    value: ExitApprovalHroStatusFilterEnum.LEAVE_UNAPPROVED
  },
  {
    text: '待审核(暂不可审)',
    value: ExitApprovalHroStatusFilterEnum.LEAVE_TO_BE_ACTIVE
  },
  {
    text: '已审核',
    value: ExitApprovalHroStatusFilterEnum.LEAVE_APPROVED
  }
];

export const EXIT_APPROVAL_OFFICE_HRO_GATE_KEEP_OPTIONS = [
  {
    text: '待审核(可审)',
    value: ExitApprovalHroStatusFilterEnum.OFFICE_UNAPPROVED
  },
  {
    text: '待审核(暂不可审)',
    value: ExitApprovalHroStatusFilterEnum.OFFICE_TO_BE_ACTIVE
  }
];

export const EXIT_APPROVAL_POWER_HRO_GATE_KEEP_OPTIONS = [
  {
    text: '待审核(可审)',
    value: ExitApprovalHroStatusFilterEnum.POWER_UNAPPROVED
  },
  {
    text: '待审核(暂不可审)',
    value: ExitApprovalHroStatusFilterEnum.POWER_TO_BE_ACTIVE
  },
  {
    text: '已审核',
    value: ExitApprovalHroStatusFilterEnum.POWER_APPROVED
  }
];


export const EXIT_INFO_PROCESS_OPTIONS = [
  {
    text: '已提交',
    value: `${EXIT_INFO_PROCESS_STATUS.SUBMITTED},${EXIT_INFO_PROCESS_STATUS.FINISH}`
  },
  {
    text: '未提交',
    value: EXIT_INFO_PROCESS_STATUS.UN_SUBMITTED
  }
];
