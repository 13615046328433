import {
  IAddExitInvoluntaryEmployeeRequest,
  IEmployeeExitFlowResponse,
  IEmployeeExitInfo,
  IExitApprovalCommentDetail,
  IExitApprovalCommentResponse,
  IExitApprovalListResponse,
  IExitEmployeeListResponse,
  IExitEmployeePersonForCommunicatingResponse,
  IExitEmployeeResponse,
  IExitEmployeeTotal,
  IExitTableDataQueryFilter,
  ISupplementUserExitInfoRequest,
} from '@/models/employee';
import axiosApi from '@/api/axiosApi';
import { NO_NEED_LOADING } from '@/constants/common';

const EXIT_URL_BASE = '/api/exit';

export const getExitTotal = (filter: IExitTableDataQueryFilter, currentTab: string): Promise<IExitEmployeeTotal> => {
  return axiosApi.post(`${EXIT_URL_BASE}/every-tab-user-count/${currentTab}`, { ...filter });
};

export const getExitEmployeeTableColumnFilterCandidate = (): Promise<IExitTableDataQueryFilter> => {
  return axiosApi.get(`${EXIT_URL_BASE}/user-list/alternative`);
};

export const getExitApprovalTableColumnFilterCandidate = (): Promise<IExitTableDataQueryFilter> => {
  return axiosApi.get(`${EXIT_URL_BASE}/approval-list/alternative`);
};

export const getExitToBeSignedTableColumnFilterCandidate = (): Promise<IExitTableDataQueryFilter> => {
  return axiosApi.get(`${EXIT_URL_BASE}/to-be-signed-list/alternative`);
};

export const getExitEmployeeList = (page: number, size: number, filter: IExitTableDataQueryFilter): Promise<IExitEmployeeListResponse> => {
  return axiosApi.post(`${EXIT_URL_BASE}/user-list?page=${page}&size=${size}`, { ...filter });
};

export const getExitApprovalList = (page: number, size: number, filter: IExitTableDataQueryFilter): Promise<IExitApprovalListResponse> => {
  return axiosApi.post(`${EXIT_URL_BASE}/approval-list?page=${page}&size=${size}`, { ...filter });
};

export const getExitFinishList = (page: number, size: number, filter: IExitTableDataQueryFilter):
  Promise<IExitEmployeeListResponse> => {
  return axiosApi.post(`/api/exit/to-be-archive?page=${page}&size=${size}`, { ...filter });
};

export const getExitFinishedTableColumnFilterCandidate = (): Promise<IExitTableDataQueryFilter> => {
  return axiosApi.get(`${EXIT_URL_BASE}/finished-list/alternative`);
};

export const getExitToBeSignedList = (page: number, size: number, filter: IExitTableDataQueryFilter)
  : Promise<IExitEmployeeListResponse> => {
  return axiosApi.post(`${EXIT_URL_BASE}/to-be-signed-list?page=${page}&size=${size}`, { ...filter });
};

export const getExitEmployeeInfo = (processId: string): Promise<IEmployeeExitInfo> => {
  return axiosApi.get(`/api/exit/personal-info/${processId}`);
};

export const getExitEmployeePreviewInfo = (processId: string): Promise<IEmployeeExitInfo> => {
  return axiosApi.get(`/api/exit/personal-info/preview/${processId}`);
};

export const updateExitEmployeeInfo = (data: IEmployeeExitInfo): Promise<void> => {
  return axiosApi.put('/api/exit/personal-info', data);
};

export const getExitUserAndInfo = (employeeId: string, processId: string): Promise<IExitEmployeeResponse> => {
  return axiosApi.get(`/api/exit/info/user/${employeeId}`, {
    params: { processId },
  });
};

export const supplementExitUserInfo = (data: ISupplementUserExitInfoRequest): Promise<void> => {
  return axiosApi.put('/api/exit/info/user', data);
};

export const initiateExitApproval = (data: { businessTeamEmployeeId: string, processId: string }): Promise<void> => {
  return axiosApi.post('/api/exit/initiate-approval', data);
};

export const exitEmployeeSelfApproval = (processId: string): Promise<void> => {
  return axiosApi.post('/api/exit/personal-approval', { processId });
};

export const getPersonForCommunicating = (): Promise<IExitEmployeePersonForCommunicatingResponse> => {
  return axiosApi.get('/api/exit/personal-approval');
};


export const reviewExitApproval =
  (exitApprovalReviewProcessId: string): Promise<void> => {
    return axiosApi.patch(`/api/exit/approval-process/${exitApprovalReviewProcessId}`);
  };

export const getExitApprovalProcessInfo = (employeeId: string) => {
  return axiosApi.get(`/api/exit/approval-process/${employeeId}`);
};

export const createExitApprovalComment = (data: IExitApprovalCommentDetail): Promise<void> => {
  return axiosApi.post('/api/exit/approval-comment', data);
};

export const getExitApprovalComment = (employeeId: string, processId: string): Promise<IExitApprovalCommentResponse> => {
  return axiosApi.get('/api/exit/approval-comment', {
    params: { employeeId, processId },
  });
};

export const addExitInvoluntaryEmployee = (data: IAddExitInvoluntaryEmployeeRequest): Promise<void> => {
  return axiosApi.post('/api/exit/involuntary', data);
};

export const getInvoluntaryExitFlowUrl = (processId: string, { noNeedLoading }: { noNeedLoading?: boolean; } = {}):
  Promise<IEmployeeExitFlowResponse> => {
  return axiosApi.get(
    `/api/user/bpfh-exit-flow-url/${processId}`,
    noNeedLoading ? NO_NEED_LOADING : undefined,
  );
};
