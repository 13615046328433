import { EMPLOYEE_EDUCATION_DEGREE, MONTH_FORMAT } from '@/constants/employee';
import { RequiredRule } from '@/constants/validation';
import { IOption } from '@/models/employee';
import { AppDispatch } from '@/models/store';
import {
  fetchMajorOptions,
  fetchNationOptions,
  fetchUniversityOptions,
  selectMajorOptions,
  selectNationOptions,
  selectUniversityOptions } from '@/store/employee';
import { filterSelectOptions } from '@/utils/commonUtils';
import { Col, DatePicker, Empty, Form, FormInstance, Radio, Row, Select } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import EmployeeFormLabel from '../employee-form-label/EmployeeFormLabel';

const EDUCATION_LABEL = {
  [EMPLOYEE_EDUCATION_DEGREE.COLLEGE]: '专科 (College)',
  [EMPLOYEE_EDUCATION_DEGREE.BACHELOR]: '本科 (Bachelor)',
  [EMPLOYEE_EDUCATION_DEGREE.MASTER]: '硕士 (Master)',
  [EMPLOYEE_EDUCATION_DEGREE.DOCTOR]: '博士 (Doctor)',
};

interface IEducationInfoProps {
    form: FormInstance;
}

const EducationInfo = ({ form }: IEducationInfoProps) => {
  const nations: IOption[] = useSelector(selectNationOptions);
  const universities: IOption[] = useSelector(selectUniversityOptions);
  const majors: IOption[] = useSelector(selectMajorOptions);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(fetchNationOptions());
    dispatch(fetchUniversityOptions());
    dispatch(fetchMajorOptions());
  }, []);
  return (
    <>
      <Form.Item
        className="form-group"
        name={[ 'educationalInfo', 'highestDegree' ]}
        label={<EmployeeFormLabel label="最高学历" bracketSubLabel="Highest Educational Degree"/>}
        rules={[ RequiredRule ]}
      >
        <Radio.Group size="large">
          <Radio.Button value={EMPLOYEE_EDUCATION_DEGREE.COLLEGE}>{EDUCATION_LABEL[EMPLOYEE_EDUCATION_DEGREE.COLLEGE]}</Radio.Button>
          <Radio.Button value={EMPLOYEE_EDUCATION_DEGREE.BACHELOR}>{EDUCATION_LABEL[EMPLOYEE_EDUCATION_DEGREE.BACHELOR]}</Radio.Button>
          <Radio.Button value={EMPLOYEE_EDUCATION_DEGREE.MASTER}>{EDUCATION_LABEL[EMPLOYEE_EDUCATION_DEGREE.MASTER]}</Radio.Button>
          <Radio.Button value={EMPLOYEE_EDUCATION_DEGREE.DOCTOR}>{EDUCATION_LABEL[EMPLOYEE_EDUCATION_DEGREE.DOCTOR]}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.List name={[ 'educationalInfo', 'educationalInfoItems' ]}>
        {fields => fields.map(field => (
          <div className="form-group" key={field.key}>
            <label className="group-title">
              {EDUCATION_LABEL[form.getFieldValue([ 'educationalInfo', 'educationalInfoItems', field.name, 'degree' ])]}
            </label>
            <Row gutter={8}>
              <Col span={8}>
                {universities &&
                    <Form.Item name={[ field.name, 'universityId' ]}
                      label={<EmployeeFormLabel label="学校名称" bracketSubLabel="School Name" />}
                      rules={[ RequiredRule ]}>
                      <Select
                        className="school-select"
                        showSearch
                        filterOption={filterSelectOptions}
                        getPopupContainer={triggerNode => triggerNode}
                        notFoundContent={<Empty description={'暂无数据'}/>}
                      >
                        {universities.map(option => (
                          <Select.Option key={option.value as string} value={option.value}>{option.text}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                }
              </Col>
              <Col span={8}>
                {majors &&
                    <Form.Item name={[ field.name, 'majorId' ]}
                      label={<EmployeeFormLabel label="专业" bracketSubLabel="Major"/>}
                      rules={[ RequiredRule ]}>
                      <Select
                        className="major-select"
                        showSearch
                        filterOption={filterSelectOptions}
                        getPopupContainer={triggerNode => triggerNode}
                        notFoundContent={<Empty description={'暂无数据'}/>}
                      >
                        {majors.map(option => (
                          <Select.Option key={option.value as string} value={option.value}>{option.text}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                }
              </Col>
            </Row>
            <Row className="last-row" gutter={8}>
              <Col span={8}>
                <Form.Item name={[ field.name, 'dateRange' ]}
                  label={<EmployeeFormLabel label="在校时间" bracketSubLabel="Start Date - End Date" />}
                  rules={[ RequiredRule ]}>
                  <DatePicker.RangePicker getPopupContainer={triggerNode => triggerNode} picker="month" format={MONTH_FORMAT}/>
                </Form.Item>
              </Col>
              <Col span={6}>
                {nations &&
                    <Form.Item name={[ field.name, 'nationId' ]}
                      label={<EmployeeFormLabel label="学校地点" bracketSubLabel="School Location"/>}
                      rules={[ RequiredRule ]}>
                      <Select
                        className="major-select"
                        showSearch
                        filterOption={filterSelectOptions}
                        getPopupContainer={triggerNode => triggerNode}
                        notFoundContent={<Empty description={'暂无数据'}/>}
                      >
                        {nations.map(option => (
                          <Select.Option key={option.value as string} value={option.value}>{option.text}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                }
              </Col>
            </Row>
          </div>
        ))
        }
      </Form.List>
    </>
  );
};

export default EducationInfo;
