import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreState } from '@/models/store';
import {
  IContractExtensionTableDataQueryFilter,
  IEmployeeTotal,
  IExitApprovalListResponse,
  IExitEmployeeListResponse,
  IExitEmployeeTotal,
  IExitTableDataQueryFilter
} from '@/models/employee';
import {
  getContractExtensionEmployeeTotal,
  getContractExtensionListColumnFilterCandidate,
  getContractNotExtensionEmployeeTotal,
} from '@/api/employeeApi';
import {
  getExitApprovalList,
  getExitApprovalTableColumnFilterCandidate,
  getExitEmployeeList,
  getExitEmployeeTableColumnFilterCandidate,
  getExitFinishedTableColumnFilterCandidate,
  getExitFinishList,
  getExitToBeSignedList,
  getExitToBeSignedTableColumnFilterCandidate,
  getExitTotal,
} from '@/api/exitApi';
import store from '@/store/index';
import { EXIT_PROCESS_TAB_STATUS } from '@/constants/status';
import { flatObjectListFields } from '@/utils/commonUtils';

const initialState = {
  willExtensionTotalCount: null,
  willNotExtensionTotalCount: null,
  exitEmployeeData: {
    content: [],
    pageable: {
      pageNumber: null,
      pageSize: null,
    },
    totalElements: null
  },
  exitApprovalData: {
    content: [],
    pageable: {
      pageNumber: null,
      pageSize: null,
    },
    totalElements: null
  },
  exitToBeSignedEmployeeData: {
    content: [],
    pageable: {
      pageNumber: null,
      pageSize: null,
    },
    totalElements: null
  },
  exitFinishData: {
    content: [],
    pageable: {
      pageNumber: null,
      pageSize: null,
    },
    totalElements: null
  },
  exitCount: null,
  exitTableSearchFilters: null,
  exitColumnCandidates: null,
  contractExtensionSearchFilters: null,
  contractExtensionColumnCandidates: null,
};

export const fetchContractExtensionEmployeeTotal = createAsyncThunk<IEmployeeTotal>(
  'hroInfo/getContractExtensionEmployeeTotal',
  async () => {
    return await getContractExtensionEmployeeTotal();
  }
);

export const fetchContractNotExtensionEmployeeTotal = createAsyncThunk<IEmployeeTotal>(
  'hroInfo/getContractNotExtensionEmployeeTotal',
  async () => {
    return await getContractNotExtensionEmployeeTotal();
  }
);

export const fetchExitTotal = createAsyncThunk<IExitEmployeeTotal, { currentTab: string }>(
  'hroInfo/getExitEmployeeTotal',
  async arg => {
    const { currentTab } = arg;
    const filter = store.getState().hro.exitTableSearchFilters;
    const flatFilter = flatObjectListFields(filter);
    return await getExitTotal(flatFilter, currentTab);
  }
);

export const fetchExitEmployeeList = createAsyncThunk<
    IExitEmployeeListResponse, { page: number, size: number }>(
      'hroInfo/getExitEmployeeList',
      async arg => {
        const { page, size } = arg;
        const filter = store.getState().hro.exitTableSearchFilters;
        const flatFilter = flatObjectListFields(filter);
        return await getExitEmployeeList(page, size, flatFilter);
      }
    );

export const fetchExitApprovalList = createAsyncThunk<
    IExitApprovalListResponse, { page: number, size: number }>(
      'hroInfo/getExitApprovalList',
      async arg => {
        const { page, size } = arg;
        const filter = store.getState().hro.exitTableSearchFilters;
        const flatFilter = flatObjectListFields(filter);
        return await getExitApprovalList(page, size, flatFilter);
      }
    );

export const fetchExitFinishList = createAsyncThunk<
  IExitEmployeeListResponse, { page: number, size: number }>(
    'hroInfo/getExitFinishList',
    async arg => {
      const { page, size } = arg;
      const filter = store.getState().hro.exitTableSearchFilters;
      const flatFilter = flatObjectListFields(filter);
      return await getExitFinishList(page, size, flatFilter);
    }
  );

export const fetchExitToBeSignedList = createAsyncThunk<
  IExitEmployeeListResponse, { page: number, size: number }>(
    'hroInfo/getExitToBeSignedList',
    async arg => {
      const { page, size } = arg;
      const filter = store.getState().hro.exitTableSearchFilters;
      const flatFilter = flatObjectListFields(filter);
      return await getExitToBeSignedList(page, size, flatFilter);
    }
  );


export const fetchExitTableColumnFilterCandidate = createAsyncThunk<IExitTableDataQueryFilter, { currentTab: string }>(
  'hroInfo/getExitEmployeeListFilterCandidate',
  async arg => {
    const { currentTab } = arg;
    // eslint-disable-next-line default-case
    switch (currentTab) {
    case EXIT_PROCESS_TAB_STATUS.EMPLOYEE_LIST:
      return await getExitEmployeeTableColumnFilterCandidate();
    case EXIT_PROCESS_TAB_STATUS.IN_PROGRESS:
      return await getExitApprovalTableColumnFilterCandidate();
    case EXIT_PROCESS_TAB_STATUS.AWAITING_SIGNATURE:
      return await getExitToBeSignedTableColumnFilterCandidate();
    case EXIT_PROCESS_TAB_STATUS.FINISHED:
      return await getExitFinishedTableColumnFilterCandidate();
    }
  }
);

export const fetchContractExtensionListFilterCandidate = createAsyncThunk<IContractExtensionTableDataQueryFilter>(
  'hroInfo/getContractExtensionListColumnFilterCandidate',
  async () => {
    return await getContractExtensionListColumnFilterCandidate();
  }
);


const hroInfoReducer = createSlice({
  name: 'hroInfo',
  initialState,
  reducers: {
    updateExitTableSearchFilters: (state, action) => {
      const newFilterFields = action.payload;
      state.exitTableSearchFilters = {
        ...state.exitTableSearchFilters,
        ...newFilterFields
      };
    },
    resetExitTableSearchFilters: (state, action) => {
      state.exitTableSearchFilters = action.payload;
    },
    setExitAllColumnCandidates: (state, action) => {
      state.exitColumnCandidates = action.payload;
    },
    updateContractExtensionTableSearchFilters: (state, action) => {
      const newFilterFields = action.payload;
      state.contractExtensionSearchFilters = {
        ...state.contractExtensionSearchFilters,
        ...newFilterFields
      };
    },
    resetContractExtensionTableSearchFilters: (state, action) => {
      state.contractExtensionSearchFilters = action.payload;
    },
    setExtensionAllColumnCandidates: (state, action) => {
      state.contractExtensionColumnCandidates = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchContractExtensionEmployeeTotal.fulfilled, (state, action: PayloadAction<IEmployeeTotal>) => {
      state.willExtensionTotalCount = action.payload;
    }).addCase(fetchExitEmployeeList.fulfilled, (state, action: PayloadAction<IExitEmployeeListResponse>) => {
      state.exitEmployeeData = action.payload;
    }).addCase(fetchExitTotal.fulfilled, (state, action: PayloadAction<IExitEmployeeTotal>) => {
      state.exitCount = action.payload;
    }).addCase(fetchExitApprovalList.fulfilled, (state, action: PayloadAction<IExitApprovalListResponse>) => {
      state.exitApprovalData = action.payload;
    }).addCase(fetchExitFinishList.fulfilled, (state, action: PayloadAction<IExitEmployeeListResponse>) => {
      state.exitFinishData = action.payload;
    }).addCase(fetchExitToBeSignedList.fulfilled, (state, action: PayloadAction<IExitEmployeeListResponse>) => {
      state.exitToBeSignedEmployeeData = action.payload;
    }).addCase(fetchExitTableColumnFilterCandidate.fulfilled, (state, action: PayloadAction<IExitTableDataQueryFilter>) => {
      state.exitColumnCandidates = action.payload;
    }).addCase(fetchContractExtensionListFilterCandidate.fulfilled,
      (state, action: PayloadAction<IContractExtensionTableDataQueryFilter>) => {
        state.contractExtensionColumnCandidates = action.payload;
      }).addCase(fetchContractNotExtensionEmployeeTotal.fulfilled, (state, action: PayloadAction<IEmployeeTotal>) => {
      state.willNotExtensionTotalCount = action.payload;
    });
  },
});

export const selectWillExtensionTotalCount = (state: StoreState) => state.hro.willExtensionTotalCount;
export const selectWillNotExtensionTotalCount = (state: StoreState) => state.hro.willNotExtensionTotalCount;
export const selectExitCount = (state: StoreState) => state.hro.exitCount;
export const selectExitEmployeeData = (state: StoreState) => state.hro.exitEmployeeData;
export const selectExitApprovalData = (state: StoreState) => state.hro.exitApprovalData;
export const selectExitFinishData = (state: StoreState) => state.hro.exitFinishData;
export const selectExitToBeSignedEmployeeData = (state: StoreState) => state.hro.exitToBeSignedEmployeeData;
export const selectExitColumnCandidates = (state: StoreState) => state.hro.exitColumnCandidates;
export const selectContractExtensionColumnCandidates = (state: StoreState) => state.hro.contractExtensionColumnCandidates;
export const { reducer } = hroInfoReducer;
export const {
  updateExitTableSearchFilters,
  resetExitTableSearchFilters,
  setExitAllColumnCandidates,
  updateContractExtensionTableSearchFilters,
  resetContractExtensionTableSearchFilters,
  setExtensionAllColumnCandidates
} = hroInfoReducer.actions;
