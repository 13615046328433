export const NO_NEED_LOADING = {
  noNeedLoading: true
};

export const WORK_DAY_ADDRESS = 'https://wd3.myworkday.com/thoughtworks/d/home.htmld';

// eslint-disable-next-line max-len
export const PERSONNEL_SYSTEM_OPERATION_MANUAL_ADDRESS = 'https://central.thoughtworks.net/home/%E4%BA%BA%E4%BA%8B%E7%B3%BB%E7%BB%9F%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C-Workday';

export const EMAIL_TO_PEOPLE_SUPPORT_CHINA = 'mailto:peoplesupport-china@thoughtworks.com';

// eslint-disable-next-line max-len
export const LEGAL_VALIDITY_OF_ELECTRONIC_SIGNATURE = 'https://docs.google.com/document/d/17wWdRvYmQIw_DV22mcp8YTfd1oFn_1BbRwD06cPsYtc/edit';
