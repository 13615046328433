import { Dropdown, message, Modal } from 'antd';
import { useState } from 'react';
import './OperationItems.scss';
import { withdrawMatomoAction, withdrawMatomoCategory } from '@/constants/matomo';
import { getWillingnessConfirmAttachmentByProcessId, withdrawContractExtensionProcess } from '@/api/employeeApi';
import { useCustomMatomo } from '@/matomo';
import CustomCarousel from '@/components/custom-carousel/CustomCarousel';

interface IContractEmployeeToBeAuditedDetail {
  employeeId: string;
  comment: string;
  contractSignProcessId: string;
  edit: boolean;
  matomoName?: string,
  callbackFn: (...arg) => void;
}

const OperationItems = ({ employeeId, comment, contractSignProcessId, edit,
  matomoName, callbackFn }: IContractEmployeeToBeAuditedDetail) => {
  const [ commentModalOpen, setCommentModalOpen ] = useState(false);
  const [ revertModalOpen, setRevertModalOpen ] = useState(false);
  const [ previewOpen, setPreviewOpen ] = useState(false);
  const [ willingConfirmAttachment, setWillingConfirmAttachment ] = useState([]);
  const { trackEvent } = useCustomMatomo();

  const attachmentPreview = () => {
    getWillingnessConfirmAttachmentByProcessId(contractSignProcessId).then(fileInfo => {
      const attachmentFileInfo = fileInfo.userFileMappingInfo && fileInfo.userFileMappingInfo.willingConfirmAttachment;
      setWillingConfirmAttachment(attachmentFileInfo);
      setPreviewOpen(true);
    });
  };

  const dropdownRender = () => (
    <div className="operation-list" data-testid='dropdown-menu'>
      <a onClick={() => setCommentModalOpen(true)}>
        留言
      </a>
      {edit && <a onClick={() => setRevertModalOpen(true)}>
        撤销
      </a>}
      <a onClick={() => attachmentPreview()}>
        预览附件
      </a>
    </div>
  );

  const revertConfirm = () => {
    if (!edit) {
      return;
    }
    if (matomoName) {
      trackEvent({
        category: withdrawMatomoCategory[matomoName],
        action: withdrawMatomoAction[matomoName],
        name: matomoName
      });
    }

    withdrawContractExtensionProcess(employeeId, contractSignProcessId)
      .then(() => {
        setRevertModalOpen(false);
        message.success('撤回成功');
        callbackFn();
      });
  };

  return (
    <>
      <Dropdown dropdownRender={() => dropdownRender()} placement="bottomRight">
        <i className="ri-more-fill" data-testid='dropdown-button'></i>
      </Dropdown>
      <Modal open={commentModalOpen} onCancel={() => setCommentModalOpen(false)} footer={null} centered>
        <div className="modal-content">
          <i className="ri-error-warning-fill"></i>
          <div className="modal-item">
            <p className="title">留言</p>
            <p>{comment}</p>
          </div>
        </div>
      </Modal>
      {
        edit && <Modal open={revertModalOpen} onCancel={() => setRevertModalOpen(false)} onOk={revertConfirm} cancelText={'取消'}
          okText={'确认'} centered>
          <div className="modal-content">
            <i className="ri-error-warning-fill"></i>
            <div className="modal-item">
              <p className="title">确认撤销该员工的续签合同签署？</p>
              <p className="content">撤销合同签署后，所有合同协议将失效，员工将移至待审核员工表格，需重新审核员工信息和材料。</p>
            </div>
          </div>
        </Modal>
      }
      {
        willingConfirmAttachment && willingConfirmAttachment.length > 0 &&
        <Modal open={previewOpen} title='预览附件' footer={null} onCancel={() => setPreviewOpen(false)} centered
          width='784px' data-testid='preview-modal'>
          <CustomCarousel fileList={willingConfirmAttachment}/>
        </Modal>
      }

    </>
  );
};

export default OperationItems;
