import { ROLE_ADMIN, ROLE_USER } from '@/constants/role';
import { intersection, isEmpty, size } from 'lodash-es';
import { UserInfo } from '@/models/auth';
import { RouterObjectExtend } from '@/models/common';
import { menuConfig } from '@/constants/menuConfig';

export const isUserOnlyHasUserRole = (userInfo): boolean => {
  return userInfo.roles?.length === 1 && !!userInfo.roles?.find(item => item.name === ROLE_USER);
};

export const isUserHasMenuPermission = (menuItem, userPermissions): boolean => {
  return size(intersection(userPermissions, menuItem.auth)) > 0;
};

export const isUserHasRouteAuthRole = (currenUserRole, matchedCurrentRoute): boolean => {
  const isMatchedCurrentRouteHasAuth = isEmpty(matchedCurrentRoute?.route?.auth);
  return (currenUserRole === ROLE_USER && !isMatchedCurrentRouteHasAuth) ||
    (currenUserRole === ROLE_ADMIN && isMatchedCurrentRouteHasAuth);
};

export const isUserHasRoutePermission = (userInfo: UserInfo, route:RouterObjectExtend):boolean => {
  if (!userInfo?.user) {
    return false;
  }
  const permissions = userInfo.permissions;
  return !route?.auth || (route?.auth && size(intersection(permissions, route?.auth)) > 0);
};

export const getEmployeeUserMenuConfig = () => {
  return menuConfig.filter(item => !item.auth);
};

export const isCurrentRouteMatchMenuFirstItem = (menuConfigs, location) => {
  if (menuConfigs[0]?.children) {
    return menuConfigs[0].path + menuConfigs[0].children[0].path === location.pathname;
  }
  return menuConfigs[0].path === location.pathname;
};


export const getAdminCurrentUserRole = (userInfo,  matchedRoutes, matchedCurrentRoute) => {
  const isRootRoute = matchedRoutes.length === 0;
  const isCurrentRouteHasAuth = !!matchedCurrentRoute?.route?.auth;
  if (isRootRoute || isCurrentRouteHasAuth) {
    return ROLE_ADMIN;
  }
  return ROLE_USER;
};

export const getAdminUserMenuConfig = userInfo => {
  const userPermissions = userInfo?.permissions;
  const parentMenConfig = menuConfig.filter(item => isUserHasMenuPermission(item, userPermissions));
  return parentMenConfig.map(menuConfig => {
    if (!menuConfig.children) {
      return menuConfig;
    }
    menuConfig.children = menuConfig.children.filter(item => !item?.auth || isUserHasMenuPermission(item, userPermissions));
    return menuConfig;
  });
};
