import { Button } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { IExitEmployeeListDetail, IExitEmployeeListResponse, } from '@/models/employee';
import { archiveEmployeeContract, } from '@/api/employeeApi';
import Icon from '@ant-design/icons';
import PreviewIcon from '@/assets/icons/preview.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isUserHasPermissions, selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { EXIT_TYPE, EXIT_TYPE_CHINESE } from '@/constants/EXIT_TYPE_CHINESE';
import ExitApprovalProcessDrawer
  from '@/pages/hro/exit-process/exit-approval-table-info/exit-approval-process-drawer/ExitApprovalProcessDrawer';
import { EMPLOYMENT_TYPE } from '@/constants/employee';
import ArchiveComponent from '@/components/archive-component/ArchiveComponent';
import DownloadContractButton from '@/components/download-contract-button/DownloadContractButton';
import ArchiveTable from '@/components/archive-table/ArchiveTable';
import TableColumnDateFilter from '@/components/table-column-date-filter/TableColumnDateFilter';
import {
  fetchExitFinishList,
  fetchExitTableColumnFilterCandidate,
  fetchExitTotal,
  selectExitColumnCandidates,
  selectExitFinishData,
  updateExitTableSearchFilters
} from '@/store/hroInfo';
import TableColumnFilter from '@/components/table-column-filter/TableColumnFilter';
import { EXIT_EMPLOYMENT_TYPE_OPTIONS, EXIT_TYPE_OPTIONS } from '@/constants/employeeOptions';
import { EXIT_PROCESS_TAB_STATUS } from '@/constants/status';
import { AppDispatch } from '@/models/store';
import { API_EXIT } from '@/api/consts';
import { EXIT_HISTORY_DOWNLOAD, EXIT_HISTORY_EDIT, EXIT_HISTORY_PREVIEW, EXIT_TYPE_READ_PERMISSION } from '@/constants/permission';

const ExitFinishedTableInfo = () => {
  const [ selectedColumn, setSelectedColumn ] = useState(null);
  const [ processDrawerOpen, setProcessDrawerOpen ] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();
  const dispatch = useDispatch<AppDispatch>();
  const hasExitHistoryEditPermission = useSelector(state =>
    isUserHasPermissions( state, [ EXIT_HISTORY_EDIT ]));
  const hasExitTypeReadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_TYPE_READ_PERMISSION ]));
  const hasExitHistoryPreviewPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_HISTORY_PREVIEW ]));
  const hasExitHistoryDownloadPermission = useSelector(state =>
    isUserHasPermissions(state, [ EXIT_HISTORY_DOWNLOAD ]));

  const dataStoredInRedux = useSelector(selectExitFinishData) as IExitEmployeeListResponse;
  const contractFinishData = dataStoredInRedux.content.map(employee => {
    return {
      ...employee,
      type: employee.type === EXIT_TYPE.VOLUNTARY ? EXIT_TYPE_CHINESE.VOLUNTARY : EXIT_TYPE_CHINESE.INVOLUNTARY,
    };
  });
  const tablePagination = {
    current: dataStoredInRedux.pageable.pageNumber + 1,
    pageSize: dataStoredInRedux.pageable.pageSize,
    total: dataStoredInRedux.totalElements,
    showSizeChanger: false,
  };

  const updateContractFinishData = (page: number, size: number) => {
    dispatch(fetchExitTableColumnFilterCandidate({ currentTab: EXIT_PROCESS_TAB_STATUS.FINISHED }));
    dispatch(fetchExitFinishList({ page, size }));
  };

  useEffect(() => {
    if (userInfo && hasPermission) {
      updateContractFinishData(0, 20);
    }
  }, [ userInfo, hasPermission ]);

  const openProcessDrawer = (columnItem: IExitEmployeeListDetail) => {
    setSelectedColumn(columnItem);
    setProcessDrawerOpen(true);
  };

  const handleQueryFilterConfirm = () => {
    updateContractFinishData(0, 20);
    dispatch(fetchExitTotal({ currentTab: EXIT_PROCESS_TAB_STATUS.FINISHED }));
  };

  const getTableFilterCommonProps = (filterFieldKey, close) => {
    return {
      close,
      filterFieldKey,
      handleConfirm: handleQueryFilterConfirm,
      filterCandidatesReduxQueryMethod: selectExitColumnCandidates,
      filterReduxUpdateMethod: updateExitTableSearchFilters
    };
  };

  const renderFilterIcon = () => {
    return <i className="ri-filter-3-line"></i>;
  };

  const handleTableChange = pagination => {
    updateContractFinishData(pagination.current - 1, pagination.pageSize);
  };

  const confirmBatchArchive = reqBody => {
    archiveEmployeeContract(API_EXIT, reqBody).then(() => {
      updateContractFinishData(tablePagination.current - 1, tablePagination.pageSize);
    });
  };

  const getColumns = () => {
    const columns = [
      {
        title: '离职日期',
        dataIndex: 'terminationDate',
        className: 'align-center',
        width: '122px',
        fixed: 'left',
        filterDropdown: ({ close }) => <TableColumnDateFilter
          filterFieldKey={'terminationDate'}
          handleConfirm={handleQueryFilterConfirm}
          filterReduxUpdateMethod={updateExitTableSearchFilters}
          close={close}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '工号',
        className: 'align-center',
        dataIndex: 'employeeId',
        width: '88px',
        fixed: 'left',
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('employeeId', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '中文名',
        dataIndex: 'chineseName',
        className: 'align-center',
        width: '100px',
        fixed: 'left',
        ellipsis: true,
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('chineseName', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '离职类型',
        className: 'align-center',
        dataIndex: 'type',
        key: 'type',
        width: '110px',
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_TYPE_OPTIONS}
          {...getTableFilterCommonProps('type', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '员工类型',
        className: 'align-center',
        dataIndex: 'employmentType',
        width: '120px',
        filterDropdown: ({ close }) => <TableColumnFilter
          filters={EXIT_EMPLOYMENT_TYPE_OPTIONS}
          {...getTableFilterCommonProps('employmentType', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '城市',
        dataIndex: 'homeOfficeChinese',
        className: 'align-center',
        width: '80px',
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('homeOffice', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '部门',
        className: 'align-center',
        dataIndex: 'supervisoryOrgName',
        width: '100px',
        ellipsis: true,
        filterDropdown: ({ close }) => <TableColumnFilter
          {...getTableFilterCommonProps('supervisoryOrgName', close)}
        />,
        filterIcon: renderFilterIcon
      },
      {
        title: '操作',
        dataIndex: 'contractSignStatus',
        width: 314,
        className: 'last-td align-center',
        render: (_, record: IExitEmployeeListDetail) => {
          const isContractorOrIntern = record.employmentType === EMPLOYMENT_TYPE.INTERN ||
            record.employmentType === EMPLOYMENT_TYPE.CONTRACTOR;

          const hiddenClassNameFlag = isContractorOrIntern || !record.editable;

          return (
            <div className='operation-button-column'>
              {
                <div className={`operation-item-buttons ${hiddenClassNameFlag && 'hidden'}`} data-testid='operation-item-buttons'>
                  {
                    hasExitHistoryPreviewPermission &&
                    <Link to={`/exit-process/contract-preview/${record.employeeId}/${record.exitSignProcessId}?tab=FINISHED`}>
                      <Button size="small" type="text" icon={<Icon className="preview-icon" component={PreviewIcon}/>}>
                        预览
                      </Button>
                    </Link>
                  }
                  {
                    hasExitHistoryEditPermission && <ArchiveComponent employeeId={record.employeeId}
                      contractSignTaskName={record.contractSignTaskName}
                      contractSignStatus={record.exitSignProcessStatus} confirmBatchArchive={confirmBatchArchive}/>
                  }
                  {
                    hasExitHistoryDownloadPermission &&
                    <DownloadContractButton employeeId={record.employeeId} contractSignTaskName={record.contractSignTaskName}
                      contractSignProcessId={record.exitSignProcessId} hireDate={record.hireDate}
                      contractExpirationDate={record.contractExpirationDate} legalFullName={record.legalFullName}
                      terminationDate={record.terminationDate}/>
                  }
                </div>
              }
              <Button
                size="small"
                type="text"
                onClick={() => openProcessDrawer(record)}
                className="exit-approval-detail-button"
              >
                <i className="ri-time-line"/>
                <span>流转详情</span>
              </Button>
            </div>
          );
        },
      },
    ];

    return hasExitTypeReadPermission ? columns : columns.filter(col => col.title !== '离职类型');
  };

  return (
    <div className="employee-contract-main-page">
      <div className="employee-contract-list">
        <ArchiveTable columns={getColumns()} dataSource={contractFinishData}
          tablePagination={tablePagination} handleTableChange={handleTableChange} confirmBatchArchive={confirmBatchArchive}/>
      </div>
      <ExitApprovalProcessDrawer
        title={'流转详情'}
        employeeId={selectedColumn?.employeeId}
        drawerOpen={processDrawerOpen}
        closeDrawer={() => setProcessDrawerOpen(false)}/>
    </div>
  );
};
export default ExitFinishedTableInfo;
