import { Button } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { IContractFinishStatusInfo, IContractFinishStatusInfoResponse } from '@/models/employee';
import {
  archiveEmployeeContract,
  getContractFinishStatusInfo,
} from '@/api/employeeApi';
import Icon from '@ant-design/icons';
import PreviewIcon from '@/assets/icons/preview.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isUserHasPermissions, selectUserInfo } from '@/store/userInfo';
import { useRouterGuard } from '@/utils/useRouterGuard';
import { contractTypeInfo } from '@/constants/employee';
import ArchiveComponent from '@/components/archive-component/ArchiveComponent';
import DownloadContractButton from '@/components/download-contract-button/DownloadContractButton';
import ArchiveTable from '@/components/archive-table/ArchiveTable';
import { CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION } from '@/constants/permission';

interface ContractListProps {
  apiUrl: string,
}

const ContractList = (props: ContractListProps) => {
  const { apiUrl } = props;
  const [ contractFinishData, setContractFinishData ] = useState<IContractFinishStatusInfo[]>([]);
  const userInfo = useSelector(selectUserInfo);
  const { hasPermission } = useRouterGuard();
  const hasExtensionProcessEditPermission = useSelector(state =>
    isUserHasPermissions( state, [ CONTRACT_EXTENSION_PROCESS_EDIT_PERMISSION ]));
  const [ tablePagination, setTablePagination ] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false
  });

  const updateContractFinishData = (contractType: string, page: number, size: number) => {
    getContractFinishStatusInfo(contractType, page, size).then((response: IContractFinishStatusInfoResponse) => {
      setContractFinishData(response.content);
      setTablePagination({
        current: response.pageable.pageNumber + 1,
        pageSize: response.pageable.pageSize,
        total: response.totalElements,
        showSizeChanger: false
      });
    });
  };

  const handleTableChange = pagination => {
    getContractFinishStatusInfo(apiUrl, pagination.current - 1, pagination.pageSize)
      .then((response: IContractFinishStatusInfoResponse) => {
        setContractFinishData(response.content);
        setTablePagination({
          ...pagination,
          current: response.pageable.pageNumber + 1,
          pageSize: response.pageable.pageSize,
          total: response.totalElements,
        });
      });
  };

  const confirmBatchArchive = reqBody => {
    archiveEmployeeContract(apiUrl, reqBody).then(() => {
      updateContractFinishData(apiUrl, tablePagination.current - 1, tablePagination.pageSize);
    });
  };

  useEffect(() => {
    if (userInfo && hasPermission) {
      updateContractFinishData(apiUrl, tablePagination.current - 1, tablePagination.pageSize);
    }
  }, [ userInfo, hasPermission ]);

  const getColumns = () => {
    return [
      {
        title: '合同到期日',
        dataIndex: 'contractExpirationDate',
        className: 'align-center',
        width: 120,
        fixed: 'left',
      },
      {
        title: '城市',
        dataIndex: 'homeOfficeChinese',
        className: 'align-center',
        width: 120,
        fixed: 'left',
      },
      {
        title: '中文名',
        dataIndex: 'chineseName',
        className: 'align-center show-ellipsis',
        width: 120,
        fixed: 'left',
      },
      {
        title: '工号',
        dataIndex: 'employeeId',
        className: 'align-center',
        width: 120,
      },
      {
        title: '部门',
        dataIndex: 'supervisoryOrgName',
        className: 'align-center',
        width: 120,
      },
      {
        title: '角色',
        dataIndex: 'role',
        className: 'align-center',
        width: 120
      },
      {
        title: '职级',
        dataIndex: 'grade',
        className: 'align-center',
        width: 120,
      },
      {
        title: '类型',
        dataIndex: 'contractSignTaskName',
        className: 'align-center',
        width: 120,
        render: (_, record) => {
          return <div>{contractTypeInfo[record.contractSignTaskName]}</div>;
        },
      },
      {
        title: '合同管理',
        dataIndex: 'contractSignStatus',
        width: 314,
        className: 'last-td align-center',
        render: (_, record: IContractFinishStatusInfo) => {
          return (
            <div className='operation-button-column'>
              {
                <div data-testid='operation-item-buttons'>
                  {/* eslint-disable-next-line max-len */}
                  <Link to={`/contract-extension-home/contract-extension-process/contract-preview/${record.employeeId}/${record.contractSignProcessId}`}>
                    <Button size="small" type="text" icon={<Icon className="preview-icon" component={PreviewIcon}/>}>
                      预览
                    </Button>
                  </Link>
                  {
                    hasExtensionProcessEditPermission && <ArchiveComponent employeeId={record.employeeId}
                      contractSignTaskName={record.contractSignTaskName}
                      contractSignStatus={record.contractSignStatus} confirmBatchArchive={confirmBatchArchive}/>
                  }
                  <DownloadContractButton employeeId={record.employeeId} contractSignTaskName={record.contractSignTaskName}
                    contractSignProcessId={record.contractSignProcessId} hireDate={record.hireDate}
                    contractExpirationDate={record.contractExpirationDate} legalFullName={record.legalFullName}
                    terminationDate={record.terminationDate}/>
                </div>
              }
            </div>
          );
        },
      },
    ];
  };

  return (
    <div className="employee-contract-main-page">
      <div className="employee-contract-list">
        <ArchiveTable columns={getColumns()} dataSource={contractFinishData}
          tablePagination={tablePagination} handleTableChange={handleTableChange} confirmBatchArchive={confirmBatchArchive}/>
      </div>
    </div>
  );
};
export default ContractList;
