import { IEmployeeExitInfo, IOption, Options } from '@/models/employee';
// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Rule } from 'rc-field-form/lib/interface';
import { requiredMessage } from '@/constants/error';
import { Checkbox, Input, Radio,  } from 'antd';
const { TextArea } = Input;

interface ComponentProps {
  optionKey?: string;
  showSearch?: boolean;
  optionFilter?: (options: IOption[], formValues: IEmployeeExitInfo) => IOption[];
}

interface FieldPreviewComponentFun<T> {
  (config: T, options: Options, getFormFieldsValue: () => IEmployeeExitInfo, employeeInfo: IEmployeeExitInfo): ReactElement;
}

export interface ExitEmployeePreviewFieldConfig {
  colSpan: number;
  name: string;
  label?: string;
  rules?: Rule[];
  component: ReactElement | FieldPreviewComponentFun<ExitEmployeePreviewFieldConfig>;
  componentProps?: ComponentProps;
  visibleCondition?: (formValues: IEmployeeExitInfo) => boolean;
  validateTrigger?: string | string[];
}

const otherCheckBoxEnChineseMap = {
  'developer': '开发',
  'pre-salesConsultant': '售前咨询',
  'operation': '运营',
  'training': '培训',
  'other': '其它'
};

const renderRadioButton = (employeeInfo: IEmployeeExitInfo) => {
  return (
    employeeInfo?.suitableJobNotification === 'true'
      ? <Radio defaultChecked={true} disabled={true} value="true"><span className="sub-form-label small-text">是 </span>Yes</Radio>
      : <Radio defaultChecked={true} disabled={true} value="true"><span className="sub-form-label small-text">否 </span>No</Radio>
  );
};

const renderFieldOfStudyOrExpertise = (employeeInfo: IEmployeeExitInfo) => {
  const checkboxString = employeeInfo?.fieldOfStudyOrExpertise;
  let splits = checkboxString.split(',');
  let otherText = undefined;
  const isOtherExists = checkboxString.includes('other');

  if (isOtherExists) {
    otherText = splits[splits.length - 1];
    splits = splits.slice(0, splits.length - 1);
  }

  return (
    <>
      {
        splits.map(checkBoxValue => {
          return (
            <span key={checkBoxValue}>
              {checkBoxValue === 'other' && <br/>}
              <Checkbox
                className='preview-check-box-item'
                defaultChecked
                disabled
              >
                <span className="sub-form-label small-text">{otherCheckBoxEnChineseMap[checkBoxValue]}</span>
              </Checkbox>
            </span>
          );
        }
        )
      }
      {
        isOtherExists &&
        <div>
          <TextArea
            key='other-text-text-area-key'
            className='preview-check-box-other-text'
            disabled={true}
            defaultValue={otherText}
            autoSize={{ minRows: 1, maxRows: 5 }}/>
        </div>
      }
    </>
  );
};


export const PreviewInfoFieldValidatorConfig: Array<ExitEmployeePreviewFieldConfig[]> = (
  [
    [
      {
        colSpan: 8,
        name: 'chineseName',
        label: '姓名',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={100} disabled={true}/>,
      },
      {
        colSpan: 12,
        name: 'personalEmail',
        label: '个人邮箱',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input maxLength={328} disabled={true}/>,
      },
    ],
    [
      {
        colSpan: 8,
        name: 'bankCard',
        label: '银行卡号',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
      },
      {
        colSpan: 12,
        name: 'accountsBank',
        label: '银行卡开户行',
        rules: [ { required: true, message: requiredMessage } ],
        component: <Input disabled={true}/>,
      }
    ],
    [
      {
        colSpan: 8,
        name: 'contactAddress',
        label: '联系地址',
        rules: [ { required: false, message: requiredMessage } ],
        component: <TextArea disabled={true} autoSize={{ minRows: 1, maxRows: 2 }}/>,
      },
      {
        colSpan: 8,
        name: 'otherContactInformation',
        label: '其他联系方式',
        rules: [ { required: false, message: requiredMessage } ],
        component: <Input disabled={true}/>,
      }
    ],
    [
      {
        colSpan: 24,
        name: 'suitableJobNotification',
        label: '你是否愿意作为一名freelancer参与到TW项目中，并在相关工作机会的时候，收到我们的消息推送？',
        rules: [ { required: true, message: requiredMessage } ],
        component: (config, option, getFormFieldsValue, employeeInfo) => renderRadioButton(employeeInfo),
      },
    ]
  ]
);

export const FieldOfStudyOrExpertiseValidatorConfig: Array<ExitEmployeePreviewFieldConfig[]> = (
  [
    [
      {
        colSpan: 20,
        name: 'fieldOfStudyOrExpertise',
        label: '你感兴趣/擅长的领域？',
        rules: [ { required: true, message: requiredMessage } ],
        component: (config, options, getFormFieldsValue, employeeInfo) => renderFieldOfStudyOrExpertise(employeeInfo)
      }
    ]
  ]
);
