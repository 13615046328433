// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import './CustomCarousel.scss';
import { IUploadFile } from '@/models/employee';
import ButtonIcon from '@/components/button-icon/ButtonIcon';
import { downloadFile } from '@/api/employeeApi';
import FailedToLoadPic from '@/assets/failed_to_load.png';


interface IFileList {
  fileList: IUploadFile[]
}

const CustomCarousel = ({ fileList } :IFileList) => {
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const [ storageUrl, setStorageUrl ] = useState(null);
  const [ objectUrl, setObjectUrl ] = useState(null);
  const [ currentTime, setCurrentTime ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');

  const reload = () => {
    setErrorMessage('');
    setCurrentTime(Date.now());
  };

  useEffect(() => {
    let url = null;
    const fileUrl = storageUrl || fileList[0].fileUrl;
    downloadFile(fileUrl, true)
      .then(data => {
        url = URL.createObjectURL(data);
        setObjectUrl(url);
      }).catch(() => {
        setErrorMessage('Fail to load file.');
      });
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [ storageUrl, currentTime ]);

  if (errorMessage) {
    return <div className={'file-load-failed'}>
      <div>
        <img src={FailedToLoadPic}/>
      </div>
      <div className={'file-load-failed-hint'}>
        <p>加载失败,请</p><a onClick={reload}>重新刷新</a>
      </div>
    </div>;
  }

  return  (
    <>
      {
        fileList && fileList.length > 0 &&
        <div className='carousel'>

          <div className='arrow'>
            {fileList.length !== 1 && objectUrl &&
              <ButtonIcon icon={<i className="ri-arrow-left-s-line"></i>}
                disabled={currentSlide === 0}
                testId='prev-arrow'
                onClick={()=>{ if (currentSlide > 0)
                {
                  setStorageUrl(fileList[currentSlide - 1].fileUrl);
                  setCurrentSlide(currentSlide - 1);
                }}}/>}
          </div>

          <div className="preview-image">
            <img className="image-content" src={objectUrl}/>
          </div>

          <div className='arrow'>
            {fileList.length !== 1 && objectUrl &&
              <ButtonIcon icon={<i className="ri-arrow-right-s-line"></i>}
                disabled={currentSlide === fileList.length - 1}
                testId='next-arrow'
                onClick={()=>{ if (currentSlide < fileList.length - 1)
                {
                  setStorageUrl(fileList[currentSlide + 1].fileUrl);
                  setCurrentSlide(currentSlide + 1);
                }}}/>}
          </div>
        </div>
      }
    </>
  );
};

export default CustomCarousel;
