export const requiredMessage = '信息不可为空';
export const invalidIdentify = '证件号无效';
export const invalidMobile = '手机号无效';
export const invalidFormat = '请正确输入信息';
export const invalidLength = '超过最大长度';

export const ERROR_CODE = {
  hireDateIllegal: 'HIRE_DATE_ILLEGAL',
  contractExtensionDateIllegal: 'CONTRACT_EXTENSION_DATE_ILLEGAL',
  userOperationIllegal: 'USER_OPERATION_ILLEGAL',
  userIllegal: 'USER_ILLEGAL',
  nationIllegal: 'NATION_ILLEGAL',
  businessCaseTypeIllegal: 'BUSINESS_CASE_TYPE_ILLEGAL',
  fileQuantityIllegal: 'FILE_QUANTITY_ILLEGAL',
  fileSuffixIllegal: 'FILE_SUFFIX_ILLEGAL',
  fileTypeIllegal: 'FILE_TYPE_ILLEGAL',
  idNumberIllegal: 'ID_NUMBER_ILLEGAL',
  nationIdTypeMatchIllegal: 'NATION_ID_TYPE_MATCH_ILLEGAL',
  requiredFileMissing: 'REQUIRED_FILE_MISSING',
  userFileIllegal: 'USER_FILE_ILLEGAL',
  fileSizeIllegal: 'FILE_SIZE_ILLEGAL',
  homeOfficeIllegal: 'HOME_OFFICE_ILLEGAL',
  eSignCreateFailed: 'ESIGN_CREATE_FAILED',
  processIdIllegal: 'PROCESS_ID_ILLEGAL',
};

export const FILE_ERROR = {
  notExceed5M: '单个文件大小不可超过5M',
  notExceed10M: '单个文件大小不可超过10M',
  fileCountExceed: '文件个数达到上限，无法上传',
  isPdf: '上传文件的格式类型为PDF',
  isImage: '上传照片文件的格式类型为（PNG或JPEG）',
  isPdfOrImage: '上传文件的格式类型为PDF、PNG或JPEG',
  uploadFailed: '文件上传失败：请删除错误文件重新上传',
  uploadHint: '请上传文件'
};
