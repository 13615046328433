import { Drawer } from 'antd';
import { IEmployeeExitInfo } from '@/models/employee';
// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import './ExitEmployeeInfoPreviewDrawer.scss';
import { EmployeeInfoForm } from '@/components/employee-form/EmployeeForm';
import useForm from 'antd/lib/form/hooks/useForm';
import {
  FieldOfStudyOrExpertiseValidatorConfig,
  PreviewInfoFieldValidatorConfig
} from '@/constants/config/employee-form/exit-employee-info-preview/FieldValidatorConfig';

interface IExitEmployeeInfoPreviewDrawerProps {
  employeeInfo: IEmployeeExitInfo;
  drawerOpen: boolean;
  closeDrawer: () => void;
}

export const ExitEmployeeInfoPreviewDrawer = ({
  employeeInfo, drawerOpen, closeDrawer
}: IExitEmployeeInfoPreviewDrawerProps) => {
  const [ form ] = useForm();

  useEffect(() => {
    form.setFieldsValue(employeeInfo);
  }, [ employeeInfo ]);

  const renderDrawerTitle = () => (
    <div>
      <span className="headline4 title">预览员工提交离职信息</span>
      <span className="medium-text employee-id">工号: {employeeInfo?.employeeId}</span>
    </div>
  );

  const handleOnClose = () => {
    form.resetFields();
    closeDrawer();
  };

  return (
    <Drawer
      width={720}
      title={renderDrawerTitle()}
      placement="right"
      closable={true}
      open={drawerOpen}
      destroyOnClose={true}
      style={{ marginTop: 64 }}
      onClose={handleOnClose}
    >
      <EmployeeInfoForm employeeInfo={employeeInfo}
        form={form}
        fieldValidatorConfig={PreviewInfoFieldValidatorConfig}
      />
      {
        employeeInfo?.suitableJobNotification === 'true' && <>
          <div className='preview-sub-div'>感谢你在TW期间的付出，我们十分重视你的宝贵经验，未来TW将探索多样化的灵活用工工作机会，具体的工作内容包括但不限于开发、售前咨询培训、运营等。</div>
          <EmployeeInfoForm employeeInfo={employeeInfo}
            form={form}
            fieldValidatorConfig={FieldOfStudyOrExpertiseValidatorConfig}
          />
        </>
      }
    </Drawer>
  );
};
