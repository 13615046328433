import { map } from 'lodash-es';
import { IEmployeeEditInfo } from '@/models/employee';
import { localStorageUtils } from '@/utils/localStorageUtils';
import { EDIT_STEP } from '../education-info/EducationUtil';
import { FormInstance, message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FILE_ERROR } from '@/constants/error';

export function initSkillInfoData(employeeData: IEmployeeEditInfo, isEdit: boolean) {
  const { skillInfo, personalInfo } = employeeData;
  if (!skillInfo) {
    return;
  }
  const { employeeId } = personalInfo;
  if (skillInfo?.certificateInfos) {
    skillInfo.certificateInfos = map(skillInfo.certificateInfos, info => {
      return ({
        ...info,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attachments: map((info.attachments as any), attachment => ({
          uid: attachment.fileId,
          name: attachment.fileName,
          status: 'done',
          response: attachment
        })),
      });
    });
  }
  if (isEdit) { // has submitted store the info to localStorage
    localStorageUtils.setCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.SKILL]}-full-form-value`, skillInfo);
  } else {
    const cacheSkillInfo = localStorageUtils.getCacheItem(`${employeeId}-${EDIT_STEP[EDIT_STEP.SKILL]}`) || {};
    employeeData.skillInfo = { ...skillInfo, ...cacheSkillInfo };
  }
}

export function transformSkillInfo(values: IEmployeeEditInfo) {
  if (!values.skillInfo) {
    return values;
  }
  return {
    ...values,
    skillInfo: {
      ...values.skillInfo,
      certificateInfos: values.skillInfo.certificateInfos.map(certificateInfo => {
        return {
          ...certificateInfo,
          attachments: certificateInfo.attachments.map(attachment => attachment.response)
        };
      }),
    }
  };
}

export function handleSkillInfoValueChange(employeeId: string, form: FormInstance, { skillInfo }: IEmployeeEditInfo) {
  if (!skillInfo) {
    return;
  }
  localStorageUtils.setCacheItem(
    `${employeeId}-${EDIT_STEP[EDIT_STEP.SKILL]}`,
    form.getFieldValue([ 'skillInfo' ]),
  );
}

export const skillCertificateValidator = (file: RcFile, previousUploadCount: number, uploadLimit: number) => {
  const fileSizeLimit =  1024 * 1024 * 5;
  const isLessThanSizeLimit = file.size < fileSizeLimit;
  if (!isLessThanSizeLimit) {
    message.error(FILE_ERROR.notExceed5M);
  }
  const isLessThanUploadLimit = previousUploadCount < uploadLimit;
  if (!isLessThanUploadLimit) {
    message.error(FILE_ERROR.fileCountExceed);
  }
  return (isLessThanSizeLimit && isLessThanUploadLimit) || Upload.LIST_IGNORE;
};
