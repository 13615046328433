import { Badge, Button, Empty, message, Table, Tooltip } from 'antd';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { ICertificateProcessStatusInfo, ICertificateProcessStatusInfoResponse, IHireInfo } from '@/models/employee';
import { downloadFile, getEmployeeHireInfo } from '@/api/employeeApi';
import { ColumnsType } from 'antd/es/table';
import Icon from '@ant-design/icons';
import DownloadSvg from '@/assets/icons/download.svg';
import './ApplicationHistory.scss';
import { CertificateType } from '@/constants/employee';
import { useCustomMatomo } from '@/matomo';
import { MATOMO_ACTION, MATOMO_CATEGORY, MATOMO_NAME } from '@/constants/matomo';
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types';
import { debounce } from 'lodash-es';
import MessageSvg from '@/assets/icons/message.svg';
import * as FileSaver from 'file-saver';
import { useLocation } from 'react-router-dom';
import { modalText } from '@/constants/modalText';
import { CERTIFICATE_TYPE_OPTIONS } from '@/constants/employeeOptions';
import NotificationModal from '@/components/modal/NotificationModal';
import { selectUserInfo } from '@/store/userInfo';
import { useSelector } from 'react-redux';
import CustomToolTipTitle from '@/components/custom-tooltip-title/CustomToolTipTitle';
import { CERTIFICATE_PROCESS_STATUS } from '@/constants/status';
import { getCertificateProcessStatusInfo } from '@/api/certificateApi';

const downloadCertificate = debounce(
  async ({ fileUrl, employeeId, certificateType }: ICertificateProcessStatusInfo, trackEvent: (params: TrackEventParams) => void) => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeApplicationHistory,
      action: MATOMO_ACTION.Download,
      name: MATOMO_NAME.Certification,
    });
    const hideMessage = message.loading('下载中，请稍等', 0);
    const response = await downloadFile(fileUrl);
    try {
      FileSaver.saveAs(response, `${employeeId}-${CertificateType[certificateType]}.pdf`);
      message.success('下载已完成');
    } catch (e) {
      message.error('下载失败，请重试');
    }
    hideMessage();
  }, 1000, { leading: true, trailing: false });

const toolTipComponent = (record: ICertificateProcessStatusInfo,  trackEvent: (params: TrackEventParams) => void) => {
  const { status, rejectReason } = record;
  const hoverMessageIcon = () => {
    trackEvent({
      category: MATOMO_CATEGORY.EmployeeApplicationHistory,
      action: MATOMO_ACTION.ViewRejectReason,
      name: MATOMO_NAME.Certification,
    });
  };
  if (status === CERTIFICATE_PROCESS_STATUS.REJECTED) {
    return <Tooltip
      className="rejected-tooltip"
      overlayClassName="label-tip"
      placement="bottomLeft"
      align={{ offset: [ -24, 0 ] }}
      title={CustomToolTipTitle(rejectReason)}
      getPopupContainer={triggerNode => triggerNode}
      arrowPointAtCenter>
      <Icon className="message-icon" component={MessageSvg} onMouseEnter={hoverMessageIcon}/>
    </Tooltip>;
  }
};

const statusComponent = (updateCertificateFinishData: () => void, record: ICertificateProcessStatusInfo) => {
  if (record.status === CERTIFICATE_PROCESS_STATUS.FINISH) {
    return <Badge className="finished-badge" color="#6B9E78" text="已完成"/>;
  }
  if (record.status === CERTIFICATE_PROCESS_STATUS.REJECTED) {
    return <Badge className="rejected-badge" color="#F2617A" text="已驳回，请修改后重新提交"/>;
  }
  return <Badge className="auditing-badge" color="#47A1AD" text="审核中"/>;
};

const getColumns = (updateCertificateFinishData: () => void, trackEvent: (params: TrackEventParams) => void) => {
  const columns: ColumnsType<ICertificateProcessStatusInfo> = [
    {
      title: '申请开具时间',
      width: '25%',
      dataIndex: 'createdAt',
      className: 'align-center',
    },
    {
      title: '证明类型',
      width: '20%',
      dataIndex: 'certificateType',
      className: 'align-center',
      render: (_, record) => {
        return <div>
          {CERTIFICATE_TYPE_OPTIONS.find(item => item.value === record.certificateType)?.label}
        </div>;
      }
    },
    {
      title: '证明管理',
      width: '55%',
      dataIndex: 'certificateManagement',
      className: 'last-td align-center',
      colSpan: 2,
      render: (_, record) => {
        return <div className="certificate-status">
          <div>
            {statusComponent(updateCertificateFinishData, record)}
            {toolTipComponent(record, trackEvent)}
          </div>
          <Button
            size="small"
            type="text"
            className="history-download-btn"
            icon={<Icon className="download-icon" component={DownloadSvg}/>}
            onClick={() => downloadCertificate(record, trackEvent)}
            disabled={record.status !== CERTIFICATE_PROCESS_STATUS.FINISH}
          >
            下载
          </Button>
        </div>;
      }
    },
  ];
  return columns;
};

const ApplicationHistory = () => {
  const [ certificateFinishData, setCertificateFinishData ] = useState<ICertificateProcessStatusInfo[]>([]);
  const [ total, setTotal ] = useState<number>(0);
  const { trackEvent } = useCustomMatomo();
  const location = useLocation();
  const userInfo = useSelector(selectUserInfo);
  const employeeId = userInfo?.user.employeeId;
  const [ employeeInfo, setEmployeeInfo ] = useState<IHireInfo>(null);
  const [ isModalOpen, setIsModalOpen ]  = useState<boolean>(location.state?.isModalOpen);

  const updateCertificateFinishData = () => {
    getCertificateProcessStatusInfo().then((res: ICertificateProcessStatusInfoResponse) => {
      setCertificateFinishData(res.data);
      setTotal(res.data.length);
    });
  };

  useEffect(() => {
    updateCertificateFinishData();
    getEmployeeHireInfo(employeeId).then(employeeInfo => setEmployeeInfo(employeeInfo));
  }, [ userInfo ]);

  return (employeeInfo &&
    <>
      <div className="main-page">
        <h3 className="headline3">开具证明历史列表</h3>
        <div className="container employee-certificate-list">
          <Table
            columns={getColumns(updateCertificateFinishData, trackEvent)}
            dataSource={certificateFinishData}
            rowKey="processId"
            pagination={{ defaultCurrent: 1, defaultPageSize: 20, total, showSizeChanger: false }}
            scroll={{ y: 'calc(100vh - 470px)' }}
            locale={{ emptyText: <Empty description={'暂无数据'}/> }}
          />
        </div>
        {isModalOpen && <NotificationModal text={modalText.onSubmit} onCancel={() => {
          setIsModalOpen(false);
        }}></NotificationModal>}
      </div>
    </>
  );
};

export default ApplicationHistory;
